import {
  FETCH_ADD_CARS,
  FETCH_DELETE_CARS,
  FETCH_GET_CSV_EXAMPLE
} from '../actions/blackwhitelistAction';

const initialState = {
  listCSV: {
    data: [],
    headers: [],
    title: ''
  },
  blackWhiteListAdd: null,
  blackWhiteListDelete: null
};

export default function(state = initialState, action) {
  switch (action.type) {
    case FETCH_ADD_CARS:
      return {
        ...state,
        blackWhiteListAdd: action.payload,
        blackWhiteListDeleted: false
      };
    case FETCH_DELETE_CARS:
      return {
        ...state,
        blackWhiteListDeleted: action.payload,
        blackWhiteListAdd: false,
        deleted: action.deleted
      };
    case FETCH_GET_CSV_EXAMPLE:
      return {
        ...state,
        listCSV: action.payload
      };
    default:
      return { ...state };
  }
}
