import { FormattedMessage } from 'react-intl';
import React, { useEffect, useState } from 'react';
import TextField from '../../../Inputs/TextField';
import CalendarTrigger from '../../../Calendar/CalendarTrigger';

const EditUser = ({
  value,
  add,
  edit,
  handleClick,
  handleChange,
  disabled,
  nbUser
}) => {
  const [tmpValue, setTmpValue] = useState(value);

  useEffect(() => {
    setTmpValue(value);
  }, [value]);

  const { email, startDate, endDate } = tmpValue;

  const handleInputChange = e => {
    const {
      target: { value }
    } = e;
    const event = { target: { value: { email: value, startDate, endDate } } };
    handleChange(event);
  };

  const handleCalendarChange = e => {
    const {
      target: {
        value: { start, end }
      }
    } = e;
    const event = {
      target: { value: { email: email, startDate: start, endDate: end } }
    };
    handleChange(event);
  };

  if (add || edit)
    return (
      <div className="customer">
        <div className="customer-content customer-edit">
          <div className="customer-edit-form">
            <TextField
              label={
                <FormattedMessage
                  id="app.mail-title"
                  defaultMessage="Adresse e-mail"
                />
              }
              type="email"
              id="email"
              name="email"
              value={email}
              handleChange={handleInputChange}
              required
            />
            <CalendarTrigger
              calendarBasedOnTop={nbUser > 4}
              startDate={startDate}
              endDate={endDate}
              handleMethod={handleCalendarChange}
            />
          </div>
          <button
            onClick={handleClick}
            className={`btn btn-primary btn-small ${
              !value.email.includes('@') && disabled ? 'btn-disable' : ''
            }`}
          >
            {edit ? (
              <FormattedMessage id="app.validate" defaultMessage="Valider" />
            ) : (
              <FormattedMessage id="app.add" defaultMessage="Ajouter" />
            )}
          </button>
        </div>
      </div>
    );
  else return null;
};

export default EditUser;
