import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import * as ROUTES from '../../constants/routes';
import { FormattedMessage } from 'react-intl';
import IllustrationCity from '../Design/illustration-city';

const pageBG = {
  background: `url( 'http://img.pandawhale.com/post-42510-IT-Crowd-Maurice-Moss-fire-gif-6zWo.gif' ) no-repeat center center fixed`,
  backgroundSize: 'cover'
};

export default class Error404 extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div className="container">
        <header className="header">
          <div className="header-left">
            <Link to={ROUTES.LANDING}>
              <span className="link">
                <i className="icon-arrow-left" />
                <span className="link-text">
                  <FormattedMessage id="app.navDsh" defaultMessage="Accueil" />
                </span>
              </span>
            </Link>
          </div>

          <div className="header-center">
            <img src="/img/logo.svg" alt="Logo sans texte Wattpark" />
          </div>
        </header>
        <div className="not-found">
          <div className="not-found-number">401</div>
          <div className="not-found-content">
            <IllustrationCity />
            <div className="not-found-text">
              Vous n'avez pas accès à cette page. Outre un joli plan de ville
              vous ne trouverez <br /> rien concernant WattPark ici.
            </div>
            <button
              className="btn btn-primary"
              onClick={() => this.props.history.push(ROUTES.DASHBOARD_PARKINGS)}
            >
              Retour à l'accueil
            </button>
          </div>
        </div>
      </div>
    );
  }
}
