import axios from 'axios';
import config from './../config/index';

import parkingService from './../services/parkingService';

const baseUrl = config.baseUrl;
export const FETCH_STATE_PARKING = 'FETCH_STATE_PARKING';
export const FETCH_GET_PARKINGS = 'FETCH_GET_PARKINGS';
export const FETCH_GET_PARKING_BY_ID = 'FETCH_GET_PARKING_BY_ID';
export const FETCH_CREATE_PARKINGS = 'FETCH_CREATE_PARKINGS;';
export const FETCH_DELETE_BOOKING = 'FETCH_DELETE_BOOKING';
export const FETCH_GET_PARKING_BOOKINGS = 'FETCH_GET_PARKING_BOOKINGS';
export const FETCH_GET_PARKING_IMAGES = 'FETCH_GET_PARKING_IMAGES';
export const FETCH_GET_PARKING_THUMB = 'FETCH_GET_PARKING_THUMB';
export const FETCH_EDIT_PARKING = 'FETCH_EDIT_PARKING';
export const FETCH_UPDATE_PARKING_MODE = 'FETCH_UPDATE_PARKING_MODE';
export const FETCH_GET_PARKING_SOLDE = 'FETCH_GET_PARKING_SOLDE';
export const FETCH_PARKING_SOLDE = 'FETCH_PARKING_SOLDE';
export const FETCH_STATION_STATUS = 'FETCH_STATION_STATUS';
export const FETCH_STATE_BOOKING = 'FETCH_STATE_BOOKING';
export const FETCH_CARS_PARKING = 'FETCH_CARS_PARKING';
export const REMOVE_PARKING_CAR = 'REMOVE_PARKING_CAR';
export const ADD_PARKING_LIST = 'ADD_PARKING_LIST';
export const FETCH_GET_LIST = 'FETCH_GET_LIST';
export const FETCH_ADD_USER_TO_LIST = 'FETCH_ADD_USER_TO_LIST';
export const FETCH_UPDATE_LIST = 'FETCH_UPDATE_LIST';
export const FETCH_CALENDAR = 'FETCH_CALENDAR';
export const FETCH_USER_REMINDER = 'FETCH_USER_REMINDER';
export const RECEIPT_LOADED = 'RECEIPT_LOADED';

export function fetchParkingsList() {
  const url = `${baseUrl}/api/user/${localStorage.getItem(
    'firebaseId'
  )}/parkings`;

  return dispatch => {
    axios
      .get(url, {
        headers: {
          'x-access-token': localStorage.getItem('token'),
          'content-type': 'application/json'
        }
      })
      .then(request => {
        if (request.data.length !== 0) {
          dispatch({
            type: FETCH_GET_PARKINGS,
            payload: request.data,
            loading: false
          });
        }
      })
      .catch(() => {
        dispatch({
          type: FETCH_GET_PARKINGS,
          payload: false,
          message: ''
        });
      });
  };
}

export function fetchAddCalendar(id, data) {
  return dispatch => {
    const url = `${baseUrl}/api/parkings/list/${id}/calendar`;
    axios
      .post(url, data, {
        headers: {
          'x-access-token': localStorage.getItem('token'),
          'content-type': 'application/json'
        }
      })
      .then(request => {
        if (request.data.length !== 0) {
          dispatch({
            type: FETCH_CALENDAR,
            payload: request.data
          });
        }
      })
      .catch(() => {
        dispatch({
          type: FETCH_CALENDAR,
          payload: false
        });
      });
  };
}

export function fetchUserReminder(parkingId, listId, data) {
  return dispatch => {
    const url = `${baseUrl}/api/parkings/${parkingId}/list/${listId}/user-reminder`;
    axios
      .post(url, data, {
        headers: {
          'x-access-token': localStorage.getItem('token'),
          'content-type': 'application/json'
        }
      })
      .then(request => {
        if (request.data.length !== 0) {
          dispatch({
            type: FETCH_USER_REMINDER,
            payload: request.data
          });
        }
      })
      .catch(() => {
        dispatch({
          type: FETCH_USER_REMINDER,
          payload: false
        });
      });
  };
}

export function fetchDeleteFromCalendar(id, slotId) {
  return dispatch => {
    const url = `${baseUrl}/api/parkings/list/${id}/calendar/${slotId}`;
    axios
      .delete(url, {
        headers: {
          'x-access-token': localStorage.getItem('token'),
          'content-type': 'application/json'
        }
      })
      .then(request => {
        if (request.data.length !== 0) {
          dispatch({
            type: FETCH_CALENDAR,
            payload: request.data
          });
        }
      })
      .catch(() => {
        dispatch({
          type: FETCH_CALENDAR,
          payload: false
        });
      });
  };
}

export function fetchDeleteUserFromList(listId, userId) {
  return dispatch => {
    const url = `${baseUrl}/api/list/${listId}/user/${userId}`;
    axios
      .delete(url, {
        headers: {
          'x-access-token': localStorage.getItem('token'),
          'content-type': 'application/json'
        }
      })
      .then(request => {
        if (request.data.length !== 0) {
          dispatch({
            type: FETCH_GET_LIST,
            payload: request.data
          });
        }
      })
      .catch(() => {
        dispatch({
          type: FETCH_GET_LIST,
          payload: false
        });
      });
  };
}

export function fetchCreateParking(data) {
  const url = `${baseUrl}/api/parkings`;
  return dispatch => {
    axios
      .post(url, data, {
        headers: {
          'x-access-token': localStorage.getItem('token'),
          'content-type': 'application/json'
        }
      })
      .then(request => {
        if (request.data.length !== 0) {
          const urlCharacteristics = `${baseUrl}/api/parking/${
            request.data['parking_id']
          }/characteristics?token=${localStorage.getItem('token')}`;
          const urlUploadImages = `${baseUrl}/api/upload/${
            request.data['parking_id']
          }/photo`;
          let photo = new Promise(function(resolve) {
            if (data['images'].length !== 0) {
              for (let i = 0; i < data['images'].length; i++) {
                if (data['images'][i] !== undefined) {
                  const formData = new FormData();
                  formData.append('file', data['images'][i]);
                  axios
                    .post(urlUploadImages, formData, {
                      headers: {
                        'x-access-token': localStorage.getItem('token'),
                        'content-type': 'multipart/form-data'
                      }
                    })
                    .then(request => {
                      if (request.data.name === data['defaultImage']) {
                        const urlDefaultImages = `${baseUrl}/api/parkings/${
                          request.data['parking_id']
                        }/photo/${
                          request.data.id
                        }/default?token=${localStorage.getItem('token')}`;
                        axios.post(urlDefaultImages);
                        resolve(true);
                      } else {
                        resolve(true);
                      }
                    })
                    .catch(() => {
                      resolve(false);
                    });
                } else {
                  resolve(true);
                }
              }
            } else {
              resolve(true);
            }
          });

          photo.then(() => {
            const urlAddWeek = `${baseUrl}/api/parkings/${
              request.data['parking_id']
            }/week`;
            axios.post(
              urlAddWeek,
              { name: 'Week 1' },
              {
                headers: {
                  'x-access-token': localStorage.getItem('token'),
                  'content-type': 'application/json'
                }
              }
            );

            if (data['file']) {
              const urlUploadFile = `${baseUrl}/api/upload/parking/${
                request.data['parking_id']
              }/documents`;
              const formData = new FormData();
              formData.append('addressProof', data['file']);
              axios.post(urlUploadFile, formData, {
                headers: {
                  'x-access-token': localStorage.getItem('token'),
                  'content-type': 'multipart/form-data'
                }
              });
            }
            if (data['characteristics']) {
              const characteristic = {
                characteristics: data['characteristics']
              };
              axios.post(urlCharacteristics, characteristic);
            }
            data.parkingId = request.data['parking_id'];
            dispatch({
              type: FETCH_CREATE_PARKINGS,
              payload: true,
              loading: false,
              data: data
            });
          });
        }
      })
      .catch(() => {
        dispatch({
          type: FETCH_CREATE_PARKINGS,
          payload: false,
          loading: true
        });
      });
  };
}

export function fetchEditParking(id, data) {
  const url = `${baseUrl}/api/parkings/${id}`;
  return dispatch => {
    axios.put(url, data, {
      headers: {
        'x-access-token': localStorage.getItem('token'),
        'content-type': 'application/json'
      }
    });

    const urlCharacteristics = `${baseUrl}/api/parking/${id}/characteristics?token=${localStorage.getItem(
      'token'
    )}`;

    const urlUploadImages = `${baseUrl}/api/upload/${id}/photo`;

    let photo = new Promise(function(resolve) {
      if (data['images'].length !== 0) {
        for (let i = 0; i < data['images'].length; i++) {
          if (data['images'][i] !== undefined) {
            if (data['images'][i].value === 0) {
              const formData = new FormData();
              formData.append('file', data['images'][i]);
              axios
                .post(urlUploadImages, formData, {
                  headers: {
                    'x-access-token': localStorage.getItem('token'),
                    'content-type': 'multipart/form-data'
                  }
                })
                .then(request => {
                  if (request.data.name === data['defaultImage']) {
                    const urlDefaultImages = `${baseUrl}/api/parkings/${id}/photo/${
                      request.data.id
                    }/default`;
                    axios
                      .post(urlDefaultImages, formData, {
                        headers: {
                          'x-access-token': localStorage.getItem('token'),
                          'content-type': 'multipart/form-data'
                        }
                      })
                      .then(() => {
                        resolve(true);
                      });
                  } else {
                    resolve(false);
                  }
                })
                .catch(() => {
                  resolve(false);
                });
            }
          }
        }
      } else {
        resolve(true);
      }
    });

    if (!isNaN(data['checked'])) {
      const urlDefaultImages = `${baseUrl}/api/parkings/${id}/photo/${
        data['checked']
      }/default?token=${localStorage.getItem('token')}`;
      axios.post(urlDefaultImages);
    }

    if (data['file']) {
      const urlUploadFile = `${baseUrl}/api/upload/parking/${id}/documents`;
      const formData = new FormData();
      formData.append('addressProof', data['file']);
      axios.post(urlUploadFile, formData, {
        headers: {
          'x-access-token': localStorage.getItem('token'),
          'content-type': 'multipart/form-data'
        }
      });
    }
    if (data['imageDeleted'].length !== 0) {
      const urlDeletePhoto = `${baseUrl}/api/parkings/${id}/photo/delete?token=${localStorage.getItem(
        'token'
      )}`;
      axios.post(urlDeletePhoto, data['imageDeleted']);
    }

    if (data['characteristics'].length > 0) {
      const characteristic = { characteristics: data['characteristics'] };
      axios.put(urlCharacteristics, characteristic);
    }
    photo.then(() => {
      const urlGetParking = `${baseUrl}/api/parkings/${id}`;
      const urlThumbnail = `${baseUrl}/api/parkings/${id}/thumbnail/l`;
      const urlCharacteristics = `${baseUrl}/api/parkings/${id}/characteristics`;
      axios
        .get(urlGetParking, {
          headers: {
            'x-access-token': localStorage.getItem('token'),
            'content-type': 'application/json'
          }
        })
        .then(resultParking => {
          axios
            .get(urlThumbnail, {
              headers: {
                'x-access-token': localStorage.getItem('token'),
                'content-type': 'application/json'
              }
            })
            .catch(error => {})
            .then(resultThumbnail => {
              axios
                .get(urlCharacteristics, {
                  headers: {
                    'x-access-token': localStorage.getItem('token'),
                    'content-type': 'application/json'
                  }
                })
                .then(resultCharacteristics => {
                  const array = [];
                  array.push(resultParking.data);
                  let Characteristics = null;
                  if (resultCharacteristics)
                    Characteristics = resultCharacteristics.data;
                  dispatch({
                    type: FETCH_EDIT_PARKING,
                    payload: {
                      parking: array,
                      thumbnail: resultThumbnail ? resultThumbnail.data : {},
                      characteristics: Characteristics
                    },
                    loading: false
                  });
                });
            });
        });
    });
  };
}

export function fetchPatchParking(parkingId, parkingDatas) {
  const url = `${baseUrl}/api/parkings/${parkingId}`;

  return new Promise((resolve, reject) => {
    axios
      .patch(url, parkingDatas, {
        headers: {
          'x-access-token': localStorage.getItem('token'),
          'content-type': 'application/json'
        }
      })
      .then(result => {
        resolve(result);
      })
      .catch(error => {
        reject(error);
      });
  });
}

export function fetchGetParkingById(id, notDynamicStatus) {
  const url = `${baseUrl}/api/parkings/${id}?size=m${
    notDynamicStatus ? '&notDynamicStatus=true' : false
  }`;
  return dispatch => {
    axios
      .get(url, {
        headers: {
          'x-access-token': localStorage.getItem('token'),
          'content-type': 'application/json'
        }
      })
      .then(requestParkings => {
        const array = [];
        if (requestParkings.data.length !== 0) {
          const urlBookings = `${baseUrl}/api/parkings/${id}/bookings`;
          axios
            .get(urlBookings, {
              headers: {
                'x-access-token': localStorage.getItem('token'),
                'content-type': 'application/json'
              }
            })
            .then(requestBooking => {
              if (requestBooking) {
                requestParkings.data.bookings = requestBooking.data;
              }
              array.push(requestParkings.data);
              dispatch({
                type: FETCH_GET_PARKING_BY_ID,
                payload: array,
                isValid: true
              });
            });
        } else {
          dispatch({
            type: FETCH_GET_PARKING_BY_ID,
            payload: false
          });
        }
      })
      .catch(() => {
        dispatch({
          type: FETCH_GET_PARKING_BY_ID,
          payload: false,
          message: ''
        });
      });
  };
}

export function fetchGetParkingImage(id) {
  const url = `${baseUrl}/api/parkings/${id}/photo`;

  return dispatch => {
    axios
      .get(url, {
        headers: {
          'x-access-token': localStorage.getItem('token'),
          'content-type': 'application/json'
        }
      })
      .then(request => {
        if (request.data.length !== 0) {
          dispatch({
            type: FETCH_GET_PARKING_IMAGES,
            payload: request
          });
        } else {
          dispatch({
            type: FETCH_GET_PARKING_IMAGES,
            payload: false
          });
        }
      })
      .catch(() => {
        dispatch({
          type: FETCH_GET_PARKING_IMAGES,
          payload: false,
          message: 'No Images'
        });
      });
  };
}

export function fetchGetParkingThumbanil(id) {
  const urlThumbnail = `${baseUrl}/api/parkings/${id}/thumbnail/m`;

  return dispatch => {
    axios
      .get(urlThumbnail, {
        headers: {
          'x-access-token': localStorage.getItem('token'),
          'content-type': 'application/json'
        }
      })
      .then(request => {
        if (request.data.length !== 0) {
          dispatch({
            type: FETCH_GET_PARKING_THUMB,
            payload: request.data['url'],
            isValid: true
          });
        } else {
          dispatch({
            type: FETCH_GET_PARKING_THUMB,
            payload: false
          });
        }
      })
      .catch(() => {
        dispatch({
          type: FETCH_GET_PARKING_THUMB,
          payload: false
        });
      });
  };
}

export function fetchUpdateParkingStatus(id, data) {
  const urlModeParking = `${baseUrl}/api/parkings/${id}/status?token=${localStorage.getItem(
    'token'
  )}`;

  return dispatch => {
    axios
      .post(urlModeParking, data)
      .then(request => {
        if (request.data.length !== 0) {
          dispatch({
            type: FETCH_UPDATE_PARKING_MODE,
            payload: { data: request.data, valid: true }
          });
        } else {
          dispatch({
            type: FETCH_UPDATE_PARKING_MODE,
            payload: false
          });
        }
      })
      .catch(() => {
        dispatch({
          type: FETCH_UPDATE_PARKING_MODE,
          payload: false
        });
      });
  };
}

export function fetchGetParkingSolde() {
  const urlSoldeParking = `${baseUrl}/api/parkings/bookings/solde?token=${localStorage.getItem(
    'token'
  )}`;

  return dispatch => {
    axios
      .get(urlSoldeParking)
      .then(request => {
        if (request.data.length !== 0) {
          dispatch({
            type: FETCH_GET_PARKING_SOLDE,
            payload: request.data
          });
        } else {
          dispatch({
            type: FETCH_GET_PARKING_SOLDE,
            payload: false
          });
        }
      })
      .catch(() => {
        dispatch({
          type: FETCH_GET_PARKING_SOLDE,
          payload: false
        });
      });
  };
}

export function fetchParkingSolde(id) {
  const urlSoldeParking = `${baseUrl}/api/parkings/${id}/bookings/solde?token=${localStorage.getItem(
    'token'
  )}`;

  return dispatch => {
    axios
      .get(urlSoldeParking)
      .then(request => {
        if (request.data.length !== 0) {
          dispatch({
            type: FETCH_PARKING_SOLDE,
            payload: request.data
          });
        } else {
          dispatch({
            type: FETCH_PARKING_SOLDE,
            payload: false
          });
        }
      })
      .catch(() => {
        dispatch({
          type: FETCH_PARKING_SOLDE,
          payload: false
        });
      });
  };
}

export function fetchGetStatusStation() {
  const urlSoldeParking = `${baseUrl}/api/station/status?token=${localStorage.getItem(
    'token'
  )}`;

  return dispatch => {
    axios
      .get(urlSoldeParking)
      .then(request => {
        if (request.data.length !== 0) {
          dispatch({
            type: FETCH_STATION_STATUS,
            payload: request.data
          });
        } else {
          dispatch({
            type: FETCH_STATION_STATUS,
            payload: false
          });
        }
      })
      .catch(() => {
        dispatch({
          type: FETCH_STATION_STATUS,
          payload: false
        });
      });
  };
}

export function fetchGetList(listId) {
  const urlList = `${baseUrl}/api/parkings/list/${listId}?token=${localStorage.getItem(
    'token'
  )}`;

  return dispatch => {
    axios
      .get(urlList)
      .then(request => {
        if (request.data.length !== 0) {
          dispatch({
            type: FETCH_GET_LIST,
            payload: request.data
          });
        } else {
          dispatch({
            type: FETCH_GET_LIST,
            payload: false
          });
        }
      })
      .catch(() => {
        dispatch({
          type: FETCH_GET_LIST,
          payload: false
        });
      });
  };
}

export function fetchAddUserToList(listId, parkingId, data) {
  const urlList = `${baseUrl}/api/parkings/${parkingId}/list/${listId}?token=${localStorage.getItem(
    'token'
  )}`;

  return dispatch => {
    axios
      .post(urlList, data)
      .then(request => {
        if (request.data.length !== 0) {
          dispatch({
            type: FETCH_ADD_USER_TO_LIST,
            payload: request.data
          });
        } else {
          dispatch({
            type: FETCH_ADD_USER_TO_LIST,
            payload: false
          });
        }
      })
      .catch(() => {
        dispatch({
          type: FETCH_ADD_USER_TO_LIST,
          payload: false
        });
      });
  };
}

export function importUserCSV(listId, data) {
  const url = `${baseUrl}/api/list/${listId}/user/import`;

  return async dispatch => {
    dispatch({
      type: RECEIPT_LOADED,
      payload: { loading: true, success: false, error: false }
    });
    await axios
      .post(url, data, {
        headers: {
          'x-access-token': localStorage.getItem('token'),
          'Content-Type': 'multipart/form-data'
        }
      })
      .then(request => {
        dispatch({
          type: RECEIPT_LOADED,
          payload: { loading: false, success: true, error: false }
        });

        if (request.data.length !== 0) {
          dispatch({
            type: FETCH_GET_LIST,
            payload: request.data
          });
        } else {
          dispatch({
            type: FETCH_GET_LIST,
            payload: false
          });
        }
      })
      .catch(() => {
        dispatch({
          type: RECEIPT_LOADED,
          payload: { loading: false, success: false, error: true }
        });

        dispatch({
          type: FETCH_GET_LIST,
          payload: false
        });
      });

    setTimeout(() => {
      dispatch({
        type: RECEIPT_LOADED,
        payload: { loading: false, success: false, error: false }
      });
    }, 5000);
  };
}

export function fetchDeleteBooking(id, userId) {
  const url = `${baseUrl}/api/user/${userId}/bookings/${id}?token=${localStorage.getItem(
    'token'
  )}`;

  return dispatch => {
    axios
      .delete(url)
      .then(request => {
        if (request.data.length !== 0) {
          dispatch({
            type: FETCH_DELETE_BOOKING,
            payload: true
          });
        } else {
          dispatch({
            type: FETCH_DELETE_BOOKING,
            payload: false
          });
        }
      })
      .catch(() => {
        dispatch({
          type: FETCH_DELETE_BOOKING,
          payload: false
        });
      });
  };
}

export function fetchInitialState() {
  return dispatch => {
    dispatch({
      type: FETCH_STATE_PARKING,
      payload: true
    });
  };
}

export function fetchInitialStateBooking() {
  return dispatch => {
    dispatch({
      type: FETCH_STATE_BOOKING,
      payload: true
    });
  };
}

export function fetchParkingCars(parkingId) {
  return {
    type: FETCH_CARS_PARKING,
    payload: parkingService.getParkingCars(parkingId)
  };
}

export function fetchUpdateList(parkingId, listId, data) {
  const urlList = `${baseUrl}/api/parkings/${parkingId}/list/${listId}?token=${localStorage.getItem(
    'token'
  )}`;

  return dispatch => {
    axios
      .put(urlList, data)
      .then(request => {
        if (request.data.length !== 0) {
          dispatch({
            type: FETCH_UPDATE_LIST,
            payload: request.data
          });
        } else {
          dispatch({
            type: FETCH_UPDATE_LIST,
            payload: false
          });
        }
      })
      .catch(() => {
        dispatch({
          type: FETCH_UPDATE_LIST,
          payload: false
        });
      });
  };
}

export function fetchUpdateUser(listId, userId, data) {
  const url = `${baseUrl}/api/list/${listId}/user/${userId}?token=${localStorage.getItem(
    'token'
  )}`;

  return dispatch => {
    axios
      .put(url, data)
      .then(request => {
        if (request.data.length !== 0) {
          dispatch({
            type: FETCH_GET_LIST,
            payload: request.data
          });
        } else {
          dispatch({
            type: FETCH_GET_LIST,
            payload: false
          });
        }
      })
      .catch(() => {
        dispatch({
          type: FETCH_GET_LIST,
          payload: false
        });
      });
  };
}

export function removeCarFromParking(parkingId, carId) {
  return dispatch => {
    dispatch({
      type: REMOVE_PARKING_CAR,
      payload: parkingService.removeCarFromParking(parkingId, carId)
    }).then(() => {
      dispatch(fetchParkingCars(parkingId));
    });
  };
}

export function addListToParking(parkingId, data) {
  return dispatch => {
    dispatch({
      type: ADD_PARKING_LIST,
      payload: parkingService.addListToParking(parkingId, data)
    })
      .then(() => {
        dispatch(fetchParkingCars(parkingId));
      })
      .catch(() => {});
  };
}
