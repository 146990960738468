import { FETCH_GET_USER_LICENCE } from '../actions/userLicenceAction';

export default function(state = [], action) {
  switch (action.type) {
    case FETCH_GET_USER_LICENCE:
      return {
        ...state,
        userLicence: action.payload,
        loading: true
      };
    default:
      return { ...state };
  }
}
