import React, { Component } from 'react';
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl';
import { menu } from '../../utils';

class Menu extends Component {
  componentDidMount() {
    menu.init();
  }

  render() {
    const { type, tab, onClickMenu } = this.props;
    return (
      <div className="menu">
        <div className="menu-scrollable">
          <div className="menu-scrollable-content">
            <a
              href="#"
              className={`menu-item ${
                tab === 'info' || tab === '' ? 'current' : ''
              }`}
              data-target="#view1"
              onClick={() => {
                onClickMenu('info');
              }}
            >
              <FormattedMessage
                id="app.user-my-information"
                defaultMessage="Mes informations"
              />
              {type ? <i className="icon-warning" /> : ''}
            </a>
            <a
              href="#"
              className="menu-item"
              data-target="#view2"
              onClick={() => {
                onClickMenu('mony');
              }}
            >
              <FormattedMessage
                id="app.user-balance"
                defaultMessage="Mon solde WATTPARK"
              />
            </a>
            <a
              href="#"
              className={`menu-item ${tab === `bank` ? `current` : ``}`}
              data-target="#view3"
              onClick={() => {
                onClickMenu('bank');
              }}
            >
              <FormattedMessage
                id="app.user-my-bank-details"
                defaultMessage="Mes coordonnées bancaires"
              />
            </a>
            <a
              href="#"
              className="menu-item"
              data-target="#view4"
              onClick={() => {
                onClickMenu('contact');
              }}
            >
              WATTPARK Contact
            </a>
            <a
              href="#"
              className="menu-item"
              data-target="#view5"
              onClick={() => {
                onClickMenu('faq');
              }}
            >
              FAQ
            </a>
            <a
              href="#"
              className="menu-item"
              data-target="#view6"
              onClick={() => {
                onClickMenu('cgv');
              }}
            >
              CGV
            </a>
          </div>
          <div className="menu-indicator"></div>
        </div>
      </div>
    );
  }
}

export default Menu;
