import { FETCH_LOGIN, FETCH_RESET_PASSWORD } from '../actions/loginAction';
import { now } from 'moment';

export default function(state = [], action) {
  switch (action.type) {
    case FETCH_LOGIN:
      return {
        ...state,
        isAuthenticated: action.isAuth,
        user: action.user
      };
    case FETCH_RESET_PASSWORD:
      return {
        ...state,
        ResetPassword: action.payload,
        time: now()
      };
    default:
      return state;
  }
}
