import config from './../config/index';
import axios from 'axios';

const baseUrl = config.baseUrl;
export const FETCH_GET_PARTNERS = 'FETCH_GET_PARTNERS';

export function fetchGetPartners() {
  const url = `${baseUrl}/api/partners?`;
  return dispatch => {
    axios
      .get(url, {
        headers: {
          'x-access-token': localStorage.getItem('token'),
          'content-type': 'application/json'
        }
      })
      .then(request => {
        if (request.data.length !== 0) {
          if (request.data.length !== 0) {
            dispatch({
              type: FETCH_GET_PARTNERS,
              payload: request.data
            });
          }
        }
      })
      .catch(err => {
        let msg = '';
        if (err.response.data.message) {
          msg = err.response.data.message;
        }
        dispatch({
          type: FETCH_GET_PARTNERS,
          payload: false,
          message: msg
        });
      });
  };
}
