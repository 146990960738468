import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Alert from './../Layouts/Alert/alert';
import * as ROUTES from '../../constants/routes';
import { connect } from 'react-redux';
import { fetchLogin } from '../../actions/loginAction';
import IllustrationCity from './../Design/illustration-city';
import { FormattedMessage } from 'react-intl';
import { DocumentTitle } from '../../utils';

//RFC 2822
const emailTest = RegExp(
  /^(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])$/
);

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      viewPassword: false,
      email: '',
      password: '',
      auth: false,
      alert: false,
      style: 'none',
      message: '',
      user: '',
      nameAlert: '',
      display: 'none',
      isLoading: false,
      typeAlert: '',
      formErros: {
        email: '',
        password: ''
      }
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleClick = this.handleClick.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.auth === true) {
      this.props.history.push(ROUTES.DASHBOARD_PARKINGS);
    } else {
      this.setState({
        alert: true,
        message: nextProps.user,
        typeAlert: 'alerts alerts-warning'
      });
    }
  }

  handleClick(event) {
    this.setState({ viewPassword: !this.state.viewPassword });
  }

  handleSubmit(event) {
    event.preventDefault();
    let values = {
      email: this.state.email,
      password: this.state.password
    };
    this.props.fetchLogin(values);
  }

  handleChange(event) {
    const target = event.target;
    const { name, value } = target;

    let formErrors = this.state.formErros;
    switch (name) {
      case 'email':
        formErrors.email = emailTest.test(value)
          ? ''
          : 'Adresse email invalide';
        break;
      case 'password':
        formErrors.password =
          value.length < 6 && value.length > 0 ? 'Mot de passe invalide' : '';
        break;
      default:
        break;
    }
    this.setState({
      formErrors,
      [name]: value
    });
  }

  render() {
    const formErrors = this.state.formErros;
    ///Validator Email
    let classEmail = 'form-group';
    let classEmail1 = 'form-input';
    let lengthEmail = 0;
    if (this.state.email) {
      classEmail1 = 'form-input on-focus';
    }
    if (formErrors) {
      if (formErrors.email.length > 0) {
        lengthEmail = formErrors.email.length;
        classEmail = 'form-group on-focus has-error has-feedback';
        classEmail1 = 'form-input on-focus has-error has-feedback';
      } else {
        classEmail1 = 'form-input on-focus';
      }
    }
    ///Validator Password
    let classPassword = 'form-group';
    let classPassword1 = `form-input ${
      this.state.viewPassword ? 'show-password' : ''
    }`;
    let lengthPassword = 0;
    if (this.state.password) {
      classPassword1 = `form-input on-focus ${
        this.state.viewPassword ? 'show-password' : ''
      }`;
    }
    if (formErrors) {
      if (formErrors.password.length > 0) {
        lengthPassword = formErrors.password.length;
        classPassword = 'form-group on-focus has-error has-feedback';
        classPassword1 = `form-input on-focus has-error has-feedback ${
          this.state.viewPassword ? 'show-password' : ''
        }`;
      } else {
        classPassword1 = `form-input on-focus ${
          this.state.viewPassword ? 'show-password' : ''
        }`;
      }
    }
    const enabled =
      formErrors.email.length > 0 ||
      this.state.email === '' ||
      formErrors.password.length > 0 ||
      this.state.password === '';

    const { isLoading } = this.state.isLoading;
    return (
      <div className="main-container login">
        {DocumentTitle('app.title-login', 'WATTPARK - Connexion')}
        <header className="header header-login">
          <div className="header-left" />
          <div className="header-center">
            <h1 className="logo-main">
              <Link to={ROUTES.LANDING}>
                <img src="/img/logo.svg" alt="Logo Wattpark" />
              </Link>
            </h1>
          </div>
          <div className="header-right">
            <p className="text-info">
              <FormattedMessage
                id="app.no-account-yet"
                defaultMessage="Pas encore de compte ?"
              />
            </p>
            <Link to={ROUTES.SIGN_UP}>
              <span className="btn btn-primary">
                <FormattedMessage
                  id="app.create-an-account"
                  defaultMessage="créer un compte"
                />
              </span>
            </Link>
          </div>
        </header>
        <div className="alerts-wrapper ">
          {this.state.alert ? (
            <Alert
              nameAlert={this.state.nameAlert}
              type={this.state.typeAlert}
              style={false}
              message={
                this.state.message && this.state.message.length > 0 ? (
                  this.state.message
                ) : (
                  <FormattedMessage
                    id={'app.msg-login-fail'}
                    defaultMessage={'Connexion impossible'}
                  />
                )
              }
            />
          ) : null}
        </div>
        <div className="form-block-wrapper">
          <form onSubmit={this.handleSubmit}>
            <div className="form-block">
              <h3 className="form-block-title">
                <FormattedMessage
                  id="app.to-log-in"
                  defaultMessage="se connecter"
                />
              </h3>

              <div className={classEmail}>
                <div className={classEmail1}>
                  <input
                    className="form-input-field has-error"
                    type="email"
                    name="email"
                    id="inputEmail"
                    required
                    value={this.state.email}
                    onChange={this.handleChange}
                  />
                  <label className="form-input-label" htmlFor="inputEmail">
                    <span className="form-input-label-content">Email</span>
                  </label>
                </div>
                {lengthEmail > 0 && (
                  <span className="help-block">{formErrors.email}</span>
                )}
              </div>
              <div className={classPassword}>
                <div className={classPassword1}>
                  <input
                    className="form-input-field"
                    type={this.state.viewPassword ? 'text' : 'password'}
                    name="password"
                    id="inputPswd"
                    required
                    value={this.state.password}
                    onChange={this.handleChange}
                  />
                  <label className="form-input-label" htmlFor="inputPswd">
                    <span className="form-input-label-content">
                      <FormattedMessage
                        id="app.password"
                        defaultMessage="Mot de passe"
                      />
                    </span>
                  </label>
                  <i className="icon-eye" onClick={this.handleClick} />
                </div>
                {lengthPassword > 0 && (
                  <span className="help-block">{formErrors.password}</span>
                )}
              </div>
              <div className="form-block-action">
                <button
                  disabled={enabled}
                  type="submit"
                  className={
                    enabled
                      ? 'btn btn-primary btn-fixed btn-disable'
                      : 'btn btn-primary btn-fixed'
                  }
                >
                  {isLoading ? (
                    <FormattedMessage
                      id="app.loading"
                      defaultMessage="Chargement..."
                    />
                  ) : (
                    <FormattedMessage
                      id="app.btnCreateParks"
                      defaultMessage="Valider"
                    />
                  )}
                </button>
                <Link to={ROUTES.PASSWORD_FORGET} href="" className="link">
                  Mot de passe oublié ?
                </Link>
              </div>
            </div>
          </form>
          <Link to={ROUTES.SIGN_UP}>
            <span className="btn btn-third btn-fixed hidden-md hidden-lg">
              <FormattedMessage
                id="app.create-an-account"
                defaultMessage="créer un compte"
              />
            </span>
          </Link>
        </div>

        <IllustrationCity />
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    auth: state.loginUser.isAuthenticated,
    user: state.loginUser.user
  };
}

export default connect(
  mapStateToProps,
  { fetchLogin }
)(Login);
