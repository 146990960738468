import { combineReducers } from 'redux';
import { routerReducer as routing } from 'react-router-redux';
import { reducer as formReducer } from 'redux-form';

import loginUser from './loginReducer';
import register from './signupReducer';
import parkings from './parkingReducer';
import slots from './hoursReducer';
import partners from './partnersReducer';
import blackwhitelist from './blackwhitelistReducer';
import issues from './issueReducer';
import files from './filesReducer';
import users from './userReducer';
import characteristics from './characteristicsReducer';
import typologies from './typologiesReducer';
import bankDetails from './lemonWayReducer';
import station from './stationReducer';
import parkingCount from './parkingCountReducer';
import userLicence from './userLicenceReducer';
import modal from './modalReducer';
import hoursContract from './hoursContractReducer';

const appReducer = combineReducers({
  loginUser,
  register,
  parkings,
  slots,
  partners,
  blackwhitelist,
  issues,
  files,
  characteristics,
  bankDetails,
  station,
  parkingCount,
  users,
  typologies,
  formReducer,
  routing,
  modal,
  userLicence,
  hoursContract
});

const rootReducer = (state, action) => {
  return appReducer(state, action);
};

export default rootReducer;
