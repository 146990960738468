import React from 'react';
import { FormattedMessage } from 'react-intl';
import Item from './item';
import TextField from '../../../Inputs/TextField';

const ListElement = ({ list, handleClick }) => {
  return (
    <div className="users-list-item" onClick={handleClick}>
      <div className="users-list-item-content">
        <div className="users-list-item-name">
          <div className="users-list-item-title">
            <FormattedMessage
              id="app.list-title"
              defaultMessage="Nom de la liste"
            />
          </div>
          <div className="users-list-item-value">{list.name}</div>
        </div>
        <div className="users-list-item-number">
          <div className="users-list-item-title">
            <FormattedMessage
              id="app.members-title"
              defaultMessage="Nombre de membres"
            />
          </div>
          <div className="users-list-item-value">{list.members.length}</div>
        </div>
      </div>
      <i className="icon-chevron" />
    </div>
  );
};

export default ListElement;
