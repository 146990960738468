import React, { Component } from 'react';
import Question from '../../Layouts/Forms/question';
import { listParking } from '../../../utils/index';
import faqQuestions from '../../../content/faq';

class Faq extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.questions = faqQuestions;
  }

  componentDidMount() {
    listParking();
  }

  render() {
    return (
      <div id="view5" className="view">
        <div className="form-block">
          <h3 className="form-block-title ">Questions fréquentes</h3>
          {this.questions.map(question => (
            <Question
              key={`faq-question-${question.id}`}
              questionKey={`faq-question-${question.id}`}
              question={question}
            />
          ))}
        </div>
      </div>
    );
  }
}

export default Faq;
