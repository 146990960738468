import {
  FETCH_GET_CHARACTERISTIC,
  FETCH_GET_CHARACTERISTIC_PARKING
} from '../actions/characteristicsAction';

export default function(state = [], action) {
  switch (action.type) {
    case FETCH_GET_CHARACTERISTIC:
      return {
        ...state,
        characteristic: action.payload,
        loading: true,
        message: action.message
      };
    case FETCH_GET_CHARACTERISTIC_PARKING:
      return {
        ...state,
        characteristicParking: action.payload,
        loading: true,
        message: action.message,
        characteristicPark: action.isValid
      };
    default:
      return state;
  }
}
