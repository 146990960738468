import config from './../config/index';
import axios from 'axios';

export const TYPES = {
  FETCH_GET_HOURS_CONTRACT_PARKING: 'FETCH_GET_HOURS_CONTRACT_PARKING',
  FETCH_UPDATE_HOURS_CONTRACT_PARKING: 'FETCH_UPDATE_HOURS_CONTRACT_PARKING',
  FETCH_ADD_HOURS_CONTRACT_SLOT: 'FETCH_ADD_HOURS_CONTRACT_SLOT',
  FETCH_UPDATE_HOURS_CONTRACT_SLOT: 'FETCH_UPDATE_HOURS_CONTRACT_SLOT',
  FETCH_DELETE_HOURS_CONTRACT_SLOT: 'FETCH_DELETE_HOURS_CONTRACT_SLOT',
  FETCH_ERRORS_HOURS_CONTRACT_SLOT: 'FETCH_ERRORS_HOURS_CONTRACT_SLOT',
  FETCH_CLEAR_MESSAGE: 'FETCH_CLEAR_MESSAGE'
};

const baseUrl = config.baseUrl;

const getHeaders = () => {
  return {
    headers: {
      'x-access-token': localStorage.getItem('token'),
      'content-type': 'application/json'
    }
  }
}

export function fetchGetParkingHoursContract(parkingId) {
  const url = `${baseUrl}/api/parking/${parkingId}/hours-contract`;

  return dispatch => {
    axios
      .get(url, getHeaders())
      .then(request => {
        if (request.data.length !== 0) {
          dispatch({
            type: TYPES.FETCH_GET_HOURS_CONTRACT_PARKING,
            payload: request.data
          });
        }
      })
      .catch(() => {
        dispatch({
          type: TYPES.FETCH_GET_HOURS_CONTRACT_PARKING,
          payload: false,
          message: 'No hours contract'
        });
      });
  };
}

export function fetchUpdateParkingHoursContract(id, data) {
  const url = `${baseUrl}/api/hours-contracts/${id}`;
  return dispatch => {
    axios
      .put(url, data, getHeaders())
      .then(request => {
        if (request.data.length !== 0) {
          dispatch({
            type: TYPES.FETCH_UPDATE_HOURS_CONTRACT_PARKING,
            payload: request.data
          });
        }
      })
      .catch(() => {
        dispatch({
          type: TYPES.FETCH_UPDATE_HOURS_CONTRACT_PARKING,
          payload: false,
          message: 'Hours contract not updated'
        });
      });
  };
}

export function fetchAddHoursContractSlot(data) {
  const url = `${baseUrl}/api/hours-contract-slots`;

  return dispatch => {
    axios
      .post(url, data, getHeaders())
      .then(request => {
        if (request.data.length !== 0) {
          dispatch({
            type: TYPES.FETCH_ADD_HOURS_CONTRACT_SLOT,
            payload: request.data
          });
        }
      })
      .catch(error => {
        dispatch({
          type: TYPES.FETCH_ERRORS_HOURS_CONTRACT_SLOT,
          code: error.data.code
        });
      });
  };
}

export function fetchUpdateHoursContractSlot(id, data) {
  const url = `${baseUrl}/api/hours-contract-slots/${id}`;

  return dispatch => {
    axios
      .put(url, data, getHeaders())
      .then(request => {
        if (request.data.length !== 0) {
          dispatch({
            type: TYPES.FETCH_UPDATE_HOURS_CONTRACT_SLOT,
            payload: request.data
          });
        }
      })
      .catch(error => {
        dispatch({
          type: TYPES.FETCH_ERRORS_HOURS_CONTRACT_SLOT,
          code: error.data.code
        });
      });
  };
}

export function fetchDeleteHoursContractSlot(id) {
  const url = `${baseUrl}/api/hours-contract-slots/${id}`;

  return dispatch => {
    axios
      .delete(url, getHeaders())
      .then(request => {
        if (request.data.length !== 0) {
          dispatch({
            type: TYPES.FETCH_DELETE_HOURS_CONTRACT_SLOT,
            payload: request.data
          });
        }
      })
      .catch(error => {
        dispatch({
          type: TYPES.FETCH_ERRORS_HOURS_CONTRACT_SLOT,
          code: error.data.code
        });
      });
  };
}

export function fetchClearMessage() {
  return dispatch => {
    dispatch({
      type: TYPES.FETCH_CLEAR_MESSAGE
    });
  };
}
