import React, { Component } from 'react';
import ElementChar from './elementCharacteristic';
import { fetchGetCharacteristics } from './../../../actions/characteristicsAction';
import { connect } from 'react-redux';
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl';

class Characteristics extends Component {
  componentDidMount() {
    this.props.fetchGetCharacteristics();
  }

  render() {
    let characteristics = [];
    if (this.props.characteristics) {
      characteristics = this.props.characteristics;
    }

    let classheightMax = 'form-group';
    let classheightMax1 = 'form-input';
    let lengthheightMax1 = 0;
    if (this.props.isHeightMax) {
      classheightMax1 = 'form-input on-focus';
    }
    if (this.props.error) {
      if (this.props.error.heightMax.length > 0) {
        lengthheightMax1 = this.props.error.heightMax.length;
        classheightMax = 'form-group on-focus has-error has-feedback';
        classheightMax1 = 'form-input on-focus has-error has-feedback';
      }
    }
    return (
      <div className="form-block">
        <h3 className="form-block-title">
          <FormattedMessage
            id="app.parking-specificities-of-the-place"
            defaultMessage="spécificités de la place"
          />
        </h3>
        <div className="row">
          {characteristics.map(characteristic => {
            let value = false;
            if (this.props.characteristicsParking) {
              for (
                let i = 0;
                i < this.props.characteristicsParking.length;
                i++
              ) {
                if (
                  characteristic.id ===
                  this.props.characteristicsParking[i].characteristics_id
                ) {
                  value = true;
                }
              }

              return (
                <ElementChar
                  key={characteristic.id}
                  id={characteristic.id}
                  name={characteristic.characteristicName}
                  characteristicsParking={this.props.characteristicsParking}
                  icon={characteristic.icon}
                  value={value}
                  parkingId={this.props.parkingId}
                  method={this.props.method}
                />
              );
            } else {
              return (
                <ElementChar
                  key={characteristic.id}
                  id={characteristic.id}
                  name={characteristic.characteristicName}
                  icon={characteristic.icon}
                  value={value}
                  parkingId={this.props.parkingId}
                  method={this.props.method}
                />
              );
            }
          })}
        </div>
        <div className="row">
          <div className="col-xs-12 col-md-6">
            <div className="parking-height">
              <div className={classheightMax}>
                <i className="icon-height" />
                <div className={classheightMax1}>
                  <input
                    className={
                      this.props.isHeightMax
                        ? 'form-input-field on-focus'
                        : 'form-input-field'
                    }
                    type="text"
                    id="inputHeight"
                    name="heightMax"
                    value={this.props.isHeightMax}
                    onChange={this.props.method}
                  />
                  <label className="form-input-label" htmlFor="inputHeight">
                    <span className="form-input-label-content">
                      <FormattedMessage
                        id="app.parking-maximum-height"
                        defaultMessage="Hauteur maximale (m)"
                      />
                    </span>
                  </label>
                </div>
                {lengthheightMax1 > 0 && (
                  <span className="help-block">
                    {this.props.error.heightMax}
                  </span>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    characteristics: state.characteristics.characteristic
  };
}

export default connect(
  mapStateToProps,
  {
    fetchGetCharacteristics
  }
)(Characteristics);
