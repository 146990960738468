import {
  FETCH_GET_FILES_FULFILLED,
  FETCH_GET_FILES_PENDING,
  FETCH_GET_FILES_REJECTED,
  FETCH_GET_FILES_USER,
  FETCH_PARKING_WITHOUT_CERTIFICATE_FULFILLED,
  FETCH_PARKING_WITHOUT_CERTIFICATE_PENDING,
  FETCH_PARKING_WITHOUT_CERTIFICATE_REJECTED,
  FETCH_UPDATE_FILES,
  GET_CERTIFICATE_FULFILLED,
  GET_CERTIFICATE_PENDING,
  GET_CERTIFICATE_REJECTED,
  UPDATE_CERTIFICATE_USER_UUID,
  FETCH_GET_PARKING_LIST_BILLING,
  FETCH_GENERATE_BOOKING_DOC
} from '../actions/filesAction';

const filesState = {
  certificate: {
    data: null
  },
  parkingsWithoutCertificates: {
    loading: false,
    data: [],
    error: false
  },
  parkingsCertificate: {
    loading: false,
    data: [],
    error: false
  },
  bookingsList: {
    loading: false,
    list: []
  },
  docCreated: false
};

export default function(state = filesState, action) {
  switch (action.type) {
    case FETCH_PARKING_WITHOUT_CERTIFICATE_PENDING:
      return {
        ...state,
        parkingsWithoutCertificates: {
          loading: true,
          data: [],
          error: false
        }
      };
    case FETCH_PARKING_WITHOUT_CERTIFICATE_FULFILLED:
      return {
        ...state,
        parkingsWithoutCertificates: {
          loading: false,
          data: action.payload.data,
          error: false
        }
      };
    case FETCH_PARKING_WITHOUT_CERTIFICATE_REJECTED:
      return {
        ...state,
        parkingsWithoutCertificates: {
          loading: false,
          data: [],
          error: true
        }
      };
    case FETCH_GET_FILES_PENDING:
      return {
        ...state,
        parkingsCertificate: {
          data: [],
          loading: true,
          error: false
        }
      };
    case FETCH_GET_FILES_FULFILLED:
      return {
        ...state,
        parkingsCertificate: {
          data: action.payload.data,
          loading: false,
          error: false
        }
      };
    case FETCH_GET_FILES_REJECTED:
      return {
        ...state,
        parkingsCertificate: {
          data: [],
          loading: false,
          error: true
        }
      };
    case FETCH_GET_FILES_USER:
      return {
        ...state,
        filesListUser: action.payload,
        loading: true,
        message: action.message
      };
    case FETCH_UPDATE_FILES:
      return {
        ...state,
        fileUpdated: action.payload,
        loading: true
      };
    case GET_CERTIFICATE_PENDING:
      return {
        ...state,
        certificate: {
          pending: true,
          success: false,
          error: false,
          data: null
        }
      };
    case GET_CERTIFICATE_FULFILLED:
      return {
        ...state,
        certificate: {
          pending: false,
          success: true,
          data: action.payload.data.length > 0 ? action.payload.data[0] : null
        }
      };
    case GET_CERTIFICATE_REJECTED:
      return {
        ...state,
        certificate: {
          pending: false,
          success: false,
          data: null
        }
      };
    case UPDATE_CERTIFICATE_USER_UUID:
      return {
        ...state,
        certificate: {
          ...state.certificate,
          data: {
            ...state.certificate.data,
            uuid: action.payload.data
          }
        }
      };
    case FETCH_GET_PARKING_LIST_BILLING:
      return {
        ...state,
        bookingsList: {
          loading: false,
          list: action.payload,
          isNewDoc: action.payload.new_doc
        },
        docCreated: false
      };
    case FETCH_GENERATE_BOOKING_DOC:
      return {
        ...state,
        docCreated: true
      };
    default:
      return state;
  }
}
