import React from 'react';
import * as ROUTES from '../../../../constants/routes';
import { Link } from 'react-router-dom';
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl';

const Activation = () => {
  return (
    <div className="dashboard-card dashboard-card-activation">
      <h3 className="dashboard-card-title">
        <FormattedMessage
          id="app.activate-your-first-car-park"
          defaultMessage="Activez votre premier parking"
        />{' '}
        <span className="text-highlight">
          <FormattedMessage id="WATTPARK" defaultMessage="WATTPARK Pro" />
        </span>
      </h3>
      <Link to={ROUTES.CREATE_PARKING} className="btn btn-primary btn-fixed">
        <FormattedMessage id="app.btn-activate" defaultMessage="Activer" />
      </Link>
    </div>
  );
};

export default Activation;
