import React, { Component } from 'react';
import FaqIndex from './faq';

class Contact extends Component {
  render() {
    return (
      <div id="view4" className="view">
        <FaqIndex />
        <div className="form-block">
          <h3 className="form-block-title">
            je ne trouve pas la réponse à ma question{' '}
          </h3>
          <div className="list-contact-item">
            <h3 className="list-contact-item-title">Contacter WATTPARK</h3>
            <div className="list-contact-item-actions">
              {/*
								<a
									href="tel:5551234567"
									className="list-contact-item-actions-link"
								>
									Par téléphone
								</a>
								*/}
              <a
                href="mailto:contact@wattpark.eu"
                className="list-contact-item-actions-link"
              >
                Par email
              </a>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Contact;
