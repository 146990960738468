import { FormattedMessage } from 'react-intl';
import React from 'react';
import TextField from '../../../Inputs/TextField';

const SearchInput = ({ value, allResult, data, handleChange, inputRef }) => {
  const handleSearch = event => {
    const { value } = event.target;

    handleChange(event);

    if (value.length >= 4) {
      goSearch(value);
    } else if (value === '') {
      resetSearch();
    } else if (allResult.length !== 0) {
      handleChange({ target: { name: 'searchResult', value: [] } });
    }
  };

  const goSearch = value => {
    handleChange({
      target: {
        name: 'searchResult',
        value: data.filter(member => member.email.includes(value))
      }
    });
  };

  const resetSearch = () => {
    handleChange({ target: { name: 'searchResult', value: [] } });
    handleChange({ target: { name: 'search', value: '' } });
  };

  return (
    <div className={`form-input ${value !== '' ? 'on-focus' : ''}`}>
      <input
        className="form-input-field"
        type="text"
        ref={inputRef}
        name="search"
        id="inputSearchEmail"
        value={value}
        onChange={handleSearch}
      />
      <label className="form-input-label" htmlFor="inputSearchEmail">
        <span className="form-input-label-content">
          <FormattedMessage
            id="app.mail-search"
            defaultMessage="Rechercher une adresse e-mail"
          />
        </span>
      </label>
      <i className="icon-search" />
    </div>
  );
};

export default SearchInput;
