import React, { Component } from 'react';
import InputMask from 'react-input-mask';
import { FormattedMessage } from 'react-intl';
import { hideModal } from '../../../../actions/modalAction';
import CurrencyInput from '../../../Inputs/CurrencyInput';
import { connect } from 'react-redux';
import {
  fetchDeleteSlots,
  fetchAddSlots
} from '../../../../actions/hoursAction';
import moment from 'moment';

const hoursInputRegex = /^\d{2}\:\d{2}?/;
const reg = /^\d+(\,\d{1,2})?$/;

class PriceModel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      slotId: props.slotId,
      inputHoursStart: props.inputHoursStart,
      inputHoursEnd: props.inputHoursEnd,
      val: props.val,
      inputHoursPrice: props.inputHoursPrice,
      formErros: props.error,
      day: props.numDay,
      showHoursError: false
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  handleSubmit(event) {
    event.preventDefault();

    if (!this.hoursHasError()) {
      this.props.handleSubmit(this.state);
      this.props.hideModal();
    }
  }

  hoursHasError() {
    const { inputHoursStart, inputHoursEnd } = this.state;
    const condition =
      moment(inputHoursStart, 'HH:mm') > moment(inputHoursEnd, 'HH:mm');
    this.setState({
      showHoursError: condition
    });
    return condition;
  }

  handleDelete(e) {
    this.props.handleDelete(this.props);
    this.props.hideModal();
  }

  handleChange(event) {
    const target = event.target;
    const name = target.name;
    const value = target.value;
    let formErrors = {
      inputHoursPrice: '',
      inputHoursStart: '',
      inputHoursEnd: ''
    };
    switch (name) {
      case 'inputHoursPrice':
        formErrors.inputHoursPrice = reg.test(value)
          ? ''
          : "Le prix n'est pas valide";
        break;
      case 'inputHoursStart':
        formErrors.inputHoursStart = reg.test(value) ? '' : 'Champ requis';
        break;
      case 'inputHoursEnd':
        formErrors.inputHoursEnd = reg.test(value) ? '' : 'Champ requis';
        break;
      default:
    }
    if (name === 'inputHoursPrice') {
      this.setState({
        inputHoursPrice: {
          display: value,
          value: value.replace(/\s+/g, '').replace(/,/g, '.')
        }
      });
    } else {
      this.setState({
        [name]: value
      });
    }
  }

  render() {
    const weekday = new Array(7);
    weekday[0] = 'Dimanche';
    weekday[1] = 'Lundi';
    weekday[2] = 'Mardi';
    weekday[3] = 'Mercredi';
    weekday[4] = 'Jeudi';
    weekday[5] = 'Vendredi';
    weekday[6] = 'Samedi';
    let day = weekday[this.props.numDay];
    let classinputHoursPrice = 'form-group';
    let classinputHoursPrice1 = 'form-input form-input-price';
    let lengthinputHoursPrice = 0;
    if (this.props.inputHoursPrice) {
      classinputHoursPrice1 = 'form-input on-focus form-input-price';
    }
    if (this.props.error) {
      if (this.props.error.inputHoursPrice.length > 0) {
        lengthinputHoursPrice = this.props.error.inputHoursPrice.length;
        classinputHoursPrice = 'form-group on-focus has-error has-feedback';
        classinputHoursPrice1 =
          'form-input form-input-price on-focus has-error has-feedback';
      }
    }

    const enabled =
      this.state.inputHoursPrice.value !== undefined &&
      this.state.inputHoursPrice.value !== '' &&
      hoursInputRegex.test(this.state.inputHoursStart) &&
      hoursInputRegex.test(this.state.inputHoursEnd);

    return (
      <div>
        <div className="modal-scheduler-title">
          <FormattedMessage
            id="app.make-available"
            defaultMessage="Rendre disponible le"
          />{' '}
          {day} de :
        </div>
        <a
          className="btn-close"
          onClick={() => {
            this.props.hideModal();
          }}
        >
          <i className="icon-close" />
        </a>

        <div className="modal-scheduler-body">
          <div className="form-input form-input-hours">
            <InputMask
              required
              className="form-input-field"
              type="text"
              mask="99:99"
              name="inputHoursStart"
              id="inputHoursStart"
              placeholder="__:__"
              value={
                this.state.inputHoursStart ? this.state.inputHoursStart : ''
              }
              onChange={this.handleChange}
            />
            <label className="form-input-label" htmlFor="inputHoursStart">
              <span className="form-input-label-content" />
            </label>
          </div>
          <div className="form-input-separator">
            <div className="form-input-separator-text">à</div>
          </div>
          <div className="form-input form-input-hours">
            <InputMask
              mask="99:99"
              required
              onChange={this.handleChange}
              className="form-input-field"
              type="text"
              id="inputHoursEnd"
              placeholder="__:__"
              name="inputHoursEnd"
              value={this.state.inputHoursEnd ? this.state.inputHoursEnd : ''}
            />
            <label className="form-input-label" htmlFor="inputHoursEnd">
              <span className="form-input-label-content" />
            </label>
          </div>
          <p className="text-legend">
            <FormattedMessage
              id="app.info-available"
              defaultMessage="Une journée commence à 00h00 et se termine à 23h59."
            />
          </p>
          <div className={classinputHoursPrice}>
            <div className={classinputHoursPrice1}>
              <CurrencyInput
                value={
                  this.state.inputHoursPrice &&
                  this.state.inputHoursPrice.display != undefined &&
                  this.state.inputHoursPrice.display != 'undefined'
                    ? this.state.inputHoursPrice.display
                    : null
                }
                className="form-input-field"
                id="inputHoursPrice"
                name="inputHoursPrice"
                onChange={this.handleChange}
                placeholder="-"
                required
              />
              <label className="form-input-label" htmlFor="inputHoursPrice">
                <span className="form-input-label-content">
                  <FormattedMessage
                    id="app.Default-hourly-rate"
                    defaultMessage="Tarif horaire par défaut"
                  />
                </span>
              </label>
            </div>
            {lengthinputHoursPrice > 0 && (
              <span className="help-block">{this.props.error.address}</span>
            )}
            {this.state.inputHoursPrice.value === '0' && (
              <p className="text-legend">
                <FormattedMessage
                  id="app.info-free-price"
                  defaultMessage="Les utilisateurs de cette liste pourront réserver des places sur votre parking et s'y
              gareront gratuitement. Cependant, ces réservations seront soumises aux conditions d'utilisation en accord avec votre contrat Wattpark"
                />
              </p>
            )}

            {this.state.showHoursError && (
              <p className="text-legend">
                <FormattedMessage
                  id="app.invalid-hours"
                  defaultMessage="Le créneau horaire est invalide."
                />
              </p>
            )}
          </div>
          <div className="modal-scheduler-actions">
            {this.props.slotId && enabled ? (
              <button onClick={this.handleDelete} className="btn btn-delete">
                <FormattedMessage
                  id="app.btnDelete"
                  defaultMessage="supprimer"
                />
              </button>
            ) : (
              ''
            )}
            <button
              disabled={!enabled}
              type="submit"
              className={
                !enabled ? 'btn btn-primary btn-disable' : 'btn btn-primary'
              }
              onClick={this.handleSubmit}
            >
              <FormattedMessage
                id="app.btnCreateParks"
                defaultMessage="valider"
              />
            </button>
          </div>
        </div>
      </div>
    );
  }
}

export default connect(
  null,
  { hideModal, fetchDeleteSlots, fetchAddSlots }
)(PriceModel);
