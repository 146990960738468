import React, { useRef, useState, useEffect } from 'react';
import { Document, Page } from 'react-pdf';

function FileWithPreview({
  src,
  onCertificatLoad,
  type,
  title,
  allowRemove,
  removeLabel,
  onRemove
}) {
  const inputRef = useRef(null);
  const initialState = {
    src: src || null,
    numPages: null,
    pageNumber: 1,
    type: type || null
  };
  const [preview, setPreview] = useState(initialState);

  useEffect(() => {
    setPreview({ ...preview, src, type });
  }, [src, type]);

  const toggleFileInput = () => {
    inputRef.current.click();
  };

  const handleChange = e => {
    if (e.target.files.length > 0) {
      const file = e.target.files[0];
      const splittedType = file.type.split('/');
      if (file.type === 'application/pdf') {
        setPreview({ ...preview, src: file, type: file.type });
      } else if (splittedType.length > 0 && splittedType[0] === 'image') {
        setPreview({
          ...preview,
          src: URL.createObjectURL(file),
          type: file.type
        });
      } else {
        setPreview({ ...preview, src: null, type: file.type });
      }
      onCertificatLoad(file);
    }
  };

  const onDocumentLoadSuccess = ({ numPages }) => {
    setPreview({ ...preview, numPages });
  };

  return (
    <div className="form-block">
      <h3 className="form-block-title">
        {title}
        {allowRemove && (
          <span
            className="link-action hand-cursor"
            onClick={() => {
              setPreview(initialState);
              onRemove();
            }}
          >
            {removeLabel}
          </span>
        )}
      </h3>
      <div className="row">
        <div className="col-xs-12">
          <div
            className="upload-block upload-block-print"
            onClick={toggleFileInput}
          >
            <input
              name="certifIrvefile"
              type="file"
              id="file"
              ref={inputRef}
              style={{ display: 'none' }}
              onChange={handleChange}
            />
            {preview && (
              <div className="upload-block-preview">
                {preview.type ? (
                  preview.type === 'application/pdf' ? (
                    <Document
                      file={preview.src}
                      onLoadSuccess={onDocumentLoadSuccess}
                    >
                      <Page pageNumber={preview.pageNumber} />
                    </Document>
                  ) : preview.type === 'image/png' ||
                    preview.type === 'image/jpeg' ? (
                    <img
                      srcSet={preview.src}
                      alt="Preview IRVE"
                      className="upload-block-preview-img"
                    />
                  ) : (
                    'Format de fichier non supporté pour prévisualisation'
                  )
                ) : (
                  'Cliquez dans la zone pour importer un fichier'
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default FileWithPreview;
