import React, { Component } from 'react';
import TableParking from './parking';
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl';

class ListParking extends Component {
  constructor(props) {
    super(props);
  }

  parking() {
    if (this.props.parking) {
      return (
        <section className="section dashboard-part dashboard-part-hidden">
          <div className="section-header">
            <h3 className="section-header-title">{this.props.title}</h3>
          </div>
          <div className="list-table">
            <div className="list-table-header">
              <span
                onClick={this.props.method}
                className="list-table-header-title list-table-header-title-info active"
              >
                <FormattedMessage
                  id="app.nameParkingTab"
                  defaultMessage="Nom"
                />
                <i className="icon-arrow-selection" />
              </span>
              <span className="list-table-header-title list-table-header-title-mode">
                <FormattedMessage
                  id="app.methodParkingTab"
                  defaultMessage="Mode"
                />
                <i className="icon-arrow-selection" />
              </span>
              <span className="list-table-header-title list-table-header-title-availability">
                <FormattedMessage
                  id="app.availability"
                  defaultMessage="Disponibilité"
                />
                <i className="icon-arrow-selection" />
              </span>
            </div>
            <div className="list-table-body">
              <TableParking
                parkingfree={this.props.parkingfree}
                parkingoccupy={this.props.parkingoccupy}
                parkinginfo={this.props.parkinginfo}
                method={this.props.method}
                parking={this.props.parking}
              />
            </div>
          </div>
        </section>
      );
    }
  }

  render() {
    return <div>{this.parking()}</div>;
  }
}

export default ListParking;
