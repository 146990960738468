import React, { Component } from 'react';
import { connect } from 'react-redux';
import { hideModal } from '../../../actions/modalAction';
import PriceModal from '../Forms/models/price';

class Modal extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    if (this.props.display) {
      const {
        modal: {
          modalData: {
            val,
            dayNumber,
            formErros,
            slotId,
            inputHoursStart,
            inputHoursEnd,
            inputHoursPrice
          }
        },
        handleSubmit,
        handleDelete
      } = this.props;

      return (
        <div>
          <div className=".modal-layer-screen"></div>
          <div className="modal-scheduler" style={{ display: 'block' }}>
            <PriceModal
              val={val}
              numDay={dayNumber}
              error={formErros}
              slotId={slotId}
              inputHoursStart={inputHoursStart}
              inputHoursEnd={inputHoursEnd}
              inputHoursPrice={inputHoursPrice}
              handleSubmit={handleSubmit}
              handleDelete={handleDelete}
            />
          </div>
        </div>
      );
    } else return null;
  }
}

function mapStateToProps(state) {
  return {
    modal: state.modal,
    display: state.modal.display
  };
}

export default connect(
  mapStateToProps,
  { hideModal }
)(Modal);
