import React from 'react';
import { FormattedMessage } from 'react-intl';
import $ from 'jquery';
import moment from 'moment';

const UserItem = ({
  member,
  listType,
  className,
  onEdit,
  onDelete,
  onRelaunch
}) => {
  const approved = listType === 'white' ? member.verify : false;

  function editOption() {
    $('.options-panel').removeClass('is-opened');
    if (onEdit) onEdit(member);
  }

  function deleteOption() {
    $('.options-panel').removeClass('is-opened');
    if (onDelete) onDelete(member);
  }

  function onClickOption() {
    let target = $('#div-option-' + member.id);
    if (target.hasClass('is-opened')) {
      target.removeClass('is-opened');
    } else {
      $('.options-panel').removeClass('is-opened');
      target.addClass('is-opened');
    }
  }

  const getPeriod = () => {
    const start = moment(member.start_date).format('DD/MM/YYYY');
    const end = moment(member.end_date).format('DD/MM/YYYY');
    return `${start} - ${end}`;
  };

  return (
    <div className={`customer ${className} ${approved ? 'approved' : ''}`}>
      <div className="customer-content">
        <div className="customer-name">
          <div className="customer-name-title">
            <FormattedMessage
              id="app.mail-title"
              defaultMessage="Adresse e-mail"
            />
          </div>
          <div className="customer-name-content">
            {member.email} <i className="icon-user" />
          </div>
        </div>

        <div className="customer-list">
          <div className="customer-list-title">
            <FormattedMessage
              id="app.user-list"
              defaultMessage="Liste utilisateurs"
            />
          </div>
          <div className="customer-list-content">{member.name}</div>
        </div>

        {!!member.start_date && (
          <div className="customer-period">
            <div className="customer-period-title">
              <FormattedMessage id="app.user-period" defaultMessage="Période" />
            </div>
            <div className="customer-period-content">{getPeriod()}</div>
          </div>
        )}

        {listType === 'white' && !member.verify && (
          <button
            className="btn btn-fourth btn-small"
            onClick={() => onRelaunch(member)}
          >
            <FormattedMessage id="app.relaunch" defaultMessage="Relancer" />
          </button>
        )}

        <i
          className="icon-options trigger-options-panel"
          id={'option-' + member.id}
          onClick={onClickOption}
        >
          <span />
          <span />
          <span />
        </i>

        <div className="options-panel" id={'div-option-' + member.id}>
          <ul className="options-panel-list">
            <li className="options-panel-list-item">
              <a
                className="options-panel-list-item-action"
                onClick={editOption}
              >
                <FormattedMessage id="app.btn-update" defaultMessage="Éditer" />
              </a>
            </li>
            <li className="options-panel-list-item">
              <a
                className="options-panel-list-item-action delete"
                onClick={deleteOption}
              >
                <FormattedMessage
                  id="app.btnDelete"
                  defaultMessage="Supprimer"
                />
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default UserItem;
