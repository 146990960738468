import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Alert from './../Layouts/Alert/alert';
import * as ROUTES from '../../constants/routes';
import IllustrationCity from './../Design/illustration-city';
import { FormattedMessage, injectIntl } from 'react-intl';
import { DocumentTitle } from '../../utils';
import { connect } from 'react-redux';
import { fetchDeleteAccount } from '../../actions/userAction';

//RFC 2822
const emailTest = RegExp(
  /^(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])$/
);

class UserDeleteRequest extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      alert: false,
      style: 'none',
      message: '',
      user: '',
      nameAlert: '',
      display: 'none',
      isLoading: false,
      typeAlert: '',
      requestSent: '',
      formErrors: {
        email: ''
      }
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentWillReceiveProps(nextProps, nextContext) {
    const { intl } = this.props;
    if (nextProps.requestSent != '') {
      this.setState(
        {
          alert: true,
          message: intl.formatMessage({
            id: 'app.user-delete-request-msg',
            defaultMessage: ''
          }),
          typeAlert: 'alerts alerts-success'
        },
        () => {
          this.setState({ requestSent: '', email: '' });
          setTimeout(() => {
            this.setState({ alert: false });
          }, 5000);
        }
      );
    }
  }

  handleSubmit(event) {
    event.preventDefault();
    let values = {
      email: this.state.email
    };
    this.props.fetchDeleteAccount(values.email);
  }

  handleChange(event) {
    const target = event.target;
    const { name, value } = target;

    let formErrors = this.state.formErrors;
    switch (name) {
      case 'email':
        formErrors.email = emailTest.test(value)
          ? ''
          : 'Adresse email invalide';
        break;
      default:
        break;
    }
    this.setState({
      formErrors,
      [name]: value
    });
  }

  render() {
    const formErrors = this.state.formErrors;
    ///Validator Email
    let classEmail = 'form-group';
    let classEmail1 = 'form-input';
    let lengthEmail = 0;
    if (this.state.email) {
      classEmail1 = 'form-input on-focus';
    }
    if (formErrors) {
      if (formErrors.email.length > 0) {
        lengthEmail = formErrors.email.length;
        classEmail = 'form-group on-focus has-error has-feedback';
        classEmail1 = 'form-input on-focus has-error has-feedback';
      } else {
        classEmail1 = 'form-input on-focus';
      }
    }

    const enabled = formErrors.email.length > 0 || this.state.email === '';

    const { isLoading } = this.state.isLoading;
    return (
      <div className="main-container login">
        {DocumentTitle(
          'app.title-main-delete',
          'WATTPARK - Suppression de compte'
        )}
        <header className="header header-login">
          <div className="header-left" />
          <div className="header-center">
            <h1 className="logo-main">
              <Link to={ROUTES.LANDING}>
                <img src="/img/logo.svg" alt="Logo Wattpark" />
              </Link>
            </h1>
          </div>
        </header>
        <div className="alerts-wrapper ">
          {this.state.alert ? (
            <Alert
              nameAlert={this.state.nameAlert}
              type={this.state.typeAlert}
              style={false}
              message={
                this.state.message && this.state.message.length > 0 ? (
                  this.state.message
                ) : (
                  <FormattedMessage
                    id={'app.msg-login-fail'}
                    defaultMessage={'Connexion impossible'}
                  />
                )
              }
            />
          ) : null}
        </div>
        <div className="form-block-wrapper">
          <form onSubmit={this.handleSubmit}>
            <div className="form-block">
              <h3 className="form-block-title">
                <FormattedMessage
                  id="app.title-main-delete"
                  defaultMessage="Suppression de compte Wattpark"
                />
              </h3>

              <div className={classEmail}>
                <div className={classEmail1}>
                  <input
                    className="form-input-field has-error"
                    type="email"
                    name="email"
                    id="inputEmail"
                    required
                    value={this.state.email}
                    onChange={this.handleChange}
                  />
                  <label className="form-input-label" htmlFor="inputEmail">
                    <span className="form-input-label-content">Email</span>
                  </label>
                </div>
                {lengthEmail > 0 && (
                  <span className="help-block">{formErrors.email}</span>
                )}
              </div>
              <div className="form-block-action">
                <button
                  disabled={enabled}
                  type="submit"
                  className={
                    enabled
                      ? 'btn btn-primary btn-fixed btn-disable'
                      : 'btn btn-primary btn-fixed'
                  }
                >
                  {isLoading ? (
                    <FormattedMessage
                      id="app.loading"
                      defaultMessage="Chargement..."
                    />
                  ) : (
                    <FormattedMessage
                      id="app.btnCreateParks"
                      defaultMessage="Valider"
                    />
                  )}
                </button>
              </div>
            </div>
          </form>
        </div>

        <IllustrationCity />
      </div>
    );
  }
}

//export default UserDeleteRequest;

function mapStateToProps(state) {
  return {
    requestSent: state.users.deleteRequest
  };
}

export default connect(
  mapStateToProps,
  { fetchDeleteAccount }
)(injectIntl(UserDeleteRequest));
