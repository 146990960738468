import React from 'react';
import { Link } from 'react-router-dom';

const Alert = ({
  classBtnName,
  type,
  btnName,
  style,
  message,
  nameAlert,
  url
}) => {
  if (classBtnName === undefined)
    classBtnName = 'btn btn-secondary btn-small hidden';
  return (
    <div className={type}>
      <Link to={url ? url : ''}>
        <i className="icon-warning-white" />
        <div className="alerts-content">
          <div className="alerts-title">{nameAlert}</div>
          <div className="alerts-text">{message}</div>
        </div>
        <button style={{ width: 150 }} className={classBtnName}>
          {btnName}
        </button>
      </Link>
    </div>
  );
};
export default Alert;
