import React, { useState } from 'react';
import DatePicker from 'react-datepicker';

export default function Calendar({ handleMethod, start, end }) {
  const [startDate, setStartDate] = useState(start ? start : new Date());
  const [endDate, setEndDate] = useState(end);

  const onChange = dates => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
    handleMethod({ target: { value: { start: start, end: end } } });
  };

  const monthNames = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December'
  ];

  return (
    <div className="calendar">
      <DatePicker
        selected={startDate}
        onChange={onChange}
        startDate={startDate}
        endDate={endDate}
        minDate={new Date()}
        selectsRange
        inline
        dateFormatCalendar="MMMM"
        renderCustomHeader={({
          date,
          changeYear,
          changeMonth,
          decreaseMonth,
          increaseMonth,
          decreaseYear,
          increaseYear,
          prevMonthButtonDisabled,
          nextMonthButtonDisabled
        }) => (
          <div className="calendar-header">
            <div className="calendar-header-section calendar-header-section-month">
              <button
                className="calendar-header-button calendar-header-button-prev"
                onClick={decreaseMonth}
                disabled={prevMonthButtonDisabled}
              >
                <i className="icon-chevron" />
              </button>
              <div className="calendar-header-section-value">
                {monthNames[date.getMonth()]}
              </div>
              <button
                className="calendar-header-button calendar-header-button-next"
                onClick={increaseMonth}
                disabled={nextMonthButtonDisabled}
              >
                <i className="icon-chevron" />
              </button>
            </div>
            <div className="calendar-header-section calendar-header-section-year">
              <button
                className="calendar-header-button calendar-header-button-prev"
                onClick={decreaseYear}
                disabled={prevMonthButtonDisabled}
              >
                <i className="icon-chevron" />
              </button>
              <div className="calendar-header-section-value">
                {date.getFullYear()}
              </div>
              <button
                className="calendar-header-button calendar-header-button-next"
                onClick={increaseYear}
                disabled={nextMonthButtonDisabled}
              >
                <i className="icon-chevron" />
              </button>
            </div>
          </div>
        )}
      />
    </div>
  );
}
