import axios from 'axios';
import config from './../config/index';

import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';

const baseUrl = config.baseUrl;
const firebaseInstance = firebase.initializeApp(config.firebase);
export const FETCH_REGISTER = 'FETCH_REGISTER';
export const FETCH_VERIFY_EMAIL = 'FETCH_VERIFY_EMAIL';
export const FETCH_STATE_USER_ADD = 'FETCH_STATE_USER_ADD';

export function fetchInitialState() {
  return dispatch => {
    dispatch({
      type: FETCH_STATE_USER_ADD,
      payload: true
    });
  };
}

export function fetchRegister(data) {
  const url = `${baseUrl}/api/user`;
  const urlVerifyEmail = `${baseUrl}/api/verifyemail/${
    data['userFB']['email']
  }`;
  return dispatch => {
    axios.get(urlVerifyEmail).then(request => {
      if (request.data === false) {
        dispatch({
          type: FETCH_REGISTER,
          payload: false,
          isNotAdd: false
        });
      }

      firebase.auth().createUserWithEmailAndPassword(data['userFB']['email'], data['userFB']['password']).then((result) => {
        data['userRegister']['firebaseId'] = result.user.uid;

        axios
          .post(url, data['userRegister'])
          .then(request => {
            if (request.data.length !== 0) {
              firebase.auth().currentUser.sendEmailVerification().then(() => {
                if (data['file']) {
                  const urlUploadFile = `${baseUrl}/api/upload/user/${
                    result.user.uid
                  }/documents`;
                  const formData = new FormData();
                  formData.append('idFile', data['file']);
                  axios
                    .post(urlUploadFile, formData, {
                      headers: {
                        'x-access-token': localStorage.getItem('token'),
                        'content-type': 'multipart/form-data'
                      }
                    })
                    .then(() => {
                      dispatch({
                        type: FETCH_REGISTER,
                        payload: true
                      });
                    })
                    .catch(() => {
                      dispatch({
                        type: FETCH_REGISTER,
                        payload: false
                      });
                    });
                } else {
                  dispatch({
                    type: FETCH_REGISTER,
                    payload: true
                  });
                }
              }).catch(() => {
                dispatch({
                  type: FETCH_REGISTER,
                  payload: false
                });
              })
              result.user.getIdToken().then((token) => {
                localStorage.setItem('token', token);
              })
            } else {
              dispatch({
                type: FETCH_REGISTER,
                payload: false
              });
            }
          })
          .catch(() => {
            dispatch({
              type: FETCH_REGISTER,
              payload: false
            });
          });
      }).catch(() => {
        dispatch({
          type: FETCH_REGISTER,
          payload: false,
          isNotAdd: false
        });
      })
    });
  };
}

export function fetchVerifyEmail(email) {
  const url = `${baseUrl}/api/verifyemail/${email}`;

  return dispatch => {
    dispatch({
      type: FETCH_VERIFY_EMAIL,
      payload: false
    });

    axios
      .get(url)
      .then(request => {
        if (request === true) {
          dispatch({
            type: FETCH_VERIFY_EMAIL,
            payload: true
          });
        }
      })
      .catch(() => {
        dispatch({
          type: FETCH_VERIFY_EMAIL,
          payload: false
        });
      });
  };
}
