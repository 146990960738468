import React, { Component } from 'react';
import Certification from './certification';
import { FormattedMessage } from 'react-intl';

class CertificationChecking extends Component {
  render() {
    return (
      <div className="view-wrapper">
        <div id="view0" className="view current">
          <div className="form-block form-block-warning">
            <h3 className="form-block-title">
              <FormattedMessage
                id="app.awaiting-certificate"
                defaultMessage="Certificats en attente d'importantion"
              />
              ({this.props.emptyParkings.length})
            </h3>
            {this.props.emptyParkings.map((file, idx) => (
              <Certification
                key={idx}
                type="empty"
                parkingId={file.parkingId}
                firstName={file.firstName}
                lastName={file.lastName}
                parkingName={file.parkingName}
                parkingStreet={file.parkingStreet}
                parkingCity={file.parkingCity}
              />
            ))}
          </div>
        </div>
        <div id="view1" className="view">
          <div className="form-block form-block-warning">
            <h3 className="form-block-title">
              <FormattedMessage
                id="app.certification-awaiting"
                defaultMessage="Certificats en attente de validation"
              />
              (
              {
                this.props.files.filter(file => file.status === 'pending')
                  .length
              }
              )
            </h3>
            {this.props.files
              .filter(file => file.status === 'pending')
              .map(file => (
                <Certification
                  type={file.status}
                  key={file.id}
                  date={file.date}
                  url={file.file}
                  localisation=""
                  idFile={file.id}
                  method={this.props.UpdateFile}
                  parkingId={file.parkingId}
                  firstName={file.firstName}
                  lastName={file.lastName}
                  parkingName={file.parkingName}
                  parkingStreet={file.parkingStreet}
                  parkingCity={file.parkingCity}
                />
              ))}
          </div>
        </div>
        <div id="view2" className="view">
          <div className="form-block">
            <h3 className="form-block-title">
              <FormattedMessage
                id="app.certification-validated"
                defaultMessage="Certificats validés par WATTpark"
              />
              (
              {
                this.props.files.filter(file => file.status === 'approved')
                  .length
              }
              )
            </h3>
            {this.props.files
              .filter(file => file.status === 'approved')
              .map(file => (
                <Certification
                  type={file.status}
                  key={file.id}
                  date={file.date}
                  url={file.file}
                  localisation=""
                  parkingId={file.parkingId}
                  firstName={file.firstName}
                  lastName={file.lastName}
                  parkingName={file.parkingName}
                  parkingStreet={file.parkingStreet}
                  parkingCity={file.parkingCity}
                />
              ))}
          </div>
        </div>
        <div id="view3" className="view">
          <div className="form-block">
            <h3 className="form-block-title">
              <FormattedMessage
                id="app.certification-Refused"
                defaultMessage="Certificats refusés par Wattpark"
              />
              (
              {
                this.props.files.filter(file => file.status === 'rejected')
                  .length
              }
              )
            </h3>
            {this.props.files
              .filter(file => file.status === 'rejected')
              .map(file => (
                <Certification
                  type={file.status}
                  key={file.id}
                  date={file.date}
                  idFile={file.id}
                  url={file.file}
                  localisation=""
                  parkingId={file.parkingId}
                  firstName={file.firstName}
                  lastName={file.lastName}
                  parkingName={file.parkingName}
                  parkingStreet={file.parkingStreet}
                  parkingCity={file.parkingCity}
                />
              ))}
          </div>
        </div>
      </div>
    );
  }
}

export default CertificationChecking;
