import { FormattedMessage } from 'react-intl';
import React from 'react';
import TextField from '../../../../Inputs/TextField';

const PermissionList = ({
  listType,
  value,
  handleChange,
  privilege,
  handleToggle,
  onSave,
  onDelete
}) => {
  const TOGGLE_ON = 'available';
  const TOGGLE_OFF = 'close';

  return (
    <div className="col-xs-12 col-sm-4">
      <div className="form-block">
        <h3 className="form-block-title">
          <FormattedMessage
            id={`app.${
              listType === 'black' ? 'restrictions' : 'authorization'
            }`}
            defaultMessage={`${
              listType === 'black' ? 'Restrictions' : 'Autorisations'
            }`}
          />
        </h3>
        <div className="row">
          <div className="col-xs-12">
            {listType === 'white' && (
              <TextField
                label={
                  <FormattedMessage
                    id="app.list-title"
                    defaultMessage="Nom de la liste"
                  />
                }
                title="list"
                id="name"
                name="name"
                value={value}
                handleChange={handleChange}
                required
              />
            )}
          </div>
          {listType === 'white' && (
            <div className="col-xs-12">
              <div className="options">
                <div className="options-title">
                  <FormattedMessage
                    id="app.privilege-offer"
                    defaultMessage="Offrir le privilège"
                  />
                </div>
                <div className="toggle-wrapper">
                  <div className="toggle">
                    <input
                      onClick={handleToggle}
                      className="toggle-input"
                      value={privilege ? TOGGLE_ON : TOGGLE_OFF}
                      checked={privilege}
                      onChange={handleToggle}
                      id="toggle-offer"
                      type="checkbox"
                    />
                    <span className="toggle-label">
                      {privilege ? (
                        <FormattedMessage
                          id="app.activated-public-mode"
                          defaultMessage="Activé"
                        />
                      ) : (
                        <FormattedMessage
                          id="app.activated-public-mode-desactivated"
                          defaultMessage="Desactivé"
                        />
                      )}
                    </span>
                    <label className="toggle-control" htmlFor="toggle-offer" />
                  </div>
                </div>
              </div>
            </div>
          )}
          <div className="col-xs-12">
            <p className="text-secondary">
              {listType === 'black' ? (
                <FormattedMessage
                  id="app.user-unauthorized-text2"
                  defaultMessage="Ces utilisateurs n'ont plus le droit de réserver de place sur votre parking."
                >
                  {txt => txt}
                </FormattedMessage>
              ) : (
                <FormattedMessage
                  id="app.privilege-text"
                  defaultMessage="Activez cette option pour réservez un ou plusieurs créneaux aux utilisateurs de cette liste."
                >
                  {txt => txt}
                </FormattedMessage>
              )}
            </p>
          </div>
        </div>
      </div>
      {listType === 'white' && (
        <>
          <div className="row">
            <div className="col-xs-12">
              <button
                className={`btn btn-primary btn-block ${
                  value === '' ? 'btn-disabled' : ''
                }`}
                style={{ marginBottom: '20px' }}
                onClick={onSave}
              >
                <FormattedMessage id="app.saved" defaultMessage="Enregistrer" />
              </button>
            </div>
          </div>
          <div className="row">
            <div className="hidden-xs col-sm-12">
              <button
                className="btn btn-third btn-delete btn-block"
                onClick={onDelete}
              >
                <FormattedMessage
                  id="app.delete-list"
                  defaultMessage="Supprimer la liste"
                />
              </button>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default PermissionList;
