import React, { Component } from 'react';

class Question extends Component {
  constructor(props) {
    super(props);
    this.state = {
      collapsed: true
    };
  }
  toggleCollapse() {
    this.setState({ collapsed: !this.state.collapsed });
  }

  render() {
    const { question, questionKey } = this.props;
    return (
      <div className="question">
        <div
          className={`question-title ${this.state.collapsed ? 'collapsed' : ''}`}
          data-toggle="collapse"
          data-target={`#${questionKey}`}
          onClick={() => {
            this.toggleCollapse();
          }}
        >
          <h3 className="list-faq-item-title">{question.question}</h3>
          {this.state.collapsed && (
            <div className="list-faq-item-actions">Lire</div>
          )}
        </div>
        <div id={questionKey} className={`question-content ${this.state.collapsed ? "collapse" : ""}`}>
          {question.sections.map((section, index) => (
            <div key={`section-${question.id}-${index}`}>
              <div className="question-content-title">{section.subtitle}</div>
              <div
                className="question-content-text"
                dangerouslySetInnerHTML={{ __html: section.text }}
              ></div>
            </div>
          ))}
        </div>
      </div>
    );
  }
}

export default Question;
