import React from 'react';
import moment from 'moment';

const SchedulerEnergy = ({ slots, offPeakSlots, handleMethod }) => {
  const hours = [];
  for (let h = 0; h <= 24; h++) {
    hours.push(h);
  }

  const mappedSlots = slots.map(slot => {
    const end =
      slot.endHour === '00:00:00'
        ? moment(slot.endHour, 'HH:mm:ss').add(1, 'day')
        : moment(slot.endHour, 'HH:mm:ss');
    return {
      ...slot,
      peak: false,
      hour: end.diff(moment(slot.startHour, 'HH:mm:ss'), 'm') / 60
    };
  });

  const mappedOffPeakSlots = offPeakSlots.map(slot => {
    const end =
      slot.endHour === '00:00:00'
        ? moment(slot.endHour, 'HH:mm:ss').add(1, 'day')
        : moment(slot.endHour, 'HH:mm:ss');
    return {
      ...slot,
      peak: true,
      hour: end.diff(moment(slot.startHour, 'HH:mm:ss'), 'm') / 60
    };
  });

  const allSlots = mappedSlots
    .concat(mappedOffPeakSlots)
    .sort((a, b) =>
      moment(a.startHour, 'HH:mm:ss').diff(moment(b.startHour, 'HH:mm:ss'))
    );

  return (
    <div className="hori-scheduler">
      <div className="hori-scheduler-header">
        {hours.map((val, index) => {
          const className = index % 4 !== 0 ? 'hide-on-mobile' : '';

          return (
            <div
              key={index}
              className={`hori-scheduler-header-item ${className}`}
            >
              <span>{index}h</span>
            </div>
          );
        })}
      </div>

      <div className="hori-scheduler-background">
        <div className="hori-scheduler-title">Heures</div>
        <div className="hori-scheduler-title">creuses</div>
      </div>

      <div className="hori-scheduler-content">
        {allSlots.map((slot, index) => {
          if (slot.peak)
            return (
              <div
                key={index}
                className="hori-scheduler-time-slot"
                style={{ width: `calc(calc(100% / 24) * ${slot.hour})` }}
                onClick={() => handleMethod(null)}
              />
            );
          else {
            return (
              <div
                key={index}
                className="hori-scheduler-time-slot hori-scheduler-time-slot-filled"
                style={{ width: `calc(calc(100% / 24) * ${slot.hour})` }}
                onClick={() => handleMethod(slot)}
              >
                <div className="hori-scheduler-title">Heures</div>
                <div className="hori-scheduler-title">pleines</div>
              </div>
            );
          }
        })}
      </div>
    </div>
  );
};

export default SchedulerEnergy;
