import React from 'react';

function RadioButton({
  label,
  name,
  value,
  checked,
  iconClass,
  className,
  onChange,
  defaultChecked
}) {
  return (
    <label
      className={`checkbox ${className} ${iconClass && 'checkbox-with-icon'}`}
    >
      <input
        type="radio"
        name={name}
        checked={checked}
        defaultChecked={defaultChecked}
        value={value}
        onChange={onChange}
      />
      <span className="checkmark" />
      {iconClass && (
        <i className={`checkbox-icon ${iconClass && `icon-${iconClass}`}`} />
      )}
      <span className="checkbox-text">{label}</span>
    </label>
  );
}

export default RadioButton;
