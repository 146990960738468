import config from './../config/index';
import axios from 'axios';

const baseUrl = config.baseUrl;
export const FETCH_GET_CHARACTERISTIC_PARKING =
  'FETCH_GET_CHARACTERISTIC_PARKING';
export const FETCH_GET_CHARACTERISTIC = 'FETCH_GET_CHARACTERISTIC';

export function fetchGetCharacteristics() {
  const url = `${baseUrl}/api/characteristics`;
  return dispatch => {
    axios
      .get(url, {
        headers: {
          'x-access-token': localStorage.getItem('token'),
          'content-type': 'application/json'
        }
      })
      .then(request => {
        if (request.data.length !== 0) {
          dispatch({
            type: FETCH_GET_CHARACTERISTIC,
            payload: request.data
          });
        }
      })
      .catch(() => {
        dispatch({
          type: FETCH_GET_CHARACTERISTIC,
          payload: false,
          message: 'No Characteristics'
        });
      });
  };
}

export function fetchGetCharacteristicsParkings(id) {
  const url = `${baseUrl}/api/parkings/${id}/characteristics`;
  return dispatch => {
    axios
      .get(url, {
        headers: {
          'x-access-token': localStorage.getItem('token'),
          'content-type': 'application/json'
        }
      })
      .then(request => {
        if (request.data.length !== 0) {
          dispatch({
            type: FETCH_GET_CHARACTERISTIC_PARKING,
            payload: request.data,
            isValid: true
          });
        }
      })
      .catch(() => {
        dispatch({
          type: FETCH_GET_CHARACTERISTIC_PARKING,
          payload: false,
          message: 'No Characteristics'
        });
      });
  };
}
