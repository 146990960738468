import React, { useEffect, useState } from 'react';
import TextField from '../Inputs/TextField';
import moment from 'moment';
import Calendar from './Calendar';

export default function CalendarTrigger({
  calendarBasedOnTop,
  startDate,
  endDate,
  handleMethod
}) {
  const [displayPopper, setDisplayPopper] = useState(false);
  const [initValue, setInitValue] = useState('');
  const [value, setValue] = useState('');
  const [start, setStart] = useState(startDate);
  const [end, setEnd] = useState(endDate);

  useEffect(() => {
    setInitValue(getValueFromDates(startDate, endDate));
    setValue(getValueFromDates(startDate, endDate));
  }, [startDate, endDate]);

  const showPopper = () => {
    setDisplayPopper(!displayPopper);
  };

  const hidePopper = () => {
    setDisplayPopper(false);
  };

  const handleChange = e => {
    const { start, end } = e.target.value;
    setStart(start);
    setEnd(end);
    setValue(getValueFromDates(start, end));
  };

  const getValueFromDates = (start, end) => {
    const startDate = start ? moment(start).format('DD/MM/YYYY') : null;
    const endDate = end ? moment(end).format('DD/MM/YYYY') : null;
    return startDate && endDate ? `${startDate} au ${endDate}` : '';
  };

  const cancel = () => {
    setValue(initValue);
    hidePopper();
  };

  const validate = () => {
    if (start ? start && end : true) {
      handleMethod({ target: { value: { start, end } } });
      hidePopper();
    }
  };

  return (
    <div className="calendar-trigger-wrapper">
      <div className="calendar-trigger">
        <TextField
          id="calendar-trigger-1"
          type="date"
          name="date"
          label="Période (facultatif)"
          title="date"
          value={value}
          onClick={showPopper}
        />
      </div>
      <div
        className={`calendar-popper ${displayPopper ? 'show' : ''} ${
          calendarBasedOnTop ? 'based-on-top' : ''
        }`}
      >
        <Calendar handleMethod={handleChange} start={startDate} end={endDate} />
        <div className="calendar-popper-footer">
          <button className="btn btn-third btn-small" onClick={cancel}>
            Annuler
          </button>
          <button className="btn btn-primary btn-small" onClick={validate}>
            Valider
          </button>
        </div>
      </div>
    </div>
  );
}
