import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { withFormik } from 'formik';
import * as Yup from 'yup';
import IBAN from 'iban';

class Bank extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hasSubmited: false,
      savePropsValues: {}
    };

    this.ibanStatus = {
      1: 'N/A',
      2: 'Enregistré via API',
      3: 'Non utilisé',
      4: 'En attente de vérification',
      5: 'Activé',
      6: 'Rejeté par la banque',
      7: 'Pas de titulaire',
      8: 'Désactivé',
      9: 'Rejeté par Lemonway'
    };

    this.lemonWayAccountStatus = {
      1: 'Non inscrit',
      2: 'Inscrit, pièces incomplètes',
      3: 'Inscrit, pièces rejetées',
      5: 'Inscrit, KYC1',
      6: 'Inscrit, KYC2',
      7: 'Vérification en cours KYC3',
      8: 'Inscrit, pièces expirées',
      10: 'Bloqué',
      12: 'Fermé',
      13: 'Documents manquants, KYC3',
      14: 'Client occasionnel',
      16: 'Compte technique'
    };
  }

  render() {
    const {
      handleChange,
      values,
      errors,
      touched,
      handleBlur,
      isSelected,
      hasRegisteredIban,
      ibanHolder,
      iban,
      ibanStatus
    } = this.state.hasSubmited ? this.state.savePropsValues : this.props;

    return (
      <div id="view3" className={`view ${isSelected ? `current` : ``}`}>
        <form className="form-block">
          <h3 className="form-block-title">
            <i className="icon-warning" />
            <FormattedMessage
              id="app.name-view-banc"
              defaultMessage="Vos coordonnées bancaires"
            />
          </h3>
          <div className={`form-group`}>
            <h4 className="form-block-title-section">
              <FormattedMessage
                id="app.info-lw-account"
                defaultMessage="Informations du compte bancaire propriétaire"
              />
            </h4>
            <span className="iban-status">
              Etat du compte :{' '}
              {this.props.lemonWayAccountStatus ? (
                <span className="validated">
                  {this.lemonWayAccountStatus[this.props.lemonWayAccountStatus]}
                </span>
              ) : (
                <span className="validated">Inconnu</span>
              )}
            </span>
          </div>
          <h4 className="form-block-title-section">
            <FormattedMessage
              id="app.International-identification"
              defaultMessage="Vos coordonnées bancaires"
            />
          </h4>
          <div
            className={`form-group ${values.iban !== '' ? 'on-focus' : ''} ${
              errors.iban ? 'has-error has-feedback' : ''
            }`}
          >
            <div
              className={`form-input ${values.iban !== '' ? 'on-focus' : ''} ${
                errors.iban ? 'has-error has-feedback' : ''
              }`}
            >
              <input
                className="form-input-field"
                type="text"
                id="inputBankAccount"
                name="iban"
                value={values.iban}
                onChange={handleChange}
                onBlur={handleBlur}
                maxLength="34"
                readOnly={false}
              />
              <label className="form-input-label" htmlFor="inputBankAccount">
                <span className="form-input-label-content">IBAN</span>
              </label>
            </div>
            {errors.iban && touched.iban && (
              <span className="help-block">{errors.iban}</span>
            )}
            {hasRegisteredIban && ibanStatus ? (
              <span className="iban-status">
                Statut du compte IBAN :{' '}
                <span
                  className={
                    parseInt(ibanStatus) === 5 ? 'validated' : 'waiting'
                  }
                >
                  {this.ibanStatus[ibanStatus]}
                </span>
              </span>
            ) : (
              <span className="iban-status">
                Statut du compte IBAN :{' '}
                <span className="validated">Inconnu</span>
              </span>
            )}
          </div>
          <p className="text-legend">
            <FormattedMessage
              id="app.description-iban"
              defaultMessage="Les 4 premiers caractères de l'IBAN sont constitués de 2 lettres et 2 chiffres :"
            />
            <br />
            exemple FR 11XXXXXXXXXXXXXXXXXXXXXXX
          </p>
          <h4 className="form-block-title-section">
            <FormattedMessage
              id="app.info-iban"
              defaultMessage="Nom et adresse du titulaire du compte (tels que figurant sur le R.I.B.)"
            />
          </h4>
          {hasRegisteredIban ? (
            <div className={`form-group on-focus`}>
              <div className={`form-input on-focus`}>
                <input
                  name="firstNameBank"
                  value={ibanHolder}
                  className="form-input-field"
                  type="text"
                  id="inputOwnerName"
                  readOnly={true}
                />
                <label className="form-input-label" htmlFor="inputOwnerName">
                  <span className="form-input-label-content">
                    <FormattedMessage
                      id="app.cardholder-first-name"
                      defaultMessage="Nom du titulaire"
                    />
                  </span>
                </label>
              </div>
              <div className="col-xs-12 col-sm-4">
                <button
                  type="submit"
                  className={`btn btn-primary btn-block ${
                    !errors.iban && values.iban !== iban ? '' : 'btn-disable'
                  }`}
                  disabled={
                    errors.iban !== undefined || values.iban === iban ||
                    this.props.isSubmitting
                  }
                  onClick={(e) => {
                    e.preventDefault()
                    this.setState({
                      savePropsValues: this.props,
                      hasSubmited: true
                    });
                    this.props.handleSubmitUpdatedIban(values);
                  }}
                >
                  {this.props.isSubmitting || this.props.isLoading ? (
                    'Loading...'
                  ) : (
                    <FormattedMessage
                      id="app.btn-update"
                      defaultMessage="Éditer"
                    />
                  )}
                </button>
              </div>
            </div>
          ) : (
            <div className="row">
              <div className="col-xs-12 col-sm-6">
                <div
                  className={`form-group ${
                    values.lastNameBank !== '' ? 'on-focus' : ''
                  } ${errors.lastNameBank ? 'has-error has-feedback' : ''}`}
                >
                  <div
                    className={`form-input ${
                      values.lastNameBank !== '' ? 'on-focus' : ''
                    } ${errors.lastNameBank ? 'has-error has-feedback' : ''}`}
                  >
                    <input
                      className="form-input-field"
                      type="text"
                      id="inputOwnerSurname"
                      name="lastNameBank"
                      value={values.lastNameBank}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      maxLength={256}
                    />
                    <label
                      className="form-input-label"
                      htmlFor="inputOwnerSurname"
                    >
                      <span className="form-input-label-content">
                        <FormattedMessage
                          id="app.cardholder-name"
                          defaultMessage="Nom du titulaire"
                        />
                      </span>
                    </label>
                  </div>
                  {errors.lastNameBank && touched.lastNameBank && (
                    <span className="help-block">{errors.lastNameBank}</span>
                  )}
                </div>
              </div>
              <div className="col-xs-12 col-sm-6">
                <div
                  className={`form-group ${
                    values.firstNameBank !== '' ? 'on-focus' : ''
                  } ${errors.firstNameBank ? 'has-error has-feedback' : ''}`}
                >
                  <div
                    className={`form-input ${
                      values.firstNameBank !== '' ? 'on-focus' : ''
                    } ${errors.firstNameBank ? 'has-error has-feedback' : ''}`}
                  >
                    <input
                      name="firstNameBank"
                      value={values.firstNameBank}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      className="form-input-field"
                      type="text"
                      id="inputOwnerName"
                      maxLength={256}
                    />
                    <label
                      className="form-input-label"
                      htmlFor="inputOwnerName"
                    >
                      <span className="form-input-label-content">
                        <FormattedMessage
                          id="app.cardholder-first-name"
                          defaultMessage="Nom du titulaire"
                        />
                      </span>
                    </label>
                  </div>
                  {errors.firstNameBank && touched.firstNameBank && (
                    <span className="help-block">{errors.firstNameBank}</span>
                  )}
                </div>
              </div>
              <div className="col-xs-12">
                <div
                  className={`form-group ${
                    values.streetBank !== '' ? 'on-focus' : ''
                  } ${errors.streetBank ? 'has-error has-feedback' : ''}`}
                >
                  <div
                    className={`form-input ${
                      values.streetBank !== '' ? 'on-focus' : ''
                    } ${errors.streetBank ? 'has-error has-feedback' : ''}`}
                  >
                    <input
                      pattern="[A-Za-z]"
                      required
                      className="form-input-field"
                      type="text"
                      name="streetBank"
                      id="inputBankStreetNumber"
                      value={values.streetBank}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      maxLength={256}
                    />
                    <label
                      className="form-input-label"
                      htmlFor="inputBankStreetNumber"
                    >
                      <span className="form-input-label-content">
                        <FormattedMessage
                          id="app.parking-locality"
                          defaultMessage="Adresse"
                        />
                      </span>
                    </label>
                  </div>
                  {errors.streetBank && touched.streetBank && (
                    <span className="help-block">{errors.streetBank}</span>
                  )}
                </div>
              </div>
              <div className="col-xs-12">
                <div
                  className={`form-group ${
                    values.addressComplBank !== '' ? 'on-focus' : ''
                  } ${errors.addressComplBank ? 'has-error has-feedback' : ''}`}
                >
                  <div
                    className={`form-input ${
                      values.addressComplBank !== '' ? 'on-focus' : ''
                    } ${
                      errors.addressComplBank ? 'has-error has-feedback' : ''
                    }`}
                  >
                    <input
                      required
                      className="form-input-field"
                      type="text"
                      id="inputBankAddressCompl"
                      value={values.addressComplBank}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      name="addressComplBank"
                    />
                    <label
                      className="form-input-label"
                      htmlFor="inputBankAddressCompl"
                    >
                      <span className="form-input-label-content">
                        <FormattedMessage
                          id="app.parking-supplement"
                          defaultMessage="Complément d'adresse"
                        />
                      </span>
                    </label>
                  </div>
                  {errors.addressComplBank && touched.addressComplBank && (
                    <span className="help-block">
                      {errors.addressComplBank}
                    </span>
                  )}
                </div>
              </div>
              <div className="col-xs-12 col-sm-4">
                <div
                  className={`form-group ${
                    values.zipCodeBank !== '' ? 'on-focus' : ''
                  } ${errors.zipCodeBank ? 'has-error has-feedback' : ''}`}
                >
                  <div
                    className={`form-input ${
                      values.zipCodeBank !== '' ? 'on-focus' : ''
                    } ${errors.zipCodeBank ? 'has-error has-feedback' : ''}`}
                  >
                    <input
                      required
                      className="form-input-field"
                      min={1}
                      type="text"
                      id="inputBankZIP"
                      value={values.zipCodeBank}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      name="zipCodeBank"
                      maxLength={10}
                    />
                    <label className="form-input-label" htmlFor="inputBankZIP">
                      <span className="form-input-label-content">
                        <FormattedMessage
                          id="app.parking-codePostal"
                          defaultMessage="Code postal"
                        />
                      </span>
                    </label>
                  </div>
                  {errors.zipCodeBank && touched.zipCodeBank && (
                    <span className="help-block">{errors.zipCodeBank}</span>
                  )}
                </div>
              </div>
              <div className="col-xs-12 col-sm-8">
                <div
                  className={`form-group ${
                    values.cityBank !== '' ? 'on-focus' : ''
                  } ${errors.cityBank ? 'has-error has-feedback' : ''}`}
                >
                  <div
                    className={`form-input ${
                      values.cityBank !== '' ? 'on-focus' : ''
                    } ${errors.cityBank ? 'has-error has-feedback' : ''}`}
                  >
                    <input
                      required
                      className="form-input-field"
                      name="cityBank"
                      type="text"
                      id="inputBankCity"
                      value={values.cityBank}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      maxLength={140}
                    />
                    <label className="form-input-label" htmlFor="inputBankCity">
                      <span className="form-input-label-content">
                        <FormattedMessage
                          id="app.parking-city"
                          defaultMessage="Ville"
                        />
                      </span>
                    </label>
                  </div>
                  {errors.cityBank && touched.cityBank && (
                    <span className="help-block">{errors.cityBank}</span>
                  )}
                </div>
              </div>
              <div className="col-xs-12 col-sm-8">
                <div
                  className={`form-group ${
                    values.countryBank !== '' ? 'on-focus' : ''
                  } ${errors.countryBank ? 'has-error has-feedback' : ''}`}
                >
                  <div
                    className={`form-input ${
                      values.countryBank !== '' ? 'on-focus' : ''
                    } ${errors.countryBank ? 'has-error has-feedback' : ''}`}
                  >
                    <input
                      required
                      className="form-input-field"
                      name="countryBank"
                      type="text"
                      id="inputBankCountry"
                      value={values.countryBank}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      maxLength={3}
                    />
                    <label
                      className="form-input-label"
                      htmlFor="inputBankCountry"
                    >
                      <span className="form-input-label-content">
                        <FormattedMessage
                          id="app.country"
                          defaultMessage="Pays"
                        />
                      </span>
                    </label>
                  </div>
                  {errors.countryBank && touched.countryBank && (
                    <span className="help-block">{errors.countryBank}</span>
                  )}
                </div>
              </div>
              <div className="col-xs-12 col-sm-4">
                <button
                  type="submit"
                  className={`btn btn-primary btn-block ${
                    !this.props.isValid || this.props.isSubmitting
                      ? 'btn-disable'
                      : ''
                  }`}
                  onClick={(e, v, r) => {
                    this.setState({
                      savePropsValues: this.props,
                      hasSubmited: true
                    });
                    this.props.handleSubmit(e, v, r);
                  }}
                  disabled={!this.props.isValid || this.props.isSubmitting}
                >
                  {this.props.isSubmitting || this.props.isLoading ? (
                    'Loading...'
                  ) : this.props.detailBanck ? (
                    <FormattedMessage
                      id="app.btn-update"
                      defaultMessage="Éditer"
                    />
                  ) : (
                    <FormattedMessage
                      id="app.btn-save"
                      defaultMessage="Enregistrer"
                    />
                  )}
                </button>
              </div>
            </div>
          )}
        </form>
      </div>
    );
  }
}

const validationSchema = Yup.object().shape({
  iban: Yup.mixed()
    .required(`L'IBAN est un champ requis`)
    .test('Iban Format', `Le format de l'IBAN est incorrect`, val =>
      IBAN.isValid(val)
    ),
  firstNameBank: Yup.string()
    .required('The first name is a required field')
    .min(2)
    .max(256, 'Le prénom ne doit pas dépasser 256 caractères'),
  lastNameBank: Yup.string()
    .required('The last name is a required field')
    .min(2)
    .max(256, 'Le nom ne doit pas dépasser 256 caractères'),
  streetBank: Yup.string()
    .required('The street is a required field')
    .max(256, 'Le champ ne doit pas dépasser 256 caractères'),
  zipCodeBank: Yup.string()
    .required('The zip code is a required field')
    .max(10, 'Le champ ne doit pas dépasser 10 caractères'),
  cityBank: Yup.string()
    .required('The city is a required field')
    .max(140, 'La champ ne doit pas dépasser 140 caractères'),
  countryBank: Yup.string()
    .required('The country is a required field')
    .length(3, 'Le code pays doit être composé de 3 caractères')
});

const formatValues = ({
  iban,
  firstName,
  lastName,
  zipCode,
  addressComplBank,
  streetBank,
  cityBank,
  countryBank
}) => ({
  iban: iban || '',
  firstNameBank: firstName || '',
  lastNameBank: lastName || '',
  streetBank: streetBank || '',
  addressComplBank: addressComplBank || '',
  zipCodeBank: zipCode || '',
  cityBank: cityBank || '',
  countryBank: countryBank || ''
});

export default withFormik({
  enableReinitialize: true,
  validateOnMount: true,
  mapPropsToValues: props => formatValues(props),
  validationSchema: () => validationSchema,
  handleSubmit: async (values, { setSubmitting, props }) => {
    await props.handleSubmitBank(values);
    setSubmitting(false);
  },
  displayName: 'BankingForm'
})(Bank);
