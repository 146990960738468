import React, { Component } from 'react';
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl';

class Main extends Component {
  componentDidMount() {
    this.props.charts();
  }

  render() {
    return (
      <div className="dashboard-card dashboard-card-main dashboard-part dashboard-visible">
        <div className="dashboard-card-content">
          <h3 className="dashboard-card-title">
            <FormattedMessage
              id="app.dashboard-card-title"
              defaultMessage="Bienvenue sur votre dashboard WATTPARK Pro"
            />
          </h3>
        </div>
        <div className="dashboard-card-stats">
          <div className="charts">
            <svg className="gauge" />
            <div className="charts-legend">
              <FormattedMessage
                id="app.taux"
                defaultMessage="Taux d'occupation actuel"
              />
            </div>
          </div>
        </div>
        <ul className="list-legend">
          <li className="list-legend-item">
            <span className="list-legend-item-color list-legend-item-color-1" />{' '}
            {this.props.libre}&nbsp;{' '}
            <FormattedMessage
              id="app.free-car-parks"
              defaultMessage="Parkings libres"
            />
          </li>
          <li className="list-legend-item">
            <span className="list-legend-item-color list-legend-item-color-2" />{' '}
            {this.props.occupe !== 0 ? this.props.occupe : 0}&nbsp;{' '}
            <FormattedMessage
              id="app.occupied-car-parks"
              defaultMessage="Parkings occupés"
            />
          </li>
          <li className="list-legend-item">
            <span className="list-legend-item-color list-legend-item-color-3" />{' '}
            {this.props.enAttente}&nbsp;{' '}
            <FormattedMessage
              id="app.car-parks-waiting-for-information"
              defaultMessage="Parkings en attente d’information"
            />
          </li>
        </ul>
      </div>
    );
  }
}

export default Main;
