import React from 'react';
import { FormattedMessage } from 'react-intl';

const BillingLink = ({
  bookingId,
  file,
  idDesktop,
  idMobile,
  defaultMess,
  className = 'btn-primary',
  handleFileGeneration,
  type,
  disable
}) => {
  const handleBtn = event => {
    event.preventDefault();
    handleFileGeneration(bookingId, type);
  };

  return (
    <div>
      <button
        onClick={handleBtn}
        disabled={disable}
        className={`btn btn-small btn-block ${className} ${
          disable ? 'disabled' : ''
        }`}
      >
        <FormattedMessage id={idDesktop} defaultMessage={defaultMess} />
      </button>
      <button
        onClick={handleBtn}
        disabled={disable}
        className="certification-item-action-link certification-item-action-link-secondary"
      >
        <FormattedMessage id={idMobile} defaultMessage={defaultMess} />
      </button>
    </div>
  );
};
export default BillingLink;
