import React, { Component } from 'react';
import Address from './../../../Layouts/Forms/address';
import Details from './../../../Layouts/Forms/details';
import Characteristics from './../../../Layouts/Forms/characteristics';
import Network from './../../../Layouts/Forms/network';
import Receipt from './../../../Layouts/Forms/receipt';
import Pictures from './../../../Layouts/Forms/pictures';
import ParkingName from '../../../Layouts/Forms/name';
import Typologies from './../../../Layouts/Forms/typologies';
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl';
import $ from 'jquery';

class Information extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fileName: this.props.fileName,
      children: this.props.children
    };
    this.onFileChange = this.onFileChange.bind(this);
    this.onClickSelect = this.onClickSelect.bind(this);
  }

  onClickSelect() {
    $('#select-' + this.props.parkingId).on('click', function() {
      if ($(this).hasClass('is-opened')) {
        $(this).removeClass('is-opened');
      } else {
        $(this).addClass('is-opened');
      }
    });
  }

  onFileChange(event) {
    if (event.target.files && event.target.files[0]) {
      this.setState({
        fileName: event.target.files[0].name
      });
    }
  }

  componentDidMount() {
    document.title = `WATTPARK - ${this.props.name}`;
  }

  render() {
    const { isCompany } = JSON.parse(localStorage.getItem('user'));
    return (
      <div id="view2" className="view">
        <Pictures method={this.props.onImageChange} img={this.props.children} />
        <ParkingName
          error={this.props.error}
          value={this.props.name}
          method={this.props.handleChange}
        />
        <div className="form-block">
          <Address
            error={this.props.error}
            valueinputAddress={this.props.address}
            valueinputAddressCompl={this.props.addressCompl}
            valueinputZIP={this.props.zipCode}
            valueinputCity={this.props.city}
            valueinputStreet={this.props.street}
            valueinputLat={this.props.lat}
            valueinputLong={this.props.longitude}
            valueinputCountry={this.props.country}
            method={this.props.handleChange}
          />
        </div>
        <Receipt
          fileName={this.props.fileName}
          method={this.props.onFileChange}
        />
        {isCompany ? (
          <Typologies
            typologies_id={this.props.typologies_id}
            urlWebSite={this.props.urlWebSite}
            method={this.props.handleChange}
          />
        ) : null}
        <Details
          error={this.props.error}
          detailsValue={this.props.details}
          method={this.props.handleChange}
        />
        <Characteristics
          error={this.props.error}
          characteristics={this.props.characteristics}
          characteristicsParking={this.props.characteristicParking}
          isHeightMax={this.props.heightMax}
          method={this.props.handleChange}
        />
        <Network
          parkingId={this.props.parkingId}
          onClickSelect={this.onClickSelect}
          method={this.props.onChangeSelect}
          type={this.props.energyProviderType}
        />

        <div className="view-footer">
          <button
            onClick={this.props.onClickCancel}
            className="btn btn-secondary btn-block"
          >
            <FormattedMessage id="app.btn-cancel" defaultMessage="Annuler" />
          </button>
          <button
            disabled={this.props.enabled || this.props.isLoading}
            type="submit"
            className={
              this.props.enabled || this.props.isLoading
                ? 'btn btn-primary btn-block btn-disable'
                : 'btn btn-primary btn-block'
            }
            onClick={this.props.handleSubmit}
          >
            {' '}
            {this.props.isLoading ? (
              <FormattedMessage
                id="app.loading"
                defaultMessage="Chargement..."
              />
            ) : (
              <FormattedMessage
                id="app.apply-changes"
                defaultMessage="Appliquer"
              />
            )}
          </button>
        </div>
        <br />
        <br />
      </div>
    );
  }
}

export default Information;
