import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import Question from './../Layouts/Forms/question';
import faqQuestions from '../../content/faq';
import { DocumentTitle } from '../../utils';

class Faq extends Component {
  constructor(props) {
    super(props);
    this.questions = faqQuestions;
  }

  render() {
    return (
      <div className="main-container faq">
        {DocumentTitle('app.title-faq', 'WATTPARK - FAQ')}
        <div className="container">
          <div className="row">
            <div className="col-xs-12 col-sm-8 col-sm-offset-2">
              <div className="form-block">
                <h3 className="form-block-title">Questions fréquentes</h3>
                {this.questions.map(question => (
                  <Question
                    key={`question-${question.id}`}
                    questionKey={`question-${question.id}`}
                    question={question}
                  />
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Faq;
