import {
  FETCH_GET_ISSUSE,
  FETCH_GET_ISSUSE_BY_PARKING
} from '../actions/issuesAction';

export default function(state = [], action) {
  switch (action.type) {
    case FETCH_GET_ISSUSE:
      return {
        ...state,
        issuesAllParkingList: action.payload,
        loading: true,
        message: action.message
      };
    case FETCH_GET_ISSUSE_BY_PARKING:
      return {
        ...state,
        issuesParkingList: action.payload,
        loading: true,
        messageIssueParking: action.message
      };
    default:
      return { ...state };
  }
}
