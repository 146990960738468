import React, {useEffect, useState} from 'react';
import * as moment from 'moment';
import Spinner from "../../utils/Spinner";
import {FormattedMessage} from 'react-intl';
import {Link} from 'react-router-dom';
import * as ROUTES from '../../constants/routes';
import {DocumentTitle, nav} from '../../utils';
import {
  fetchBookingsListBilling,
  generateBookingDoc
} from '../../actions/filesAction';
import {connect} from 'react-redux';
import BookingBill from './Booking';
import Pagination from '../Layouts/Pagination/pagination';
import Alert from '../Layouts/Alert/alert';

const PER_PAGE = 5;

function AdminBilling(props) {
  const [inputSearch, setInputSearch] = useState(moment().format('YYYY-MM-DD'));
  const [currentPage, setCurrentPage] = useState(1);
  const [showAlert, setShowAlert] = useState(false);
  let {bookingsList, totalPages = 1, alert} = props;

  useEffect(() => {
    props.fetchBookings(inputSearch);
    nav.init();
  }, []);

  useEffect(() => {
    if (alert !== false) {
      props.fetchBookings(inputSearch);
      setShowAlert(true);
      setTimeout(() => {
        alert = false;
        setShowAlert(false);
      }, 3000);
    }
  }, [alert]);

  const logout = () => {
    localStorage.clear();
    window.location.href = ROUTES.LOGIN;
  };

  const handleFileGeneration = (bookingId, type) => {
    props.generateBookingDoc(bookingId, type);
  };

  const handleChangeDate = event => {
    const target = event.target;
    const {value} = target;
    setInputSearch(value);
    props.fetchBookings(value);
  };

  const changePage = page => {
    setCurrentPage(page);
  };

  return (
    <div className="main-container certification invoices">
      {DocumentTitle('app.title-billing', 'WATTPARK - Facturation')}
      <header className="header">
        <div className="header-left">
          <div className="logo-secondary">
            <Link to={ROUTES.DASHBOARD_PARKINGS}>
              <img src="/img/logo_no_text.svg" alt="Logo sans texte Wattpark"/>
            </Link>
          </div>
          <Link className="link" to={ROUTES.DASHBOARD_PARKINGS}>
            <i className="icon-arrow-left"/>
            <span className="link-text">
              <FormattedMessage id="app.return" defaultMessage="Retour"/>
            </span>
          </Link>
          <nav className="nav-main">
            <Link className="nav-main-item" to={ROUTES.DASHBOARD_PARKINGS}>
              Dashboard
            </Link>
            <Link className="nav-main-item" to={ROUTES.ACCOUNT}>
              Compte
            </Link>
            <Link className="nav-main-item current" to={ROUTES.BILLING}>
              <FormattedMessage id="app.navbilling" defaultMessage="Billing"/>
            </Link>
            <div className="nav-main-user">
              <i className="icon-user"/>
              <div className="nav-main-user-name">
                {localStorage.getItem('username')}
              </div>
              <Link to="" className="nav-main-user-connexion" onClick={logout}>
                <FormattedMessage
                  id="app.navLogout"
                  defaultMessage="Déconnexion"
                />
              </Link>
            </div>
          </nav>
        </div>
        <div className="header-center">
          <h2 className="header-title">
            <FormattedMessage
              id="app.billing-creation"
              defaultMessage="Facturation"
            />
          </h2>
        </div>
        <div className="header-right">
          <h2 className="nav-trigger-title">Facturation</h2>
          <div className="nav-trigger">
            <span className="nav-trigger-line"/>
            <span className="nav-trigger-line"/>
            <span className="nav-trigger-line"/>
          </div>
        </div>
      </header>
      <div className="screen-filter"/>
      <div className="container">
        {
          bookingsList.loading ?
            <Spinner/>
            :
            <div className="row">
              <div className="col-xs-12 col-md-4">
                <div className="form-input on-focus">
                  <input
                    required
                    className={
                      inputSearch
                        ? 'form-input-field on-focus'
                        : 'form-input-field'
                    }
                    type="date"
                    name="bookingDate"
                    id="bookingDate"
                    value={inputSearch}
                    onChange={handleChangeDate}
                    data-date=""
                    data-date-format="DD MMMM YYYY"
                  />
                  <label className="form-input-label" htmlFor="bookingDate">
                  <span className="form-input-label-content">
                    <FormattedMessage
                      id="app.bookingDate"
                      defaultMessage="Date de réservation"
                    />
                  </span>
                  </label>
                </div>
              </div>
              <div className="col-xs-12 col-md-8">
                {showAlert && (
                  <Alert
                    nameAlert={'Effectué'}
                    type={'alerts success'}
                    style={false}
                    message={'Elément créé avec succès'}
                  />
                )}
                <div className="view-wrapper">
                  <div id="view0" className="view current">
                    <div className="form-block form-block">
                      <h3 className="form-block-title">
                        <FormattedMessage
                          id="app.reservations"
                          defaultMessage="Bookings"
                        />
                        ({bookingsList.length})
                      </h3>
                      {bookingsList.length > 0 &&
                        bookingsList
                          .slice(
                            currentPage * PER_PAGE - PER_PAGE,
                            currentPage * PER_PAGE
                          )
                          .map((booking, idx) => (
                            <BookingBill
                              key={idx}
                              type="empty"
                              bookingToShow={booking}
                              handleFileGeneration={handleFileGeneration}
                            />
                          ))}
                      <Pagination
                        pages={totalPages}
                        actualPage={currentPage}
                        handlePage={changePage}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
        }
      </div>
    </div>
  );
}

function mapStateToProps({files}) {
  return {
    bookingsList: files.bookingsList.list,
    totalPages: Math.ceil(files.bookingsList.list.length / PER_PAGE),
    currentPage: 1,
    alert: files.docCreated
  };
}

function mapDispatchToProps(dispatch, ownProps) {
  return {
    fetchBookings: date => {
      dispatch(fetchBookingsListBilling(date));
    },
    generateBookingDoc: (bookingId, docType) => {
      dispatch(generateBookingDoc(bookingId, docType));
    }
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AdminBilling);
