import React from 'react';
import { FormattedMessage } from 'react-intl';
import ModalCustom from './modalCustom';
import { MODAL_USER_DELETE } from './modals';

const ModalDeleteUser = ({ handleConfirm, type }) => {
  if (type === MODAL_USER_DELETE)
    return (
      <ModalCustom
        title={
          <FormattedMessage id="app.attention" defaultMessage="Attention" />
        }
        content={
          <FormattedMessage
            id="app.confirm-user-delete"
            defaultMessage="Êtes-vous sûr de vouloir supprimer cet utilisateur de la liste ?"
          />
        }
        handleConfirm={handleConfirm}
      />
    );
  else return null;
};

export default ModalDeleteUser;
