import configDev from './config.dev';
import configStaging from './config.staging';
import configProd from './config.prod';

const getEnvironment = () => {
  switch (process.env.REACT_APP_STAGE) {
    case 'production':
      return configProd;
      break;
    case 'staging':
      return configStaging;
      break;
    default:
      return configDev;
      break;
  }
};

export default getEnvironment();
