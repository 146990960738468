import React from 'react';

const Term = ({ term }) => {
  return (
    <div className="form-block" key={`term-${term.id}`}>
      <h3 className="form-block-title">{term.title}</h3>
      <div
        className="form-block-text"
        dangerouslySetInnerHTML={{ __html: term.content }}
      ></div>
    </div>
  );
};
export default Term;
