import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import {
  hideModal,
  showModalParkingActivation
} from '../../../actions/modalAction';
import { fetchUpdateParkingStatus } from '../../../actions/parkingAction';

class Mode extends Component {
  constructor(props) {
    super(props);
    this.handleToggle = this.handleToggle.bind(this);
  }

  handleToggle(e) {
    e.preventDefault();
    const toggledValue = e.target.value === 'close' ? 'available' : 'close';
    if (toggledValue === 'close')
      this.props.showModalParkingActivation({
        parkingId: this.props.parkingId,
        status: toggledValue
      });
    else {
      this.props.fetchUpdateParkingStatus(this.props.parkingId, {
        status: toggledValue
      });
      this.setState({ status: toggledValue });
    }
  }

  render() {
    return (
      <div className="form-block">
        <h3 className="form-block-title">
          <FormattedMessage id="app.methodParkingTab" defaultMessage="Mode" />
        </h3>
        <div className="options">
          <div className="options-title">
            <FormattedMessage
              id="app.public-mode"
              defaultMessage="Mode public"
            />
          </div>
          <div className="toggle-wrapper">
            <div className="toggle">
              <input
                onClick={this.handleToggle}
                className="toggle-input"
                value={this.props.status}
                checked={this.props.status === 'available' ? true : false}
                onChange={this.handleToggle}
                id="toggle-input"
                type="checkbox"
                disabled={this.props.status === 'coming soon' ? true : false}
              />
              <span className="toggle-label">
                {this.props.status === 'available' ? (
                  <FormattedMessage
                    id="app.activated-public-mode"
                    defaultMessage="Activé"
                  />
                ) : null}
                {this.props.status === 'close' ? (
                  <FormattedMessage
                    id="app.activated-public-mode-desactivated"
                    defaultMessage="Desactivé"
                  />
                ) : null}
                {this.props.status === 'coming soon' ? (
                  <FormattedMessage
                    id="app.activated-public-mode-incomplete"
                    defaultMessage="Parking incomplet"
                  />
                ) : null}
              </span>
              <label className="toggle-control" htmlFor="toggle-input" />
            </div>
          </div>
        </div>
        <p className="text-legend">
          <FormattedMessage
            id="app.warning-public-mode"
            defaultMessage="Attention, la désactivation du mode public peut occasionner des annulations de réservation auprès de vos utilisateurs."
          />
        </p>
        <p className="text-legend">
          <FormattedMessage
            id="app.warning-public-mode-resa"
            defaultMessage="Le mode public, rend votre parking accessible à la réservation. En mode privé votre parking n'est plus accessible à la réservation."
          />
        </p>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    modal: state.modal,
    display: state.modal.display
  };
}

export default connect(
  mapStateToProps,
  {
    hideModal,
    showModalParkingActivation,
    fetchUpdateParkingStatus
  }
)(Mode);
