import React, {useEffect, useState} from 'react';
import Spinner from "../../utils/Spinner";
import {Link} from 'react-router-dom';
import {FormattedMessage} from 'react-intl';
import {connect} from 'react-redux';

import * as ROUTES from '../../constants/routes';
import {
  fetchCertificate,
  updateCertificate,
  updateCertificateUserUUID
} from '../../actions/filesAction';

import TextField from './../Inputs/TextField';
import RadioButton from './../Inputs/RadioButton';
import FileWithPreview from './../Inputs/FileWithPreview';
import {nav} from '../../utils';

function CertificationForm({
                             getCertificate,
                             postIrveCertificatFile,
                             updateUserUUID,
                             match,
                             certificate,
                             loading
                           }) {
  const [file, setFile] = useState(null);
  const [src, setSrc] = useState(null);
  const [type, setType] = useState(null);
  const [UUID, setUUID] = useState('');
  const [status, setStatus] = useState('pending');

  // Fetch the certificate, executed once
  useEffect(() => {
    nav.init();
    getCertificate(match.params.parkingId);
  }, []);

  // Executed when the certificate prop updates
  useEffect(() => {
    if (certificate && certificate.file) {
      setSrc(certificate.file);
      setType(certificate.type);
      setStatus(certificate.status);
    }
  }, [certificate]);

  const formattedAddress =
    certificate && certificate.street ? certificate.street : '';

  const logout = () => {
    localStorage.clear();
    window.location.href = ROUTES.LOGIN;
  };

  const saveForm = () => {
    postIrveCertificatFile(match.params.parkingId, file, status);
    if (certificate && !certificate.uuid && UUID) {
      updateUserUUID(certificate.userId, UUID);
      setUUID('');
      getCertificate(match.params.parkingId);
    }
  };

  return (
    <div className="main-container certification certification-import">
      <header className="header">
        <div className="header-left">
          <div className="logo-secondary">
            <Link to={ROUTES.CERTIFICATIONVALIDATION}>
              <img src="/img/arrow_back.svg" alt="Logo sans texte Wattpark"/>
            </Link>
          </div>
          <Link className="link" to={ROUTES.CERTIFICATIONVALIDATION}>
            <i className="icon-arrow-left"/>
            <span className="link-text">
              <FormattedMessage id="app.return" defaultMessage="Retour"/>
            </span>
          </Link>
          <nav className="nav-main">
            <Link className="nav-main-item" to={ROUTES.DASHBOARD_PARKINGS}>
              Dashboard
            </Link>
            <Link className="nav-main-item" to={ROUTES.ACCOUNT}>
              Compte
            </Link>
            <Link
              className="nav-main-item current"
              to={ROUTES.CERTIFICATIONVALIDATION}
            >
              Certificats
            </Link>
            <div className="nav-main-user">
              <i className="icon-user"/>
              <div className="nav-main-user-name">
                {localStorage.getItem('username')}
              </div>
              <Link to="" className="nav-main-user-connexion" onClick={logout}>
                <FormattedMessage
                  id="app.navLogout"
                  defaultMessage="Déconnexion"
                />
              </Link>
            </div>
          </nav>
        </div>
        <div className="header-center">
          <h2 className="header-title">
            <FormattedMessage
              id="app.valid-certificat"
              defaultMessage="Validation des certificats IRVE"
            />
          </h2>
        </div>
        <div className="header-right">
          <h2 className="nav-trigger-title">Certification</h2>
          <div className="nav-trigger">
            <span className="nav-trigger-line"/>
            <span className="nav-trigger-line"/>
            <span className="nav-trigger-line"/>
          </div>
        </div>
      </header>
      <div className="screen-filter"/>
      <div className="search-banner">
        <div className="container">
          <div className="row">
            <div className="col-xs-4">
              <h2 className="title-main">
                <FormattedMessage
                  id="app.irve-certification"
                  defaultMessage="Certification IRVE"
                />
              </h2>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        {
          loading ?
            <Spinner/>
            :
            <>
              {!certificate ? (
                <div className="row">
                  <div className="col-xs-12">
                    <FormattedMessage
                      id="app.no-parking-found"
                      defaultMessage="Le parking que vous avez demandé n'existe pas"
                    />
                  </div>
                  <div className="col-xs-12">
                    <Link className="link" to={ROUTES.CERTIFICATIONVALIDATION}>
                      <FormattedMessage id="app.return" defaultMessage="Retour"/>
                    </Link>
                  </div>
                </div>
              ) : (
                <div className="row">
                  <div className="col-xs-12 col-sm-4">
                    <div className="form-block form-block-no-editable">
                      <h3 className="form-block-title">
                        <FormattedMessage
                          id="app.parking-address"
                          defaultMessage="Adresse du parking"
                        />
                      </h3>
                      <div className="row">
                        <div className="col-xs-12">
                          <TextField
                            name="inputLastName"
                            id="inputLastName"
                            value={certificate && certificate.lastName}
                            label="Nom"
                            readOnly
                          />
                        </div>
                        <div className="col-xs-12">
                          <TextField
                            name="inputFirstName"
                            id="inputFirstName"
                            label="Prénom"
                            value={certificate && certificate.firstName}
                            readOnly
                          />
                        </div>
                        <div className="col-xs-12">
                          <TextField
                            name="inputAddress"
                            id="inputAddress"
                            label="Adresse"
                            value={formattedAddress}
                            readOnly
                          />
                        </div>
                        <div className="col-xs-12">
                          <TextField
                            name="inputAddressCompl"
                            id="inputAddressCompl"
                            label="Complément d'adresse"
                            value=""
                            readOnly
                          />
                        </div>
                        <div className="col-xs-6">
                          <TextField
                            name="inputZIP"
                            id="inputZIP"
                            label="Code postal"
                            value={certificate && certificate.zipCode}
                            readOnly
                          />
                        </div>
                        <div className="col-xs-12">
                          <TextField
                            name="inputCity"
                            id="inputCity"
                            label="Ville"
                            value={certificate && certificate.city}
                            readOnly
                          />
                        </div>
                      </div>
                      <h3 className="form-block-title">
                        <FormattedMessage
                          id="app.ownerUuid"
                          defaultMessage="UUID propriétaire"
                        />
                      </h3>
                      <div className="row">
                        <div className="col-xs-12">
                          {certificate && certificate.uuid ? (
                            <TextField
                              name="inputUUID"
                              id="inputUUID"
                              label="UUID"
                              value={certificate && certificate.uuid}
                              readOnly
                            />
                          ) : (
                            <div className={'form-group'}>
                              <div
                                className={`form-input ${UUID ? 'on-focus' : ''}`}
                              >
                                <input
                                  className={`form-input-field ${
                                    UUID ? 'on-focus' : ''
                                  }`}
                                  name="uuid"
                                  value={UUID}
                                  onChange={e => setUUID(e.target.value)}
                                  type="text"
                                  id="uuid"
                                  autoComplete="no"
                                />
                                <label className="form-input-label" htmlFor="uuid">
                              <span className="form-input-label-content">
                                <FormattedMessage
                                  id="app.uuid"
                                  defaultMessage="UUID"
                                />
                              </span>
                                </label>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="hidden-xs col-sm-12">
                      {certificate && certificate.file && (
                        <div className="form-block">
                          <h3 className="form-block-title">
                            <FormattedMessage
                              id="app.analyze-claim"
                              defaultMessage="Etudier la demande"
                            />
                          </h3>
                          <div className="row">
                            <div className="col-xs-6">
                              <RadioButton
                                name="claim"
                                value="yes"
                                defaultChecked={certificate.status === 'approved'}
                                label={
                                  <FormattedMessage
                                    id="app.approve"
                                    defaultMessage="Valider"
                                  />
                                }
                                onChange={() => setStatus('approved')}
                                className="checkbox-validate"
                                iconClass="validate"
                              />
                            </div>
                            <div className="col-xs-6">
                              <RadioButton
                                name="claim"
                                value="no"
                                defaultChecked={certificate.status === 'rejected'}
                                label={
                                  <FormattedMessage
                                    id="app.refuse"
                                    defaultMessage="Refuser"
                                  />
                                }
                                onChange={() => setStatus('rejected')}
                                className="checkbox-cancel"
                                iconClass="cancel"
                              />
                            </div>
                          </div>
                        </div>
                      )}
                      <div className="form-validation">
                        <button
                          className={`btn btn-third btn-block ${
                            file ||
                            (certificate && status !== 'pending') ||
                            UUID !== ''
                              ? ''
                              : 'btn-disable'
                          }`}
                          onClick={saveForm}
                        >
                          Enregistrer
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="col-xs-12 col-sm-8">
                    <FileWithPreview
                      title="Certification IRVE"
                      removeLabel="Supprimer le certificat"
                      allowRemove={!(certificate && certificate.file)}
                      src={src}
                      onCertificatLoad={loadedFile => {
                        setFile(loadedFile);
                      }}
                      type={type}
                      onRemove={() => {
                        setFile(null);
                      }}
                    />
                  </div>
                  <div className="col-xs-12 hidden-sm hidden-md hidden-lg">
                    {certificate && certificate.file && (
                      <div className="form-block">
                        <h3 className="form-block-title">
                          <FormattedMessage
                            id="app.analyze-claim"
                            defaultMessage="Etudier la demande"
                          />
                        </h3>
                        <div className="row">
                          <div className="col-xs-6">
                            <RadioButton
                              name="claim"
                              value="yes"
                              defaultChecked={certificate.status === 'approved'}
                              label={
                                <FormattedMessage
                                  id="app.approve"
                                  defaultMessage="Valider"
                                />
                              }
                              onChange={() => setStatus('approved')}
                              className="checkbox-validate"
                              iconClass="validate"
                            />
                          </div>
                          <div className="col-xs-6">
                            <RadioButton
                              name="claim"
                              value="no"
                              defaultChecked={certificate.status === 'rejected'}
                              label={
                                <FormattedMessage
                                  id="app.refuse"
                                  defaultMessage="Refuser"
                                />
                              }
                              onChange={() => setStatus('rejected')}
                              className="checkbox-cancel"
                              iconClass="cancel"
                            />
                          </div>
                        </div>
                      </div>
                    )}
                    <div className="form-validation">
                      <button
                        className={`btn btn-third btn-block ${
                          file ||
                          (certificate && status !== 'pending') ||
                          UUID !== ''
                            ? ''
                            : 'btn-disable'
                        }`}
                        onClick={saveForm}
                      >
                        Enregistrer
                      </button>
                    </div>
                  </div>
                </div>
              )}
            </>
        }
      </div>
    </div>
  );
}

const mapStateToProps = ({files}) => {
  return {
    certificate: files.certificate.data || null,
    success: files.certificate.success,
    loading: files.certificate.pending
  };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  getCertificate: id => {
    dispatch(fetchCertificate(id));
  },
  postIrveCertificatFile: (parkingId, file, status) => {
    dispatch(updateCertificate(parkingId, file, status));
  },
  updateUserUUID: (userId, uuid) => {
    dispatch(updateCertificateUserUUID(userId, uuid));
  }
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CertificationForm);
