import config from './../config/index';
import axios from 'axios';

import filesService from './../services/filesService';
import * as ROUTES from '../constants/routes';
import { push } from 'react-router-redux';

const baseUrl = config.baseUrl;

export const FETCH_GET_FILES = 'FETCH_GET_FILES';
export const FETCH_GET_FILES_PENDING = 'FETCH_GET_FILES_PENDING';
export const FETCH_GET_FILES_FULFILLED = 'FETCH_GET_FILES_FULFILLED';
export const FETCH_GET_FILES_REJECTED = 'FETCH_GET_FILES_REJECTED';

export const FETCH_PARKING_WITHOUT_CERTIFICATE =
  'FETCH_PARKING_WITHOUT_CERTIFICATE';
export const FETCH_PARKING_WITHOUT_CERTIFICATE_PENDING =
  'FETCH_PARKING_WITHOUT_CERTIFICATE_PENDING';
export const FETCH_PARKING_WITHOUT_CERTIFICATE_FULFILLED =
  'FETCH_PARKING_WITHOUT_CERTIFICATE_FULFILLED';
export const FETCH_PARKING_WITHOUT_CERTIFICATE_REJECTED =
  'FETCH_PARKING_WITHOUT_CERTIFICATE_REJECTED';
export const FETCH_GET_PARKING_LIST_BILLING = 'FETCH_GET_PARKING_LIST_BILLING';

export const FETCH_GET_FILES_USER = 'FETCH_GET_FILES_USER';
export const FETCH_UPDATE_FILES = 'FETCH_UPDATE_FILES';

export const GET_CERTIFICATE = 'GET_CERTIFICATE';
export const GET_CERTIFICATE_PENDING = 'GET_CERTIFICATE_PENDING';
export const GET_CERTIFICATE_FULFILLED = 'GET_CERTIFICATE_FULFILLED';
export const GET_CERTIFICATE_REJECTED = 'GET_CERTIFICATE_REJECTED';

export const UPDATE_CERTIFICATE_USER_UUID = 'UPDATE_CERTIFICATE_USER_UUID';

export const FETCH_GENERATE_BOOKING_DOC = 'FETCH_GENERATE_BOOKING_DOC';

export function fetchGetFiles() {
  return {
    type: FETCH_GET_FILES,
    payload: filesService.getParkingsCertificate()
  };
}

export function fetchParkingsWithoutCertificate() {
  return {
    type: FETCH_PARKING_WITHOUT_CERTIFICATE,
    payload: filesService.getParkingsWithoutCertificate()
  };
}

export const loadCertificates = () => {
  return dispatch => {
    dispatch(fetchGetFiles());
    dispatch(fetchParkingsWithoutCertificate());
  };
};

export function fetchGetFilesUser() {
  const url = `${baseUrl}/api/user/${localStorage.getItem('firebaseId')}/files`;
  return dispatch => {
    axios
      .get(url, {
        headers: {
          'x-access-token': localStorage.getItem('token'),
          'content-type': 'application/json'
        }
      })
      .then(request => {
        if (request.data.length !== 0) {
          if (request.data.length !== 0) {
            dispatch({
              type: FETCH_GET_FILES_USER,
              payload: request.data
            });
          }
        }
      })
      .catch(() => {
        dispatch({
          type: FETCH_GET_FILES_USER,
          payload: false,
          message: ''
        });
      });
  };
}

export function fetchUpdatetFile(id, data) {
  const url = `${baseUrl}/api/files/${id}?token=${localStorage.getItem(
    'token'
  )}`;
  return dispatch => {
    axios
      .patch(url, data, {
        headers: {
          'x-access-token': localStorage.getItem('token'),
          'content-type': 'application/json'
        }
      })
      .then(request => {
        if (request.data.length !== 0) {
          if (request.data.length !== 0) {
            dispatch({
              type: FETCH_UPDATE_FILES,
              payload: request.data
            });
          }
        }
      })
      .catch(() => {
        dispatch({
          type: FETCH_UPDATE_FILES,
          payload: false
        });
      });
  };
}

export function fetchCertificate(parkingId) {
  return {
    type: GET_CERTIFICATE,
    payload: filesService.getCertificate(parkingId)
  };
}

export function updateCertificate(
  parkingId,
  certifIrvefile,
  status = 'pending'
) {
  const url = `${baseUrl}/api/upload/parking/${parkingId}/IrveCertificate`;
  const urlUpdateStatus = `${baseUrl}/api/upload/parking/${parkingId}/certificateStatus`;
  const formData = new FormData();
  formData.append('certificatIRVEFile', certifIrvefile);
  formData.append('status', status);

  return dispatch => {
    axios
      .post(url, formData, {
        headers: {
          'x-access-token': localStorage.getItem('token'),
          'content-type': 'multipart/form-data'
        }
      })
      .then(response => {
        axios
          .post(
            urlUpdateStatus,
            { status },
            {
              headers: {
                'x-access-token': localStorage.getItem('token'),
                'content-type': 'application/json'
              }
            }
          )
          .then(response => {
            dispatch(push(ROUTES.CERTIFICATIONVALIDATION));
          })
          .catch(error => {
            dispatch({
              type: FETCH_UPDATE_FILES,
              payload: false
            });
          });
      })
      .catch(() => {
        dispatch({
          type: FETCH_UPDATE_FILES,
          payload: false
        });
      });
  };
}

export function updateCertificateUserUUID(userId, uuid) {
  const url = `${baseUrl}/api/user/${userId}/uuid?token=${localStorage.getItem(
    'token'
  )}`;

  return dispatch => {
    axios
      .put(url, { uuid })
      .then(request => {
        dispatch({
          type: UPDATE_CERTIFICATE_USER_UUID,
          payload: request.data
        });
      })
      .catch(() => {
        dispatch({
          type: UPDATE_CERTIFICATE_USER_UUID,
          payload: false
        });
      });
  };
}

export function fetchBookingsListBilling(bookingDate = '') {
  const url = `${baseUrl}/api/bookings/billing?bookingDate=${bookingDate}&token=${localStorage.getItem(
    'token'
  )}`;

  return dispatch => {
    axios
      .get(url)
      .then(request => {
        if (request.data.length !== 0) {
          dispatch({
            type: FETCH_GET_PARKING_LIST_BILLING,
            payload: request.data
          });
        } else {
          dispatch({
            type: FETCH_GET_PARKING_LIST_BILLING,
            payload: []
          });
        }
      })
      .catch(() => {
        dispatch({
          type: FETCH_GET_PARKING_LIST_BILLING,
          payload: false
        });
      });
  };
}

export function generateBookingDoc(bookingId, doctype) {
  const url = `${baseUrl}/api/booking/billing/${bookingId}`;

  return dispatch => {
    axios
      .post(
        url,
        {
          docType: doctype
        },
        {
          headers: {
            'x-access-token': localStorage.getItem('token'),
            'content-type': 'application/json'
          }
        }
      )
      .then(request => {
        if (request.data.length !== 0) {
          dispatch({
            type: FETCH_GENERATE_BOOKING_DOC,
            payload: request.data
          });
        } else {
          dispatch({
            type: FETCH_GENERATE_BOOKING_DOC,
            payload: false
          });
        }
      })
      .catch(() => {
        dispatch({
          type: FETCH_GENERATE_BOOKING_DOC,
          payload: false
        });
      });
  };
}
