import config from './../config/index';
import axios from 'axios';

const baseUrl = config.baseUrl;
export const FETCH_GET_BANCK_DETAIL = 'FETCH_GET_BANCK_DETAIL';
export const FETCH_CREATE_BANCK_DETAIL = 'FETCH_CREATE_BANCK_DETAIL';
export const FETCH_UPDATE_WALLET = 'FETCH_UPDATE_WALLET';
export const FETCH_TRANSFERT_SOLD = 'FETCH_TRANSFERT_SOLD';

const CONFIG = {
  headers: {
    'x-access-token': localStorage.getItem('token'),
    'content-type': 'application/json'
  }
};

export function fetchGetBanckDetail() {
  const url = `${baseUrl}/api/user/${localStorage.getItem(
    'firebaseId'
  )}/getwalletdetails`;
  return dispatch => {
    axios
      .get(url, CONFIG)
      .then(request => {
        if (request.data.length !== 0) {
          dispatch({
            type: FETCH_GET_BANCK_DETAIL,
            payload: request.data
          });
        }
      })
      .catch(err => {
        dispatch({
          type: FETCH_GET_BANCK_DETAIL,
          payload: false
        });
      });
  };
}

export function fetchCreateBanckDetail(data, BanckAddIban) {
  const url = `${baseUrl}/api/user/${localStorage.getItem(
    'firebaseId'
  )}/registerwallet`;
  return dispatch => {
    axios
      .post(url, data, CONFIG)
      .then(request => {
        if (request.data.length !== 0) {
          const urlIban = `${baseUrl}/api/user/${localStorage.getItem(
            'firebaseId'
          )}/registeriban`;
          axios
            .post(
              urlIban,
              {...data, ...BanckAddIban},
              {
                headers: {
                  'x-access-token': localStorage.getItem('token'),
                  'content-type': 'application/json'
                }
              }
            )
            .then(requestIban => {
              if (requestIban.data.length !== 0) {
                dispatch({
                  type: FETCH_CREATE_BANCK_DETAIL,
                  payload: {
                    wallet: request.data,
                    iban: requestIban.data
                  }
                });
              } else {
                dispatch({
                  type: FETCH_CREATE_BANCK_DETAIL,
                  payload: false
                });
              }
            })
            .catch(err => {
              dispatch({
                type: FETCH_CREATE_BANCK_DETAIL,
                payload: false
              });
            });
        } else {
          dispatch({
            type: FETCH_CREATE_BANCK_DETAIL,
            payload: false
          });
        }
      })
      .catch(err => {
        dispatch({
          type: FETCH_CREATE_BANCK_DETAIL,
          payload: false
        });
      });
  };
}

export function fetchUpdateWallet(data, BanckAddIban) {
  const url = `${baseUrl}/api/user/${localStorage.getItem(
    'firebaseId'
  )}/updatewalletdetails?`;
  return dispatch => {
    axios
      .post(url, data, CONFIG)
      .then(request => {
        if (request.data.length !== 0) {
          if (Object.keys(BanckAddIban).length > 0) {
            const urlIban = `${baseUrl}/api/user/${localStorage.getItem(
              'firebaseId'
            )}/registeriban`;

            axios
              .post(urlIban, BanckAddIban, {
                headers: {
                  'x-access-token': localStorage.getItem('token'),
                  'content-type': 'application/json'
                }
              })
              .then(iban => {
                dispatch({
                  type: FETCH_UPDATE_WALLET,
                  payload: iban.data
                });
              });
          } else {
            dispatch({
              type: FETCH_UPDATE_WALLET,
              payload: request.data
            });
          }
        }
      })
      .catch(err => {
        dispatch({
          type: FETCH_UPDATE_WALLET,
          payload: false
        });
      });
  };
}

export function fetchTransfertSolde(data) {
  const url = `${baseUrl}/api/user/${localStorage.getItem(
    'firebaseId'
  )}/moneyout`;
  return dispatch => {
    axios
      .post(url, data, CONFIG)
      .then(request => {
        if (request.data.length !== 0) {
          dispatch({
            type: FETCH_TRANSFERT_SOLD,
            payload: request.data
          });
        }
      })
      .catch(err => {
        dispatch({
          type: FETCH_TRANSFERT_SOLD,
          payload: err.data
        });
      });
  };
}

export function fetchUpdateIban(data) {
  const url = `${baseUrl}/api/user/${localStorage.getItem(
    'firebaseId'
  )}/update-iban`;

  return dispatch => {
    return axios
      .post(url, data, CONFIG)
      .then(iban => {
          dispatch({
            type: FETCH_UPDATE_WALLET,
            payload: iban.data
          })
        }
      )
      .catch((error) => {
          dispatch({
            type: FETCH_UPDATE_WALLET,
            payload: false
          })
        }
      );
  };
}
