import React from 'react';
import {createRoot} from 'react-dom/client';
import {Provider} from 'react-redux';
import {pdfjs} from 'react-pdf';
import configureStore from './store';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import {IntlProvider} from 'react-intl';
import HttpsRedirect from 'react-https-redirect';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

let language = navigator.language.split(/[-_]/)[0]; // language without region code
const loadLocaleData = (language) => {
  switch (language) {
    case 'fr' :
      return import('./translations/fr.json')
    default :
      return import('./translations/en.json')
  }
}

const messages = await loadLocaleData(language)
const container = document.getElementById('root')
const root = createRoot(container);

root.render(
  <IntlProvider locale={language} messages={messages}>
    <Provider store={configureStore()}>
      <HttpsRedirect>
        <App/>
      </HttpsRedirect>
    </Provider>
  </IntlProvider>
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
