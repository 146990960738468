import React from 'react';
import SchedulerPrice from './scheduler-price';
import { FormattedMessage } from 'react-intl';

const Mode = ({ daysSlots, handleMethod }) => {
  return (
    <div className="form-block">
      <h3 className="form-block-title">
        <FormattedMessage
          id="app.Weekly-availability"
          defaultMessage="Mise en disponibilité hebdomadaire"
        />
      </h3>
      <p className="text-legend">
        <FormattedMessage
          id="app.info-hour"
          defaultMessage="Pour ajouter une plage h"
        />
      </p>
      <SchedulerPrice daysSlots={daysSlots} handleMethod={handleMethod} />
    </div>
  );
};
export default Mode;
