import React, { Component } from 'react';
import * as moment from 'moment';
import BillingLink from '../Layouts/Forms/billingLink';

const BookingBill = ({ bookingToShow, handleFileGeneration }) => {
  const { address, booking, parking, user, docs } = bookingToShow;

  const transportFileGeneration = (bookingId, type) => {
    handleFileGeneration(bookingId, type);
  };

  const hasInvoice = docs && docs.find(doc => doc.doc_type === 'invoice');
  const hasRefund = docs && docs.find(doc => doc.doc_type === 'creditnote');

  return (
    <div key={`booking-${booking.id}`} className={'certification-item'}>
      <div className="certification-item-info">
        <div className="certification-item-info-title">
          {user.firstName && user.lastName
            ? `${user.firstName} ${user.lastName}`
            : user.firstName
            ? user.firstName
            : user.lastName}
        </div>
        <div className="certification-item-info-content">
          <span className="certification-item-info-content-details">
            Réservation du {moment(booking.bookingDate).format('DD-MM-YY')}
          </span>
        </div>
      </div>
      <div className="certification-item-info">
        <div className="certification-item-info-title">{parking.name}</div>
        <div className="certification-item-info-content">
          {address.street}, {address.city} - {address.zipCode}
        </div>
      </div>
      <div className="certification-item-action">
        <BillingLink
          bookingId={booking.id}
          file={docs.find(doc => doc.doc_type === 'invoice')}
          idDesktop="app.generate-bill"
          idMobile="app.generate-bill"
          defaultMess="Générer une facture"
          type={'invoice'}
          disable={hasInvoice !== undefined}
          handleFileGeneration={transportFileGeneration}
        />
        <BillingLink
          bookingId={booking.id}
          file={docs.find(doc => doc.doc_type === 'creditnote')}
          idDesktop="app.generate-refund"
          idMobile="app.generate-refund"
          defaultMess="Remboursement"
          className="btn-secondary"
          type={'creditnote'}
          disable={hasRefund !== undefined}
          handleFileGeneration={transportFileGeneration}
        />
      </div>
      <div className="invoice-informations">
        <div className="information-block">
          <div className="certification-item-info-content">
            <span className="certification-item-info-content-details">
              Démarrage le {moment(booking.startDate).format('DD-MM-YY HH:mm')}
            </span>
          </div>
        </div>
        <div className="information-block">
          <div className="certification-item-info-content">
            <span className="certification-item-info-content-details">
              {docs && docs.find(doc => doc.doc_type === 'invoice') && (
                <div>
                  Facture num{' '}
                  {docs.find(doc => doc.doc_type === 'invoice').invoice_num}
                </div>
              )}
              {docs && docs.find(doc => doc.doc_type === 'creditnote') && (
                <div>
                  Avoir num{' '}
                  {docs.find(doc => doc.doc_type === 'creditnote').invoice_num}
                </div>
              )}
              {docs.length < 1 && <div>Pas de document généré</div>}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BookingBill;
