import React, { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import SearchNotFound from './search-not-found';
import SearchInput from './search-input';
import MembersList from './list/members-list';
import { paginate } from '../../../../utils';

function EmailSearch({
  value,
  data,
  handleChange,
  allResult,
  email,
  inputRef,
  edit,
  onAdd,
  onUpdate,
  onEdit,
  onDelete,
  onRelaunch,
  className
}) {
  const [currentPage, setPage] = useState(1);
  const [results, setResults] = useState([]);
  const [inputValue, setValue] = useState(email);

  useEffect(() => {
    setResults(allResult);
  }, [allResult]);

  useEffect(() => {
    setResults(data.filter(member => member.email.includes(value)));
  }, [data]);

  useEffect(() => {
    setValue(email);
  }, [email]);

  const resetSearch = () => {
    handleChange({ target: { name: 'searchResult', value: [] } });
    handleChange({ target: { name: 'search', value: '' } });
  };

  return (
    <div className="search">
      <div className="search-header">
        {value.length >= 4 && (
          <a className="search-back" onClick={() => resetSearch()}>
            <i className="icon-arrow-left" />
            <FormattedMessage id="app.return" defaultMessage="Retour" />
          </a>
        )}

        <SearchInput
          value={value}
          handleChange={handleChange}
          data={data}
          allResult={results}
          inputRef={inputRef}
        />

        {value.length >= 4 && (
          <p className="search-info">
            <span className="search-info-number">{results.length} </span>
            <span className="search-info-label">
              <FormattedMessage id="app.results" defaultMessage="résultat(s)" />
            </span>
          </p>
        )}
      </div>

      {value.length >= 4 && (
        <>
          <SearchNotFound results={results} />

          <div className="search-content">
            <MembersList
              members={paginate(results, 7)}
              actualPage={currentPage}
              className={className}
              email={inputValue}
              handleChange={handleChange}
              edit={edit}
              onAdd={onAdd}
              onUpdate={onUpdate}
              onDelete={onDelete}
              onEdit={onEdit}
              onRelaunch={onRelaunch}
            />
          </div>
        </>
      )}
    </div>
  );
}

export default EmailSearch;
