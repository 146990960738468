import React, { Component } from 'react';

class TypologiesElem extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div className="col-xs-6 col-sm-4 col-md-3">
        <label className="checkbox checkbox-with-icon">
          {this.props.checked ? (
            <input
              type="radio"
              value={this.props.id}
              name="typologies_id"
              checked
              onChange={this.props.method}
            />
          ) : (
            <input
              type="radio"
              value={this.props.id}
              name="typologies_id"
              onChange={this.props.method}
            />
          )}
          <span className="checkmark" />
          <i className={`checkbox-icon icon-${this.props.icon}`} />
          <span className="checkbox-text">{this.props.name}</span>
        </label>
      </div>
    );
  }
}

export default TypologiesElem;
