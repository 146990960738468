import React from 'react';
import { Link } from 'react-router-dom';

const Action = ({ url, btnName, name, text }) => {
  return (
    <div className="actions-block">
      <div className="actions-block-content">
        <h4 className="actions-block-title"> {name}</h4>
        <p className="actions-block-text">{text}</p>
      </div>
      <Link to={url} className="btn btn-primary btn-fixed">
        {btnName}
      </Link>
    </div>
  );
};
export default Action;
