import React, { useState } from 'react';

function TextField({
  name,
  label,
  readOnly,
  id,
  type,
  title,
  value,
  required,
  handleChange,
  onClick
}) {
  const onChange = e => {
    if (handleChange) handleChange(e);
    // setValue(e.target.value);
  };

  return (
    <div className={`form-input ${value !== '' ? 'on-focus' : ''}`}>
      <input
        className={`form-input-field ${value !== '' ? 'on-focus' : ''}`}
        type={type && 'text'}
        id={id}
        title={title}
        required={required}
        name={name}
        onChange={onChange}
        value={value || ''}
        readOnly={readOnly}
        onClick={onClick && onClick}
      />
      <label className="form-input-label" htmlFor={id}>
        <span className="form-input-label-content">{label}</span>
      </label>
    </div>
  );
}

TextField.defaultProps = {
  readOnly: false
};
export default TextField;
