import React from 'react';
import { FormattedMessage } from 'react-intl';
import ModalCustom from './modalCustom';
import { MODAL_ADD_USER } from './modals';

const ModalAddUser = ({ handleConfirm, name, type }) => {
  if (type === MODAL_ADD_USER)
    return (
      <ModalCustom
        title={
          <FormattedMessage id="app.attention" defaultMessage="Attention" />
        }
        content={
          <>
            <FormattedMessage
              id="app.user-alreadyExist"
              defaultMessage="Cet utilisateur est déjà présent dans la liste"
            />{' '}
            <b> {name}</b>
          </>
        }
        subContent={
          <FormattedMessage
            id="app.user-alreadyExist-confirm"
            defaultMessage="Etes-vous sur de vouloir le changer de liste ?"
          />
        }
        confirm={
          <FormattedMessage id="app.confirm" defaultMessage="Confirmer" />
        }
        cancel={<FormattedMessage id="app.cancel" defaultMessage="Annuler" />}
        handleConfirm={handleConfirm}
      />
    );
  else return null;
};

export default ModalAddUser;
