import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';

const CertificationLink = ({ to, idDesktop, idMobile, defaultMess }) => {
  return (
    <div>
      <Link to={to} className="btn btn-secondary btn-small btn-block">
        <FormattedMessage id={idDesktop} defaultMessage={defaultMess} />
      </Link>
      <Link
        to={to}
        className="certification-item-action-link certification-item-action-link-secondary"
      >
        <FormattedMessage id={idMobile} defaultMessage={defaultMess} />
      </Link>
    </div>
  );
};
export default CertificationLink;
