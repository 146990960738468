import React, { Component } from 'react';
import * as ROUTES from '../../constants/routes';
import { home } from '../../utils';
import { Redirect } from 'react-router-dom';

class Dashboard extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    document.title = 'WATTPARK';
    home();
  }

  render() {
    if (localStorage.getItem('auth')) {
      return <Redirect to={ROUTES.DASHBOARD_PARKINGS} />;
    }
    return <Redirect to={ROUTES.LOGIN} />;
  }
}

export default Dashboard;
