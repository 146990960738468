import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import * as ROUTES from '../../../constants/routes';
import Actions from './../../Layouts/Forms/action';
import IllustrationCity from './../../Design/illustration-city';
import { DocumentTitle, nav } from '../../../utils';

import { connect } from 'react-redux';
import {
  fetchCreateParking,
  fetchInitialState
} from './../../../actions/parkingAction';
import { FormattedMessage } from 'react-intl';

class Action extends Component {
  constructor(props) {
    super(props);
  }
  componentDidMount() {
    nav.init();
  }

  logout() {
    localStorage.clear();
    window.location.href = ROUTES.LOGIN;
  }

  render() {
    return (
      <div className="main-container validation">
        {DocumentTitle(
          'app.title-parking-confirmation',
          'WATTPARK - Confirmation'
        )}
        <IllustrationCity />
        <header className="header">
          <div className="header-left">
            <div className="logo-main">
              <a href={ROUTES.DASHBOARD_PARKINGS}>
                <img src="/img/logo.svg" alt="Logo Wattpark" />
              </a>
            </div>
            <Link className="link" to={ROUTES.DASHBOARD_PARKINGS}>
              <i className="icon-arrow-left" />
              <span className="link-text">Dashboard</span>
            </Link>
            <nav className="nav-main">
              <div className="nav-main-user">
                <i className="icon-user" />
                <div className="nav-main-user-name">
                  {localStorage.getItem('username')}
                </div>
                <Link
                  to=""
                  className="nav-main-user-connexion"
                  onClick={this.logout}
                >
                  <FormattedMessage
                    id="app.navLogout"
                    defaultMessage="Déconnexion"
                  />
                </Link>
              </div>
            </nav>
          </div>

          <div className="header-center">
            <h2 className="header-title">Création d’un parking WATTPARK</h2>
          </div>
          <div className="header-right">
            <div className="nav-trigger">
              <span className="nav-trigger-line"></span>
              <span className="nav-trigger-line"></span>
              <span className="nav-trigger-line"></span>
            </div>
            <div className="header-user">
              <i className="icon-user" />
              <div className="header-user-info">
                <div className="header-user-name">
                  {localStorage.getItem('username')}
                </div>
                <Link
                  to="#"
                  className="header-user-connexion"
                  onClick={this.logout}
                >
                  Déconnexion
                </Link>
              </div>
            </div>
          </div>
        </header>
        <div className="screen-filter"></div>
        <div className="container">
          <div className="row">
            <div className="col-xs-12 col-sm-4">
              <h2 className="title-main">
                Votre parking <br />a bien été créé !
              </h2>
              <p className="text-main">
                Plus qu’une dernière étape avant la validation de sa mise en
                exploitation.
              </p>
            </div>
          </div>
          <div className="row">
            <div className="col-xs-12 col-sm-8">
              <section className="section-actions">
                <h3 className="section-actions-title">
                  {this.props.parkingAddressDocProvided
                    ? 'Fournissez ce document :'
                    : 'Fournissez ces 2 documents :'}
                </h3>
                <Actions
                  url={ROUTES.CERTIFICATION}
                  name="1. Certificat IRVE"
                  text="Veuillez faire valider le certificat IRVE de votre parking par un professionnel agréé."
                  btnName="demander"
                />
                {this.props.parkingAddressDocProvided !== true ? (
                  <Actions
                    url={`/parking/details/${this.props.parkingId}`}
                    name="2. Justificatif d’adresse"
                    text="Veuillez transmettre un justificatif pour son adresse."
                    btnName="transmettre"
                  />
                ) : null}
              </section>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    parkingAddressDocProvided: state.parkings.parkingAddressDocProvided,
    parkingId: state.parkings.parkingId
  };
}

export default connect(
  mapStateToProps,
  {
    fetchCreateParking,
    fetchInitialState
  }
)(Action);
