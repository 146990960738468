import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Alert from './../Layouts/Alert/alert';
import * as ROUTES from '../../constants/routes';
import { connect } from 'react-redux';
import { fetchResetPassword } from './../../actions/loginAction';
import IllustrationCity from './../Design/illustration-city';
import { setInputBehaviour, DocumentTitle } from '../../utils';
import { FormattedMessage } from 'react-intl';

//RFC 2822
const emailTest = RegExp(
  /^(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])$/
);

class RestPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      isLoading: false,
      emailReseted: false,
      formErros: {
        email: ''
      }
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleSubmit(event) {
    event.preventDefault();
    this.props.fetchResetPassword(this.state.email);
    this.setState({});
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.ResetPassword === true) {
      this.setState({ emailReseted: true });
    } else {
      this.setState({ emailReseted: false });
    }
  }

  handleChange(event) {
    const target = event.target;
    const { name, value } = target;

    let formErrors = this.state.formErros;
    switch (name) {
      case 'email':
        formErrors.email = emailTest.test(value)
          ? ''
          : 'Adresse email non valide';
        break;
      default:
        break;
    }
    this.setState({
      formErrors,
      [name]: value
    });
  }

  render() {
    const formErrors = this.state.formErros;
    ///Validator Email
    let classEmail = 'form-group';
    let classEmail1 = 'form-input';
    let lengthEmail = 0;
    if (this.state.email) {
      classEmail1 = 'form-input on-focus';
    }
    if (formErrors) {
      if (formErrors.email.length > 0) {
        lengthEmail = formErrors.email.length;
        classEmail = 'form-group on-focus has-error has-feedback';
        classEmail1 = 'form-input on-focus has-error has-feedback';
      } else {
        classEmail1 = 'form-input on-focus';
      }
    }
    const enabled = formErrors.email.length > 0 || this.state.email === '';
    const { isLoading } = this.state.isLoading;
    const resetPwdForm = (
      <form onSubmit={this.handleSubmit}>
        <div className="form-block">
          <div className={classEmail}>
            <div className={classEmail1}>
              <input
                className="form-input-field has-error"
                type="email"
                name="email"
                id="inputEmail"
                required
                value={this.state.email}
                onChange={this.handleChange}
              />
              <label className="form-input-label" htmlFor="inputEmail">
                <span className="form-input-label-content">Email</span>
              </label>
            </div>
            {lengthEmail > 0 && (
              <span className="help-block">{formErrors.email}</span>
            )}
          </div>
        </div>
        <button
          disabled={enabled}
          type="submit"
          className={
            enabled
              ? 'btn btn-primary btn-block btn-disable'
              : 'btn btn-primary btn-block'
          }
        >
          {isLoading ? (
            'Loading…'
          ) : (
            <FormattedMessage
              id="app.btnCreateParks"
              defaultMessage="Valider"
            />
          )}
        </button>
      </form>
    );

    const messageLinkToLogin = (
      <div>
        <p className="text-legend">
          <FormattedMessage
            id="app.reset.mdp"
            defaultMessage="Un email vous a été envoyé avec un lien vous permettant de changer votre mot de passe."
          />
        </p>
        <Link className="link" to={ROUTES.LOGIN}>
          <span className="link-text">
            <FormattedMessage
              id="app.to-log-in"
              defaultMessage="Se connecter"
            />
          </span>
        </Link>
      </div>
    );

    return (
      <div className="main-container login">
        {DocumentTitle(
          'app.title-mdp-forgotten',
          'WATTPARK - Mot de passe oublié'
        )}
        <header className="header header-login">
          <div className="header-left">
            <Link className="link" to={ROUTES.LOGIN}>
              <i className="icon-arrow-left" />
              <span className="link-text">
                <FormattedMessage id="app.return" defaultMessage="Retour" />
              </span>
            </Link>
          </div>
          <div className="header-center">
            <h1 className="logo-main">
              <Link to={ROUTES.LANDING}>
                <img src="/img/logo.svg" alt="Logo Wattpark" />
              </Link>
            </h1>
          </div>
        </header>
        <div className="form-block-wrapper">
          {this.state.alert ? (
            <Alert
              nameAlert={this.state.nameAlert}
              type={this.state.typeAlert}
              style={false}
              message={this.state.message}
            />
          ) : null}
          {this.state.emailReseted ? messageLinkToLogin : resetPwdForm}
        </div>
        <IllustrationCity />
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    ResetPassword: state.loginUser.ResetPassword,
    time: state.loginUser.time
  };
}

export default connect(
  mapStateToProps,
  { fetchResetPassword }
)(RestPassword);
