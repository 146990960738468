import React from 'react';

const Pictures = ({ img, method, photo }) => {
  return (
    <div className="form-block">
      <h3 className="form-block-title">Photos</h3>
      <div className="thumb-wrapper">
        {photo ? photo : ''}
        {img}
        <div className="thumb thumb-add">
          <input
            name="myImage"
            type="file"
            id="file"
            className="fileInput"
            accept=".jpeg,.jpg,.png"
            onChange={method}
          />
        </div>
      </div>
    </div>
  );
};

export default Pictures;
