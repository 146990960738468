import React, {Component} from 'react';
import {
  DocumentTitle,
  handleSwitchInput,
  listParking,
  nav,
  setInputBehaviour
} from '../../utils';
import Menu from './menu';
import Contact from './contact';
import Bank from './bank';
import Info from './information';
import Mony from './mony';
import Faq from './Faq';
import Cgv from './Terms';
import {Link} from 'react-router-dom';
import * as ROUTES from '../../constants/routes';
import {fetchGetFilesUser} from '../../actions/filesAction';
import {
  fetchEditUser,
  fetchGetUser,
  fetchInitialState
} from '../../actions/userAction';
import {fetchGetParkingSolde} from '../../actions/parkingAction';
import {
  fetchCreateBanckDetail,
  fetchGetBanckDetail,
  fetchTransfertSolde,
  fetchUpdateIban,
  fetchUpdateWallet
} from '../../actions/lemonWayAction';
import {connect} from 'react-redux';
import {FormattedMessage} from 'react-intl';
import Alert from './../Layouts/Alert/alert';
import Spinner from "../../utils/Spinner";

const date_regex = /^(0[1-9]|1[0-2])\/(0[1-9]|1\d|2\d|3[01])\/(19|20)\d{2}$/;
const reg = /^\d+(\,\d{1,2})?$/;

class Compte extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedTab: '',
      TVANumber: '',
      fiscal: '',
      companyName: '',
      statusFile: '',
      soldIban: '',
      fileNameUser: '',
      type: true,
      address: '',
      addressCompl: '',
      zipCode: '',
      country: '',
      soldWattPark: '',
      zipCodeBank: '',
      streetBank: '',
      checked: false,
      cityBank: '',
      countryBank: '',
      iban: '',
      ibanId: '',
      ibanHolder: '',
      ibanStatus: null,
      birthdate: '',
      addressComplBank: '',
      firstNameBank: '',
      lastNameBank: '',
      city: '',
      add: false,
      update: false,
      firstName: '',
      soldeWattpark: 0,
      lastName: '',
      phoneNumber: '',
      file: null,
      isLoading: false,
      IbanIsLoading: false,
      loading: false,
      alert: false,
      display: 'none',
      message: '',
      nameAlert: '',
      style: 'none',
      lemonWayAccountStatus: '',
      formErros: {
        idIban: '',
        zipCode: '',
        city: '',
        country: '',
        address: '',
        fileNameUser: '',
        file: null,
        phoneNumber: '',
        firstName: '',
        soldWattPark: '',
        birthdate: '',
        lastName: '',
        addressCompl: '',
        zipCodeBank: '',
        streetBank: '',
        cityBank: '',
        countryBank: '',
        iban: '',
        addressComplBank: '',
        firstNameBank: '',
        lastNameBank: '',
        companyName: '',
        TVANumber: '',
        fiscal: ''
      }
    };
    this.handleChange = this.handleChange.bind(this);
    this.onFileChange = this.onFileChange.bind(this);
    this.onClickCancel = this.onClickCancel.bind(this);
    this.handleSubmitBank = this.handleSubmitBank.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleSubmitBankUpdate = this.handleSubmitBankUpdate.bind(this);
    this.handleSubmitUpdatedIban = this.handleSubmitUpdatedIban.bind(this);
    this.handleSubmitTransfertSold = this.handleSubmitTransfertSold.bind(this);
  }

  componentDidMount() {
    this.props.fetchGetParkingSolde();
    this.props.fetchGetUser(localStorage.getItem('firebaseId'));
    this.props.fetchGetFilesUser();
    this.props.fetchGetBanckDetail();
    nav.init();
  }

  onClickCancel() {
    this.setState({
      zipCode: '',
      city: '',
      address: '',
      fileNameUser: '',
      file: null,
      phoneNumber: '',
      firstName: '',
      lastName: '',
      birthdate: '',
      addressCompl: ''
    });
  }

  handleSubmit(event) {
    event.preventDefault();
    window.scrollTo({top: 0, behavior: 'smooth'});

    let UserUpdate = {
      user: {
        fiscal: this.state.fiscal,
        TVANumber: this.state.TVANumber,
        lastName: this.state.lastName,
        firstName: this.state.firstName,
        phoneNumber: this.state.phoneNumber,
        birthdate: new Date(this.state.birthdate).toISOString().slice(0, 10),
        companyName: this.state.companyName,
        address: {
          city: this.state.city,
          country: this.state.country,
          street: this.state.address,
          addressCompl: this.state.addressCompl,
          zipCode: this.state.zipCode
        }
      },
      fileUser: this.state.fileUser
    };
    this.props.fetchEditUser(localStorage.getItem('firebaseId'), UserUpdate);
    this.props.fetchGetFilesUser();
  }

  handleSubmitBankUpdate(values) {
    this.setState({
      loading: true,
      update: true
    });
    let BanckUpdate = {
      lastName: values.lastNameBank,
      firstName: values.firstNameBank,
      city: values.cityBank,
      country: values.countryBank,
      street: values.streetBank,
      address: values.addressComplBank,
      zipCode: values.zipCodeBank,
      iban: values.iban
    };
    let BanckUpdateIban = {};
    if (this.state.ibanNext !== values.iban) {
      BanckUpdateIban = {
        iban: values.iban
      };
    }
    this.props.fetchUpdateWallet(BanckUpdate, BanckUpdateIban);
  }

  handleSubmitUpdatedIban(values) {
    this.setState({
      loading: true,
      update: true
    });

    const data = {
      iban: values.iban,
      previousIban: this.state.iban
    };

    if (this.state.ibanNext !== values.iban) data.iban = values.iban;

    this.props.fetchUpdateIban(data);
  }

  handleSubmitBank(values) {
    this.setState({
      loading: true,
      add: true
    });

    let BanckAdd = {
      lastName: values.lastNameBank,
      firstName: values.firstNameBank,
      city: values.cityBank,
      country: values.countryBank,
      street: values.streetBank,
      address: values.addressComplBank,
      zipCode: values.zipCodeBank,
      iban: values.iban
    };
    let BanckAddIban = {
      iban: values.iban
    };
    this.props.fetchCreateBanckDetail(BanckAdd, BanckAddIban);
  }

  handleSubmitTransfertSold(e) {
    e.preventDefault();
    this.setState({
      IbanIsLoading: true
    });
    let soldWattpark = {
      amountTot: this.state.soldWattPark,
      ibanId: this.state.soldIban
    };
    this.props.fetchTransfertSolde(soldWattpark);
  }

  logout() {
    localStorage.clear();
    window.location.href = ROUTES.LOGIN;
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.parkingSolde)
      this.setState({soldeWattpark: nextProps.parkingSolde.total});

    if (nextProps.filesListUser) {
      nextProps.filesListUser.map(file => {
        if (file.fileType === 'identity proof') {
          this.setState({
            fileNameUser: file.name,
            statusFile: file.status,
            type: false
          });
        }
      });
    }
    if (this.state.update === true) {
      if (nextProps.updateBankDetail.type !== 'error') {
        this.setState({
          iban: this.state.iban,
          loading: false,
          update: false,
          alert: true,
          message: 'Compte bancaire modifié',
          typeAlert: 'alerts',
          nameAlert: 'Succès'
        });
      } else {
        this.setState({
          alert: true,
          iban: this.state.iban,
          IbanIsLoading: false,
          message: nextProps.updateBankDetail.message.Msg,
          typeAlert: 'alerts alerts-warning',
          nameAlert: 'Erreur'
        });
        this.props.fetchInitialState();
      }
    } else {
      if (this.state.add === true) {
        if (
          nextProps.createBankDetail.wallet &&
          nextProps.createBankDetail.iban
        ) {
          if (
            nextProps.createBankDetail.iban.type !== 'error' &&
            nextProps.createBankDetail.wallet.type !== 'error'
          ) {
            this.setState({
              loading: false,
              add: false,
              alert: true,
              message: 'Compte bancaire ajouté',
              typeAlert: 'alerts',
              nameAlert: 'Succès'
            });
          } else {
            if (nextProps.createBankDetail.wallet.type === 'error') {
              this.setState({
                add: false,
                loading: false,
                alert: true,
                message: nextProps.createBankDetail.wallet.message.Msg,
                typeAlert: 'alerts  alerts-warning',
                nameAlert: 'Erreur'
              });
            } else {
              this.setState({
                add: false,
                loading: false,
                alert: true,
                message: nextProps.createBankDetail.iban.message.Msg,
                typeAlert: 'alerts  alerts-warning',
                nameAlert: 'Erreur'
              });
            }
          }
        } else {
          this.setState({
            loading: false,
            add: false,
            alert: true,
            message:
              'Compte bancaire non ajouté, veuillez vérifier "Mes informations"',
            typeAlert: 'alerts  alerts-warning',
            nameAlert: 'Erreur'
          });
        }
      } else {
        if (nextProps.bankDetail) {
          this.setState({
            iban: nextProps.bankDetail.iban,
            ibanId: nextProps.bankDetail.id,
            ibanHolder: nextProps.bankDetail.holder,
            soldeWattpark: nextProps.bankDetail.balance,
            ibanStatus: nextProps.bankDetail.status,
            lemonWayAccountStatus: nextProps.bankDetail.lemonWayAccountStatus
          });
        }
        if (nextProps.userUpdated) {
          this.setState({
            alert: true,
            message: 'Compte Modifié',
            typeAlert: 'alerts',
            nameAlert: 'Succès'
          });
        } else if (nextProps.getUser) {
          const user = nextProps.getUser;
          const companyName = user.companyName ? user.companyName : '';
          this.setState({
            fiscal: user.fiscal,
            TVANumber: user.TVANumber,
            companyName: companyName,
            isCompany: user.isCompany,
            birthdate: nextProps.getUser.birthdate,
            firstName: nextProps.getUser.firstName,
            lastName: nextProps.getUser.lastName,
            isLoading: true,
            phoneNumber: nextProps.getUser.phoneNumber
          });
          if (user.address) {
            this.setState({
              fiscal: user.fiscal,
              TVANumber: user.TVANumber,
              companyName: companyName,
              isCompany: user.isCompany,
              address: user.address.street || '',
              addressCompl: user.address.addressCompl || '',
              zipCode: user.address.zipCode || '',
              city: user.address.city || '',
              country: user.address.country || ''
            });
          }
          if (nextProps.filesListUser) {
            nextProps.filesListUser.map(file => {
              if (file.fileType === 'identity proof') {
                this.setState({
                  fileNameUser: file.name,
                  statusFile: file.status,
                  type: false
                });
              }
            });
          }
        }

        if (nextProps.transfertSold) {
          if (nextProps.transfertSold.type === 'error') {
            this.setState({
              alert: true,
              iban: this.state.iban,
              IbanIsLoading: false,
              message: 'Erreur durant le transfert vers votre IBAN',
              typeAlert: 'alerts alerts-warning',
              nameAlert: 'Erreur',
              soldWattPark: ''
            });
            this.props.fetchInitialState();
          } else {
            this.setState({
              alert: false,
              IbanIsLoading: false,
              soldeWattpark: nextProps.transfertSold.balance,
              soldWattPark: ''
            });
          }
        }
      }
    }
    setTimeout(
      function () {
        this.setState({
          alert: false
        });
      }.bind(this),
      5000
    );
  }

  handleChange(event) {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    if (target.checked) {
      this.setState({checked: true});
    }
    if (!target.checked && target.type === 'checkbox') {
      this.setState({checked: false});
    }
    const name = target.name;
    let formErrors = this.state.formErros;
    switch (name) {
      case 'phoneNumber':
        formErrors.phoneNumber =
          isNaN(value) || value <= 0
            ? "Le numéro de téléphone n'est pas valide"
            : '';
        break;
      case 'address':
        formErrors.address =
          value.length < 3 && value.length > 0
            ? 'Minimum 3 caractères sont requis'
            : '';
        break;
      case 'birthdate':
        formErrors.birthdate = date_regex.test(value)
          ? 'Date de Naissance est requis'
          : '';
        break;
      case 'zipCode':
        formErrors.zipCode =
          isNaN(value) || value <= 0 ? "Le code postal n'est pas valide" : '';
        break;
      case 'soldWattPark':
        formErrors.soldWattPark = reg.test(value)
          ? ''
          : "Le montant n'est pas valide";
        break;
      case 'idIban':
        formErrors.idIban = value === '' ? 'Iban est requis' : '';
        break;
      case 'city':
        formErrors.city =
          value.length < 3 && value.length > 0
            ? 'Minimum 3 caractères sont requis'
            : '';
        break;
      case 'country':
        formErrors.country =
          value.length < 3 && value.length > 0
            ? 'Minimum 3 caractères sont requis'
            : '';
        break;
      case 'firstName':
        formErrors.firstName =
          value.length < 3 && value.length > 0
            ? 'Minimum 3 caractères sont requis'
            : '';
        break;
      case 'lastName':
        formErrors.lastName =
          value.length < 3 && value.length > 0
            ? 'Minimum 3 caractères sont requis'
            : '';
        break;

      case 'addressCompl':
        formErrors.addressCompl =
          value.length < 6 && value.length > 0
            ? 'Minimum 6 caractères sont requis'
            : '';
        break;
      case 'iban':
        formErrors.iban =
          value.length < 3 && value.length > 0
            ? 'Minimum 3 caractères sont requis'
            : '';
        break;
      case 'firstNameBank':
        formErrors.firstNameBank =
          value.length < 3 && value.length > 0
            ? 'Minimum 3 caractères sont requis'
            : '';
        break;
      case 'lastNameBank':
        formErrors.lastNameBank =
          value.length < 3 && value.length > 0
            ? 'Minimum 3 caractères sont requis'
            : '';
        break;
      case 'addressComplBank':
        formErrors.addressComplBank =
          value.length < 6 && value.length > 0
            ? 'Minimum 6 caractères sont requis'
            : '';
        break;
      case 'streetBank':
        formErrors.streetBank =
          value.length < 6 && value.length > 0
            ? 'Minimum 6 caractères sont requis'
            : '';
        break;
      case 'cityBank':
        formErrors.cityBank =
          value.length < 3 && value.length > 0
            ? 'Minimum 3 caractères sont requis'
            : '';
        break;
      case 'countryBank':
        formErrors.countryBank =
          value.length < 3 && value.length > 0
            ? 'Minimum 3 caractères sont requis'
            : '';
        break;
      case 'zipCodeBank':
        formErrors.zipCodeBank =
          isNaN(value) || value <= 0 ? "Le code postal n'est pas valide" : '';
        break;
      default:
        break;
    }

    this.setState({
      [name]: value
    });
  }

  onFileChange(event) {
    if (event.target.files && event.target.files[0]) {
      this.setState({
        fileUser: event.target.files[0],
        fileNameUser: event.target.files[0].name
      });
    }
  }

  render() {
    setInputBehaviour();
    handleSwitchInput();
    listParking();
    const formErrors = this.state.formErros;
    let user = {};
    if (this.props.getUser) {
      user = this.props.getUser;
    }
    const enabled =
      formErrors.birthdate.length > 0 ||
      this.state.birthdate === '' ||
      formErrors.firstNameBank.length > 0 ||
      this.state.firstNameBank === '' ||
      formErrors.lastNameBank.length > 0 ||
      this.state.lastNameBank === '' ||
      formErrors.streetBank.length > 0 ||
      this.state.streetBank === '' ||
      formErrors.cityBank.length > 0 ||
      this.state.cityBank === '' ||
      formErrors.zipCodeBank.length > 0 ||
      this.state.zipCodeBank === '' ||
      formErrors.countryBank.length > 0 ||
      this.state.countryBank === '';

    const infoEnabled =
      formErrors.zipCode > 0 ||
      this.state.zipCode === '' ||
      formErrors.city > 0 ||
      this.state.city === '' ||
      formErrors.country > 0 ||
      this.state.country === '' ||
      formErrors.address > 0 ||
      this.state.address === '' ||
      formErrors.phoneNumber > 0 ||
      this.state.phoneNumber === '' ||
      formErrors.firstName > 0 ||
      this.state.firstName === '' ||
      formErrors.birthdate > 0 ||
      this.state.birthdate === '' ||
      formErrors.lastName > 0 ||
      this.state.lastName === '' ||
      (this.state.isCompany &&
        (formErrors.companyName > 0 || this.state.companyName === '')) ||
      (this.state.isCompany &&
        (formErrors.TVANumber > 0 || this.state.TVANumber === '') &&
        (formErrors.fiscal > 0 || this.state.fiscal === ''));

    const enabledIban =
      formErrors.soldWattPark.length > 0 ||
      this.state.soldWattPark === '' ||
      formErrors.idIban.length > 0 ||
      !this.state.checked;

    const loading = this.state.loading;
    const isLoading = this.state.isLoading;

    let parkingSolde = {};
    if (this.props.parkingSolde) {
      parkingSolde = this.props.parkingSolde;
    }
    let banck = null;
    if (this.props.bankDetail) banck = this.props.bankDetail;

    let selectTab = new URLSearchParams(this.props.location.search);
    selectTab = selectTab.get('tab')
      ? selectTab.get('tab')
      : this.state.selectedTab;

    return (
      <div className="main-container account">
        {DocumentTitle('app.title-account', 'WATTPARK - Mon compte')}
        <header className="header">
          <div className="header-left">
            <h1 className="logo-main">
              <Link to="/">
                <img src="/img/logo.svg" alt="Logo Wattpark"/>
              </Link>
            </h1>
            <nav className="nav-main">
              <Link to={ROUTES.DASHBOARD_PARKINGS} className="nav-main-item">
                <FormattedMessage id="app.navDsh" defaultMessage="Accueil"/>
              </Link>
              <Link to={ROUTES.COMPTE} className="nav-main-item current">
                <FormattedMessage id="app.navcpt" defaultMessage="Compte"/>
              </Link>
              {localStorage.getItem('usertype') === 'admin' ? (
                <Link
                  to={ROUTES.CERTIFICATIONVALIDATION}
                  className="nav-main-item"
                >
                  <FormattedMessage
                    id="app.navcertif"
                    defaultMessage="Certificats"
                  />
                </Link>
              ) : (
                ''
              )}
              <div className="nav-main-user">
                <i className="icon-user"/>
                <div className="nav-main-user-name">
                  {localStorage.getItem('username')}
                </div>
                <Link
                  to=""
                  className="nav-main-user-connexion"
                  onClick={this.logout}
                >
                  <FormattedMessage
                    id="app.navLogout"
                    defaultMessage="Déconnexion"
                  />
                </Link>
              </div>
            </nav>
          </div>

          <div className="header-right">
            <div className="nav-trigger">
              <span className="nav-trigger-line"/>
              <span className="nav-trigger-line"/>
              <span className="nav-trigger-line"/>
            </div>
            <div className="header-user">
              <i className="icon-user"/>
              <div className="header-user-info">
                <div className="header-user-name">
                  {localStorage.getItem('username')}
                </div>
                <Link
                  to=""
                  className="header-user-connexion"
                  onClick={this.logout}
                >
                  <FormattedMessage
                    id="app.navLogout"
                    defaultMessage="Déconnexion"
                  />
                </Link>
              </div>
            </div>
          </div>
        </header>
        <div className="screen-filter"/>
        <div className="container">
          {
            !isLoading ? <Spinner/> :
              <div className="row">
                <div className="col-xs-12 col-md-4">
                  <Menu
                    type={this.state.type}
                    tab={selectTab}
                    onClickMenu={type => {
                      this.setState({selectedTab: type});
                    }}
                  />
                </div>
                <div className="col-xs-12 col-md-8">
                  {this.state.alert ? (
                    <Alert
                      nameAlert={this.state.nameAlert}
                      type={this.state.typeAlert}
                      style={false}
                      message={this.state.message}
                    />
                  ) : null}
                  <div className="view-wrapper">
                    <Contact/>
                    <Bank
                      isSelected={selectTab === 'bank'}
                      detailBanck={banck}
                      isLoading={loading}
                      enabled={enabled}
                      error={formErrors}
                      handleSubmitBank={this.handleSubmitBank}
                      handleSubmitBankUpdate={this.handleSubmitBankUpdate}
                      handleSubmitUpdatedIban={this.handleSubmitUpdatedIban}
                      firstName={this.state.firstNameBank}
                      lastName={this.state.lastNameBank}
                      zipCode={this.state.zipCodeBank}
                      addressComplBank={this.state.addressComplBank}
                      streetBank={this.state.streetBank}
                      cityBank={this.state.cityBank}
                      countryBank={this.state.countryBank}
                      iban={this.state.iban}
                      lemonWayAccountStatus={this.state.lemonWayAccountStatus}
                      ibanHolder={this.state.ibanHolder}
                      hasRegisteredIban={!!this.state.ibanId}
                      ibanStatus={this.state.ibanStatus}
                      user={user.bank_details}
                      methodChange={this.handleChange}
                    />
                    <Mony
                      goToTab={() => {
                        this.setState({selectedTab: 'bank'});
                      }}
                      IbanIsLoading={this.state.IbanIsLoading}
                      enabledIban={enabledIban}
                      detailBanck={banck}
                      soldWattPark={this.state.soldWattPark}
                      idIban={this.state.ibanId}
                      iban={this.state.iban}
                      ibanHolder={this.state.ibanHolder}
                      solde={this.state.soldeWattpark}
                      error={formErrors}
                      methodChange={this.handleChange}
                      handleSubmitTransfertSold={this.handleSubmitTransfertSold}
                    />
                    <Info
                      onUpdateTvaAndFiscalField={companyData => {
                        this.setState({
                          fiscal: companyData.fiscal,
                          TVANumber: companyData.TVANumber,
                          companyName: companyData.companyName
                        });
                      }}
                      isSelected={selectTab === 'info' || selectTab === ''}
                      error={formErrors}
                      nameFile={this.state.fileNameUser}
                      fileNameUser={this.state.fileNameUser}
                      handleSubmit={this.handleSubmit}
                      method={this.handleChange}
                      onFileChange={this.onFileChange}
                      firstName={this.state.firstName}
                      birthdate={this.state.birthdate}
                      lastName={this.state.lastName}
                      zipCode={this.state.zipCode}
                      street={this.state.address}
                      city={this.state.city}
                      country={this.state.country}
                      addressCompl={this.state.addressCompl}
                      phoneNumber={this.state.phoneNumber}
                      user={{
                        ...user,
                        fiscal: this.state.fiscal,
                        TVANumber: this.state.TVANumber,
                        companyName: this.state.companyName
                      }}
                      onClickCancel={this.onClickCancel}
                      enabled={infoEnabled}
                    />
                    <Faq/>
                    <Cgv/>
                  </div>
                </div>
              </div>
          }
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    filesListUser: state.files.filesListUser,
    userUpdated: state.users.userUpdated,
    getUser: state.users.getUser,
    parkingSolde: state.parkings.parkingSolde,
    bankDetail: state.bankDetails.bankDetail,
    createBankDetail: state.bankDetails.createBankDetail,
    createIban: state.bankDetails.createIban,
    updateBankDetail: state.bankDetails.updateBankDetail,
    transfertSold: state.bankDetails.transfertSold,
    lemonWayAccountStatus: state.bankDetails.lemonWayAccountStatus
  };
}

export default connect(
  mapStateToProps,
  {
    fetchGetFilesUser,
    fetchEditUser,
    fetchGetUser,
    fetchGetParkingSolde,
    fetchGetBanckDetail,
    fetchCreateBanckDetail,
    fetchUpdateWallet,
    fetchUpdateIban,
    fetchInitialState,
    fetchTransfertSolde
  }
)(Compte);
