import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';

class Station extends Component {
  onRedirectMenu() {
    window.$('#menu-item-price').addClass('current');
  }

  render() {
    let style = 'select  state-1';
    let name = '';

    let powerStyle = 'select';
    let powerName = '';

    const infoRedirectStyle = {
      fontSize: 10 + 'px',
      display: 'inline',
      borderRadius: 0,
      margin: 0,
      padding: 0,
      lineHeight: 'unset'
    };

    const powerEnum = [
      { name: 'app.station-power.3-fixed', value: '3,7' },
      { name: 'app.station-power.3-variable', value: 'until 3,7' },
      { name: 'app.station-power.7-fixed', value: '7,4' },
      { name: 'app.station-power.7-variable', value: 'until 7,4' }
    ];

    const statusEnum = [
      { name: 'Occupé', value: 0 },
      { name: 'Disponible', value: 1 },
      { name: 'Privé', value: 3 },
      { name: 'Entretien', value: 4 },
      { name: 'Inactif', value: 5 }
    ];

    switch (this.props.status) {
      case 0:
        if (this.props.statusSelect === 0) {
          style = this.props.styleSelect;
          name = 'Occupé';
          break;
        }
        style = 'select state-2';
        name = 'Occupé';
        break;
      case 1:
        if (this.props.statusSelect === 1) {
          style = this.props.styleSelect;
          name = 'Disponible';
          break;
        }
        style = 'select state-3';
        name = 'Disponible';
        break;
      case 3:
        if (this.props.statusSelect === 3) {
          style = this.props.styleSelect;
          name = 'Privé';
          break;
        }
        style = 'select state-1';
        name = 'Privé';
        break;
      case 4:
        if (this.props.statusSelect === 4) {
          style = this.props.styleSelect;
          name = 'Entretien';
          break;
        }
        style = 'select state-5';
        name = 'Entretien';
        break;
      case 5:
        if (this.props.statusSelect === 5) {
          style = this.props.styleSelect;
          name = 'Inactif';
          break;
        }
        style = 'select state-4';
        name = 'Inactif';
        break;
      default:
        break;
    }

    switch (this.props.power) {
      case 'until 3,7':
        if (this.props.powerSelect === 'until 3,7') {
          powerStyle = this.props.styleSelect;
          powerName = 'app.station-power.3-variable';
          break;
        }
        powerName = 'app.station-power.3-variable';
        break;
      case '3,7':
        if (this.props.powerSelect === '3,7') {
          powerStyle = this.props.styleSelect;
          powerName = 'app.station-power.3-fixed';
          break;
        }
        powerName = 'app.station-power.3-fixed';
        break;
      case 'until 7,4':
        if (this.props.powerSelect === 'until 7,4') {
          powerStyle = this.props.styleSelect;
          powerName = 'app.station-power.7-variable';
          break;
        }
        powerName = 'app.station-power.7-variable';
        break;
      case '7,4':
        if (this.props.powerSelect === '7,4') {
          powerStyle = this.props.styleSelect;
          powerName = 'app.station-power.7-fixed';
          break;
        }
        powerName = 'app.station-power.7-fixed';
        break;
      default:
        break;
    }

    style = style + ' is-filled';
    powerStyle = powerStyle + ' is-filled';

    return (
      <div key={this.props.id} className="station">
        <div className="station-name">
          <div className="station-name-title">Nom de la station</div>
          <div className="station-name-content">{this.props.publicId}</div>
        </div>
        <div className="station-hardware">
          <div className="station-hardware-title">Hardware ID</div>
          <div className="station-hardware-content">
            {this.props.hardwareId}
          </div>
        </div>
        <div className="station-state">
          <div
            id={'select-' + this.props.id}
            className={style}
            key={this.props.id}
            onClick={this.onClickSelect}
          >
            <div className="select-input">
              <div className="select-label">État de la station</div>
              <div className="select-value">{name ? name : 'INACTIF'}</div>
              <i className="icon-arrow-select" />
            </div>
            <div className="select-box">
              <ul className="select-box-list">
                {statusEnum.map((status, index) => {
                  return (
                    <li
                      key={index}
                      className="select-box-list-item"
                      data-stationid={this.props.id}
                      onClick={this.props.onPatch}
                      data-select="state"
                      data-index={status.value}
                    >
                      {status.name}
                    </li>
                  );
                })}
              </ul>
            </div>
          </div>
        </div>
        <div className="station-power">
          <div
            id={'select-' + this.props.id}
            className={powerStyle}
            key={this.props.id}
            onClick={this.onClickSelect}
          >
            <div className="select-input">
              <div className="select-label">
                Puissance
                <div className="info-wrapper">
                  <i className="icon-info" />
                  <div className="info">
                    <span className="info-content">
                      Si vous optez pour le choix de puissance{' '}
                      <span>Variable</span>, celle-ci le sera selon votre
                      indication des heures pleines et des heures creuses dans
                      l’entrée{' '}
                      <a
                        href="#"
                        data-target="#view3"
                        className="menu-item current"
                        style={infoRedirectStyle}
                        onClick={this.onRedirectMenu}
                      >
                        Tarifs & horaires
                      </a>{' '}
                      de cette fiche parking.
                    </span>
                  </div>
                </div>
              </div>
              <div className="select-value">
                <FormattedMessage
                  id={powerName ? powerName : 'app.station-power.choose'}
                  default="Sélectionner la puissance"
                />
              </div>
              <i className="icon-arrow-select" />
            </div>
            <div className="select-box">
              <ul className="select-box-list">
                {powerEnum.map((power, index) => {
                  return (
                    <li
                      key={index}
                      className="select-box-list-item"
                      data-stationid={this.props.id}
                      onClick={this.props.onPatch}
                      data-select="power"
                      data-value={power.value}
                    >
                      <FormattedMessage
                        id={power.name}
                        default="Sélectionner la puissance"
                      />
                    </li>
                  );
                })}
              </ul>
            </div>
          </div>
        </div>
        <i
          className="icon-options trigger-options-panel"
          id={'option-' + this.props.id}
          onClick={this.onClickOption}
        >
          <span />
          <span />
          <span />
        </i>
        <div className="options-panel" id={'div-option-' + this.props.id}>
          <ul className="options-panel-list">
            <li className="options-panel-list-item">
              <a
                href="#"
                className="options-panel-list-item-action"
                data-stationid={this.props.id}
                onClick={this.props.onDelete}
                data-hardwareid={this.props.hardwareId}
                data-publicid={this.props.publicId}
                data-type="edit"
              >
                <FormattedMessage id="app.btn-update" defaultMessage="Éditer" />
              </a>
            </li>
            <li className="options-panel-list-item">
              <a
                href="#"
                className="options-panel-list-item-action delete"
                onClick={this.props.onDelete}
                data-stationid={this.props.id}
                data-type="delete"
              >
                <FormattedMessage
                  id="app.btnDelete"
                  defaultMessage="Supprimer"
                />
              </a>
            </li>
          </ul>
        </div>
      </div>
    );
  }
}

export default Station;
