import * as d3 from 'd3';
import ScrollMagic from 'scrollmagic';
import { TweenMax } from 'gsap';
import { FormattedMessage } from 'react-intl';
import React from 'react';

//import 'imports-loader?define=>false!scrollmagic/scrollmagic/uncompressed/plugins/animation.gsap';

/**
 * Explode hours in float to hour-min
 */
export const timeConvert = time => {
  const roundHours = Math.floor(time);
  const minutes = (time - roundHours) * 60;
  const roundMinutes = Math.round(minutes);
  return [roundHours, roundMinutes];
};

export const setInputBehaviour = () => {
  const $trigger = window.$('.form-input-field');

  $trigger.on('focus', event => {
    const { currentTarget } = event;
    const $current = window.$(currentTarget);
    $current.parent().addClass('on-focus');
  });

  $trigger.on('blur', event => {
    const { currentTarget } = event;
    const $current = window.$(currentTarget);

    if ($current.val().length < 1) {
      $current.parent().removeClass('on-focus');
    }
  });
};

export const listParking = () => {
  const $trigger = window.$('.menu-item');
  const $wrapper = window.$('.view-wrapper');
  $wrapper.css('height', window.$('.view.current').height() + 'px');

  $trigger.on('click tap', event => {
    event.preventDefault();
    const { currentTarget } = event;
    const $current = window.$(currentTarget);
    const $target = window.$($current.attr('data-target'));

    $trigger.removeClass('current');
    $current.addClass('current');
    window.$('.view').removeClass('current');
    $wrapper.css('height', $target.height() + 'px');
    $target.addClass('current');
  });
};

export const defaultPriceModalData = e => {
  return {
    val: e.currentTarget.dataset.id,
    dayNumber: e.currentTarget.dataset.daynumber,
    slotId: parseInt(e.currentTarget.dataset.idslot),
    inputHoursPrice: {
      display: String(
        e.currentTarget.dataset.price === undefined
          ? 1
          : e.currentTarget.dataset.price
      ).replace(/\./g, ','),
      value:
        e.currentTarget.dataset.price === undefined
          ? 1
          : e.currentTarget.dataset.price
    },
    inputHoursStart: e.currentTarget.dataset.startdate,
    inputHoursEnd: e.currentTarget.dataset.enddate
  };
};

export const defaultPublicHours = [
  { label: 'Lundi', dayNumber: 1, slots: [] },
  { label: 'Mardi', dayNumber: 2, slots: [] },
  { label: 'Mercredi', dayNumber: 3, slots: [] },
  { label: 'Jeudi', dayNumber: 4, slots: [] },
  { label: 'Vendredi', dayNumber: 5, slots: [] },
  { label: 'Samedi', dayNumber: 6, slots: [] },
  { label: 'Dimanche', dayNumber: 0, slots: [] }
];

export const handleSelectOpening = () => {
  const $trigger = window.$('.select-input');

  $trigger.on('click tap', event => {
    const { currentTarget } = event;
    const $current = window.$(currentTarget);

    $current.parents('.select').addClass('is-opened');
  });
};

export function paginate(arr, size) {
  return arr.reduce((acc, val, i) => {
    let idx = Math.floor(i / size);
    let page = acc[idx] || (acc[idx] = []);
    page.push(val);

    return acc;
  }, []);
}

export const handleSelectIsFilled = () => {
  const $trigger = window.$('.select-box-list-item');

  $trigger.on('click tap', event => {
    const { currentTarget } = event;
    const $current = window.$(currentTarget);
    const parent = $current.parents('.select');
    const text = $current.text();

    parent.removeClass('is-opened');
    parent.find('.select-value').html(text);
    parent.addClass('is-filled');
  });
};

export const valueToSet = (defaultData, event, arrayIndex) => {
  const { name, value, title } = event.target;

  const simpleData = { [name]: value };

  if (arrayIndex === 0 || arrayIndex)
    return {
      ...defaultData,
      [title]: [
        ...defaultData[title].map((data, index) => {
          if (index === arrayIndex) return simpleData;
          else return data;
        })
      ]
    };
  else
    return {
      ...defaultData,
      [title]: { ...defaultData[title], ...simpleData }
    };
};

export const handleSwitchInput = () => {
  window.$('.switch-text').on('click tap', event => {
    const { currentTarget } = event;
    const $current = window.$(currentTarget);
    const $indicator = $current.parent('.switch').find('.switch-moving-bg');
    const $sizeFirstChild = $current
      .parent('.switch')
      .find('.switch-text:first-of-type')
      .innerWidth();
    const $sizeLastChild = $current
      .parent('.switch')
      .find('.switch-text:last-of-type')
      .innerWidth();

    if (window.$($indicator).hasClass('go-to-right')) {
      window
        .$($indicator)
        .removeClass('go-to-right')
        .css({
          transform: 'translateX(1px)',
          width: $sizeFirstChild
        });
    } else {
      window
        .$($indicator)
        .addClass('go-to-right')
        .css({
          transform: 'translateX(' + $sizeFirstChild + 'px)',
          width: $sizeLastChild
        });
    }
  });
};

export const handleCharts = (dataArray, TauxActuel) => {
  const time = 750;
  const margin = 15;
  const size = 220 - margin;
  const radius = size / 2;
  const format = d3.format('.0%');
  const angleRange = 0.5 * Math.PI;
  const score = TauxActuel / 100;

  const pie = d3
    .pie(dataArray)
    .value(0)
    .sort(null)
    .startAngle(angleRange)
    .endAngle(angleRange * -1);

  const arc = d3
    .arc()
    .outerRadius(radius * 0.8)
    .innerRadius(radius * 0.6);

  const svg = d3
    .selectAll('.gauge')
    .attr('preserveAspectRatio', 'xMidYMid')
    .attr('viewBox', `0 0 ${size} ${size / 2 + 2 * margin}`)
    .attr('width', '100%')
    .attr('height', '100%')
    .append('g')
    .attr('transform', `translate( ${size / 2} , ${size / 2} )`);

  const sliceGroup = svg
    .datum(dataArray)
    .selectAll('path')
    .data(pie)
    .enter()
    .append('g');

  const slicePath = sliceGroup
    .append('path')
    .attr('fill', function(d, i) {})
    .attr('d', arc)
    .each(function(d) {
      this._current = d;
      if (d.data.colorID === 'help') {
        window.$(this).attr('class', 'charts-color-2');
      } else if (d.data.colorID === 'plan') {
        window.$(this).attr('class', 'charts-color-1');
      } else if (d.data.colorID === 'buy') {
        window.$(this).attr('class', 'charts-color-3');
      }
    });

  const textData = svg
    .append('text')
    .text(0)
    .attr('class', 'text-data')
    .attr('id', 'scorenum')
    .attr('text-anchor', 'middle')
    .attr('dy', '.4em');

  function arcTween(a) {
    const i = d3.interpolate(this._current, a);
    this._current = i(0);
    return function(t) {
      return arc(i(t));
    };
  }

  function animateSVG() {
    svg.datum(dataArray);

    pie.value(function(d) {
      return d.value;
    });

    slicePath
      .data(pie)
      .transition()
      .duration(time)
      .attrTween('d', arcTween);

    textData
      .transition()
      .duration(time)
      .on('start', function repeat() {
        d3.active(this)
          .tween('text', function() {
            const that = d3.select(this);
            const i = d3.interpolate(that.text(), score);
            return function(t) {
              that.text(format(i(t)));
            };
          })
          .transition();
      });
  }

  animateSVG();
};

export const handleSearchBanner = () => {
  const controller = new ScrollMagic.Controller();
  const startTriggerPosition = window.$('.main-container').css('padding-top');

  new ScrollMagic.Scene({
    offset: startTriggerPosition,
    triggerHook: 0
  })
    .setClassToggle('.main-container', 'scrolling')
    .addTo(controller);
};

const sceneConsctructor = $trigger => {
  const controller = new ScrollMagic.Controller();

  $trigger.each((index, elem) => {
    const $current = window.$(elem);

    const tween = TweenMax.staggerTo($current, 0.8, {
      className: '+=animated'
    });

    new ScrollMagic.Scene({
      reverse: false,
      triggerElement: elem,
      triggerHook: 0.85
    })
      .setTween(tween)
      .addTo(controller);
  });
};

const handleAnimIntro = () => {
  const $text = window.$('.home .section-intro .intro-transi-zoom');
  const $formBlock = window.$('.home .section-intro .intro-transi-move');
  const $illustration = window.$(
    '.home .section-intro .intro-transi-side-left'
  );
  const triggerClass = 'animated';

  $illustration.addClass(triggerClass);

  setTimeout(() => {
    $text.addClass(triggerClass);
  }, 400);

  setTimeout(() => {
    $formBlock.addClass(triggerClass);
  }, 800);
};

const handleTransitionMoveOnScroll = () => {
  const $animItem = window.$('.transi-move');

  sceneConsctructor($animItem);
};
const handleTransitionZoomOnScroll = () => {
  const $animItem = window.$('.transi-zoom');

  sceneConsctructor($animItem);
};
const handleTransitionSideLeft = () => {
  const $animItem = window.$('.transi-side-left');

  sceneConsctructor($animItem);
};

const handleTransitionSideRight = () => {
  const $animItem = window.$('.transi-side-right');

  sceneConsctructor($animItem);
};

const handleTransitionSide = () => {
  handleTransitionSideLeft();
  handleTransitionSideRight();
};
export const home = () => {
  handleAnimIntro();
  handleTransitionMoveOnScroll();
  handleTransitionZoomOnScroll();
  handleTransitionSide();
};
const handleOptionsPanelOpening = () => {
  const $trigger = window.$('.trigger-options-panel');
  let $elemFocus = $trigger;

  $trigger.on('click', event => {
    const { currentTarget } = event;
    const $current = window.$(currentTarget);
    const target = $current.next('.options-panel');
    $elemFocus = $current;
    $elemFocus.focus();
    target.toggleClass('is-opened');
  });
};

const handleOptionsPanelChoice = () => {
  const $trigger = window.$('.trigger-options-panel-item-action');

  $trigger.on('click tap', event => {
    const { currentTarget } = event;
    const $current = window.$(currentTarget);
    const parent = $current.parents('.options-panel');

    parent.removeClass('is-opened');
  });
};

export const OptionsPanel = () => {
  handleOptionsPanelOpening();
  handleOptionsPanelChoice();
};

export const DocumentTitle = (id, defaultMessage) => (
  <FormattedMessage id={id} defaultMessage={defaultMessage}>
    {message => {
      if (document.title !== message) {
        document.title = message;
      }
      return null;
    }}
  </FormattedMessage>
);

const debounce = (func, wait, immediate) => {
  let timeout;
  return function() {
    const context = this,
      args = arguments;
    const later = function() {
      timeout = null;
      if (!immediate) func.apply(context, args);
    };
    const callNow = immediate && !timeout;
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
    if (callNow) func.apply(context, args);
  };
};

const calcDataMenuTabs = (currentItem, indicator) => {
  const screenSize = window.$(window).width();
  const $partToShowOrHide = window.$('.dashboard-part');
  const currentWidth = Math.trunc(currentItem.outerWidth(true));
  const positionScreen = Math.trunc(
    (currentItem.offset().left / screenSize) * 100
  );

  if (!currentItem.hasClass('current')) {
    $partToShowOrHide.toggleClass(
      'dashboard-part-visible dashboard-part-hidden'
    );
  }

  indicator.animate(
    {
      left: positionScreen + '%'
    },
    250
  );
  indicator.css('width', currentWidth);
};

const handleMenuTabs = () => {
  if (window.$('.menu-tabs').length > 0) {
    const $trigger = window.$('.menu-tabs .menu-item');
    let $currentItem = window.$('.menu-tabs  .menu-item.current');
    const $indicator = window.$('.menu-tabs .menu-indicator');
    const $elemInitCurrent = window.$('.menu-tabs .menu-item.current');
    const initialWidth = Math.trunc($elemInitCurrent.outerWidth(true));
    let initialWidthScreen = window.innerWidth;
    const positionInitial = Math.trunc($elemInitCurrent.offset().left);

    $indicator.css({
      left: positionInitial,
      width: initialWidth
    });

    $trigger.on('click tap', event => {
      event.preventDefault();
      const { currentTarget } = event;
      const $current = window.$(currentTarget);
      $currentItem = $current;
      calcDataMenuTabs($current, $indicator);
    });
    window.addEventListener(
      'resize',
      debounce(() => {
        if (window.innerWidth !== initialWidthScreen) {
          if (window.innerWidth < 992) {
            $currentItem.trigger('click');
          }
          initialWidthScreen = window.innerWidth;
        }
      }, 250)
    );
  }
};

const calcDataMenu = (currentItem, indicator, delta) => {
  const currentWidth = Math.trunc(currentItem.outerWidth(true));
  const positionScrollProgress = Math.trunc(
    currentItem.parents('.menu-scrollable').scrollLeft()
  );
  const positionScreen = Math.trunc(currentItem.offset().left);
  const positionToReach = positionScrollProgress + positionScreen - delta;

  setTimeout(() => {
    window.$('.menu-scrollable').animate(
      {
        scrollLeft: positionToReach
      },
      400
    );

    indicator.animate(
      {
        left: positionToReach + delta
      },
      250
    );
    indicator.css('width', currentWidth);
  }, 250);
};

const handleMenuMobile = () => {
  if (window.$('.menu-scrollable').length > 0) {
    const $trigger = window.$('.menu-scrollable .menu-item');
    let $currentItem = window.$('.menu-scrollable  .menu-item.current');
    const $indicator = window.$('.menu-scrollable .menu-indicator');
    const delta = 20;
    const initialWidth = Math.trunc($currentItem.outerWidth(true));
    let initialWidthScreen = window.innerWidth;

    $indicator.css('width', initialWidth);

    $trigger.on('click tap', event => {
      event.preventDefault();
      const { currentTarget } = event;
      const $current = window.$(currentTarget);
      $currentItem = $current;
      calcDataMenu($current, $indicator, delta);
    });

    window.addEventListener(
      'resize',
      debounce(() => {
        if (window.innerWidth !== initialWidthScreen) {
          if (window.innerWidth < 992) {
            $currentItem.trigger('click');
          }
          initialWidthScreen = window.innerWidth;
        }
      }, 250)
    );
  }
};

const handleMenuDesktop = () => {
  const $trigger = window.$('.menu-item');
  const $wrapper = window.$('.view-wrapper');
  $wrapper.css('height', window.$('.view.current').height() + 'px');

  $trigger.on('click tap', event => {
    event.preventDefault();
    const { currentTarget } = event;
    const $current = window.$(currentTarget);
    const $target = window.$($current.attr('data-target'));

    $trigger.removeClass('current');
    $current.addClass('current');
    window.$('.view').removeClass('current');
    $wrapper.css('height', $target.height() + 'px');
    $target.addClass('current');
  });
};

const handleNavMobile = () => {
  const $trigger = window.$('.nav-trigger');
  const $screenFilter = window.$('.screen-filter');
  const $body = window.$('.main-container');

  $trigger.on('click tap', () => {
    $body.toggleClass('nav-is-opened');
  });

  $screenFilter.on('click tap', () => {
    $body.removeClass('nav-is-opened');
  });
};

export const nav = {
  init() {
    handleNavMobile();
  }
};

export const menu = {
  init() {
    handleMenuTabs();
    handleMenuDesktop();
    handleMenuMobile();
  }
};
