import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import * as ROUTES from '../../constants/routes';
import IllustrationCity from './../Design/illustration-city';
import { DocumentTitle } from '../../utils';
import { FormattedMessage } from 'react-intl';

import UserService from '../../services/userService';

const birthdate_regex = /^[0-9][0-9][0-9][0-9]\-[0-9][0-9]\-[0-9][0-9]$/;

function isValidBirthdate(value) {
  const regex = new RegExp(birthdate_regex);
  return regex.test(value);
}

class UserDeletion extends Component {
  constructor(props) {
    super(props);

    this.state = {
      alert: false,
      isLoading: false,
      enabled: true,
      isDeleted: null
    };
  }

  async componentDidMount() {
    const token = new URLSearchParams(window.location.search).get('token');

    if (token === undefined || token === '' || token === null)
      window.location.href = '/';

    await UserService.checkDeletionToken(token)
      .then(response => {
        this.setState({
          isDeleted: true
        });
      })
      .catch(e => {
        this.setState({
          isDeleted: false
        });
      });
  }

  render() {
    const { isLoading, enabled, isDeleted } = this.state;

    return (
      <div className="main-container subscription">
        {DocumentTitle(
          'app.title-delete-account',
          'WATTPARK - Supprimer un compte'
        )}
        <IllustrationCity />
        <header className="header header-login">
          <div className="header-left">
            <Link to={ROUTES.LOGIN}>
              <span className="link">
                <i className="icon-arrow-left" />
                <span className="link-text">
                  <FormattedMessage id="app.return" defaultMessage="Retour" />
                </span>
              </span>
            </Link>
          </div>
          <div className="header-center">
            <h1 className="logo-main">
              <Link to={ROUTES.LANDING}>
                <img src="/img/logo.svg" alt="Logo Wattpark" />
              </Link>
            </h1>
          </div>
        </header>

        <div className="container">
          <div className="row">
            <div className="col-xs-12 col-sm-4">
              <div className="form-block-intro">
                <h2 className="title-main">
                  <FormattedMessage
                    id="app.title-main-delete"
                    defaultMessage="Suppression de votre compte WATTPARK"
                  />
                </h2>
              </div>
            </div>
            <div className="col-xs-12 col-sm-8">
              <div className="form-block-wrapper">
                <div className="form-block">
                  <div className="row">
                    <div className="col-xs-12 input-position text-center">
                      {isDeleted === false && (
                        <>
                          <FormattedMessage
                            id="app.user-undeleted"
                            defaultMessage="Il semble y avoir un problème, votre compte n'a pas été supprimé ou a déjà été supprimé. Veuillez vérifier et lancer une nouvelle procédure de suppression svp."
                          />
                        </>
                      )}
                      {isDeleted === true && (
                        <>
                          <FormattedMessage
                            id="app.user-deleted"
                            defaultMessage="Votre compte a bien été supprimé."
                          />
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default UserDeletion;
