import React from 'react';
import { FormattedMessage } from 'react-intl';

const Search = ({ inputSearch, handleChange }) => {
  return (
    <div className="search-banner search-banner-scroll">
      <div className="container">
        <div className="row">
          <div className="col-xs-4">
            <h2 className="title-main">Certification IRVE</h2>
          </div>
          <div className="col-xs-8">
            {/*<div className="form-input">
              <input
                className="form-input-field"
                type="text"
                id="inputSearch"
                name="inputSearch"
                onChange={handleChange}
                value={inputSearch}
              />
              <label className="form-input-label" htmlFor="inputSearch">
                <span className="form-input-label-content">
                  <FormattedMessage
                    id="app.search-certificate"
                    defaultMessage="Rechercher un certificat"
                  />
                </span>
              </label>
            </div>*/}
          </div>
        </div>
      </div>
    </div>
  );
};
export default Search;
