import {
  ADD_PARKING_LIST,
  FETCH_ADD_USER_TO_LIST,
  FETCH_CALENDAR,
  FETCH_CARS_PARKING,
  FETCH_CREATE_PARKINGS,
  FETCH_DELETE_BOOKING,
  FETCH_EDIT_PARKING,
  FETCH_GET_LIST,
  FETCH_GET_PARKING_BOOKINGS,
  FETCH_GET_PARKING_BY_ID,
  FETCH_GET_PARKING_IMAGES,
  FETCH_GET_PARKING_SOLDE,
  FETCH_GET_PARKING_THUMB,
  FETCH_GET_PARKINGS,
  FETCH_PARKING_SOLDE,
  FETCH_STATE_BOOKING,
  FETCH_STATE_PARKING,
  FETCH_STATION_STATUS,
  FETCH_UPDATE_LIST,
  FETCH_UPDATE_PARKING_MODE,
  RECEIPT_LOADED,
  REMOVE_PARKING_CAR
} from '../actions/parkingAction';

const initialState = {
  parkingAddressDocProvided: false,
  cars: {
    data: [],
    total: 0,
    pending: false,
    success: false,
    rejected: false,
    deletion: {
      pending: false,
      success: false,
      rejected: false
    },
    add: {
      pending: false,
      success: false,
      rejected: false
    }
  },
  receipt: { loading: false, success: false, error: false }
};

const calculTotalList = data => {
  if (data.length === 0) return 0;
  else {
    const allData = data.find(list => list.pagination === true).list;

    let total = 0;

    allData.map(a => {
      total = total + a.length;
    });

    return total;
  }
};

export default function(state = initialState, action) {
  switch (action.type) {
    case RECEIPT_LOADED:
      return {
        ...state,
        receipt: action.payload
      };
    case FETCH_GET_PARKINGS:
      return {
        ...state,
        parkings: action.payload.parking ? action.payload.parking.yours : [],
        associatedParking: action.payload.parking
          ? action.payload.parking.associated
          : [],
        missingCertificate: action.payload.missingCertificate,
        missingAddressProof: action.payload.missingAddressProof,
        loading: true,
        parkingAdd: false,
        message: action.message
      };
    case FETCH_GET_LIST:
      return {
        ...state,
        list: action.payload,
        loading: true
      };
    case FETCH_CALENDAR:
      return {
        ...state,
        list: action.payload.list,
        calendarInfo: action.payload.info,
        loading: true
      };
    case FETCH_ADD_USER_TO_LIST:
      return {
        ...state,
        list: action.payload.list,
        exist: action.payload.exist,
        loading: true
      };
    case FETCH_UPDATE_LIST:
      return {
        ...state,
        list: action.payload,
        loading: true
      };
    case FETCH_CREATE_PARKINGS:
      const hasAddressDocProvided =
        action.data &&
        action.data.addressFile &&
        action.data.addressFile.length > 0
          ? true
          : false;
      return {
        ...state,
        parkingAdd: action.payload,
        parkingAddressDocProvided: hasAddressDocProvided,
        parkingId: action.data.parkingId
      };
    case FETCH_GET_PARKING_BY_ID:
      return {
        ...state,
        parkingById: action.payload,
        loading: false,
        parkingAdd: false,
        message: action.message,
        validParking: action.isValid
      };
    case FETCH_GET_PARKING_BOOKINGS:
      return {
        ...state,
        parkingBookings: action.payload,
        loading: true,
        ok: action.ok,
        parkingAdd: false,
        messageBooking: action.message
      };
    case FETCH_GET_PARKING_IMAGES:
      return {
        ...state,
        parkingImages: action.payload.data,
        loading: true,
        parkingAdd: false,
        message: action.message
      };
    case FETCH_GET_PARKING_THUMB:
      return {
        ...state,
        parkingThumb: action.payload,
        loading: true,
        parkingAdd: false,
        validThumb: action.isValid
      };
    case FETCH_EDIT_PARKING:
      return {
        ...state,
        parkingEdit: action.payload,
        parkingAdd: false,
        loading: true
      };
    case FETCH_UPDATE_PARKING_MODE:
      return {
        ...state,
        parkingMode: action.payload,
        parkingAdd: false,
        loading: true
      };
    case FETCH_GET_PARKING_SOLDE:
      return {
        ...state,
        parkingSolde: action.payload,
        parkingAdd: false,
        loading: true
      };
    case FETCH_PARKING_SOLDE:
      return {
        ...state,
        parking_Solde: action.payload,
        parkingAdd: false,
        loading: true
      };
    case FETCH_STATION_STATUS:
      return {
        ...state,
        stationStatus: action.payload,
        parkingAdd: false,
        loading: true
      };
    case FETCH_DELETE_BOOKING:
      return {
        ...state,
        deleteBooking: action.payload,
        loading: true
      };
    case FETCH_STATE_PARKING:
      return {
        ...state,
        parkingSolde: '',
        ok: '',
        parking_Solde: '',
        parkingAdd: '',
        deleteBooking: '',
        parkingEdit: '',
        parkingMode: '',
        messageIssueParking: '',
        issuesParkingList: '',
        parkingBookings: '',
        characteristicParking: '',
        parkingById: '',
        parkingImages: '',
        parkingThumb: ''
      };
    case FETCH_STATE_BOOKING:
      return {
        ...state,
        deleteBooking: ''
      };
    case `${FETCH_CARS_PARKING}_PENDING`:
      return {
        ...state,
        cars: {
          ...initialState.cars,
          data: [...state.cars.data],
          total: calculTotalList(state.cars.data),
          pending: true
        }
      };
    case `${FETCH_CARS_PARKING}_FULFILLED`:
      const payload = action.payload;
      return {
        ...state,
        cars: {
          ...initialState.cars,
          success: true,
          data:
            payload && payload.data && Array.isArray(payload.data)
              ? payload.data
              : [],
          total:
            payload && payload.data && Array.isArray(payload.data)
              ? calculTotalList(payload.data)
              : 0
        }
      };
    case `${FETCH_CARS_PARKING}_REJECTED`:
      return {
        ...state,
        cars: {
          ...initialState.cars,
          error: true,
          total: 0
        }
      };
    case `${REMOVE_PARKING_CAR}_PENDING`:
      return {
        ...state,
        cars: {
          ...state.cars,
          deletion: {
            ...initialState.cars.deletion,
            pending: true
          }
        }
      };
    case `${REMOVE_PARKING_CAR}_FULFILLED`:
      return {
        ...state,
        cars: {
          ...state.cars,
          deletion: {
            ...initialState.cars.deletion,
            success: true
          }
        }
      };
    case `${REMOVE_PARKING_CAR}_REJECTED`:
      return {
        ...state,
        cars: {
          ...state.cars,
          deletion: {
            ...initialState.cars.deletion,
            rejected: true
          }
        }
      };
    case `${ADD_PARKING_LIST}_PENDING`:
      return {
        ...state,
        cars: {
          ...state.cars,
          add: {
            ...initialState.cars.add,
            pending: true
          }
        }
      };
    case `${ADD_PARKING_LIST}_FULFILLED`:
      return {
        ...state,
        cars: {
          ...state.cars,
          add: {
            ...initialState.cars.add,
            success: true,
            id: action.payload.data[0]
          }
        }
      };
    case `${ADD_PARKING_LIST}_REJECTED`:
      if (action.payload.status === 409) {
        return {
          ...state,
          cars: {
            ...state.cars,
            add: {
              ...initialState.cars.add,
              knownCar: true
            }
          }
        };
      } else {
        return {
          ...state,
          cars: {
            ...state.cars,
            add: {
              ...initialState.cars.add,
              rejected: true
            }
          }
        };
      }

    default:
      return state;
  }
}
