import { FormattedMessage } from 'react-intl';
import React from 'react';

const SearchNotFound = ({ results }) => {
  if (results.length === 0)
    return (
      <div className="search-content">
        <h4 className="search-title">
          <FormattedMessage
            id="app.list-notfound"
            defaultMessage="Les termes recherchés n'aboutissent à aucun résultat."
          />
        </h4>
        <p className="search-text">
          <FormattedMessage
            id="app.list-notfound-fix"
            defaultMessage="Nous vous invitons à en vérifier l'orthographe ou à procéder à une autre recherche."
          />
        </p>
      </div>
    );
  else return null;
};

export default SearchNotFound;
