import React, { Component } from 'react';
import Scheduler from './../../../Layouts/Forms/scheduler';

import { fetchPatchParking } from '../../../../actions/parkingAction';
import {
  fetchAddHoursContractSlot,
  fetchDeleteHoursContractSlot,
  fetchUpdateHoursContractSlot,
  fetchUpdateParkingHoursContract
} from '../../../../actions/hoursContractAction';
import { connect } from 'react-redux';
import {
  hideModal,
  showFullHoursModal,
  showModal
} from '../../../../actions/modalAction';
import { defaultPriceModalData } from '../../../../utils';
import HoursContractMode from '../../../Layouts/Forms/hours-contract-mode';
import { MODAL_FULL_HOURS } from '../../../Layouts/Modals/modals';
import ModalFullHours from '../../../Layouts/Modals/modalFullHours';

class Price extends Component {
  constructor(props) {
    super(props);

    this.state = {
      hoursContract: props.hoursContract,
      message: props.message
    };

    this.showModal = this.showModal.bind(this);
    this.showPowerModal = this.showPowerModal.bind(this);
    this.handleContractMode = this.handleContractMode.bind(this);
    this.handleContractFullCharge = this.handleContractFullCharge.bind(this);
    this.handleSubmitSlot = this.handleSubmitSlot.bind(this);
    this.handleDeleteSlot = this.handleDeleteSlot.bind(this);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.hoursContract !== this.props.hoursContract) {
      this.setState({
        hoursContract: this.props.hoursContract
      });

      this.props.hideModal();
    }
  }

  showModal(e) {
    this.props.showModal(defaultPriceModalData(e));
  }

  showPowerModal(slot) {
    this.props.showFullHoursModal({ type: MODAL_FULL_HOURS, slot: slot });
  }

  handleContractMode() {
    this.setState({
      hoursContract: {
        ...this.state.hoursContract,
        activated: !this.state.hoursContract.activated
      }
    });

    const data = {
      activated: !this.state.hoursContract.activated,
      fullHourCharge: this.state.hoursContract.fullHourCharge
    };

    this.props.fetchUpdateParkingHoursContract(
      this.state.hoursContract.id,
      data
    );
  }

  handleContractFullCharge(value, fetch = false) {
    this.setState({
      hoursContract: {
        ...this.state.hoursContract,
        fullHourCharge: value
      }
    });

    if (fetch) {
      const data = {
        activated: this.state.hoursContract.activated,
        fullHourCharge: value
      };

      this.props.fetchUpdateParkingHoursContract(
        this.state.hoursContract.id,
        data
      );
    }
  }

  handleDeleteSlot(slotId) {
    this.props.fetchDeleteHoursContractSlot(slotId);
  }

  handleSubmitSlot(slot) {
    const data = {
      startHour: slot.startHour,
      endHour: slot.endHour
    };

    if (slot.id) this.props.fetchUpdateHoursContractSlot(slot.id, data);
    else {
      let addedData = {
        ...data,
        hours_contract_id: this.state.hoursContract.id
      };

      this.props.fetchAddHoursContractSlot(addedData);
    }
  }

  render() {
    return (
      <div id="view3" className="view">
        <Scheduler
          daysSlots={this.props.parking.publicHours}
          handleMethod={this.showModal}
        />
        <HoursContractMode
          parkingId={this.props.parking.id}
          hoursContract={this.state.hoursContract}
          handleContractMode={this.handleContractMode}
          handleContractFullCharge={this.handleContractFullCharge}
          handleShowModal={this.showPowerModal}
        />
        <ModalFullHours
          type={this.props.modal.type}
          slot={this.props.modal.slot}
          handleDelete={this.handleDeleteSlot}
          handleSubmit={this.handleSubmitSlot}
        />
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    hoursContract: state.hoursContract.hoursContract,
    message: state.hoursContract.message,
    modal: state.modal
  };
}

export default connect(mapStateToProps, {
  showModal,
  showFullHoursModal,
  hideModal,
  fetchPatchParking,
  fetchUpdateParkingHoursContract,
  fetchAddHoursContractSlot,
  fetchUpdateHoursContractSlot,
  fetchDeleteHoursContractSlot
})(Price);
