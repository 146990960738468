import React from 'react';

const Reporting = ({ desc, date, name, id }) => {
  const dateNow = new Date();
  const dateIssue = new Date(date);
  const days = parseInt((dateIssue - dateNow) / (1000 * 60 * 60 * 24)) * -1;
  const hours = parseInt(
    (Math.abs(dateIssue - dateNow) / (1000 * 60 * 60)) % 24
  );
  const minutes = parseInt(
    (Math.abs(dateIssue.getTime() - dateNow.getTime()) / (1000 * 60)) % 60
  );
  let result;
  if (days > 30) result = parseInt(days / 30) + ' m ';
  else if (days * 24 > 24) result = parseInt(days) + ' j ';
  else {
    result = hours + ' h ' + minutes + ' min';
  }
  return (
    <div className="list-reporting-item">
      <i className="icon-dialog" />
      <div className="list-reporting-item-content">
        <div className="list-reporting-item-title">{name}</div>
        <div className="list-reporting-item-text">{desc}</div>
        <div className="list-reporting-item-time">Il y a {result}</div>
      </div>
    </div>
  );
};
export default Reporting;
