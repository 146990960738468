import config from './../config/index';
import axios from 'axios';

const baseUrl = config.baseUrl;
export const FETCH_GET_ISSUSE = 'FETCH_GET_ISSUSE';
export const FETCH_GET_ISSUSE_BY_PARKING = 'FETCH_GET_ISSUSE_BY_PARKING';

export function fetchGetIssues() {
  const url = `${baseUrl}/api/issue`;
  return dispatch => {
    axios
      .get(url, {
        headers: {
          'x-access-token': localStorage.getItem('token'),
          'content-type': 'application/json'
        }
      })
      .then(request => {
        dispatch({
          type: FETCH_GET_ISSUSE,
          payload: request.data ? request.data : []
        });
      })
      .catch(err => {
        dispatch({
          type: FETCH_GET_ISSUSE,
          payload: false,
          message: 'No Issues'
        });
      });
  };
}

export function fetchGetIssuesByParking(id) {
  const url = `${baseUrl}/api/parkings/${id}/issues`;
  return dispatch => {
    axios
      .get(url, {
        headers: {
          'x-access-token': localStorage.getItem('token'),
          'content-type': 'application/json'
        }
      })
      .then(request => {
        dispatch({
          type: FETCH_GET_ISSUSE_BY_PARKING,
          payload: request.data ? request.data : []
        });
      })
      .catch(err => {
        dispatch({
          type: FETCH_GET_ISSUSE_BY_PARKING,
          payload: false,
          message: 'No Issues'
        });
      });
  };
}
