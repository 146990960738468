import React, { Component } from 'react';
import * as ROUTES from '../../../constants/routes';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';

class Footer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      className: 'btn btn-primary btn-block'
    };
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.disabled)
      this.setState({
        className: nextProps.disabled
          ? 'btn btn-primary btn-block disabled'
          : 'btn btn-primary btn-block'
      });
  }

  render() {
    return (
      <footer className="footer-dashboard">
        <div className="container">
          <div className="row">
            <div className="col-xs-12 col-sm-4 col-sm-offset-8">
              <Link to={ROUTES.CREATE_PARKING} className={this.state.className}>
                <FormattedMessage
                  id="app.parking-activate-parking"
                  defaultMessage="Créer un parking"
                />
              </Link>
            </div>
          </div>
        </div>
      </footer>
    );
  }
}

export default Footer;
