import React from 'react';

const Avatar = ({
  methodDelete,
  picture,
  method,
  id,
  checked,
  imageId,
  nameFile
}) => {
  return (
    <div id="display-data" className="thumb thumb-filled" key={id}>
      <div className="show-image">
        <div className="thumb-content">
          <img
            src={picture}
            className="thumb-img"
            alt="img Parking"
            id={id}
            style={{ width: 70, height: 70 }}
          />

          <label className="radio-button">
            <input
              data-val={id}
              data-imageid={imageId}
              onChange={method}
              type="radio"
              name="thumbRadio"
              defaultChecked={checked}
            />
            <span className="checkmark">
              <i className="icon-check" />
            </span>
          </label>
        </div>
        <a
          onClick={methodDelete}
          data-imageid={imageId}
          data-num={id}
          data-picture={nameFile}
          href="#"
          className="thumb-delete delete"
        >
          <i className="icon-delete">
            <span />
            <span />
          </i>
        </a>
      </div>
    </div>
  );
};
export default Avatar;
