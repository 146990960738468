import axios from 'axios';
import config from './../config/index';

const baseUrl = config.baseUrl;

export const FETCH_STATION_ADD = 'FETCH_STATION_ADD';
export const FETCH_STATION_ADD_EVER_EXIST = 'FETCH_STATION_ADD_EVER_EXIST';
export const FETCH_STATION_DELETE = 'FETCH_STATION_DELETE';
export const FETCH_STATION_PATCH = 'FETCH_STATION_PATCH';
export const FETCH_STATION_EDIT = 'FETCH_STATION_EDIT';
export const FETCH_STATE = 'FETCH_STATE';
export const FETCH_STATIONS_INIT_STATE = 'FETCH_STATIONS_INIT_STATE';

export function fetchAddStation(id, data) {
  const urlSoldeParking = `${baseUrl}/api/parkings/${id}/station?token=${localStorage.getItem(
    'token'
  )}`;

  return dispatch => {
    axios
      .post(urlSoldeParking, data)
      .then(res => {
        if (res.data === 'ever exist') {
          // Station hardwareId ever exist
          return dispatch({
            type: FETCH_STATION_ADD_EVER_EXIST,
            payload: 'exist'
          });
          return false;
        }
        if (res.data.length !== 0) {
          dispatch({
            type: FETCH_STATION_ADD,
            payload: res.data
          });
        } else {
          dispatch({
            type: FETCH_STATION_ADD,
            payload: false
          });
        }
      })
      .catch(err => {
        dispatch({
          type: FETCH_STATION_ADD,
          payload: false
        });
      });
  };
}

export function fetchInitStationState() {
  return dispatch => {
    dispatch({
      type: FETCH_STATIONS_INIT_STATE,
      payload: false
    });
  };
}

export function fetchEditStation(parkingId, id, data) {
  const urlSoldeParking = `${baseUrl}/api/parkings/${parkingId}/station/${id}?token=${localStorage.getItem(
    'token'
  )}`;

  return dispatch => {
    axios
      .put(urlSoldeParking, data)
      .then(request => {
        if (request.data.length !== 0) {
          dispatch({
            type: FETCH_STATION_EDIT,
            payload: request.data
          });
        } else {
          dispatch({
            type: FETCH_STATION_EDIT,
            payload: false
          });
        }
      })
      .catch(() => {
        dispatch({
          type: FETCH_STATION_EDIT,
          payload: false
        });
      });
  };
}

export function fetchResetEditStationField() {
  return dispatch => {
    dispatch({
      type: FETCH_STATION_EDIT,
      payload: ''
    });
  };
}

export function fetchDeleteStation(id) {
  const urlStationParking = `${baseUrl}/api/station/${id}?token=${localStorage.getItem(
    'token'
  )}`;

  return dispatch => {
    axios
      .delete(urlStationParking)
      .then(request => {
        if (request.data) {
          dispatch({
            type: FETCH_STATION_DELETE,
            payload: request.data,
            deleted: true
          });
        } else {
          dispatch({
            type: FETCH_STATION_DELETE,
            payload: false,
            deleted: false
          });
        }
      })
      .catch(() => {
        dispatch({
          type: FETCH_STATION_DELETE,
          payload: false,
          deleted: false
        });
      });
  };
}

export function fetchPatchStation(id, data) {
  const urlStationParking = `${baseUrl}/api/station/${id}?token=${localStorage.getItem(
    'token'
  )}`;

  return dispatch => {
    axios
      .patch(urlStationParking, data)
      .then(request => {
        if (request.data === true) {
          dispatch({
            type: FETCH_STATION_PATCH,
            payload: request.data
          });
        } else {
          dispatch({
            type: FETCH_STATION_PATCH,
            payload: false
          });
        }
      })
      .catch(() => {
        dispatch({
          type: FETCH_STATION_PATCH,
          payload: false
        });
      });
  };
}
export function fetchInitialState() {
  return dispatch => {
    dispatch({
      type: FETCH_STATE,
      payload: true
    });
  };
}
