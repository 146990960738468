import React from 'react';
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl';

const Details = ({ detailsValue, method, error }) => {
  //Validator addressCompl
  let classdetails = 'form-group';
  let classadetails1 = 'form-input';
  let lengthdetails1 = 0;
  if (detailsValue) {
    classadetails1 = 'form-input on-focus';
  }

  if (error) {
    if (error.details.length > 0) {
      lengthdetails1 = error.details.length;
      classdetails = 'form-group on-focus has-error has-feedback';
      classadetails1 = 'form-input on-focus has-error has-feedback';
    }
  }
  return (
    <div className="form-block">
      <h3 className="form-block-title">
        <FormattedMessage
          id="app.parking-access-detail"
          defaultMessage="détail d’accès"
        />
      </h3>
      <div className={classdetails}>
        <div className="form-area">
          <input
            rows="10"
            name="details"
            style={{ height: 100 }}
            className="form-area-input"
            placeholder="Précisez ici les conditions d’accès à votre parking. N’oubliez pas de mentionner les éventuels codes d’accès et spécificités liées aux ouvertures de portails."
            defaultValue={detailsValue}
            //value={detailsValue}
            onChange={method}
          />
          {lengthdetails1 > 0 && (
            <span className="help-block">{error.details}</span>
          )}
        </div>
      </div>
    </div>
  );
};
export default Details;
