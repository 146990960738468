import config from './../config/index';
import axios from 'axios';

const baseUrl = config.baseUrl;
export const FETCH_GET_CARS = 'FETCH_GET_CARS';
export const FETCH_ADD_CARS = 'FETCH_ADD_CARS';
export const FETCH_DELETE_CARS = 'FETCH_DELETE_CARS';
export const FETCH_GET_CSV_EXAMPLE = 'FETCH_GET_CSV_EXAMPLE';

export function fetchAddCars(id, data) {
  const url = `${baseUrl}/api/parkings/${id}/blackwhitelist?token=${localStorage.getItem(
    'token'
  )}`;
  return dispatch => {
    axios
      .post(url, data)
      .then(request => {
        if (request.data.length !== 0) {
          dispatch({
            type: FETCH_ADD_CARS,
            payload: request.data
          });
        }
      })
      .catch(() => {
        dispatch({
          type: FETCH_ADD_CARS,
          payload: false
        });
      });
  };
}

export function fetchDeleteList(ParkingId, id) {
  const url = `${baseUrl}/api/parkings/${ParkingId}/list/${id}?token=${localStorage.getItem(
    'token'
  )}`;
  return dispatch => {
    axios
      .delete(url)
      .then(request => {
        if (request.data.length !== 0) {
          if (request.data.length !== 0) {
            dispatch({
              type: FETCH_DELETE_CARS,
              payload: request.data,
              deleted: true
            });
          }
        }
      })
      .catch(() => {
        dispatch({
          type: FETCH_DELETE_CARS,
          payload: false
        });
      });
  };
}

export function getCSVExample() {
  const url = `${baseUrl}/api/list/csv-example?token=${localStorage.getItem(
    'token'
  )}`;

  return dispatch => {
    axios
      .get(url)
      .then(request => {
        dispatch({
          type: FETCH_GET_CSV_EXAMPLE,
          payload: request.data
        });
      })
      .catch(() => {
        dispatch({
          type: FETCH_GET_CSV_EXAMPLE,
          payload: false
        });
      });
  };
}
