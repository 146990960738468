import {
  HIDE_MODAL,
  SHOW_CUSTOM_MODAL,
  SHOW_MODAL,
  SHOW_MODAL_DELETE_CAR,
  SHOW_MODAL_FULL_HOURS,
  SHOW_MODAL_PARKING_ACTIVATION
} from '../actions/modalAction';

const initialState = {
  type: null,
  modalData: null,
  display: false,
  displayModal: false,
  displayActivation: false,
  displayDeleteCar: false
};

export default function(state = initialState, action) {
  switch (action.type) {
    case SHOW_MODAL:
      return {
        ...state,
        type: 'modal',
        modalData: action.payload,
        display: true
      };
    case SHOW_CUSTOM_MODAL:
      return {
        ...state,
        type: action.payload,
        displayModal: true
      };
    case SHOW_MODAL_FULL_HOURS:
      return {
        ...state,
        type: action.payload.type,
        slot: action.payload.slot,
        displayModal: true
      };
    case SHOW_MODAL_PARKING_ACTIVATION:
      return {
        ...state,
        type: 'parkingActivation',
        modalData: action.payload,
        displayActivation: true
      };
    case SHOW_MODAL_DELETE_CAR:
      return {
        ...state,
        type: 'deleteCard',
        modalData: action.payload,
        displayDeleteCar: true
      };
    case HIDE_MODAL:
      return {
        ...state,
        type: null,
        display: false,
        displayModal: false,
        displayActivation: false,
        displayDeleteCar: false
      };
    default:
      return state;
  }
}
