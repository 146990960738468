import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import {setKey, fromAddress} from 'react-geocode'
import * as ROUTES from '../../../constants/routes';
import ParkingName from './../../Layouts/Forms/name';
import Pictures from './../../Layouts/Forms/pictures';
import Address from './../../Layouts/Forms/address';
import LatLong from './../../Layouts/Forms/lat_long';
import Receipt from './../../Layouts/Forms/receipt';
import Details from './../../Layouts/Forms/details';
import Characteristics from './../../Layouts/Forms/characteristics';
import Network from './../../Layouts/Forms/network';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import {
  fetchCreateParking,
  fetchInitialState
} from '../../../actions/parkingAction';
import {
  DocumentTitle,
  handleSelectIsFilled,
  handleSelectOpening,
  menu,
  nav,
  setInputBehaviour
} from '../../../utils';
import Avatar from './../../Layouts/Forms/avatar';
import $ from 'jquery';
import Typologies from '../../Layouts/Forms/typologies';

const reg = /^\d+(\,\d{1,2})?$/;
setKey('AIzaSyD056fUWPndS7K-8X5TFIt5rqBvyrwrWA4');

const formValidFieled = (...rest) => {
  let valid = true;

  Object.values(rest).forEach(val => {
    val.length < 0 && (valid = false);
  });
  return valid;
};

class CreateParkings extends Component {
  constructor(props) {
    super(props);
    this.state = {
      listChar: [],
      Children: [],
      array: [],
      images: [],
      picture: 'public/img/parkingDefault.jpg',
      name: '',
      numChildren: 0,
      address: '',
      defaultImage: false,
      addressCompl: '',
      checkedImg: false,
      zipCode: '',
      country: '',
      city: '',
      details: this.props.children,
      lat: '',
      imagesParking: [],
      fileParking: null,
      fileNameParking: '',
      longitude: '',
      energyProviderType: '',
      fileName: '',
      heightMax: '',
      isLoading: false,
      typologies_id: null,
      urlWebSite: '',
      formErros: {
        name: '',
        address: '',
        country: '',
        zipCode: '',
        city: '',
        details: '',
        addressCompl: '',
        lat: '',
        longitude: '',
        heightMax: ''
      }
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.onImageChange = this.onImageChange.bind(this);
    this.onFileChange = this.onFileChange.bind(this);
    this.onChangeSelect = this.onChangeSelect.bind(this);
    this.handleChangeInputDefault = this.handleChangeInputDefault.bind(this);
    this.onDeletePhoto = this.onDeletePhoto.bind(this);
    this.onClickSelect = this.onClickSelect.bind(this);
    this.handleGeocode = this.handleGeocode.bind(this);
  }

  componentDidMount() {
    window.scrollTo(500, 0);
    setInputBehaviour();
    handleSelectOpening();
    handleSelectIsFilled();
    this.handleGeocode();
    menu.init();
    nav.init();
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.parkingAdd === true) {
      this.props.history.push(ROUTES.PARKINGVALIDATION);
    }
  }

  onClickSelect() {
    $('.select').on('click', function() {
      if ($(this).hasClass('is-opened')) {
        $(this).removeClass('is-opened');
      } else {
        $(this).addClass('is-opened');
      }
    });
  }

  handleSubmit(event) {
    event.preventDefault();

    if (!formValidFieled(this.state)) {
    } else {
      this.setState({
        isLoading: true
      });
      let parkingAdd = {
        name: this.state.name,
        guarded: this.state.guarded,
        iscover: this.state.covered,
        isLight: this.state.lighting,
        PMRAccess: this.state.PMRAccess,
        checked: this.state.checkedImg,
        status: 'coming soon',
        images: this.state.imagesParking,
        file: this.state.fileParking,
        addressFile: this.state.fileNameParking,
        defaultImage: this.state.defaultImage,
        conciergerie: this.state.conciergerie,
        secured: this.state.secured,
        maxHeight: this.state.heightMax,
        typologies_id: this.state.typologies_id,
        urlWebSite: this.state.urlWebSite,
        //description: this.state.details,
        characteristics: this.state.listChar,
        energyProviderType: this.state.energyProviderType,
        user_id: localStorage.getItem('userId'),
        address: {
          city: this.state.city,
          country: this.state.country,
          department: this.state.zipCode,
          addressCompl: this.state.addressCompl,
          accessDescription: this.state.details,
          street: this.state.address,
          zipCode: this.state.zipCode,
          latitude: this.state.lat,
          longitude: this.state.longitude
        }
      };
      this.props.fetchCreateParking(parkingAdd);
      this.props.fetchInitialState();
    }
  }

  handleChangeInputDefault(e) {
    for (let i = 0; i < this.state.images.length; i++) {
      if (this.state.images[i] !== undefined) {
        this.state.images[i].default = 0;
        this.state.images[e.currentTarget.dataset.val]['default'] = 1;
        this.state.defaultImage = this.state.images[
          e.currentTarget.dataset.val
        ].name;
      }
    }
    const target = e.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
    this.setState({
      [name]: value,
      images: this.state.images,
      defaultImage: this.state.defaultImage
    });
  }

  handleGeocode() {
    const parkingForm = $(document).find('#create-parking-form');
    const $trigger = parkingForm.find('.form-input-field');

    $trigger.on('blur', () => {
      const { address, city, lat, longitude, zipCode } = this.state;

      if (address && zipCode && city && lat === '' && longitude === '') {
        fromAddress(`${address}, ${zipCode} ${city}`).then(response => {
          const { lat, lng } = response.results[0].geometry.location;
          this.setState({ lat, longitude: lng });
        });
      }
    });
  }

  handleChange(event) {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    let formErrors = this.state.formErros;
    switch (name) {
      case 'name':
        formErrors.name =
          value.length < 3 && value.length > 0
            ? 'Minimum 3 caractères sont requis'
            : '';
        break;
      case 'address':
        formErrors.address =
          value.length < 3 && value.length > 0
            ? 'Minimum 3 caractères sont requis'
            : '';
        break;
      case 'zipCode':
        formErrors.zipCode = isNaN(value)
          ? "Le code postal n'est pas valide"
          : '';
        break;
      case 'city':
        formErrors.city =
          value.length < 3 && value.length > 0
            ? 'Minimum 3 caractères sont requis'
            : '';
        break;
      case 'longitude':
        formErrors.longitude = isNaN(value)
          ? 'Minimum 3 caractères sont requis'
          : '';
        break;
      case 'lat':
        formErrors.lat = isNaN(value) ? 'Minimum 3 caractères sont requis' : '';
        break;
      case 'heightMax':
        formErrors.heightMax =
          reg.test(value) || value === '' ? '' : "La hauteur n'est pas valide";
        break;
      case 'details':
        formErrors.details =
          value.length < 6 && value.length > 0
            ? 'Minimum 6 caractères sont requis'
            : '';
        break;
      case 'addressCompl':
        formErrors.addressCompl =
          value.length < 6 && value.length > 0
            ? 'Minimum 6 caractères sont requis'
            : '';
        break;
      default:
        break;
    }
    this.setState({
      formErrors,
      [name]: value
    });
    if (target.type === 'checkbox') {
      if (target.checked) {
        this.state.listChar.push(event.currentTarget.dataset.value);
      } else {
        if (this.state.listChar.length !== 0) {
          for (let i = 0; i < this.state.listChar.length; i++) {
            if (this.state.listChar[i] === event.currentTarget.dataset.value)
              this.state.listChar.splice(i, 1);
          }
        }
      }
    }
    this.setState({
      [name]: value,
      images: this.state.images,
      listChar: this.state.listChar
    });
  }

  onImageChange(event) {
    if (event.target.files && event.target.files[0]) {
      let reader = new FileReader();
      const file = event.target.files[0];
      this.state.images.push(file);
      this.setState({
        imagesParking: this.state.images,
        fileName: event.target.files[0].name
      });

      reader.onload = e => {
        this.state.array.push(
          <Avatar
            key={this.state.numChildren}
            id={this.state.numChildren}
            picture={e.target.result}
            methodDelete={this.onDeletePhoto}
            method={this.handleChangeInputDefault}
            checked={this.state.checkedImg}
            nameFile={this.state.fileName}
          />
        );

        this.setState({
          numChildren: this.state.numChildren + 1,
          picture: e.target.result,
          children: [...this.state.array],
          images: [...this.state.images]
        });
      };
      reader.readAsDataURL(event.target.files[0]);
    }
  }

  onFileChange(event) {
    if (event.target.files && event.target.files[0]) {
      this.setState({
        fileParking: event.target.files[0],
        fileNameParking: event.target.files[0].name
      });
    }
  }

  logout() {
    localStorage.clear();
    window.location.href = ROUTES.LOGIN;
  }

  onChangeSelect(e) {
    this.setState({
      energyProviderType: e.currentTarget.dataset.value
    });
  }

  onDeletePhoto(event) {
    try {
      for (let i = 0; i < this.state.images.length; i++) {
        if (this.state.images[i] !== undefined) {
          if (
            event.currentTarget.dataset.picture === this.state.images[i].name
          ) {
            delete this.state.images[i];
          }
          this.setState({
            images: this.state.images
          });
        }
      }
      for (let i = 0; i < this.state.array.length; i++) {
        if (this.state.array[i] !== undefined) {
          if (event.currentTarget.dataset.num === this.state.array[i].key) {
            delete this.state.array[i];
            this.setState({
              children: [...this.state.array]
            });
          }
        }
      }
    } catch (e) {
      return false;
    }
  }

  handleClickOutside(e) {
    window.$('.select').removeClass('is-opened');
  }

  render() {
    document.addEventListener('click', this.handleClickOutside, true);
    const formErrors = this.state.formErros;
    const enabled =
      formErrors.name.length > 0 ||
      this.state.name === '' ||
      this.state.energyProviderType === '' ||
      formErrors.address.length > 0 ||
      this.state.address === '' ||
      formErrors.longitude.length > 0 ||
      this.state.longitude === '' ||
      formErrors.lat.length > 0 ||
      this.state.lat === '' ||
      formErrors.details.length > 0 ||
      this.state.details === '' ||
      formErrors.city.length > 0 ||
      this.state.city === '' ||
      formErrors.zipCode > 0 ||
      this.state.zipCode === '';

    const { isLoading } = this.state.isLoading;
    const { isCompany } = JSON.parse(localStorage.getItem('user'));
    return (
      <div className="main-container creation">
        {DocumentTitle(
          'app.title-create-parking',
          'WATTPARK - Créer un parking'
        )}
        <div className="illustration-city" />
        <header className="header">
          <div className="header-left">
            <h1 className="logo-main">
              <Link to={ROUTES.LANDING}>
                <img src="/img/logo.svg" alt="Logo Wattpark"></img>
              </Link>
            </h1>
            <nav className="nav-main">
              <div className="nav-main-user">
                <i className="icon-user" />
                <div className="nav-main-user-name">
                  {localStorage.getItem('username')}
                </div>
                <Link
                  to=""
                  className="nav-main-user-connexion"
                  onClick={this.logout}
                >
                  <FormattedMessage
                    id="app.navLogout"
                    defaultMessage="Déconnexion"
                  />
                </Link>
              </div>
            </nav>
            <Link className="link" to={ROUTES.DASHBOARD_PARKINGS}>
              <i className="icon-arrow-left" />
              <span className="link-text">
                <FormattedMessage id="app.navDsh" defaultMessage="Accueil" />
              </span>
            </Link>
          </div>
          <div className="header-center">
            <h2 className="header-title">
              <FormattedMessage
                id="app.creating-a-WATTPARK-car-park"
                defaultMessage="Création d’un parking WATTPARK"
              />
            </h2>
          </div>
          <div className="header-right">
            <div className="nav-trigger">
              <span className="nav-trigger-line"></span>
              <span className="nav-trigger-line"></span>
              <span className="nav-trigger-line"></span>
            </div>
            <div className="header-user">
              <i className="icon-user" />
              <div className="header-user-info">
                <div className="header-user-name">
                  {localStorage.getItem('username')}
                </div>
                <Link
                  to=""
                  className="header-user-connexion"
                  onClick={this.logout}
                >
                  <FormattedMessage
                    id="app.navLogout"
                    defaultMessage="Déconnexion"
                  />
                </Link>
              </div>
            </div>
          </div>
        </header>
        <div className="screen-filter"></div>
        <div className="container">
          <form
            id="create-parking-form"
            onSubmit={this.handleSubmit}
            noValidate
          >
            <div className="row">
              <div className="col-xs-12 col-sm-4">
                <div className="form-block-intro">
                  <h2 className="title-main">
                    <FormattedMessage
                      id="app.creating-your-WATTPARK-car-park"
                      defaultMessage="Création de votre parking WATTPARK"
                    />
                  </h2>
                  <p className="text-main">
                    <FormattedMessage
                      id="app.creating-info-park"
                      defaultMessage=" Saisissez les informations nécessaires à la création de votre
                                    WATTPARK."
                    />
                  </p>
                </div>
              </div>
              <div
                className="col-xs-12 col-sm-8
              "
              >
                <div className="form-block-wrapper">
                  <ParkingName
                    value={this.state.name}
                    error={formErrors}
                    method={this.handleChange}
                  />
                  <Pictures
                    method={this.onImageChange}
                    img={this.state.children}
                    id={this.state.numChildren}
                  />
                  <div className="form-block">
                    <h3 className="form-block-title">
                      <FormattedMessage
                        id="app.parking-address"
                        defaultMessage="Adresse du parking"
                      />
                    </h3>
                    <div className="row">
                      <Address
                        error={formErrors}
                        valueinputAddress={this.state.address}
                        valueinputAddressCompl={this.state.addressCompl}
                        valueinputZIP={this.state.zipCode}
                        valueinputCity={this.state.city}
                        valueinputLat={this.state.lat}
                        valueinputLong={this.state.longitude}
                        valueinputCountry={this.state.country}
                        method={this.handleChange}
                      />
                      <LatLong
                        error={formErrors}
                        valueinputLat={this.state.lat}
                        valueinputLong={this.state.longitude}
                        method={this.handleChange}
                      />
                    </div>
                  </div>
                  <Receipt
                    method={this.onFileChange}
                    fileName={this.state.fileNameParking}
                  />
                  {isCompany ? (
                    <Typologies
                      urlWebSite={this.state.urlWebSite}
                      method={this.handleChange}
                    />
                  ) : null}
                  <Details
                    error={formErrors}
                    detailsValue={this.state.details}
                    method={this.handleChange}
                  />
                  <Characteristics
                    error={formErrors}
                    isHeightMax={this.state.heightMax}
                    method={this.handleChange}
                  />
                  <Network
                    onClickSelect={this.onClickSelect}
                    type={this.state.energyProviderType}
                    method={this.onChangeSelect}
                  />
                </div>
                <div className="form-validation">
                  <div className="form-validation-action">
                    <button
                      disabled={enabled || this.state.isLoading}
                      type="submit"
                      className={
                        enabled || this.state.isLoading
                          ? 'btn btn-primary btn-block btn-disable'
                          : 'btn btn-primary btn-block'
                      }
                    >
                      {' '}
                      {isLoading ? (
                        'Loading…'
                      ) : (
                        <FormattedMessage
                          id="app.btnCreateParks"
                          defaultMessage="Valider"
                        />
                      )}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    parkingAdd: state.parkings.parkingAdd,
    isAuthenticated: state.loginUser.isAuthenticated
  };
}

export default connect(mapStateToProps, {
  fetchCreateParking,
  fetchInitialState
})(CreateParkings);
