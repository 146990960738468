import axios from 'axios';

import config from './../config/index';

const baseUrl = config.baseUrl;

const getCertificate = id => {
  const url = `${baseUrl}/api/files/certificate/${id}`;
  return axios.get(url, {
    headers: {
      'x-access-token': localStorage.getItem('token'),
      'content-type': 'application/json'
    }
  });
};

const getParkingsCertificate = () => {
  const url = `${baseUrl}/api/files?type=certificat irve`;
  return axios.get(url, {
    headers: {
      'x-access-token': localStorage.getItem('token'),
      'content-type': 'application/json'
    }
  });
};

const getParkingsWithoutCertificate = () => {
  const url = `${baseUrl}/api/files/nocertificate`;
  return axios.get(url, {
    headers: {
      'x-access-token': localStorage.getItem('token'),
      'content-type': 'application/json'
    }
  });
};

export default {
  getCertificate,
  getParkingsCertificate,
  getParkingsWithoutCertificate
};
