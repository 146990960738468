import React, { Component } from 'react';
import Question from '../../Layouts/Forms/question';
import faqQuestions from '../../../content/faqContact';

class FaqIndex extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.questions = faqQuestions;
  }

  render() {
    return (
      <div className="form-block">
        <h3 className="form-block-title">Questions fréquentes</h3>
        {this.questions.map(question => (
          <Question
            key={`contact-faq-question-${question.id}`}
            questionKey={`contact-faq-question-${question.id}`}
            question={question}
          />
        ))}
      </div>
    );
  }
}

export default FaqIndex;
