import React from 'react';
import { FormattedMessage } from 'react-intl';
import ModalCustom from './modalCustom';
import { MODAL_DELETE_LIST } from './modals';

const ModalDeleteList = ({ handleConfirm, type }) => {
  if (type === MODAL_DELETE_LIST)
    return (
      <ModalCustom
        title={
          <FormattedMessage id="app.attention" defaultMessage="Attention" />
        }
        content={
          <FormattedMessage
            id="app.list-delete"
            defaultMessage="Êtes-vous sur de vouloir supprimer cette liste ?"
          />
        }
        handleConfirm={handleConfirm}
      />
    );
  else return null;
};

export default ModalDeleteList;
