import {
  FETCH_REGISTER,
  FETCH_VERIFY_EMAIL,
  FETCH_STATE_USER_ADD
} from '../actions/signupAction';

export default function(state = [], action) {
  switch (action.type) {
    case FETCH_REGISTER:
      return {
        ...state,
        add: action.payload
      };
    case FETCH_VERIFY_EMAIL:
      return {
        ...state,
        verify: action.payload,
        add: ''
      };
    case FETCH_STATE_USER_ADD:
      return {
        ...state,
        verify: '',
        add: ''
      };
    default:
      return state;
  }
}
