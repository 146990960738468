import React, { Component } from 'react';
import _ from 'lodash';
import Day from './day';
import { fetchAddSlots, fetchDeleteSlots } from '../../../actions/hoursAction';
import { connect } from 'react-redux';
import Alert from './../Alert/alert';
import { showModal } from '../../../actions/modalAction';

const reg = /^\d+(\,\d{1,2})?$/;
const hoursDays = [
  '00:00:00',
  '01:00:00',
  '02:00:00',
  '03:00:00',
  '04:00:00',
  '05:00:00',
  '06:00:00',
  '07:00:00',
  '08:00:00',
  '09:00:00',
  '10:00:00',
  '11:00:00',
  '12:00:00',
  '13:00:00',
  '14:00:00',
  '15:00:00',
  '16:00:00',
  '17:00:00',
  '18:00:00',
  '19:00:00',
  '20:00:00',
  '21:00:00',
  '22:00:00',
  '23:00:00',
  '24:00:00'
];
const hoursDays1 = [
  '00:00:00',
  '00:30:00',
  '01:00:00',
  '01:30:00',
  '02:00:00',
  '02:30:00',
  '03:00:00',
  '03:30:00',
  '04:00:00',
  '04:30:00',
  '05:00:00',
  '05:30:00',
  '06:00:00',
  '06:30:00',
  '07:00:00',
  '07:30:00',
  '08:00:00',
  '08:30:00',
  '09:00:00',
  '09:30:00',
  '10:00:00',
  '10:30:00',
  '11:00:00',
  '11:30:00',
  '12:00:00',
  '12:30:00',
  '13:00:00',
  '13:30:00',
  '14:00:00',
  '14:30:00',
  '15:00:00',
  '15:30:00',
  '16:00:00',
  '16:30:00',
  '17:00:00',
  '17:30:00',
  '18:00:00',
  '18:30:00',
  '19:00:00',
  '19:30:00',
  '20:00:00',
  '20:30:00',
  '21:00:00',
  '21:30:00',
  '22:00:00',
  '22:30:00',
  '23:00:00',
  '23:30:00',
  '24:00:00'
];

class Scheduler extends Component {
  constructor(props) {
    super(props);
    this.state = {
      slotId: '',
      days: this.props.daysSlots,
      val: '',
      alert: false,
      display: 'none',
      message: '',
      nameAlert: ''
    };

    this.handleShowModal = this.handleShowModal.bind(this);
    this.showConfirmModal = this.showConfirmModal.bind(this);
  }

  hours() {
    return _.map(hoursDays, i => {
      return (
        <div className="scheduler-background-item" key={i}>
          <div className="scheduler-background-item-title">{i.slice(0, 5)}</div>
          <div className="scheduler-background-item-line" />
        </div>
      );
    });
  }

  numDay(k) {
    switch (k) {
      case 1:
        return <span className="scheduler-column-header-item-text">l</span>;
      case 2:
        return <span className="scheduler-column-header-item-text">m</span>;
      case 3:
        return <span className="scheduler-column-header-item-text">m</span>;
      case 4:
        return <span className="scheduler-column-header-item-text">j</span>;
      case 5:
        return <span className="scheduler-column-header-item-text">v</span>;
      case 6:
        return <span className="scheduler-column-header-item-text">s</span>;
      case 0:
        return <span className="scheduler-column-header-item-text">d</span>;
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.daysSlots) {
      this.setState({ days: nextProps.daysSlots });
    }

    if (nextProps.action === 0) {
      if (nextProps.slotsDeleted) {
        if (nextProps.slotsDeleted.type === 'success') {
          for (let i = 0; i < this.state.days.length; i++) {
            if (this.state.days[i].slots.length !== 0) {
              for (let j = 0; j < this.state.days[i].slots.length; j++) {
                if (this.state.days[i].slots[j] !== this.undefined) {
                  if (this.state.days[i].slots[j].id === this.state.slotId)
                    delete this.state.days[i].slots[j];
                }
              }
            }
          }
          this.setState({
            alert: true,
            days: this.state.days,
            message: nextProps.slotsDeleted.message,
            typeAlert: 'alerts',
            nameAlert: 'Succès'
          });
          setTimeout(
            function() {
              this.setState({
                alert: false
              });
            }.bind(this),
            3000
          );
        } else {
          this.setState({
            alert: true,
            message: nextProps.slotsDeleted.message,
            typeAlert: 'alerts alerts-warning',
            nameAlert: 'Attention'
          });
          setTimeout(
            function() {
              this.setState({
                alert: false
              });
            }.bind(this),
            3000
          );
        }
      }
    }
    if (nextProps.action === 1) {
      if (nextProps.slots) {
        if (nextProps.slots.code !== 404) {
          for (let i = 0; i < this.state.days.length; i++) {
            if (this.state.days[i].idDay === nextProps.slots.days_id) {
              if (this.state.days[i].slots.length !== 0) {
                for (let j = 0; j < this.state.days[i].slots.length; j++) {
                  if (this.state.days[i].slots !== undefined) {
                    if (nextProps.typeAction === 'edit') {
                      if (
                        this.state.days[i].slots[j] &&
                        parseInt(this.state.days[i].slots[j].id) ===
                          parseInt(nextProps.slots.id)
                      ) {
                        this.state.days[i].slots[j] = {
                          days_id: nextProps.slots.days_id,
                          endDate: nextProps.slots.endDate,
                          id: nextProps.slots.id,
                          name: nextProps.slots.name,
                          price: nextProps.slots.price,
                          startDate: nextProps.slots.startDate
                        };
                        this.setState({
                          alert: true,
                          message: 'Créneau modifié',
                          typeAlert: 'alerts',
                          nameAlert: 'Succès'
                        });
                        break;
                      }
                    } else {
                      this.state.days[i].slots.push({
                        days_id: nextProps.slots.days_id,
                        endDate: nextProps.slots.endDate,
                        id: nextProps.slots.id,
                        name: nextProps.slots.name,
                        price: nextProps.slots.price,
                        startDate: nextProps.slots.startDate
                      });
                      this.setState({
                        days: this.state.days,
                        alert: true,
                        message: 'Créneau ajouté',
                        typeAlert: 'alerts',
                        nameAlert: 'Succès'
                      });
                      break;
                    }
                  }
                }
              } else {
                this.state.days[i].slots.push({
                  days_id: nextProps.slots.days_id,
                  endDate: nextProps.slots.endDate,
                  id: nextProps.slots.id,
                  name: nextProps.slots.name,
                  price: nextProps.slots.price,
                  startDate: nextProps.slots.startDate
                });
                this.setState({
                  days: this.state.days,
                  alert: true,
                  message: 'Créneau ajouté',
                  typeAlert: 'alerts',
                  nameAlert: 'Succès'
                });
                break;
              }
            }
          }
          setTimeout(
            function() {
              this.setState({
                alert: false
              });
            }.bind(this),
            3000
          );
        } else {
          this.setState({
            alert: true,
            message: nextProps.slots.message,
            typeAlert: 'alerts alerts-warning',
            nameAlert: 'Attention'
          });
          setTimeout(
            function() {
              this.setState({
                alert: false
              });
            }.bind(this),
            3000
          );
        }
      }
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.daysSlots !== this.props.daysSlots) {
      this.setState({ days: this.props.daysSlots });
    }

    if (prevProps.calendarInfo !== this.props.calendarInfo) {
      switch (this.props.calendarInfo.label) {
        case 'added':
          this.setState({
            days: this.state.days,
            alert: true,
            message: 'Créneau ajouté',
            typeAlert: 'alerts',
            nameAlert: 'Succès'
          });
          break;
        case 'edit':
          this.setState({
            alert: true,
            message: 'Créneau modifié',
            typeAlert: 'alerts',
            nameAlert: 'Succès'
          });
          break;
        case 'delete':
          this.setState({
            alert: true,
            message: 'Créneau supprimé',
            typeAlert: 'alerts',
            nameAlert: 'Succès'
          });
          break;
        case 'supplement':
          this.showConfirmModal();
          break;
        case 'error':
          this.setState({
            alert: true,
            message: 'Une erreur est survenue',
            typeAlert: 'alerts alerts-warning',
            nameAlert: 'Attention'
          });
          break;
      }

      setTimeout(
        function() {
          this.setState({
            alert: false
          });
        }.bind(this),
        3000
      );
    }
  }

  showConfirmModal() {
    const { calendarInfo, showConfirmModal } = this.props;
    showConfirmModal(calendarInfo);
  }

  handleShowModal(e) {
    e.preventDefault();
    this.props.handleMethod(e);

    this.setState({
      slotId: parseInt(e.currentTarget.dataset.idslot),
      val: e.currentTarget.dataset.id
    });
  }

  render() {
    const daysSlots = this.state.days ? this.state.days : [];

    if (daysSlots[0].dayNumber === 0)
      daysSlots.splice(6, 0, daysSlots.splice(0, 1)[0]);

    const retourender = () => {
      if (this.props.daysSlots) {
        return (
          <div>
            {this.state.alert ? (
              <Alert
                nameAlert={this.state.nameAlert}
                type={this.state.typeAlert}
                style={false}
                message={this.state.message}
              />
            ) : null}

            <div className="scheduler">
              <div className="scheduler-grid">
                <div className="scheduler-background">{this.hours()}</div>
                <div className="scheduler-planning">
                  {daysSlots.map((day, idx) => (
                    <Day
                      key={idx}
                      hours={hoursDays1}
                      day={day}
                      method={this.handleShowModal}
                      numDay={this.numDay}
                    />
                  ))}
                </div>
              </div>
            </div>
          </div>
        );
      } else return <div>pas de semaine</div>;
    };
    return retourender();
  }
}

function mapStateToProps(state) {
  return {
    slots: state.slots.slotsAdd,
    typeAction: state.slots.typeAction,
    slotsDeleted: state.slots.slotsDeleted,
    action: state.slots.action,
    calendarInfo: state.parkings.calendarInfo
  };
}

export default connect(
  mapStateToProps,
  {
    fetchAddSlots,
    fetchDeleteSlots,
    showModal
  }
)(Scheduler);
