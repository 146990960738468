import React from 'react';
import moment from 'moment';

const hoursDays = [
  '00:00:00',
  '00:30:00',
  '01:00:00',
  '01:30:00',
  '02:00:00',
  '02:30:00',
  '03:00:00',
  '03:30:00',
  '04:00:00',
  '04:30:00',
  '05:00:00',
  '05:30:00',
  '06:00:00',
  '06:30:00',
  '07:00:00',
  '07:30:00',
  '08:00:00',
  '08:30:00',
  '09:00:00',
  '09:30:00',
  '10:00:00',
  '10:30:00',
  '11:00:00',
  '11:30:00',
  '12:00:00',
  '12:30:00',
  '13:00:00',
  '13:30:00',
  '14:00:00',
  '14:30:00',
  '15:00:00',
  '15:30:00',
  '16:00:00',
  '16:30:00',
  '17:00:00',
  '17:30:00',
  '18:00:00',
  '18:30:00',
  '19:00:00',
  '19:30:00',
  '20:00:00',
  '20:30:00',
  '21:00:00',
  '21:30:00',
  '22:00:00',
  '22:30:00',
  '23:00:00',
  '23:30:00',
  '24:00:00'
];
const Element = ({
  slotId,
  dayId,
  dayNumber,
  startDate,
  endDate,
  price,
  method,
  idx
}) => {
  let datetimeStart = new Date('1970-01-01T' + startDate + 'Z');
  let datetimeEnd = new Date('1970-01-01T' + endDate + 'Z');
  let hours = Math.abs(datetimeEnd - datetimeStart) / 3600000;

  return (
    <div
      key={slotId}
      data-id={dayId}
      data-daynumber={dayNumber}
      data-enddate={endDate}
      data-idslot={slotId}
      data-startdate={startDate}
      data-price={price}
      onClick={method}
      className={
        price >= 3
          ? 'scheduler-time-slot scheduler-time-slot-filled expensive'
          : 'scheduler-time-slot scheduler-time-slot-filled'
      }
      style={{ height: hours * 22 - 2 + 'px' }}
    >
      {moment(endDate, 'HH:mm').diff(moment(startDate, 'HH:mm'), 'minutes') > 50
        ? parseInt(startDate) in hoursDays
          ? new Intl.NumberFormat(navigator.language).format(price) + ' € / h.'
          : ''
        : null}
    </div>
  );
};
export default Element;
