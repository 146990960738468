import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import * as ROUTES from '../../constants/routes';
import IllustrationCity from './../Design/illustration-city';
import { connect } from 'react-redux';
import {
  fetchRegister,
  fetchVerifyEmail,
  fetchInitialState
} from './../../actions/signupAction';
import {
  setInputBehaviour,
  handleSwitchInput,
  DocumentTitle
} from '../../utils';
import Alert from './../Layouts/Alert/alert';
import Address from './../Layouts/Forms/address';
import { FormattedMessage } from 'react-intl';

//RFC 2822
const emailTest = RegExp(
  /^(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])$/
);
const date_regex = /^(0[1-9]|1[0-2])\/(0[1-9]|1\d|2\d|3[01])\/(19|20)\d{2}$/;
const birthdate_regex = /^[0-9][0-9][0-9][0-9]\-[0-9][0-9]\-[0-9][0-9]$/;

function isValidBirthdate(value) {
  const regex = new RegExp(birthdate_regex);
  return regex.test(value);
}

class Signup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      userFailed: false,
      information_entered: false,
      disabledInput: false,
      proof_later: false,
      email: '',
      tva: '',
      fiscal: '',
      password: '',
      passwordConfirmation: '',
      firstName: '',
      lastName: '',
      zipCode: '',
      country: '',
      address: '',
      birthdate: '',
      longitude: '',
      isCompany: false,
      lat: '',
      alert: false,
      display: 'none',
      message: '',
      nameAlert: '',
      style: 'none',
      city: '',
      phoneNumber: '',
      fileNameUser: '',
      fileUser: null,
      addressCompl: '',
      companyName: '',
      formErros: {
        tvaAndFiscal: '',
        email: '',
        information_entered: '',
        password: '',
        passwordConfirmation: '',
        firstName: '',
        lastName: '',
        zipCode: '',
        country: '',
        address: '',
        birthdate: '',
        city: '',
        phoneNumber: '',
        addressCompl: '',
        longitude: '',
        lat: ''
      }
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.keyUpHandler = this.keyUpHandler.bind(this);
    this.onFileChange = this.onFileChange.bind(this);
  }

  keyUpHandler(event) {
    event.preventDefault();
    this.props.fetchVerifyEmail(this.state.email);
  }

  onFileChange(event) {
    if (event.target.files && event.target.files[0]) {
      this.setState({
        fileUser: event.target.files[0],
        proof_later: false,
        disabledInput: false,
        fileNameUser: event.target.files[0].name
      });
    }
  }

  handleSubmit(event) {
    event.preventDefault();
    if (
      this.state.tva.length <= 0 &&
      this.state.fiscal.length <= 0 &&
      this.state.isCompany
    ) {
      this.setState({
        formErros: {
          ...this.state.formErros,
          tvaAndFiscal:
            'Veuillez entrer un numéro de TVA et/ou un numéro fiscal'
        }
      });
      return false;
    }

    let tva = this.state.isCompany ? this.state.tva : null;
    let fiscal = this.state.isCompany ? this.state.fiscal : null;
    let companyName = this.state.isCompany ? this.state.companyName : null;

    this.setState({
      isLoading: true,
      formErros: { ...this.state.formErros, tvaAndFiscal: '' }
    });
    let addressProof = this.state.proof_later ? null : this.state.fileUser;

    let register = {
      userFB: {
        email: this.state.email,
        password: this.state.password,
        passwordConfirmation: this.state.password
      },
      file: addressProof,
      userRegister: {
        TVANumber: tva,
        tva: tva,
        fiscal: fiscal,
        email: this.state.email,
        firstName: this.state.firstName,
        isCompany: this.state.isCompany,
        lastName: this.state.lastName,
        birthdate: this.state.birthdate,
        phoneNumber: this.state.phoneNumber,
        userType: 'owner',
        language: 'FR',
        companyName: companyName,
        address: {
          city: this.state.city,
          country: this.state.country,
          //department: this.state.addressCompl,
          addressCompl: this.state.addressCompl,
          street: this.state.address,
          zipCode: this.state.zipCode
        }
      }
    };
    this.props.fetchRegister(register);
    this.props.fetchInitialState();
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.add === true) {
      this.props.history.push(ROUTES.LOGIN);
    }
    if (nextProps.add === false) {
      window.scrollTo(500, 0);
      this.setState({
        alert: true,
        message: 'Email existe',
        typeAlert: 'alerts alerts-warning',
        isLoading: false
      });
    }
    setTimeout(
      function() {
        this.setState({
          alert: false,
          isLoading: false
        });
      }.bind(this),
      5000
    );
  }

  handleChange(event) {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    if (target.checked) {
      this.setState({ checked: true });
    }
    if (!target.checked && target.type === 'checkbox') {
      this.setState({ checked: false });
    }
    const name = target.name;
    let formErrors = this.state.formErros;
    switch (name) {
      case 'tva':
        formErrors.tva = value ? '' : 'num de tva oblige';
        break;
      case 'fiscal':
        formErrors.fiscal = value ? '' : 'num de fiscal oblige';
        break;
      case 'email':
        formErrors.email = emailTest.test(value) ? '' : 'Email Non Valide';
        break;
      case 'password':
        formErrors.password =
          value.length < 6 && value.length > 0 ? 'Mot de passe invalide' : '';
        break;
      case 'passwordConfirmation':
        formErrors.passwordConfirmation =
          value !== this.state.password
            ? 'Confirmation de mot de passe non correcte'
            : '';
        break;
      case 'firstName':
        formErrors.firstName =
          value.length < -1 && value.length > 0
            ? 'Minimum 3 caractères sont requis'
            : '';
        break;
      case 'lastName':
        formErrors.lastName =
          value.length < -1 && value.length > 0
            ? 'Minimum 3 caractères sont requis'
            : '';
        break;

      case 'zipCode':
        formErrors.zipCode =
          isNaN(value) || value <= 0
            ? 'Code postal doit être supérieur a 0'
            : '';
        break;
      case 'country':
        formErrors.country =
          value.length < -1 && value.length > 0
            ? 'Minimum 3 caractères sont requis'
            : '';
        break;
      case 'city':
        formErrors.city =
          value.length < -1 && value.length > 0
            ? 'Minimum 3 caractères sont requis'
            : '';
        break;
      case 'address':
        formErrors.address =
          value.length < -1 && value.length > 0
            ? 'Minimum 3 caractères sont requis'
            : '';
        break;
      case 'phoneNumber':
        formErrors.phoneNumber =
          value <= -1 ? 'Numéro de téléphone est requis' : '';
        formErrors.phoneNumber = isNaN(value)
          ? 'Numéro de téléphone invalide'
          : '';
        break;
      case 'birthdate':
        formErrors.birthdate = !isValidBirthdate(value)
          ? 'Date de Naissance est requise (YYYY-MM-DD)'
          : '';
        break;
      case 'addressCompl':
        formErrors.addressCompl =
          value.length < -1 && value.length > 0
            ? 'Minimum 6 caractères sont requis'
            : '';
        break;
      default:
        break;
    }
    this.setState({
      formErrors,
      [name]: value
    });
  }

  componentDidMount() {
    setInputBehaviour();
    handleSwitchInput();
  }

  render() {
    const formErrors = this.state.formErros;
    ///Validator First Name
    let classFirstName = 'form-group';
    let classFirstName1 = 'form-input';
    let lengthFirstName = 0;
    if (this.state.firstName) {
      classFirstName1 = 'form-input on-focus';
    }
    if (formErrors) {
      if (formErrors.firstName.length > 0) {
        lengthFirstName = formErrors.firstName.length;
        classFirstName = 'form-group on-focus has-error has-feedback';
        classFirstName1 = 'form-input on-focus has-error has-feedback';
      }
    }
    ///Validator Last Name
    let classLastName = 'form-group';
    let classLastName1 = 'form-input';
    let lengthLastName = 0;
    if (this.state.lastName) {
      classLastName1 = 'form-input on-focus';
    }
    if (formErrors) {
      if (formErrors.lastName.length > 0) {
        lengthLastName = formErrors.lastName.length;
        classLastName = 'form-group on-focus has-error has-feedback';
        classLastName1 = 'form-input on-focus has-error has-feedback';
      }
    }
    ///Validator PhoneNumber
    let classPhoneNumber = 'form-group';
    let classPhoneNumber1 = 'form-input';
    let lengthPhoneNumber = 0;
    if (this.state.phoneNumber) {
      classPhoneNumber1 = 'form-input on-focus';
    }
    if (formErrors) {
      if (formErrors.phoneNumber.length > 0) {
        lengthPhoneNumber = formErrors.phoneNumber.length;
        classPhoneNumber = 'form-group on-focus has-error has-feedback';
        classPhoneNumber1 = 'form-input on-focus has-error has-feedback';
      }
    }
    ///Validator birthdate
    let classBirthDate = 'form-group';
    let classBirthDate1 = 'form-input on-focus';
    let lengthBirthDate = 0;
    if (this.state.birthdate) {
      classBirthDate1 = 'form-input on-focus';
    }
    if (formErrors) {
      if (formErrors.birthdate || formErrors.birthdate.length > 0) {
        lengthBirthDate = formErrors.birthdate.length;
        classBirthDate = 'form-group on-focus has-error has-feedback';
        classBirthDate1 = 'form-input on-focus has-error has-feedback';
      }
    }
    ///Validator Email
    let classEmail = 'form-group';
    let classEmail1 = 'form-input';
    let lengthEmail = 0;
    if (this.state.email) {
      classEmail1 = 'form-input on-focus';
    }
    if (formErrors) {
      if (formErrors.email.length > 0) {
        lengthEmail = formErrors.email.length;
        classEmail = 'form-group on-focus has-error has-feedback';
        classEmail1 = 'form-input on-focus has-error has-feedback';
      }
    }

    ///Validator Password
    let classPassword = 'form-group';
    let classPassword1 = 'form-input';
    let lengthPassword = 0;
    if (this.state.password) {
      classPassword1 = 'form-input on-focus';
    }
    if (formErrors) {
      if (formErrors.password.length > 0) {
        lengthPassword = formErrors.password.length;
        classPassword = 'form-group on-focus has-error has-feedback';
        classPassword1 = 'form-input on-focus has-error has-feedback';
      }
    }
    ///Validator Password
    let classConfimPassword = 'form-group';
    let classConfimPassword1 = 'form-input';
    let lengthConfimPassword = 0;
    if (this.state.passwordConfirmation) {
      classConfimPassword1 = 'form-input on-focus';
    }
    if (formErrors) {
      if (formErrors.passwordConfirmation.length > 0) {
        lengthConfimPassword = formErrors.passwordConfirmation.length;
        classConfimPassword = 'form-group on-focus has-error has-feedback';
        classConfimPassword1 = 'form-input on-focus has-error has-feedback';
      }
    }
    const enabled =
      formErrors.firstName.length > 0 ||
      this.state.firstName === '' ||
      this.state.information_entered === false ||
      (this.state.proof_later === false && this.state.fileUser === null) ||
      formErrors.email.length > 0 ||
      this.state.email === '' ||
      formErrors.birthdate.length > 0 ||
      this.state.birthdate === '' ||
      formErrors.passwordConfirmation.length > 0 ||
      this.state.passwordConfirmation === '' ||
      this.state.passwordConfirmation !== this.state.password ||
      formErrors.password.length > 0 ||
      this.state.password === '' ||
      formErrors.lastName.length > 0 ||
      this.state.lastName === '' ||
      formErrors.phoneNumber.length > 0 ||
      this.state.phoneNumber === '' ||
      formErrors.address.length > 0 ||
      this.state.address === '' ||
      formErrors.country.length > 0 ||
      this.state.country === '' ||
      formErrors.city.length > 0 ||
      this.state.city === '' ||
      formErrors.zipCode.length > 0 ||
      this.state.zipCode === '' ||
      (this.state.isCompany && this.state.companyName === '') ||
      (this.state.isCompany &&
        this.state.tva === '' &&
        this.state.fiscal === '');

    const isLoading = this.state.isLoading;

    return (
      <div className="main-container subscription">
        {DocumentTitle(
          'app.title-create-account',
          'WATTPARK - Créer un compte'
        )}
        <IllustrationCity />
        <header className="header header-login">
          <div className="header-left">
            <Link to={ROUTES.LOGIN}>
              <span className="link">
                <i className="icon-arrow-left" />
                <span className="link-text">
                  <FormattedMessage id="app.return" defaultMessage="Retour" />
                </span>
              </span>
            </Link>
          </div>
          <div className="header-center">
            <h1 className="logo-main">
              <Link to={ROUTES.LANDING}>
                <img src="/img/logo.svg" alt="Logo Wattpark" />
              </Link>
            </h1>
          </div>
          <div className="header-right">
            <p className="text-info">
              <FormattedMessage
                id="app.already-an-account"
                defaultMessage="Déjà un compte ?"
              />
            </p>
            <Link to={ROUTES.LOGIN}>
              <span className="btn btn-primary">
                <FormattedMessage
                  id="app.identify"
                  defaultMessage="s'identifier"
                />
              </span>
            </Link>
          </div>
        </header>

        <div className="container">
          <div className="row">
            <div className="col-xs-12 col-sm-4">
              <div className="form-block-intro">
                <h2 className="title-main">
                  <FormattedMessage
                    id="app.title-main"
                    defaultMessage="Création de votre compte WATTPARK Pro"
                  />
                </h2>
                <p className="text-main">
                  <FormattedMessage
                    id="app.text-main"
                    defaultMessage="Création de votre compte WATTPARK Pro"
                  />
                </p>
              </div>
            </div>
            <div className="col-xs-12 col-sm-8">
              {this.state.alert ? (
                <Alert
                  nameAlert={this.state.nameAlert}
                  type={this.state.typeAlert}
                  style={false}
                  message={this.state.message}
                />
              ) : null}
              <div className="form-block-wrapper">
                <form onSubmit={this.handleSubmit} noValidate>
                  <div className="form-block">
                    <div className="row">
                      <div className="col-xs-12">
                        <div className="switch">
                          <input
                            className="switch-input"
                            name="particulier"
                            type="radio"
                            value={this.state.isCompany}
                            id="radio1"
                            onClick={() =>
                              this.setState({
                                isCompany: !this.state.isCompany
                              })
                            }
                          />
                          <label className="switch-text" htmlFor="radio1">
                            <FormattedMessage
                              id="app.particular"
                              defaultMessage="Particulier"
                            />
                          </label>
                          <input
                            className="switch-input"
                            name="pro"
                            type="radio"
                            value={this.state.isCompany}
                            id="radio2"
                            onClick={() =>
                              this.setState({
                                isCompany: !this.state.isCompany
                              })
                            }
                          />
                          <label className="switch-text" htmlFor="radio2">
                            Pro
                          </label>
                          <span
                            className="switch-moving-bg"
                            aria-hidden="true"
                          />
                        </div>
                      </div>
                      <div className="col-xs-12 col-md-6">
                        <div className={classFirstName}>
                          <div className={classFirstName1}>
                            <input
                              required
                              className="form-input-field"
                              type="text"
                              name="firstName"
                              value={this.state.firstName}
                              onChange={this.handleChange}
                              id="inputName"
                            />
                            <label
                              className="form-input-label"
                              htmlFor="inputName"
                            >
                              <span className="form-input-label-content">
                                *{' '}
                                <FormattedMessage
                                  id="app.user-first-name"
                                  defaultMessage="Nom"
                                />
                              </span>
                            </label>
                          </div>
                          {lengthFirstName > 0 && (
                            <span className="help-block">
                              {formErrors.firstName}
                            </span>
                          )}
                        </div>
                      </div>
                      <div className="col-xs-12 col-md-6">
                        <div className={classLastName}>
                          <div className={classLastName1}>
                            <input
                              required
                              className="form-input-field"
                              type="text"
                              id="inputSurname"
                              name="lastName"
                              value={this.state.lastName}
                              onChange={this.handleChange}
                            />

                            <label
                              className="form-input-label"
                              htmlFor="inputSurname"
                            >
                              <span className="form-input-label-content">
                                *{' '}
                                <FormattedMessage
                                  id="app.user-last-name"
                                  defaultMessage="Prénom"
                                />
                              </span>
                            </label>
                          </div>
                          {lengthLastName > 0 && (
                            <span className="help-block">
                              {formErrors.lastName}
                            </span>
                          )}
                        </div>
                      </div>
                      <div className="col-xs-6">
                        <div className={classPhoneNumber}>
                          <div className={classPhoneNumber1}>
                            <input
                              required
                              className="form-input-field"
                              type="tel"
                              name="phoneNumber"
                              id="inputTel"
                              value={this.state.phoneNumber}
                              onChange={this.handleChange}
                            />
                            <label
                              className="form-input-label"
                              htmlFor="inputTel"
                            >
                              <span className="form-input-label-content">
                                *{' '}
                                <FormattedMessage
                                  id="app.user-phone"
                                  defaultMessage="Téléphone"
                                />
                              </span>
                            </label>
                          </div>
                          {lengthPhoneNumber > 0 && (
                            <span className="help-block">
                              {formErrors.phoneNumber}
                            </span>
                          )}
                        </div>
                      </div>
                      <div className="col-xs-12 col-md-6">
                        <div className={classBirthDate}>
                          <div className={classBirthDate1}>
                            <input
                              required
                              className="form-input-field"
                              type="date"
                              name="birthdate"
                              id="birthdate"
                              value={this.state.birthdate}
                              onChange={this.handleChange}
                            />
                            <label
                              className="form-input-label"
                              htmlFor="birthdate"
                            >
                              <span className="form-input-label-content">
                                *{' '}
                                <FormattedMessage
                                  id="app.birthdate"
                                  defaultMessage="date de naissance"
                                />
                              </span>
                            </label>
                          </div>
                          {lengthBirthDate > 0 && (
                            <span className="help-block">
                              {formErrors.birthdate}
                            </span>
                          )}
                        </div>
                      </div>
                      <Address
                        error={formErrors}
                        valueinputAddress={this.state.address}
                        valueinputAddressCompl={this.state.addressCompl}
                        valueinputZIP={this.state.zipCode}
                        valueinputCity={this.state.city}
                        valueinputLat={this.state.lat}
                        valueinputLong={this.state.longitude}
                        valueinputCountry={this.state.country}
                        method={this.handleChange}
                      />
                      {this.state.isCompany ? (
                        <div className="row">
                          <div className="col-xs-12 col-md-6">
                            <div
                              className={`form-group ${
                                this.state.companyName.length > 0
                                  ? ' on-focus'
                                  : ''
                              }  ${
                                this.state.companyName.length <= 0
                                  ? ' has-error has-feedback'
                                  : ''
                              }`}
                            >
                              <div
                                className={`form-input ${
                                  this.state.companyName.length > 0
                                    ? ' on-focus'
                                    : ''
                                }  ${
                                  this.state.companyName.length <= 0
                                    ? ' has-error has-feedback'
                                    : ''
                                }`}
                              >
                                <input
                                  className="form-input-field"
                                  type="text"
                                  name="companyName"
                                  id="companyName"
                                  value={this.state.companyName}
                                  onChange={e => {
                                    this.setState({
                                      companyName: e.target.value,
                                      formErros: {
                                        ...this.state.formErros,
                                        companyName: ''
                                      }
                                    });
                                  }}
                                />
                                <label
                                  className="form-input-label"
                                  htmlFor="companyName"
                                >
                                  <span className="form-input-label-content">
                                    *{' '}
                                    <FormattedMessage
                                      id="app.company-name"
                                      defaultMessage="Raison sociale"
                                    />
                                  </span>
                                </label>
                              </div>
                            </div>
                          </div>
                          <div className="col-xs-12 col-md-6">
                            <div
                              className={`form-group ${
                                this.state.tva.length > 0 ? ' on-focus' : ''
                              }  ${
                                this.state.tva.length <= 0 &&
                                this.state.fiscal.length <= 0
                                  ? ' has-error has-feedback'
                                  : ''
                              }`}
                            >
                              <div
                                className={`form-input ${
                                  this.state.tva.length > 0 ? ' on-focus' : ''
                                }  ${
                                  this.state.tva.length <= 0 &&
                                  this.state.fiscal.length <= 0
                                    ? ' has-error has-feedback'
                                    : ''
                                }`}
                              >
                                <input
                                  className="form-input-field"
                                  type="text"
                                  name="tvaNumber"
                                  id="tva"
                                  value={this.state.tva}
                                  onChange={e => {
                                    this.setState({
                                      tva: e.target.value,
                                      formErros: {
                                        ...this.state.formErros,
                                        tvaAndFiscal: ''
                                      }
                                    });
                                  }}
                                />
                                <label
                                  className="form-input-label"
                                  htmlFor="tva"
                                >
                                  <span className="form-input-label-content">
                                    *{' '}
                                    <FormattedMessage
                                      id="app.tva-number"
                                      defaultMessage="TVA intracommunautaire"
                                    />
                                  </span>
                                </label>
                              </div>
                            </div>
                          </div>

                          <div className="col-xs-12 col-md-6">
                            <div
                              className={`form-group ${
                                this.state.fiscal.length > 0 ? ' on-focus' : ''
                              }  ${
                                this.state.formErros.tvaAndFiscal.length > 0
                                  ? ' has-error has-feedback'
                                  : ''
                              }`}
                            >
                              <div
                                className={`form-input ${
                                  this.state.fiscal.length > 0
                                    ? ' on-focus'
                                    : ''
                                }  ${
                                  this.state.formErros.tvaAndFiscal.length > 0
                                    ? ' has-error has-feedback'
                                    : ''
                                }`}
                              >
                                <input
                                  className="form-input-field"
                                  type="text"
                                  name="fiscalNumber"
                                  id="fiscal"
                                  value={this.state.fiscal}
                                  onChange={e => {
                                    this.setState({
                                      fiscal: e.target.value,
                                      formErros: {
                                        ...this.state.formErros,
                                        tvaAndFiscal: ''
                                      }
                                    });
                                  }}
                                />
                                <label
                                  className="form-input-label"
                                  htmlFor="fiscal"
                                >
                                  <span className="form-input-label-content">
                                    *{' '}
                                    <FormattedMessage
                                      id="app.fiscal-number"
                                      defaultMessage="Fiscal intracommunautaire"
                                    />
                                  </span>
                                </label>
                              </div>
                            </div>
                          </div>

                          <div className="col-xs-12 col-md-6">
                            <span
                              className="help-block"
                              style={{ color: '#a94442' }}
                            >
                              {formErrors.tvaAndFiscal}
                            </span>
                          </div>
                        </div>
                      ) : null}

                      <div className="col-xs-12 col-md-6">
                        <div className={classEmail}>
                          <div className={classEmail1}>
                            <input
                              required
                              className="form-input-field"
                              type="email"
                              name="email"
                              id="inputEmail"
                              value={this.state.email}
                              onChange={this.handleChange}
                              onKeyUp={this.keyUpHandler}
                            />
                            <label
                              className="form-input-label"
                              htmlFor="inputEmail"
                            >
                              <span className="form-input-label-content">
                                * Email
                              </span>
                            </label>
                          </div>
                          {lengthEmail > 0 && (
                            <span className="help-block">
                              {formErrors.email}
                            </span>
                          )}
                        </div>
                      </div>
                      <div className="col-xs-12 col-md-6">
                        <div className={classPassword}>
                          <div className={classPassword1}>
                            <input
                              required
                              className="form-input-field"
                              type="password"
                              name="password"
                              id="inputPswd"
                              value={this.state.password}
                              onChange={this.handleChange}
                            />
                            <label
                              className="form-input-label"
                              htmlFor="inputPswd"
                            >
                              <span className="form-input-label-content">
                                *{' '}
                                <FormattedMessage
                                  id="app.password"
                                  defaultMessage=" Mot de passe"
                                />
                              </span>
                            </label>
                          </div>
                          {lengthPassword > 0 && (
                            <span className="help-block">
                              {formErrors.password}
                            </span>
                          )}
                        </div>
                      </div>

                      <div className="col-xs-12 col-md-6">
                        <div className={classConfimPassword}>
                          <div className={classConfimPassword1}>
                            <input
                              required
                              className="form-input-field"
                              type="password"
                              name="passwordConfirmation"
                              id="inputpasswordConfirmation"
                              value={this.state.passwordConfirmation}
                              onChange={this.handleChange}
                            />
                            <label
                              className="form-input-label"
                              htmlFor="inputpasswordConfirmation"
                            >
                              <span className="form-input-label-content">
                                *{' '}
                                <FormattedMessage
                                  id="app.password-confirmation"
                                  defaultMessage="confirmation mot de passe"
                                />
                              </span>
                            </label>
                          </div>
                          {lengthConfimPassword > 0 && (
                            <span className="help-block">
                              {formErrors.passwordConfirmation}
                            </span>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="form-block">
                    <h3 className="form-block-title">
                      <FormattedMessage
                        id="app.your-proof-of-identity"
                        defaultMessage="votre justificatif d’identité"
                      />
                    </h3>
                    <div className="upload-block inputWrapper">
                      <input
                        onChange={this.onFileChange}
                        type="file"
                        id="file"
                        className="fileInput"
                      />
                      <label className="upload-block-title" htmlFor="file">
                        {this.state.fileNameUser
                          ? this.state.fileNameUser
                          : "Importer mon justificatif d'identité"}
                      </label>
                    </div>
                    <p className="text-legend">
                      *{' '}
                      <FormattedMessage
                        id="app.user-text-legend"
                        defaultMessage="Les documents suivants sont acceptés : carte d’identité
                                            française, titre de séjour, passeport, permis de conduire
                                            européen."
                      />
                    </p>
                    <label className="checkbox checkbox-right">
                      <span className="checkbox-text">
                        <FormattedMessage
                          id="app.proof-later"
                          defaultMessage=" Je transmettrai le justificatif plus tard"
                        />
                      </span>
                      <input
                        disabled={this.state.disabledInput}
                        type="checkbox"
                        value={this.state.proof_later}
                        onChange={this.handleChange}
                        name="proof_later"
                      />
                      <span className="checkmark">
                        <i className="icon-check" />
                      </span>
                    </label>
                  </div>
                  <div className="form-validation">
                    <label className="checkbox">
                      <input
                        type="checkbox"
                        value={this.state.information_entered}
                        onChange={this.handleChange}
                        name="information_entered"
                      />
                      <span className="checkmark">
                        <i className="icon-check" />
                      </span>
                      <span className="checkbox-text">
                        <FormattedMessage
                          id="app.information-entered"
                          defaultMessage="Je certifie sur l’honneur que les informations saisies
                                            sont exactes"
                        />
                      </span>
                    </label>
                    <div className="form-validation-action">
                      <button
                        disabled={enabled || isLoading}
                        type="submit"
                        className={
                          enabled || isLoading
                            ? 'btn btn-primary  btn-fixed btn-block btn-disable'
                            : 'btn btn-primary  btn-fixed btn-block'
                        }
                      >
                        {isLoading ? (
                          'Loading…'
                        ) : (
                          <FormattedMessage
                            id="app.btnCreateParks"
                            defaultMessage="Valider"
                          />
                        )}
                      </button>
                    </div>
                  </div>
                  <p className="text-legend">
                    *{' '}
                    <FormattedMessage
                      id="app.field-mandatory"
                      defaultMessage="Champ obligatoire"
                    />
                  </p>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    add: state.register.add,
    verify: state.register.verify
  };
}

export default connect(
  mapStateToProps,
  {
    fetchRegister,
    fetchInitialState,
    fetchVerifyEmail
  }
)(Signup);
