import React, { Component } from 'react';
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl';

class Balance extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const pourcent = () => {
      if (this.props.pourcent) {
        if (this.props.pourcent > 0) {
          return (
            <div className="evolution positive">
              <i className="icon-evol-pos" /> + {this.props.pourcent} %
              <span className="evolution-legend">
                &nbsp;
                <FormattedMessage
                  id="app.compared-to-last-month"
                  defaultMessage="par rapport au mois dernier"
                />
              </span>
            </div>
          );
        } else if (this.props.pourcent < 0) {
          return (
            <div className="evolution negative">
              <i className="icon-evol-neg" /> - {this.props.pourcent * -1} %
              <span className="evolution-legend">
                &nbsp;
                <FormattedMessage
                  id="app.compared-to-last-month"
                  defaultMessage="par rapport au mois dernier"
                />
              </span>
            </div>
          );
        }
      }
      return (
        <div className="evolution">
          <i className="icon-evol-neutral" />0 %
          <span className="evolution-legend">
            &nbsp;
            <FormattedMessage
              id="app.compared-to-last-month"
              defaultMessage="par rapport au mois dernier"
            />
          </span>
        </div>
      );
    };
    return (
      <div className="dashboard-card dashboard-part dashboard-visible">
        <div className="dashboard-card-title">
          <FormattedMessage
            id="app.my-wattpark-balance"
            defaultMessage="Cumul Wattpark"
          />
        </div>
        <div className="dashboard-card-subtitle">
          <FormattedMessage
            id="app.current-kitty-of-yourWATTPARK-account"
            defaultMessage=" Cagnotte actuelle de votre compte WATTPARK"
          />
        </div>
        <div className="amount">
          {this.props.solde
            ? parseFloat(this.props.solde)
                .toFixed(2)
                .replace('.', ',')
            : 0}
          <span className="amount-currency"> €</span>
        </div>
        {pourcent()}
      </div>
    );
  }
}

export default Balance;
