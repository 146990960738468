import React, { useEffect, useState } from 'react';
import UserItem from '../item';
import Pagination from '../../../../Layouts/Pagination/pagination';
import EditUser from '../edit-user';

function MembersList({
  email,
  startDate,
  endDate,
  members,
  actualPage,
  className,
  onAdd,
  onUpdate,
  onDelete,
  onEdit,
  edit,
  handleChange,
  add,
  onRelaunch,
  disabled
}) {
  const [currentPage, setPage] = useState(actualPage);
  const [pagination, setPagination] = useState([]);
  const [value, setValue] = useState({ email, startDate, endDate });

  /* TODO pagination via API */

  useEffect(() => {
    setPagination(members.length !== 0 ? members[actualPage - 1] : []);
  }, [members]);

  useEffect(() => {
    setValue({ email, startDate, endDate });
  }, [email, startDate, endDate]);

  const changePage = page => {
    setPage(page);
    setPagination(members[page - 1]);
  };

  const onInputChange = e => {
    handleChange(e);
    setValue(e.target.value);
  };

  return (
    <>
      <EditUser
        handleChange={e => onInputChange(e)}
        value={value}
        edit={edit}
        add={add}
        handleClick={edit ? onUpdate : onAdd}
        disabled={disabled}
        nbUser={members.length}
      />
      {pagination.length !== 0 && (
        <div>
          {pagination.map((data, index) => (
            <UserItem
              className="listing"
              member={data}
              key={index}
              listType={data.listType}
              onEdit={onEdit}
              onDelete={onDelete}
              onRelaunch={onRelaunch}
            />
          ))}
          <Pagination
            pages={members.length}
            handlePage={page => changePage(page)}
            actualPage={currentPage}
          />
        </div>
      )}
    </>
  );
}

export default MembersList;
