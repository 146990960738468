import { FormattedMessage } from 'react-intl';
import React from 'react';

const ListHeader = ({ goBack, title }) => {
  return (
    <header className="header">
      <div className="header-left">
        <a onClick={goBack} className="link">
          <i className="icon-arrow-left" />
          <span className="link-text">
            <FormattedMessage id="app.return" defaultMessage="Retour" />
          </span>
        </a>
      </div>
      <div className="header-center">
        <h2 className="header-title">{title}</h2>
      </div>
    </header>
  );
};

export default ListHeader;
