import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import Address from './../../Layouts/Forms/address';
import TextField from '../../Inputs/TextField';

class Subscription extends Component {
  render() {
    const formErrors = this.props.error;
    ///Validator First Name
    let classFirstName = 'form-group';
    let classFirstName1 = 'form-input';
    let lengthFirstName = 0;
    if (this.props.firstName) {
      classFirstName1 = 'form-input on-focus';
    }
    if (formErrors) {
      if (formErrors.firstName.length > 0) {
        lengthFirstName = formErrors.firstName.length;
        classFirstName = 'form-group on-focus has-error has-feedback';
        classFirstName1 = 'form-input on-focus has-error has-feedback';
      }
    }
    ///Validator Last Name
    let classLastName = 'form-group';
    let classLastName1 = 'form-input';
    let lengthLastName = 0;
    if (this.props.lastName) {
      classLastName1 = 'form-input on-focus';
    }
    if (formErrors) {
      if (formErrors.lastName.length > 0) {
        lengthLastName = formErrors.lastName.length;
        classLastName = 'form-group on-focus has-error has-feedback';
        classLastName1 = 'form-input on-focus has-error has-feedback';
      }
    }
    ///Validator PhoneNumber
    let classPhoneNumber = 'form-group';
    let classPhoneNumber1 = 'form-input';
    let lengthPhoneNumber = 0;
    if (this.props.phoneNumber) {
      classPhoneNumber1 = 'form-input on-focus';
    }
    if (formErrors) {
      if (formErrors.phoneNumber.length > 0) {
        lengthPhoneNumber = formErrors.phoneNumber.length;
        classPhoneNumber = 'form-group on-focus has-error has-feedback';
        classPhoneNumber1 = 'form-input on-focus has-error has-feedback';
      }
    }
    ///Validator birthdate
    let classBirthDate = 'form-group';
    let classBirthDate1 = 'form-input on-focus';
    let lengthBirthDate = 0;
    if (this.props.birthdate) {
      classBirthDate1 = 'form-input on-focus';
    }
    if (formErrors) {
      if (formErrors.birthdate.length > 0) {
        lengthBirthDate = formErrors.birthdate.length;
        classBirthDate = 'form-group on-focus has-error has-feedback';
        classBirthDate1 = 'form-input on-focus has-error has-feedback';
      }
    }
    let date = new Date(this.props.birthdate);
    if (date instanceof Date && !isNaN(date.valueOf()))
      date = new Date(this.props.birthdate).toISOString().slice(0, 10);

    return (
      <div className="form-block">
        <h3 className="form-block-title">
          <FormattedMessage
            id="app.user-your-informations"
            defaultMessage="Vos informations"
          />
        </h3>
        <div className="row">
          <div className="col-xs-12 col-md-6">
            <div className={classLastName1}>
              <input
                className={
                  this.props.lastName
                    ? 'form-input-field on-focus'
                    : 'form-input-field'
                }
                type="text"
                id="inputName"
                name="lastName"
                value={this.props.lastName ? this.props.lastName : ''}
                onChange={this.props.method}
              />
              <label className="form-input-label" htmlFor="inputName">
                <span className="form-input-label-content">
                  <FormattedMessage
                    id="app.user-last-name"
                    defaultMessage="Nom"
                  />
                </span>
              </label>
            </div>
            {lengthLastName > 0 && (
              <span className="help-block">{formErrors.lastName}</span>
            )}
          </div>
          <div className="col-xs-12 col-md-6">
            <div className={classFirstName1}>
              <input
                className={
                  this.props.firstName
                    ? 'form-input-field on-focus'
                    : 'form-input-field'
                }
                type="text"
                name="firstName"
                id="inputSurname"
                value={this.props.firstName ? this.props.firstName : ''}
                onChange={this.props.method}
              />
              <label className="form-input-label" htmlFor="inputSurname">
                <span className="form-input-label-content">
                  <FormattedMessage
                    id="app.user-first-name"
                    defaultMessage="Prénom"
                  />
                </span>
              </label>
            </div>
            {lengthFirstName > 0 && (
              <span className="help-block">{formErrors.firstName}</span>
            )}
          </div>
          <div className="col-xs-12 col-md-6">
            <div className={classPhoneNumber1}>
              <input
                className={
                  this.props.phoneNumber
                    ? 'form-input-field on-focus'
                    : 'form-input-field'
                }
                type="tel"
                id="inputTel"
                name="phoneNumber"
                value={this.props.phoneNumber ? this.props.phoneNumber : ''}
                onChange={this.props.method}
              />
              <label className="form-input-label" htmlFor="inputTel">
                <span className="form-input-label-content">
                  <FormattedMessage
                    id="app.user-phone"
                    defaultMessage="Téléphone"
                  />
                </span>
              </label>
            </div>
            {lengthPhoneNumber > 0 && (
              <span className="help-block">{formErrors.phoneNumber}</span>
            )}
          </div>
          <div className="col-xs-12 col-md-6">
            <div className={classBirthDate1}>
              <input
                required
                className={
                  this.props.birthdate
                    ? 'form-input-field on-focus'
                    : 'form-input-field'
                }
                type="date"
                name="birthdate"
                id="birthdate"
                value={date}
                onChange={this.props.method}
                data-date=""
                data-date-format="DD MMMM YYYY"
              />
              <label className="form-input-label" htmlFor="birthdate">
                <span className="form-input-label-content">
                  <FormattedMessage
                    id="app.birthdate"
                    defaultMessage="date de naissance"
                  />
                </span>
              </label>
            </div>
            {lengthBirthDate > 0 && (
              <span className="help-block">{formErrors.birthdate}</span>
            )}
          </div>
          <Address
            error={this.props.error}
            valueinputAddress={this.props.address}
            valueinputAddressCompl={this.props.addressCompl}
            valueinputZIP={this.props.zipCode}
            valueinputCity={this.props.city}
            valueinputCountry={this.props.country}
            method={this.props.method}
          />

          {this.props.user.isCompany ? (
            <div className="row">
              <div className="col-xs-12 col-md-6">
                <div
                  className={`form-group ${
                    this.props.user.companyName.length > 0 ? ' on-focus' : ''
                  }  ${
                    this.props.user.companyName.length <= 0
                      ? ' has-error has-feedback'
                      : ''
                  }`}
                >
                  <div
                    className={`form-input ${
                      this.props.user.companyName.length > 0 ? ' on-focus' : ''
                    }  ${
                      this.props.user.companyName.length <= 0
                        ? ' has-error has-feedback'
                        : ''
                    }`}
                  >
                    <input
                      className="form-input-field"
                      type="text"
                      name="companyName"
                      id="companyName"
                      value={this.props.user.companyName}
                      onChange={e => {
                        this.props.onUpdateTvaAndFiscalField({
                          TVANumber: this.props.user.TVANumber,
                          fiscal: this.props.user.fiscal,
                          companyName: e.target.value
                        });
                      }}
                    />
                    <label className="form-input-label" htmlFor="companyName">
                      <span className="form-input-label-content">
                        *{' '}
                        <FormattedMessage
                          id="app.company-name"
                          defaultMessage="Raison sociale"
                        />
                      </span>
                    </label>
                  </div>
                </div>
              </div>
              <div className="col-xs-12 col-md-6">
                <div
                  className={`form-group ${
                    this.props.user.TVANumber.length > 0 ? ' on-focus' : ''
                  }  ${
                    this.props.user.TVANumber.length <= 0 &&
                    this.props.user.fiscal.length <= 0
                      ? ' has-error has-feedback'
                      : ''
                  }`}
                >
                  <div
                    className={`form-input ${
                      this.props.user.TVANumber.length > 0 ? ' on-focus' : ''
                    }  ${
                      this.props.user.TVANumber.length <= 0 &&
                      this.props.user.fiscal.length <= 0
                        ? ' has-error has-feedback'
                        : ''
                    }`}
                  >
                    <input
                      className="form-input-field"
                      type="text"
                      name="tvaNumber"
                      id="tva"
                      value={this.props.user.TVANumber}
                      onChange={e => {
                        this.props.onUpdateTvaAndFiscalField({
                          TVANumber: e.target.value,
                          fiscal: this.props.user.fiscal,
                          companyName: this.props.user.companyName
                        });
                      }}
                    />
                    <label className="form-input-label" htmlFor="tva">
                      <span className="form-input-label-content">
                        *{' '}
                        <FormattedMessage
                          id="app.tva-number"
                          defaultMessage="TVA intracommunautaire"
                        />
                      </span>
                    </label>
                  </div>
                </div>
              </div>

              <div className="col-xs-12 col-md-6">
                <div
                  className={`form-group ${
                    this.props.user.fiscal.length > 0 ? ' on-focus' : ''
                  }  ${
                    this.props.user.fiscal.length > 0
                      ? ' has-error has-feedback'
                      : ''
                  }`}
                >
                  <div
                    className={`form-input ${
                      this.props.user.fiscal.length > 0 ? ' on-focus' : ''
                    }  ${
                      (this.props.user.fiscal + this.props.user.TVANumber)
                        .length <= 0
                        ? ' has-error has-feedback'
                        : ''
                    }`}
                  >
                    <input
                      className="form-input-field"
                      type="text"
                      name="fiscalNumber"
                      id="fiscal"
                      value={this.props.user.fiscal}
                      onChange={e => {
                        this.props.onUpdateTvaAndFiscalField({
                          fiscal: e.target.value,
                          TVANumber: this.props.user.TVANumber,
                          companyName: this.props.user.companyName
                        });
                      }}
                    />
                    <label className="form-input-label" htmlFor="fiscal">
                      <span className="form-input-label-content">
                        *{' '}
                        <FormattedMessage
                          id="app.fiscal-number"
                          defaultMessage="Fiscal intracommunautaire"
                        />
                      </span>
                    </label>
                  </div>
                </div>
              </div>

              <div className="col-xs-12 col-md-6">
                <span className="help-block" style={{ color: '#a94442' }}>
                  {formErrors.tvaAndFiscal}
                </span>
              </div>
            </div>
          ) : null}
        </div>
        {this.props.user.uuid ? (
          <>
            <h3 className="form-block-title">
              <FormattedMessage
                id="app.your-uuid"
                defaultMessage="Vos informations Advenir"
              />
            </h3>
            <TextField
              name="inputUUID"
              id="inputUUID"
              value={this.props.user.uuid}
              label="UUID"
              readOnly
            />
          </>
        ) : null}
      </div>
    );
  }
}

export default Subscription;
