import axios from 'axios';
import config from './../config/index';

const baseUrl = config.baseUrl;
export const FETCH_GET_USER_LICENCE = 'FETCH_GET_USER_LICENCE';

export function fetchGetUserLicence() {
  const firebaseId = localStorage.getItem('firebaseId');
  const url = `${baseUrl}/api/users/${firebaseId}/licence`;

  return dispatch => {
    axios
      .get(url, {
        headers: {
          'x-access-token': localStorage.getItem('token'),
          'content-type': 'application/json'
        }
      })
      .then(request => {
        if (request.data.length !== 0) {
          dispatch({
            type: FETCH_GET_USER_LICENCE,
            payload: request.data,
            loading: false
          });
        }
      })
      .catch(() => {
        dispatch({
          type: FETCH_GET_USER_LICENCE,
          payload: false,
          message: ''
        });
      });
  };
}
