import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { menu } from '../../../utils';

class Menu extends Component {
  componentDidMount() {
    menu.init();
  }

  render() {
    const {
      typeCertificat,
      typeAddress,
      station,
      nbStations,
      nbPermissions,
      nbBookings,
      nbReports
    } = this.props;
    return (
      <div className="menu">
        <div className="menu-scrollable">
          <div className="menu-scrollable-content">
            <a href="#" className="menu-item current" data-target="#view1">
              <FormattedMessage id="app.general" defaultMessage="Général" />
              {typeCertificat === false ? <i className="icon-warning" /> : ''}
            </a>
            <a href="#" className="menu-item" data-target="#view2">
              <FormattedMessage
                id="app.informations"
                defaultMessage="Informations"
              />
              {typeAddress === '' ? <i className="icon-warning" /> : ''}
            </a>
            <a
              href="#"
              id="menu-item-price"
              className="menu-item"
              data-target="#view3"
            >
              <FormattedMessage
                id="app.rates-schedules"
                defaultMessage="Tarifs & horaires"
              />
            </a>
            <a href="#" className="menu-item" data-target="#view6">
              <FormattedMessage
                id="app.permissions"
                defaultMessage="Autorisations"
              />
              <span className="menu-item-number">
                {nbPermissions ? nbPermissions : 0}
              </span>
            </a>
            <a href="#" className="menu-item" data-target="#view5">
              <FormattedMessage
                id="app.reservations"
                defaultMessage="Réservations"
              />
              <span className="menu-item-number">
                {nbBookings ? nbBookings : 0}
              </span>
            </a>
            <a href="#" className="menu-item" data-target="#view4">
              <FormattedMessage
                id="app.reports"
                defaultMessage="Signalements"
              />
              <span className="menu-item-number">
                {nbReports ? nbReports : 0}
              </span>
            </a>
            <a href="#" className="menu-item" data-target="#view7">
              <FormattedMessage
                id="app.station"
                defaultMessage="Paramètres bornes"
              />
              {nbStations === 0 ? (
                <i className="icon-warning" />
              ) : (
                <span className="menu-item-number">{nbStations}</span>
              )}
            </a>
          </div>
          <div className="menu-indicator"></div>
        </div>
      </div>
    );
  }
}
export default Menu;
