import React from 'react';
import { FormattedMessage } from 'react-intl';
import ModalCustom from './modalCustom';
import { MODAL_CONFIRM_CALENDAR } from './modals';

const ModalConfirmCalendar = ({ handleConfirm, listExisting, type }) => {
  if (type === MODAL_CONFIRM_CALENDAR)
    return (
      <ModalCustom
        title={
          <FormattedMessage id="app.attention" defaultMessage="Attention" />
        }
        content={
          <FormattedMessage
            id="app.confirm-advantage"
            defaultMessage="Confirmer vous l'avantage ?"
          />
        }
        subContent={
          <FormattedMessage
            id="app.hours-similar"
            values={{
              lists: `${listExisting.lists} `,
              dateTime: listExisting.dateTime
            }}
            defaultMessage="Attention, il y a un chevauchement d'horaire avec {lists} pour le créneau du {dateTime}"
          />
        }
        confirm={
          <FormattedMessage id="app.confirm" defaultMessage="Confirmer" />
        }
        cancel={<FormattedMessage id="app.cancel" defaultMessage="Annuler" />}
        handleConfirm={handleConfirm}
      />
    );
  else return null;
};

export default ModalConfirmCalendar;
