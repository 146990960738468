import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  hideModal,
  showModalParkingActivation
} from '../../../actions/modalAction';
import { FormattedMessage } from 'react-intl';
import { fetchUpdateParkingStatus } from '../../../actions/parkingAction';

class ModalParkingActivation extends Component {
  constructor(props) {
    super(props);
    this.parkingActivation = this.parkingActivation.bind(this);
  }

  parkingActivation() {
    this.props.fetchUpdateParkingStatus(this.props.modal.modalData.parkingId, {
      status: this.props.modal.modalData.status
    });
    this.props.hideModal();
  }

  render() {
    if (this.props.displayActivation) {
      return (
        <div>
          <div className=".modal-layer-screen"></div>
          <div className="modal-scheduler" style={{ display: 'block' }}>
            <div className="modal-scheduler-title">Confirmation</div>
            <a
              className="btn-close"
              onClick={() => {
                this.props.hideModal();
              }}
            >
              <i className="icon-close" />
            </a>
            <div className="modal-scheduler-body">
              <div className="form-group">
                <FormattedMessage
                  id="app.private-message"
                  defaultMessage="Attention, la désactivation du mode public peut occasionner des annulations de réservation auprès de vos utilisateurs. Confirmez-vous la désativation ?"
                />
              </div>
              <div className="modal-scheduler-actions">
                <button
                  className="btn btn-primary  btn-small "
                  onClick={this.parkingActivation}
                >
                  <FormattedMessage id="app.btn-yes" defaultMessage="oui" />
                </button>
                <button
                  className="btn btn-secondary  btn-small btn-fixed"
                  onClick={() => this.props.hideModal()}
                >
                  <FormattedMessage id="app.btn-no" defaultMessage="non" />
                </button>
              </div>
            </div>
          </div>
        </div>
      );
    } else return null;
  }
}

function mapStateToProps(state) {
  return {
    modal: state.modal,
    displayActivation: state.modal.displayActivation
  };
}

export default connect(mapStateToProps, {
  hideModal,
  fetchUpdateParkingStatus,
  showModalParkingActivation
})(ModalParkingActivation);
