import React, { Component } from 'react';
import ReportingElem from './../../../Layouts/Forms/reporting';
import { fetchGetIssues } from './../../../../actions/issuesAction';
import { connect } from 'react-redux';
import _ from 'lodash';
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl';

class Reporting extends Component {
  componentDidMount() {
    this.props.fetchGetIssues();
  }

  reporting() {
    return _.map(this.props.issues, (issue, idx) => {
      return (
        <ReportingElem
          key={idx}
          id={issue.id}
          name={issue.name}
          desc={issue.issueDescription}
          date={issue.issueDate}
        />
      );
    });
  }

  render() {
    if (this.props.issues && this.props.issues.length > 0) {
      return (
        <div className="dashboard-card dashboard-part dashboard-visible">
          <h3 className="dashboard-card-title">
            <FormattedMessage id="app.reports" defaultMessage="Signalements" />
          </h3>
          <div className="list-reporting">{this.reporting()}</div>
        </div>
      );
    }
    return <div />;
  }
}

function mapStateToProps(state) {
  return {
    issues: state.issues.issuesAllParkingList,
    message: state.issues.message
  };
}

export default connect(
  mapStateToProps,
  { fetchGetIssues }
)(Reporting);
