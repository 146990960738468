import { FETCH_GET_TYPOLOGY } from '../actions/typologiesAction';

export default function(state = [], action) {
  switch (action.type) {
    case FETCH_GET_TYPOLOGY:
      return {
        ...state,
        typology: action.payload,
        loading: true,
        message: action.message
      };
    default:
      return state;
  }
}
