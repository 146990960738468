import React from 'react';

const Address = ({ valueinputLong, valueinputLat, method, error }) => {
  //Validator Latitude
  let classlat = 'form-group';
  let classlat1 = 'form-input';
  let lengthlat1 = 0;
  if (valueinputLat) {
    classlat1 = 'form-input on-focus';
  }
  if (error) {
    if (error.lat.length > 0) {
      lengthlat1 = error.lat.length;
      classlat = 'form-group on-focus has-error has-feedback';
      classlat1 = 'form-input on-focus has-error has-feedback';
    }
  }

  // /Validator Longtitude
  let classlon = 'form-group';
  let classlon1 = 'form-input';
  let lengthlon1 = 0;
  if (valueinputLong) {
    classlon1 = 'form-input on-focus';
  }
  if (error) {
    if (error.longitude.length > 0) {
      lengthlon1 = error.longitude.length;
      classlon = 'form-group on-focus has-error has-feedback';
      classlon1 = 'form-input on-focus has-error has-feedback';
    }
  }
  return (
    <div>
      <div className="col-xs-12 col-md-4">
        <div className={classlat}>
          <div className={classlat1}>
            <input
              required
              className={
                valueinputLat ? 'form-input-field on-focus' : 'form-input-field'
              }
              type="text"
              id="lat"
              value={valueinputLat}
              onChange={method}
              name="lat"
              autoComplete="no"
            />
            <label className="form-input-label" htmlFor="lat">
              <span className="form-input-label-content">Latitude</span>
            </label>
          </div>
          {lengthlat1 > 0 && <span className="help-block">{error.lat}</span>}
        </div>
      </div>
      <div className="col-xs-12 col-md-4">
        <div className={classlon}>
          <div className={classlon1}>
            <input
              required
              className={
                valueinputLong
                  ? 'form-input-field on-focus'
                  : 'form-input-field'
              }
              name="longitude"
              type="text"
              id="long"
              min="0"
              value={valueinputLong}
              onChange={method}
              autoComplete="no"
            />
            <label className="form-input-label" htmlFor="long">
              <span className="form-input-label-content">Longitude</span>
            </label>
          </div>
          {lengthlon1 > 0 && (
            <span className="help-block">{error.longitude}</span>
          )}
        </div>
      </div>
    </div>
  );
};
export default Address;
