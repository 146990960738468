import React from 'react';

function InputRange({ min, max, step, value, handleChange }) {
  // Return true/false in handle method to know if can update api data
  // False: update state - True: update state and call api (in parent method)
  return (
    <div className="range-slider">
      <span className="range-slider-min">{min}%</span>
      <span
        className="range-slider-value"
        style={{ '--leftPosition': `${value}%` }}
      >
        {value}%
      </span>
      <input
        className="range-slider-input"
        type="range"
        min={min}
        max={max}
        step={step}
        value={value}
        onMouseUp={event => handleChange(event.target.value, true)}
        onChange={event => handleChange(event.target.value, false)}
        onInput={event => handleChange(event.target.value, false)}
      />
      <span className="range-slider-max">{max}%</span>
    </div>
  );
}

InputRange.defaultProps = {
  readOnly: false
};
export default InputRange;
