import React, { Component } from 'react';
import { connect } from 'react-redux';
import { hideModal } from '../../../actions/modalAction';
import { FormattedMessage } from 'react-intl';

class ModalCustom extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const {
      modal,
      hideModal,
      title,
      content,
      subContent,
      confirm,
      cancel,
      handleConfirm,
      handleCancel
    } = this.props;

    if (modal.displayModal) {
      return (
        <div className="modal-validation" style={{ display: 'block' }}>
          <div className="modal-validation-title">{title}</div>
          <a className="btn-close" onClick={hideModal}>
            <i className="icon-close" />
          </a>
          <div className="modal-validation-body">
            <p className="actions-label">{content}</p>
            <p className="text-legend">{subContent}</p>
            <div className="modal-validation-actions">
              <button
                className="btn btn-fourth"
                onClick={handleCancel ? handleCancel : hideModal}
              >
                {cancel ? (
                  cancel
                ) : (
                  <FormattedMessage id="app.btn-no" defaultMessage="non" />
                )}
              </button>
              <button className="btn btn-primary" onClick={handleConfirm}>
                {confirm ? (
                  confirm
                ) : (
                  <FormattedMessage id="app.btn-yes" defaultMessage="oui" />
                )}
              </button>
            </div>
          </div>
        </div>
      );
    } else return null;
  }
}

function mapStateToProps(state) {
  return {
    modal: state.modal
  };
}

export default connect(
  mapStateToProps,
  {
    hideModal
  }
)(ModalCustom);
