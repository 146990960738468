import {
  FETCH_STATION_ADD,
  FETCH_STATION_DELETE,
  FETCH_STATION_EDIT,
  FETCH_STATION_PATCH,
  FETCH_STATE,
  FETCH_STATIONS_INIT_STATE,
  FETCH_STATION_ADD_EVER_EXIST
} from '../actions/stationAction';

const intialState = {
  stationAdd: '',
  deleted: '',
  stationPatch: '',
  stationEdit: '',
  loading: true,
  stationExist: false
};
export default function(state = intialState, action) {
  switch (action.type) {
    case FETCH_STATION_ADD:
      return {
        ...state,
        stationAdd: action.payload,
        deleted: '',
        stationPatch: '',
        stationEdit: '',
        loading: true
      };
    case FETCH_STATIONS_INIT_STATE:
      return {
        ...state,
        ...intialState
      };
    case FETCH_STATION_ADD_EVER_EXIST:
      return {
        ...state,
        stationExist: true,
        stationAdd: action.payload,
        deleted: '',
        stationPatch: '',
        stationEdit: '',
        loading: true
      };
    case FETCH_STATION_DELETE:
      return {
        ...state,
        stationDelete: action.payload,
        stationAdd: '',
        stationEdit: '',
        stationPatch: '',
        loading: true,
        deleted: action.deleted
      };
    case FETCH_STATION_EDIT:
      return {
        ...state,
        stationEdit: action.payload,
        stationAdd: '',
        deleted: '',
        stationPatch: '',
        loading: true
      };
    case FETCH_STATION_PATCH:
      return {
        ...state,
        stationPatch: action.payload,
        stationAdd: '',
        deleted: '',
        stationEdit: '',
        loading: true
      };
    case FETCH_STATE:
      return {
        ...state,
        stationPatch: '',
        stationAdd: '',
        deleted: '',
        stationEdit: '',
        loading: true
      };
    default:
      return state;
  }
}
