export default {
  firebase: {
    apiKey: 'AIzaSyDgUxjYcNnvlyXFrJtamZyqAg3PgxlaeZ4',
    authDomain: 'prizz-35837.firebaseapp.com',
    databaseURL: 'https://prizz-35837.firebaseio.com',
    projectId: 'prizz-35837',
    storageBucket: 'prizz-35837.appspot.com',
    messagingSenderId: '244779136524'
  },
  baseUrl: 'https://api.wattpark.eu'
};
