import React, { Component } from 'react';
import Subscription from './../../Layouts/Forms/subscription';
import { FormattedMessage } from 'react-intl';

class Info extends Component {
  render() {
    return (
      <div
        id="view1"
        className={`view ${this.props.isSelected ? `current` : ``}`}
      >
        {this.props.user ? (
          <Subscription
            onUpdateTvaAndFiscalField={companyData => {
              this.props.onUpdateTvaAndFiscalField(companyData);
            }}
            user={this.props.user}
            error={this.props.error}
            file={this.props.fileNameUser}
            method={this.props.method}
            firstName={this.props.firstName}
            birthdate={this.props.birthdate}
            lastName={this.props.lastName}
            zipCode={this.props.zipCode}
            addressCompl={this.props.addressCompl}
            address={this.props.street}
            city={this.props.city}
            country={this.props.country}
            phoneNumber={this.props.phoneNumber}
          />
        ) : null}
        <div className="form-block">
          <h3 className="form-block-title">
            <FormattedMessage
              id="app.your-proof-of-identity"
              defaultMessage="votre justificatif d’identité"
            />
          </h3>
          <div className="upload-block inputWrapper">
            <input
              onChange={this.props.onFileChange}
              type="file"
              id="file"
              className="fileInput"
            />
            <label className="upload-block-title" htmlFor="file">
              {this.props.fileNameUser ? (
                this.props.fileNameUser
              ) : (
                <FormattedMessage
                  id="app.import-my-identity-credential"
                  defaultMessage="Importer mon justificatif d'identité"
                />
              )}
            </label>
          </div>
          <p className="text-legend">
            *{' '}
            <FormattedMessage
              id="app.user-text-legend"
              defaultMessage="Les documents suivants sont acceptés : carte d’identité française, titre de séjour, passeport, permis de conduire européen."
            />
          </p>
        </div>
        <div className="view-footer">
          <button
            disabled={this.props.enabled || this.props.isLoading}
            type="submit"
            className={
              this.props.enabled || this.props.isLoading
                ? 'btn btn-primary btn-block btn-disable'
                : 'btn btn-primary btn-block'
            }
            onClick={this.props.handleSubmit}
          >
            {' '}
            {this.props.isLoading ? (
              'Loading…'
            ) : (
              <FormattedMessage
                id="app.apply-changes"
                defaultMessage="Appliquer les modifications"
              />
            )}
          </button>
        </div>
        <br />
        <br />
      </div>
    );
  }
}

export default Info;
