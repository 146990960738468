import React, { Component } from 'react';

class Pagination extends Component {
  constructor(props) {
    super(props);
  }

  paginate(pages, handlePage) {
    const pageNumber = [];

    for (let nb = 0; nb < pages; nb++) {
      const isCurrent = this.props.actualPage - 1 === nb;

      pageNumber.push(
        <a
          key={nb}
          onClick={() => handlePage(nb + 1)}
          className={`pagination-nav-item ${isCurrent ? 'current' : ''}`}
        >
          {nb + 1}
        </a>
      );
    }

    return pageNumber;
  }

  render() {
    const { pages, handlePage, actualPage } = this.props;

    if (pages > 1) {
      return (
        <div className="pagination">
          <a
            className={`pagination-btn pagination-prev ${
              actualPage === 1 ? 'disabled' : ''
            }`}
            onClick={() => handlePage(actualPage - 1)}
          >
            <i className="icon-arrow" />
          </a>
          <nav className="pagination-nav">
            {this.paginate(pages, handlePage)}
          </nav>
          <a
            className={`pagination-btn pagination-next ${
              actualPage === pages ? 'disabled' : ''
            }`}
            onClick={() => handlePage(actualPage + 1)}
          >
            <i className="icon-arrow" />
          </a>
        </div>
      );
    } else return null;
  }
}

export default Pagination;
