import React from 'react';
import Slot from './slots';
import _ from 'lodash';

const Day = ({ hours, day, method, numDay }) => {
  const slots = () => {
    const array = [];
    const array1 = [];
    let slots = day.slots;

    let slot = _.orderBy(slots, 'startDate');

    for (let k = 1; k < slot.length; k++) {
      if (slot[k] !== undefined) array1.push(slot[k].startDate);
    }
    for (let k = 0; k < slot.length; k++) {
      if (slot[k] !== undefined) {
        if (slot[k].startDate !== '00:00:00') {
          let datetimeEnd = new Date('1970-01-01T' + slot[k].startDate + 'Z');
          let datetimeStart = new Date('1970-01-01T00:00:00Z');
          let hoursDiff = Math.abs(datetimeEnd - datetimeStart) / 3600000;
          for (let j = 1; j <= hoursDiff * 2; j++) {
            array.push(
              <div
                key={
                  k +
                  j +
                  hoursDiff * slot[k].id * day.dayNumber +
                  hoursDiff +
                  Math.random()
                }
                className="scheduler-time-slot"
                data-daynumber={day.dayNumber}
                data-id={day.idDay}
                onClick={method}
              />
            );
          }
        }
        break;
      }
    }

    for (let k = 0; k < slot.length; k++) {
      if (slot[k] !== undefined) {
        array.push(
          <Slot
            idx={k}
            key={slot[k].id}
            slotId={slot[k].id}
            dayId={day.idDay}
            dayNumber={day.dayNumber}
            startDate={slot[k].startDate}
            endDate={slot[k].endDate}
            price={slot[k].price}
            method={method}
          />
        );

        if (slot[k].endDate < array1[k]) {
          let datetimeEnd = new Date('1970-01-01T' + slot[k].endDate + 'Z');
          let datetimeStart = new Date('1970-01-01T' + array1[k] + 'Z');
          let hoursDiff = Math.abs(datetimeStart - datetimeEnd) / 3600000;
          for (let j = 1; j <= hoursDiff * 2; j++) {
            const key =
              slot[k].price === 0
                ? (slot[k].id + j) *
                  (slot[k].day_id !== undefined
                    ? slot[k].day_id
                    : Math.random())
                : (slot[k].id + j) *
                  slot[k].price *
                  (slot[k].day_id !== undefined
                    ? slot[k].day_id
                    : Math.random());
            array.push(
              <div
                key={key}
                className="scheduler-time-slot"
                data-daynumber={day.dayNumber}
                data-id={day.idDay}
                onClick={method}
              />
            );
          }
        }
      }
    }
    return array;
  };
  return (
    <div
      className={
        day.slots.length !== 0 ? 'scheduler-column' : 'scheduler-column empty'
      }
    >
      <div className="scheduler-column-header">
        <div className="scheduler-column-header-item">
          <a
            data-daynumber={day.dayNumber}
            data-id={day.idDay}
            onClick={method}
            href="#"
          >
            <div className="scheduler-column-header-item-circle">
              {' '}
              {numDay(day.dayNumber)}
            </div>
          </a>
        </div>
      </div>
      <div className="scheduler-column-body">
        {slots()}
        <div
          className="scheduler-column-backdrop"
          data-daynumber={day.dayNumber}
          data-id={day.idDay}
          onClick={method}
        />
      </div>
    </div>
  );
};
export default Day;
