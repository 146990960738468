import React, { Component } from 'react';
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl';
import { connect } from 'react-redux';

class Receipt extends Component {
  render() {
    const {
      receipt,
      method,
      fileName,
      acceptedFiles,
      label,
      legend,
      className
    } = this.props;

    return (
      <div className={className === 0 ? '' : 'form-block'}>
        <h3 className="form-block-title">
          {label ? (
            label
          ) : (
            <FormattedMessage
              id="app.parking-proof-of-address"
              defaultMessage="justificatif d'adresse"
            />
          )}
        </h3>
        <div className="upload-block inputWrapper">
          <input
            onChange={method}
            type="file"
            id="file"
            className="fileInput"
            accept={acceptedFiles ? acceptedFiles : '.jpeg,.jpg,.png,.pdf'}
          />
          <label className="upload-block-title" htmlFor="file">
            {fileName ? (
              fileName
            ) : (
              <FormattedMessage
                id="app.import-my-address-credential"
                defaultMessage="Importer mon justificatif d'adresse"
              />
            )}
          </label>
        </div>
        <p className="text-legend">
          *{' '}
          {legend ? (
            legend
          ) : (
            <FormattedMessage
              id="app.parking-text-legend"
              defaultMessage="Les documents suivants sont acceptés : facture d’électricité, bail du lieu, certificat de propriété (tout document doit avoir moins de 3 mois)"
            />
          )}
        </p>

        <p style={{ margin: '5px' }} className="form-block-title-section">
          {receipt.loading && (
            <FormattedMessage
              id="app.import-email.loading"
              defaultMessage="Import en cours..."
            />
          )}
          {receipt.success && (
            <FormattedMessage
              id="app.import-email.success"
              defaultMessage="Emails importés avec succès"
            />
          )}
          {receipt.error && (
            <FormattedMessage
              id="app.import-email.error"
              defaultMessage="Erreur lors de l'import des emails"
            />
          )}
        </p>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    receipt: state.parkings.receipt
  };
}

export default connect(mapStateToProps)(Receipt);
