import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';

class stationAdd extends Component {
  render() {
    return (
      <div
        className={
          this.props.styleAddStation ? 'station edit hide' : 'station edit'
        }
      >
        <div className="station-edit">
          <div
            className={
              this.props.styleInput ? 'form-input on-focus' : 'form-input'
            }
          >
            <input
              onChange={this.props.methodChange}
              value={this.props.publicId}
              name="publicId"
              className="form-input-field"
              type="text"
              id="inputStationName"
            />
            <label className="form-input-label" htmlFor="inputStationName">
              <span className="form-input-label-content">
                <FormattedMessage
                  id="app.Name-of-station"
                  defaultMessage="Nom de la station"
                />
              </span>
            </label>
          </div>
          <div
            className={
              this.props.styleInput ? 'form-input on-focus' : 'form-input'
            }
          >
            <input
              onChange={this.props.methodChange}
              value={this.props.hardwareId}
              name="hardwareId"
              className="form-input-field"
              type="text"
              id="inputHardwareID"
            />
            <label className="form-input-label" htmlFor="inputHardwareID">
              <span className="form-input-label-content">Hardware ID</span>
            </label>
          </div>
          <button
            onClick={this.props.method}
            className="btn btn-primary btn-small"
          >
            <FormattedMessage
              id="app.btnCreateParks"
              defaultMessage="valider"
            />
          </button>
        </div>
      </div>
    );
  }
}

export default stationAdd;
