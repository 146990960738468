import React, { Component } from 'react';

class CharacteristicsElem extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div className="col-xs-6 col-sm-4 col-md-3">
        <label className="checkbox checkbox-with-icon">
          <input
            type="checkbox"
            data-value={this.props.id}
            name="covered"
            //value={value}
            defaultChecked={this.props.value}
            onChange={this.props.method}
          />
          <span className="checkmark" />
          <i className={`checkbox-icon icon-${this.props.icon}`} />
          <span className="checkbox-text">{this.props.name}</span>
        </label>
      </div>
    );
  }
}

export default CharacteristicsElem;
