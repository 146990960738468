import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import * as moment from 'moment';
import CertificationLink from './certificationLink';

class Certification extends Component {
  render() {
    const {
      firstName,
      lastName,
      parkingName,
      parkingStreet,
      parkingCity
    } = this.props;
    const Date = this.props.date ? this.props.date.split('T')[0] : null;
    let formattedAddress = '';
    if (parkingStreet && parkingCity) {
      formattedAddress = `${parkingStreet && parkingStreet}, ${parkingCity &&
        parkingCity}`;
    }
    if (!this.props.parkingId || this.props.parkingId == null) return false;
    return (
      <div
        key={this.props.idFile}
        className={
          this.props.type === 'pending' || this.props.type === 'empty'
            ? 'certification-item  certification-item-warning'
            : 'certification-item'
        }
      >
        <div className="certification-item-info">
          <div className="certification-item-info-title">
            {firstName && lastName
              ? `${firstName} ${lastName}`
              : firstName
              ? firstName
              : lastName}
          </div>
          <div className="certification-item-info-content">
            <span className="certification-item-info-content-details">
              {Date && this.props.type && this.props.type === 'pending'
                ? `importé le `
                : this.props.type === 'approved'
                ? `accepté le `
                : this.props.type === 'rejected'
                ? `Refusé le `
                : ''}
              {Date && moment(Date).format('DD-MM-YYYY')}
            </span>
          </div>
        </div>
        <div className="certification-item-info">
          <div className="certification-item-info-title">{parkingName}</div>
          <div className="certification-item-info-content">
            {formattedAddress}
          </div>
        </div>
        <div className="certification-item-action">
          {(this.props.type === 'rejected' ||
            this.props.type === 'approved') && (
            <CertificationLink
              to={`/certification/validation/${this.props.parkingId}`}
              idDesktop="app.edit-certification"
              idMobile="app.consult"
              defaultMess="Consulter ou Modifier"
            ></CertificationLink>
          )}
          {this.props.type === 'pending' && (
            <CertificationLink
              to={`/certification/validation/${this.props.parkingId}`}
              idDesktop="app-analyze-certification"
              idMobile="app.import"
              defaultMess="Etudier la demande"
            ></CertificationLink>
          )}
          {this.props.type === 'empty' && (
            <CertificationLink
              to={`/certification/validation/${this.props.parkingId}`}
              idDesktop="import-certificate"
              idMobile="app.import"
              defaultMess="Importer le certificat"
            ></CertificationLink>
          )}
        </div>
      </div>
    );
  }
}

export default Certification;
