import React, { Component } from 'react';

import {
  removeCarFromParking,
  addListToParking,
  fetchDeleteUserFromList,
  fetchUpdateUser,
  fetchParkingCars,
  fetchUserReminder
} from '../../../../actions/parkingAction';

import { connect } from 'react-redux';
import Alert from './../../../Layouts/Alert/alert';
import { setInputBehaviour, handleSwitchInput } from '../../../../utils';
import EmailSearch from './email-search';
import Pagination from '../../../Layouts/Pagination/pagination';
import BlackList from './blacklist';
import WhiteList from './whiteList';
import { MODAL_USER_DELETE } from '../../../Layouts/Modals/modals';
import ModalAddUser from '../../../Layouts/Modals/modalAddUser';
import ModalDeleteUser from '../../../Layouts/Modals/modalDeleteUser';
import { hideModal, showCustomModal } from '../../../../actions/modalAction';

class Permissions extends Component {
  constructor(props) {
    super(props);

    this.state = {
      page: 1,
      totalPage: 1,
      blackList: {
        type: 'black',
        content: [
          {
            name: 'Utilisateurs non autorisés',
            members: []
          }
        ]
      },
      whiteList: {
        type: 'white',
        content: []
      },
      allMembers: [],
      listName: '',
      search: '',
      email: '',
      searchResult: [],
      showAdd: false,
      edit: false,
      userEditing: null,
      userDeleting: null
    };

    this.handleChange = this.handleChange.bind(this);
    this.changePage = this.changePage.bind(this);
    this.addList = this.addList.bind(this);
    this.getAllMembers = this.getAllMembers.bind(this);
  }

  componentDidMount() {
    setInputBehaviour();
    handleSwitchInput();

    this.setBlackList();
    this.setWhiteList();
  }

  componentWillReceiveProps(nextProps, nextContext) {
    if (nextProps.list) {
      const { userEditing, userDeleting, allMembers, email } = this.state;

      const newMembersList = [];

      allMembers.forEach(member => {
        if (userEditing && member.id === userEditing.id) member.email = email;

        if (userDeleting && member.id === userDeleting.id) return null;
        else newMembersList.push(member);
      });

      this.setState({
        allMembers: newMembersList,
        userEditing: null,
        userDeleting: null
      });
    }
  }

  componentDidUpdate(nextProps) {
    const { goToListInfo, lists } = this.props;

    if (nextProps.addListStatus.success) {
      this.setState({ listName: '' });
      this.setWhiteList();
      goToListInfo(nextProps.listId);
    }

    if (lists !== nextProps.lists) {
      this.setBlackList();
      this.setWhiteList();
    }
  }

  setBlackList() {
    let { lists } = this.props;
    const blackList = lists.filter(list => list.listType === 'black');

    this.setState(
      {
        blackList: {
          type: 'black',
          content: blackList
        }
      },
      () => this.getAllMembers()
    );
  }

  setWhiteList() {
    let { lists } = this.props;
    const { page } = this.state;

    let pagination = lists.find(list => list.pagination === true);

    pagination = pagination ? pagination.list : [];

    const whiteList =
      pagination.length !== 0
        ? pagination[page - 1].filter(list => list.listType === 'white')
        : [];

    this.setState(
      {
        whiteList: {
          type: 'white',
          content: whiteList
        },
        totalPage: pagination.length
      },
      () => this.getAllMembers()
    );
  }

  getAllMembers() {
    let { whiteList, blackList } = this.state;
    const memberList = [];

    const lists = blackList.content.concat(whiteList.content);

    lists.forEach(list => {
      list.members.forEach(member => {
        memberList.push({
          ...member,
          name: list.name,
          listType: list.listType
        });
      });
    });

    this.setState({
      allMembers: memberList
    });
  }

  handleChange(event) {
    const { name, value } = event.target;

    this.setState({
      [name]: value
    });
  }

  changePage(page) {
    this.setState(
      {
        page: page
      },
      () => {
        this.setWhiteList();
      }
    );
  }

  addList(e) {
    e.preventDefault();
    const { listName } = this.state;
    this.setState({
      showAdd: false
    });
    this.props.addListToParking(this.props.parkingid, { name: listName });
  }

  // USER LIT

  addEditingUser(user) {
    this.setState({
      userEditing: user,
      email: user.email,
      edit: true
    });
  }

  updateUser() {
    const { userEditing, email } = this.state;
    this.props.fetchUpdateUser(userEditing.blackwhitelist_id, userEditing.id, {
      email: email
    });
    this.setState({
      edit: false
    });
  }

  deleteUser() {
    const { userDeleting } = this.state;
    this.props.fetchDeleteUserFromList(
      userDeleting.blackwhitelist_id,
      userDeleting.id
    );
    this.props.hideModal();
  }

  relaunchUser(member) {
    const { parkingId } = this.props;
    this.props.fetchUserReminder(parkingId, member.blackwhitelist_id, member);
  }

  confirmDeleteUser(user) {
    this.setState(
      {
        userDeleting: user
      },
      () => {
        this.props.showCustomModal(MODAL_USER_DELETE);
      }
    );
  }

  showAdd() {
    this.setState({
      showAdd: true
    });
  }

  render() {
    const {
      whiteList,
      blackList,
      totalPage,
      nameAlert,
      alert,
      typeAlert,
      message,
      search,
      searchResult,
      page,
      listName,
      showAdd,
      edit,
      deleteUserId,
      email,
      allMembers
    } = this.state;
    const { goToListInfo, modal, total } = this.props;

    return (
      <div id="view6" className="view">
        {alert && (
          <Alert
            nameAlert={nameAlert}
            type={typeAlert}
            style={false}
            message={message}
          />
        )}

        <ModalDeleteUser
          handleConfirm={() => this.deleteUser(deleteUserId)}
          type={modal.type}
        />

        <EmailSearch
          value={search}
          data={allMembers}
          className={'searching'}
          allResult={searchResult}
          handleChange={this.handleChange}
          edit={edit}
          email={email}
          onUpdate={() => this.updateUser()}
          onEdit={member => this.addEditingUser(member)}
          onDelete={member => this.confirmDeleteUser(member)}
          onRelaunch={member => this.relaunchUser(member)}
        />

        {search.length < 4 && (
          <>
            <BlackList
              blackList={blackList}
              goToList={id => goToListInfo(id)}
            />
            <WhiteList
              whiteList={whiteList}
              goToList={id => goToListInfo(id)}
              showAdd={() => this.showAdd()}
              adding={showAdd}
              handleChange={this.handleChange}
              addList={this.addList}
              value={listName}
              total={total}
            />
            <Pagination
              pages={totalPage}
              actualPage={page}
              handlePage={this.changePage}
            />
          </>
        )}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    removeListStatus: state.parkings.cars.deletion,
    addListStatus: state.parkings.cars.add,
    lists: state.parkings.cars.data,
    list: state.parkings.list,
    listId: state.parkings.cars.add.id,
    total: state.parkings.cars.total,
    modal: state.modal,
    loading:
      state.parkings.cars.deletion.pending ||
      state.parkings.cars.pending ||
      state.parkings.cars.add.pending
  };
}

export default connect(
  mapStateToProps,
  {
    removeCarFromParking,
    addListToParking,
    fetchDeleteUserFromList,
    fetchParkingCars,
    fetchUpdateUser,
    showCustomModal,
    hideModal,
    fetchUserReminder
  }
)(Permissions);
