import React from 'react';
import { FormattedMessage } from 'react-intl';

const Network = ({ type, method, onClickSelect, parkingId }) => {
  const electricalNetworks = [
    { key: 'null', value: null, label: 'Aucun fournisseur' },
    { key: 'edf', value: 'EDF', label: 'EDF' },
    { key: 'engie', value: 'Engie', label: 'Engie' },
    {
      key: 'totalDirectEnergie',
      value: 'Total Direct Energie',
      label: 'Total Direct Energie'
    },
    { key: 'eni', value: 'Eni', label: 'Eni' },
    { key: 'directEnergie', value: 'Direct Energie', label: 'Direct Energie' },
    { key: 'dukeEnergy', value: 'Duke Energy', label: 'Duke Energy' },
    { key: 'nexteraEnergy', value: 'NextEra Energy', label: 'NextEra Energy' },
    {
      key: 'dominionResources',
      value: 'Dominion Resources',
      label: 'Dominion Resources'
    },
    {
      key: 'southernCompany',
      value: 'Southern Company',
      label: 'Southern Company'
    },
    {
      key: 'exelonCorporation',
      value: 'Exelon Corporation',
      label: 'Exelon Corporation'
    },
    { key: 'other', value: 'Autre', label: 'Autre' }
  ];

  let val = electricalNetworks.find(elec => elec.key === type);

  return (
    <div className="form-block">
      <h3 className="form-block-title">
        <FormattedMessage
          id="app.parking-type-of-provider"
          defaultMessage="Fournisseur d'électricité"
        />
      </h3>
      <div
        className={val ? 'select is-filled' : 'select'}
        id={'select-' + parkingId}
        onClick={onClickSelect}
      >
        <div className="select-input">
          <div className="select-label">
            <FormattedMessage
              id="app.parking-electrical-networks"
              defaultMessage="Choisir le fournisseur"
            />
          </div>
          <div className="select-value">
            {val ? val.label : 'Aucun fournisseur'}
          </div>
          <i className="icon-arrow-select" />
        </div>
        <div className="select-box">
          <ul className="select-box-list">
            {electricalNetworks.map(elecNetwork => (
              <li
                key={elecNetwork.key}
                className="select-box-list-item"
                onClick={method}
                data-value={elecNetwork.key}
              >
                {elecNetwork.label}
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Network;
