import config from './../config/index';
import axios from 'axios';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';

const baseUrl = config.baseUrl;
const firebaseInstance = firebase.initializeApp(config.firebase);

export const FETCH_LOGIN = 'FETCH_LOGIN';
export const FETCH_RESET_PASSWORD = 'FETCH_RESET_PASSWORD';

// Renew token to setup lifetime login
firebase.auth().onAuthStateChanged((user) => {
  if (user) {
    firebase.auth().currentUser.getIdToken(/* forceRefresh */ true)
      .then((idToken) => {
        localStorage.setItem('token', idToken);
      })
      .catch((err) => {
        console.log(err)
      })
  } else {
    localStorage.clear()
  }
})

export function fetchLogin(data) {
  return dispatch => {
    firebase.auth().signInWithEmailAndPassword(data['email'], data['password']).then((connectedUser) => {
      connectedUser.user.getIdToken().then((token) => {
          const url = `${baseUrl}/api/user/${connectedUser.user.uid}?token=${
            token
          }`;
          axios
            .get(url)
            .then(request => {
              if (request.data.length !== 0) {
                localStorage.setItem(
                  'username',
                  request.data.firstName + ' ' + request.data.lastName
                );
                localStorage.setItem('token', token);
                localStorage.setItem('refreshToken', connectedUser.user.refreshToken);
                localStorage.setItem('firebaseId', connectedUser.user.uid);
                localStorage.setItem('auth', true);
                localStorage.setItem('user', JSON.stringify(request.data));
                localStorage.setItem('userId', JSON.stringify(request.data.id));
                localStorage.setItem('usertype', request.data.userType);
                return dispatch({
                  type: FETCH_LOGIN,
                  payload: connectedUser,
                  isAuth: true,
                  user: ''
                });
              } else {
                dispatch({
                  type: FETCH_LOGIN,
                  payload: false,
                  isAuth: false,
                  user: 'User Not Found'
                });
              }
            })
            .catch(() => {
              dispatch({
                type: FETCH_LOGIN,
                payload: false,
                isAuth: false,
                user: ''
              });
            });
        });

      }
    ).catch((err) => {
      console.log("MY ERROR")
      console.log(err)
        if (err.code === 'INVALID_PASSWORD') {
          dispatch({
            type: FETCH_LOGIN,
            payload: false,
            isAuth: false,
            user:
              'Impossible de se connecter, veuillez vérifier votre login et votre mot de passe'
          });
        } else if (err.code === 'EMAIL_NOT_FOUND') {
          dispatch({
            type: FETCH_LOGIN,
            payload: false,
            isAuth: false,
            user:
              'Impossible de se connecter, veuillez vérifier votre login et votre mot de passe'
          });
        } else if (err.code === 'UNKNOWN_ERROR') {
          dispatch({
            type: FETCH_LOGIN,
            payload: false,
            isAuth: false,
            user:
              'Une erreur technique est survenue, veuillez réessayer ultérieurement'
          });
        } else if (err.code === 'USER_DISABLED') {
          dispatch({
            type: FETCH_LOGIN,
            payload: false,
            isAuth: false,
            user:
              'Une erreur technique est survenue, veuillez réessayer ultérieurement'
          });
        } else if (err.code === 400) {
          dispatch({
            type: FETCH_LOGIN,
            payload: false,
            isAuth: false,
            user:
              'Impossible de se connecter, veuillez vérifier votre login et votre mot de passe'
          });
        }
      }
    )
  };
}

export function fetchResetPassword(email) {
  return dispatch => {
    firebase.auth().sendPasswordResetEmail(email).then(() => {
      dispatch({
        type: FETCH_RESET_PASSWORD,
        payload: true
      });
    })
  };
}
