import axios from 'axios';
import config from './../config/index';

const baseUrl = config.baseUrl;

const getParkingCars = parkingId => {
  const token = localStorage.getItem('token');
  const url = `${baseUrl}/api/parkings/${parkingId}/blackwhitelist/?token=${token}`;
  return axios.get(url, {
    headers: {
      'x-access-token': token,
      'content-type': 'application/json'
    }
  });
};

const addListToParking = (parkingId, data) => {
  const token = localStorage.getItem('token');
  const url = `${baseUrl}/api/parkings/${parkingId}/blackwhitelist?token=${token}`;
  return axios.post(url, data);
};

const removeCarFromParking = (parkingId, carId) => {
  const token = localStorage.getItem('token');
  const url = `${baseUrl}/api/parkings/${parkingId}/blackwhitelist/${carId}?token=${token}`;
  return axios.delete(url);
};

export default {
  getParkingCars,
  addListToParking,
  removeCarFromParking
};
