import React, { Component } from 'react';
import Term from './../Layouts/Forms/terms';
import confidentiality from '../../content/confidentiality';
import { DocumentTitle } from '../../utils';

class Confidentiality extends Component {
  constructor(props) {
    super(props);
    this.confidentiality = confidentiality;
  }

  render() {
    return (
      <div>
        {DocumentTitle(
          'app.title-confidentiality',
          'WATTPARK - Politique de confidentialité'
        )}
        <div className="main-container cgv">
          <div className="container">
            <div className="row">
              <div className="col-xs-12 col-sm-8 col-sm-offset-2">
                {this.confidentiality.map((c, idx) => (
                  <Term key={idx} term={c} />
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Confidentiality;
