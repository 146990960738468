import React, { Component } from 'react';
import Term from '../../Layouts/Forms/terms';
import terms from '../../../content/terms';

class Terms extends Component {
  constructor(props) {
    super(props);
    this.terms = terms;
  }

  render() {
    return (
      <div id="view6" className="view">
        <div className="dashboard-card">
          <div className="row">
            {this.terms.map((term, idx) => (
              <Term key={idx} term={term} />
            ))}
          </div>
        </div>
      </div>
    );
  }
}

export default Terms;
