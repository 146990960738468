import {
  FETCH_DELETE_USER,
  FETCH_EDIT_USER,
  FETCH_GET_USER,
  FETCH_STATE_USER
} from '../actions/userAction';
import { FETCH_STATE } from '../actions/stationAction';

export default function(state = [], action) {
  switch (action.type) {
    case FETCH_EDIT_USER:
      return {
        ...state,
        userUpdated: action.payload,
        transfertSold: '',
        createBankDetail: '',
        updateBankDetail: ''
      };
    case FETCH_GET_USER:
      return {
        ...state,
        getUser: action.payload,
        transfertSold: '',
        createBankDetail: '',
        updateBankDetail: ''
      };
    case FETCH_STATE:
      return {
        ...state,
        getUser: '',
        transfertSold: '',
        createBankDetail: '',
        updateBankDetail: ''
      };
    case FETCH_STATE_USER:
      return {
        ...state,
        transfertSold: '',
        createBankDetail: '',
        updateBankDetail: ''
      };
    case FETCH_DELETE_USER:
      return {
        ...state,
        deleteRequest: true
      };
    default:
      return state;
  }
}
