export const SHOW_MODAL = 'SHOW_MODAL';
export const HIDE_MODAL = 'HIDE_MODAL';
export const SHOW_MODAL_PARKING_ACTIVATION = 'SHOW_MODAL_PARKING_ACTIVATION';
export const SHOW_MODAL_DELETE_CAR = 'SHOW_MODAL_DELETE_CAR';
export const SHOW_CUSTOM_MODAL = 'SHOW_CUSTOM_MODAL';
export const SHOW_MODAL_FULL_HOURS = 'SHOW_MODAL_FULL_HOURS';

export function showModal(data) {
  return dispatch => {
    dispatch({
      type: SHOW_MODAL,
      payload: data
    });
  };
}

export function showModalParkingActivation(data) {
  return dispatch => {
    dispatch({
      type: SHOW_MODAL_PARKING_ACTIVATION,
      payload: data
    });
  };
}

export function showCustomModal(data) {
  return dispatch => {
    dispatch({
      type: SHOW_CUSTOM_MODAL,
      payload: data
    });
  };
}

export function showFullHoursModal(data) {
  return dispatch => {
    dispatch({
      type: SHOW_MODAL_FULL_HOURS,
      payload: data
    });
  };
}

export function showModalDeleteCar(data) {
  return dispatch => {
    dispatch({
      type: SHOW_MODAL_DELETE_CAR,
      payload: data
    });
  };
}

export function hideModal() {
  return dispatch => {
    dispatch({
      type: HIDE_MODAL
    });
  };
}
