import config from './../config/index';
import axios from 'axios';

const baseUrl = config.baseUrl;
export const FETCH_ADD_SLOTS = 'FETCH_ADD_SLOTS';
export const FETCH_DELETE_SLOTS = 'FETCH_DELETE_SLOTS';

export function fetchAddSlots(id, data) {
  return dispatch => {
    if (isNaN(data['slotId'])) {
      const url = `${baseUrl}/api/days/${id}/slots`;
      axios
        .post(url, data, {
          headers: {
            'x-access-token': localStorage.getItem('token'),
            'content-type': 'application/json'
          }
        })
        .then(request => {
          if (request.data.length !== 0) {
            dispatch({
              type: FETCH_ADD_SLOTS,
              payload: request.data,
              typeAction: 'add'
            });
          }
        })
        .catch(() => {
          dispatch({
            type: FETCH_ADD_SLOTS,
            payload: false
          });
        });
    } else {
      let slot = {
        endDate: data['endDate'],
        startDate: data['startDate'],
        price: data['price']
      };

      const url = `${baseUrl}/api/slots/${data['slotId']}`;
      axios
        .put(url, slot, {
          headers: {
            'x-access-token': localStorage.getItem('token'),
            'content-type': 'application/json'
          }
        })
        .then(request => {
          if (request.data.length !== 0) {
            dispatch({
              type: FETCH_ADD_SLOTS,
              payload: request.data,
              typeAction: 'edit'
            });
          }
        })
        .catch(() => {
          dispatch({
            type: FETCH_ADD_SLOTS,
            payload: false
          });
        });
    }
  };
}

export function fetchDeleteSlots(id) {
  return dispatch => {
    const url = `${baseUrl}/api/slots/${id}`;
    axios
      .delete(url, {
        headers: {
          'x-access-token': localStorage.getItem('token'),
          'content-type': 'application/json'
        }
      })
      .then(request => {
        if (request.data.length !== 0) {
          dispatch({
            type: FETCH_DELETE_SLOTS,
            payload: request.data
          });
        }
      })
      .catch(() => {
        dispatch({
          type: FETCH_DELETE_SLOTS,
          payload: false
        });
      });
  };
}
