import React, { Component } from 'react';
import Term from './../Layouts/Forms/terms';
import legalsMentions from '../../content/mentions';
import { DocumentTitle } from '../../utils';

class LegalsMentions extends Component {
  constructor(props) {
    super(props);
    this.legalsMentions = legalsMentions;
  }

  render() {
    return (
      <div>
        {DocumentTitle('app.title-lm', 'WATTPARK - Mention légale')}
        <div className="main-container cgv">
          <div className="container">
            <div className="row">
              <div className="col-xs-12 col-sm-8 col-sm-offset-2">
                {this.legalsMentions.map((mention, idx) => (
                  <Term key={idx} term={mention} />
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default LegalsMentions;
