import React from 'react';
import _ from 'lodash';
import { Link } from 'react-router-dom';

const Partners = ({ partners }) => {
  const listPartners = () => {
    return _.map(partners, partner => {
      return (
        <div className="partner" key={partner.id}>
          <div className="partner-title">{partner.name}</div>
          <div className="partner-content">{partner.websiteUrl}</div>
          <a
            target="_blank"
            href={partner.websiteUrl}
            className="partner-action"
          >
            Prendre RDV
          </a>
        </div>
      );
    });
  };
  return <div>{listPartners()}</div>;
};
export default Partners;
