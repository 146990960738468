import React from 'react';
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl';

const ParkingName = ({ method, value, error }) => {
  let classname = 'form-group';
  let classname1 = 'form-input';
  let lengthname1 = 0;
  if (value) {
    classname1 = 'form-input on-focus';
  }
  if (error) {
    if (error.name.length > 0) {
      lengthname1 = error.name.length;
      classname = 'form-group on-focus has-error has-feedback';
      classname1 = 'form-input on-focus has-error has-feedback';
    }
  }
  return (
    <div className="form-block">
      <h3 className="form-block-title">
        <FormattedMessage id="app.namePark" defaultMessage="Nom du parking" />
      </h3>
      <div className={classname}>
        <div className={classname1}>
          <input
            required
            className="form-input-field"
            value={value}
            noValidate
            onChange={method}
            type="text"
            id="inputName"
            name="name"
          />
          <label className="form-input-label" htmlFor="inputName">
            <span className="form-input-label-content">
              <FormattedMessage
                id="app.namePark"
                defaultMessage="Nom du parking"
              />
            </span>
          </label>
        </div>
        {lengthname1 > 0 && <span className="help-block">{error.name}</span>}
      </div>
    </div>
  );
};

export default ParkingName;
