import React, { Component } from 'react';
import ReportingElem from './../../../Layouts/Forms/reporting';
import { FormattedMessage } from 'react-intl';

class Reportings extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const returnIssue = () => {
      if (this.props.issues && this.props.issues.length > 0) {
        return (
          <div>
            <h3 className="form-block-title">
              <FormattedMessage
                id="app.reports-parking"
                defaultMessage="Signalements à propos de ce parking"
              />
            </h3>
            {this.props.issues.length &&
              this.props.issues.map((issue, idx) => (
                <ReportingElem
                  key={idx}
                  id={issue.id}
                  name={issue.name}
                  desc={issue.issueDescription}
                  date={issue.issueDate}
                />
              ))}
          </div>
        );
      }
      return (
        <h3 className="form-block-title">
          <FormattedMessage
            id="app.no-reports"
            defaultMessage=" Pas de Signalements à propos de ce parking"
          />
        </h3>
      );
    };
    return (
      <div id="view4" className="view">
        <div className="form-block">{returnIssue()}</div>
      </div>
    );
  }
}

export default Reportings;
