import { FETCH_ADD_SLOTS, FETCH_DELETE_SLOTS } from '../actions/hoursAction';

export default function(state = [], action) {
  switch (action.type) {
    case FETCH_ADD_SLOTS:
      return {
        ...state,
        slotsAdd: action.payload,
        typeAction: action.typeAction,
        action: 1
      };
    case FETCH_DELETE_SLOTS:
      return {
        ...state,
        slotsDeleted: action.payload,
        action: 0
      };
    default:
      return state;
  }
}
