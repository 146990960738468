import React from 'react';
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl';

const Address = ({
  valueinputAddress,
  valueinputAddressCompl,
  valueinputZIP,
  valueinputCity,
  valueinputCountry,
  method,
  error
}) => {
  //Validator ZipCode Input
  let classZipCode = 'form-group';
  let classZipCode1 = 'form-input';
  let lengthZipCode1 = 0;
  if (valueinputZIP) {
    classZipCode1 = 'form-input on-focus';
  }
  if (error) {
    if (error.zipCode.length > 0) {
      lengthZipCode1 = error.zipCode.length;
      classZipCode = 'form-group on-focus has-error has-feedback';
      classZipCode1 = 'form-input on-focus has-error has-feedback';
    }
  }
  //Validator Address Input
  let classAddress = 'form-group';
  let classAddress1 = 'form-input';
  let lengthAddress1 = 0;
  if (valueinputAddress) {
    classAddress1 = 'form-input on-focus';
  }
  if (error) {
    if (error.address.length > 0) {
      lengthAddress1 = error.address.length;
      classAddress = 'form-group on-focus has-error has-feedback';
      classAddress1 = 'form-input on-focus has-error has-feedback';
    }
  }
  //Validator City
  let classCity = 'form-group';
  let classCity1 = 'form-input';
  let lengthCity1 = 0;
  if (valueinputCity) {
    classCity1 = 'form-input on-focus';
  }
  if (error) {
    if (error.city.length > 0) {
      lengthCity1 = error.city.length;
      classCity = 'form-group on-focus has-error has-feedback';
      classCity1 = 'form-input on-focus has-error has-feedback';
    }
  }

  //Validator country
  let classCountry = 'form-group';
  let classCountry1 = 'form-input';
  let lengthCountry1 = 0;
  if (valueinputCountry) {
    classCountry1 = 'form-input on-focus';
  }
  if (error) {
    if (error.country.length > 0) {
      lengthCountry1 = error.country.length;
      classCountry = 'form-group on-focus has-error has-feedback';
      classCountry1 = 'form-input on-focus has-error has-feedback';
    }
  }

  //Validator addressCompl
  let classaddressCompl = 'form-group';
  let classaddressCompl1 = 'form-input';
  let lengthaddressCompl1 = 0;
  if (valueinputAddressCompl) {
    classaddressCompl1 = 'form-input on-focus';
  }
  if (error) {
    if (error.addressCompl.length > 0) {
      lengthaddressCompl1 = error.addressCompl.length;
      classaddressCompl = 'form-group on-focus has-error has-feedback';
      classaddressCompl1 = 'form-input on-focus has-error has-feedback';
    }
  }

  return (
    <div className="row">
      <div className="col-xs-12">
        <div className={classAddress}>
          <div className={classAddress1}>
            <input
              required
              className={
                valueinputAddress
                  ? 'form-input-field on-focus'
                  : 'form-input-field'
              }
              name="address"
              value={valueinputAddress}
              onChange={method}
              type="text"
              id="inputAddress"
              autoComplete="no"
            />
            <label className="form-input-label" htmlFor="inputAddress">
              <span className="form-input-label-content">
                *{' '}
                <FormattedMessage
                  id="app.parking-locality"
                  defaultMessage="Adresse"
                />
              </span>
            </label>
          </div>
          {lengthAddress1 > 0 && (
            <span className="help-block">{error.address}</span>
          )}
        </div>
      </div>
      <div className="col-xs-12">
        <div className={classaddressCompl}>
          <div className={classaddressCompl1}>
            <input
              required
              className={
                valueinputAddressCompl
                  ? 'form-input-field on-focus'
                  : 'form-input-field'
              }
              type="text"
              id="inputAddressCompl"
              value={valueinputAddressCompl ? valueinputAddressCompl : ''}
              onChange={method}
              name="addressCompl"
            />
            <label className="form-input-label" htmlFor="inputAddressCompl">
              <span className="form-input-label-content">
                <FormattedMessage
                  id="app.parking-supplement"
                  defaultMessage="Complément d'adresse"
                />
              </span>
            </label>
          </div>
          {lengthaddressCompl1 > 0 && (
            <span className="help-block">{error.addressCompl}</span>
          )}
        </div>
      </div>
      <div className="col-xs-12 col-md-4">
        <div className={classZipCode}>
          <div className={classZipCode1}>
            <input
              required
              className={
                valueinputZIP ? 'form-input-field on-focus' : 'form-input-field'
              }
              min={1}
              type="text"
              id="inputZIP"
              value={valueinputZIP}
              onChange={method}
              name="zipCode"
              autoComplete="no"
            />
            <label className="form-input-label" htmlFor="inputZIP">
              <span className="form-input-label-content">
                *{' '}
                <FormattedMessage
                  id="app.parking-codePostal"
                  defaultMessage="Code postal"
                />
              </span>
            </label>
          </div>
          {lengthZipCode1 > 0 && (
            <span className="help-block">{error.zipCode}</span>
          )}
        </div>
      </div>
      <div className="col-xs-12 col-md-8">
        <div className={classCity}>
          <div className={classCity1}>
            <input
              required
              className={
                valueinputCity
                  ? 'form-input-field on-focus'
                  : 'form-input-field'
              }
              name="city"
              type="text"
              id="inputCity"
              value={valueinputCity}
              onChange={method}
              autoComplete="no"
            />
            <label className="form-input-label" htmlFor="inputCity">
              <span className="form-input-label-content">
                *{' '}
                <FormattedMessage
                  id="app.parking-city"
                  defaultMessage="Ville"
                />
              </span>
            </label>
          </div>
          {lengthCity1 > 0 && <span className="help-block">{error.city}</span>}
        </div>
      </div>
      <div className="col-xs-12">
        <div className={classCountry}>
          <div className={classCountry1}>
            <input
              required
              onChange={method}
              value={valueinputCountry}
              className={
                valueinputCountry
                  ? 'form-input-field on-focus'
                  : 'form-input-field'
              }
              name="country"
              type="text"
              id="inputCountry"
            />
            <label className="form-input-label" htmlFor="inputCountry">
              <span className="form-input-label-content">
                * <FormattedMessage id="app.country" defaultMessage="Pays" />
              </span>
            </label>
          </div>
        </div>
        {lengthCountry1 > 0 && (
          <span className="help-block">{error.country}</span>
        )}
      </div>
    </div>
  );
};
export default Address;
