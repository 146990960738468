import React, { Component } from 'react';
import Station from './../../../Layouts/Forms/station';
import StationAdd from './../../../Layouts/Forms/station-add';
import {
  fetchAddStation,
  fetchDeleteStation,
  fetchEditStation,
  fetchInitialState,
  fetchInitStationState,
  fetchPatchStation,
  fetchResetEditStationField
} from '../../../../actions/stationAction';
import { fetchGetUserLicence } from '../../../../actions/userLicenceAction';
import { connect } from 'react-redux';
import Alert from './../../../Layouts/Alert/alert';
import { FormattedMessage } from 'react-intl';
import {
  handleSelectIsFilled,
  handleSelectOpening,
  OptionsPanel
} from '../../../../utils';

class Stations extends Component {
  constructor(props) {
    super(props);
    let station = [];
    if (this.props.stations) station = this.props.stations;
    this.state = {
      stations: station,
      stationNull: this.props.stationNull,
      publicId: '',
      styleInput: false,
      hardwareId: '',
      styleSelect: '',
      status: '',
      power: '',
      typeAction: '',
      add: [],
      index: '',
      stationId: '',
      alert: false,
      display: 'none',
      message: '',
      nameAlert: '',
      styleAddStation: 'hide',
      style: 'none',
      userLicence: null
    };
    this.handulSubmit = this.handulSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handulOnClickAdd = this.handulOnClickAdd.bind(this);
    this.handulDeleteStation = this.handulDeleteStation.bind(this);
    this.onClickSelect = this.onClickSelect.bind(this);
  }

  componentDidMount() {
    handleSelectOpening();
    handleSelectIsFilled();
    OptionsPanel();
    this.props.fetchGetUserLicence();
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.userLicence !== this.state.userLicence)
      this.setState({
        userLicence: nextProps.userLicence
      });

    if (nextProps.add) {
      this.state.stations.push({
        id: nextProps.add.id,
        publicId: nextProps.add.publicId,
        hardwareId: nextProps.add.hardwareId,
        parking_id: nextProps.add.parking_id,
        status: nextProps.add.status
      });

      this.setState({
        styleInput: false,
        stationNull: false,
        alert: true,
        message: 'Station ajoutée',
        typeAlert: 'alerts',
        nameAlert: 'Succès',
        styleAddStation: 'hide',
        publicId: '',
        add: null,
        hardwareId: ''
      });
      setTimeout(
        function() {
          this.setState({
            alert: false
          });
          this.props.fetchInitialState();
        }.bind(this),
        3000
      );
    }
    if (nextProps.deleted === true) {
      for (let i = 0; i < this.state.stations.length; i++) {
        if (this.state.stations[i] !== undefined) {
          if (
            parseInt(this.state.stations[i].id) ===
            parseInt(this.state.stationId)
          ) {
            delete this.state.stations[i];
          }
        }
      }
      this.setState({
        styleInput: false,
        alert: true,
        message: 'Station supprimée',
        typeAlert: 'alerts',
        nameAlert: 'Succès',
        publicId: '',
        hardwareId: ''
      });
      setTimeout(
        function() {
          this.setState({
            alert: false
          });
        }.bind(this),
        3000
      );
    }

    if (nextProps.stationEdit === false) {
      this.props.fetchResetEditStationField();
      this.setState({
        alert: true,
        message: 'Nom de la station ou Hardware ID déjà existant',
        typeAlert: 'alerts alerts-warning',
        nameAlert: 'Warning'
      });
      setTimeout(
        function() {
          this.setState({
            alert: false
          });
        }.bind(this),
        3000
      );
    }

    if (nextProps.stationEdit) {
      for (let i = 0; i < this.state.stations.length; i++) {
        if (this.state.stations[i] !== undefined) {
          if (
            parseInt(this.state.stations[i].id) ===
            parseInt(this.state.stationId)
          ) {
            this.state.stations[i] = {
              id: nextProps.stationEdit.id,
              publicId: nextProps.stationEdit.publicId,
              hardwareId: nextProps.stationEdit.hardwareId,
              parking_id: nextProps.stationEdit.parking_id,
              status: nextProps.stationEdit.status
            };
          }
        }
      }
      this.setState({
        styleInput: false,
        alert: true,
        message: 'Station modifiée',
        typeAlert: 'alerts',
        nameAlert: 'Succès',
        publicId: '',
        styleAddStation: 'hide',
        hardwareId: ''
      });
      setTimeout(
        function() {
          this.setState({
            alert: false
          });
        }.bind(this),
        3000
      );
    }
    if (nextProps.stationPatch === true) {
      for (let i = 0; i < this.state.stations.length; i++) {
        if (this.state.stations[i]) {
          if (parseInt(this.state.stationId) === this.state.stations[i].id) {
            this.state.stations[i] = {
              id: this.state.stations[i].id,
              publicId: this.state.stations[i].publicId,
              hardwareId: this.state.stations[i].hardwareId,
              parking_id: this.state.stations[i].parking_id,
              status: parseInt(this.state.index)
            };
            this.setState({
              stations: this.state.stations
            });
          }
        }
      }

      this.setState({
        styleInput: false,
        alert: true,
        message: 'Station modifiée',
        typeAlert: 'alerts',
        nameAlert: 'Succès',
        publicId: '',
        styleAddStation: 'hide',
        hardwareId: ''
      });
      setTimeout(
        function() {
          this.setState({
            alert: false
          });
        }.bind(this),
        3000
      );
    }

    if (nextProps.add === false) {
      this.setState({
        styleInput: false,
        alert: true,
        message: 'Station non ajoutée',
        typeAlert: 'alerts alerts-warning',
        nameAlert: 'Warning',
        styleAddStation: 'hide',
        publicId: '',
        add: null,
        hardwareId: ''
      });
      setTimeout(() => {
        this.setState({ alert: false });
      }, 3000);
    }

    if (nextProps.stationExist === true) {
      this.setState({
        styleInput: false,
        alert: true,
        message: 'Une station portant cet identifiant existe déjà.',
        typeAlert: 'alerts alerts-warning',
        nameAlert: 'Warning',
        styleAddStation: 'hide',
        publicId: '',
        add: null,
        hardwareId: ''
      });
      setTimeout(() => {
        this.setState({
          alert: false,
          message: '',
          typeAlert: '',
          nameAlert: ''
        });
        this.props.fetchInitialState();
        this.props.fetchInitStationState();
      }, 3000);
      return false;
    }
  }

  handulSubmit() {
    const data = {
      hardwareId: this.state.hardwareId,
      publicId: this.state.publicId
    };

    if (this.state.typeAction === 'edit') {
      this.props.fetchEditStation(
        this.props.parkingid,
        this.state.stationId,
        data
      );
      this.setState({ typeAction: '' });
    } else {
      this.props.fetchAddStation(this.props.parkingid, data);
      this.props.fetchInitialState();
    }
  }

  handulOnClickAdd() {
    this.setState({
      styleAddStation: ''
    });
  }

  onClickSelect(e) {
    e.preventDefault();

    if (e.currentTarget.dataset.select === 'state') this.handleStateStation(e);
    else this.handlePowerStation(e);
  }

  handlePowerStation(e) {
    this.setState({
      styleSelect: 'select state-5 is-filled',
      power: e.currentTarget.dataset.value
    });

    let data = {
      power: e.currentTarget.dataset.value
    };
    this.props.fetchPatchStation(e.currentTarget.dataset.stationid, data);
    this.props.fetchInitialState();
  }

  handleStateStation(e) {
    if (parseInt(e.currentTarget.dataset.index) === 4) {
      this.setState({
        styleSelect: 'select state-5 is-filled',
        status: 4
      });
    }
    if (parseInt(e.currentTarget.dataset.index) === 3) {
      this.setState({
        styleSelect: 'select state-1 is-filled',
        status: 3
      });
    }
    if (parseInt(e.currentTarget.dataset.index) === 1) {
      this.setState({
        styleSelect: 'select state-3 is-filled',
        status: 1
      });
    }
    if (parseInt(e.currentTarget.dataset.index) === 5) {
      this.setState({
        styleSelect: 'select state-4 is-filled',
        status: 5
      });
    }
    if (parseInt(e.currentTarget.dataset.index) === 0) {
      this.setState({
        styleSelect: 'select state-2 is-filled',
        status: 0
      });
    }
    this.setState({
      index: e.currentTarget.dataset.index,
      stationId: e.currentTarget.dataset.stationid
    });
    if (e.currentTarget.dataset.index) {
      let data = {
        status: e.currentTarget.dataset.index
      };
      this.props.fetchPatchStation(e.currentTarget.dataset.stationid, data);
      this.props.fetchInitialState();
    }
  }

  handulDeleteStation(e) {
    e.preventDefault();
    if (e.currentTarget.dataset.type === 'delete') {
      this.setState({
        stationId: e.currentTarget.dataset.stationid,
        typeAction: 'delete'
      });
      this.props.fetchDeleteStation(e.currentTarget.dataset.stationid);
      this.props.fetchInitialState();
    }
    if (e.currentTarget.dataset.type === 'edit') {
      this.setState({
        stationId: e.currentTarget.dataset.stationid,
        styleInput: true,
        typeAction: 'edit',
        hardwareId: e.currentTarget.dataset.hardwareid,
        styleAddStation: '',
        publicId: e.currentTarget.dataset.publicid
      });
    }
  }

  handleChange(event) {
    const target = event.target;
    const { name, value } = target;

    this.setState({
      [name]: name === 'hardwareId' ? value.replace(/ /g, '') : value
    });
  }

  handleClickOutside(e) {
    window.$('.select').removeClass('is-opened');
    window.$('.options-panel').removeClass('is-opened');
  }

  render() {
    document.addEventListener('click', this.handleClickOutside, true);

    if (this.props.add) {
      handleSelectOpening();
      handleSelectIsFilled();
      OptionsPanel();
    }
    let stations = [];
    if (this.state.stations) {
      stations = this.state.stations;
    }

    const { userLicence } = this.state;
    const disabled = userLicence ? userLicence.remainingStations === 0 : false;

    return (
      <div id="view7" className="view">
        {this.state.alert ? (
          <Alert
            nameAlert={this.state.nameAlert}
            type={this.state.typeAlert}
            style={false}
            message={this.state.message}
          />
        ) : null}

        <div className="form-block">
          {!disabled ? (
            <a
              //style={{position: 'relative', marginTop: 15, marginRight: 27}}
              style={{ position: 'relative', margin: -10 }}
              onClick={this.handulOnClickAdd}
              href="#"
              className="icon-circle-add-small pull-right col-md-1"
            >
              <span />
              <span />
            </a>
          ) : null}

          <h3 className="form-block-title col-md-11">
            <FormattedMessage
              id="app.station"
              defaultMessage="Paramètres bornes"
            />
          </h3>
          <br />
          <br />
          <StationAdd
            styleAddStation={this.state.styleAddStation}
            method={this.handulSubmit}
            styleInput={this.state.styleInput}
            methodChange={this.handleChange}
            publicId={this.state.publicId}
            hardwareId={this.state.hardwareId}
          />
          {stations
            .filter(st => st.hardwareId && st.publicId)
            .map(station => (
              <Station
                onClickOption={this.props.onClickOption}
                styleSelect={this.state.styleSelect}
                statusSelect={this.state.status}
                powerSelect={this.state.power}
                key={station.id}
                id={station.id}
                hardwareId={station.hardwareId}
                parking_id={station.parking_id}
                status={station.status}
                publicId={station.publicId}
                power={station.power}
                onDelete={this.handulDeleteStation}
                onPatch={this.onClickSelect}
              />
            ))}
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    add: state.station.stationAdd,
    stationDelete: state.station.stationDelete,
    stationEdit: state.station.stationEdit,
    stationPatch: state.station.stationPatch,
    deleted: state.station.deleted,
    stationExist: state.station.stationExist,
    userLicence: state.userLicence.userLicence
  };
}

export default connect(
  mapStateToProps,
  {
    fetchAddStation,
    fetchInitialState,
    fetchInitStationState,
    fetchDeleteStation,
    fetchEditStation,
    fetchPatchStation,
    fetchGetUserLicence,
    fetchResetEditStationField
  }
)(Stations);
