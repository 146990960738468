import config from './../config/index';
import axios from 'axios';

const baseUrl = config.baseUrl;
export const FETCH_EDIT_USER = 'FETCH_EDIT_USER';
export const FETCH_GET_USER = 'FETCH_GET_USER';
export const FETCH_STATE_USER = 'FETCH_STATE_USER';
export const FETCH_DELETE_USER = 'FETCH_DELETE_USER';

export function fetchInitialState() {
  return dispatch => {
    dispatch({
      type: FETCH_STATE_USER,
      payload: true
    });
  };
}

export function fetchDeleteAccount(email) {
  const url = `${baseUrl}/api/user/delete-request`;
  return dispatch => {
    axios
      .post(url, { email: email })
      .then(result => {
        dispatch({
          type: FETCH_DELETE_USER,
          payload: true
        });
      })
      .catch(err => {
        dispatch({
          type: FETCH_DELETE_USER,
          payload: false
        });
      });
  };
}

export function fetchEditUser(id, data) {
  const url = `${baseUrl}/api/user/${id}?token=${localStorage.getItem(
    'token'
  )}`;

  return dispatch => {
    axios
      .patch(url, data['user'])
      .then(request => {
        if (request.data.length !== 0) {
          if (data['fileUser']) {
            const urlUploadFile = `${baseUrl}/api/upload/user/${id}/documents`;
            const formData = new FormData();
            formData.append('idFile', data['fileUser']);
            axios.post(urlUploadFile, formData, {
              headers: {
                'x-access-token': localStorage.getItem('token'),
                'content-type': 'multipart/form-data'
              }
            });
          }

          dispatch({
            type: FETCH_EDIT_USER,
            payload: true
          });
        }
      })
      .catch(() => {
        dispatch({
          type: FETCH_EDIT_USER,
          payload: false
        });
      });
  };
}

export function fetchGetUser(id) {
  const url = `${baseUrl}/api/user/${id}?token=${localStorage.getItem(
    'token'
  )}`;

  return dispatch => {
    axios
      .get(url)
      .then(request => {
        if (request.data.length !== 0) {
          dispatch({
            type: FETCH_GET_USER,
            payload: request.data
          });
        }
      })
      .catch(() => {
        dispatch({
          type: FETCH_GET_USER,
          payload: false
        });
      });
  };
}
