import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { MODAL_FULL_HOURS } from './modals';
import InputMask from 'react-input-mask';
import { connect } from 'react-redux';
import { hideModal } from '../../../actions/modalAction';
import { fetchClearMessage } from '../../../actions/hoursContractAction';
import moment from 'moment';

const hoursInputRegex = /^\d{2}?/;
const reg = /^\d+(\,\d{1,2})?$/;

class ModalFullHours extends Component {
  constructor(props) {
    super(props);
    this.defaultSlot = {
      slotId: null,
      inputHoursStart: '',
      inputHoursEnd: ''
    };

    this.state = {
      ...this.defaultSlot,
      formErrors: props.error,
      message: null
    };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleClose = this.handleClose.bind(this);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.slot !== this.props.slot) {
      this.props.fetchClearMessage();

      if (this.props.slot) {
        this.setState({
          slotId: this.props.slot.id,
          inputHoursStart: moment(this.props.slot.startHour, 'HH:mm:ss').format(
            'HH'
          ),
          inputHoursEnd: moment(this.props.slot.endHour, 'HH:mm:ss').format(
            'HH'
          )
        });
      } else {
        this.setState({ ...this.defaultSlot });
      }
    }

    if (prevProps.message !== this.props.message) {
      this.setState({
        message: this.props.message
      });
    }
  }

  handleSubmit() {
    if (!this.hoursHasError()) {
      this.props.handleSubmit({
        id: this.state.slotId,
        startHour: moment(this.state.inputHoursStart, 'HH').format('HH:mm:ss'),
        endHour: moment(this.state.inputHoursEnd, 'HH').format('HH:mm:ss')
      });
      this.setState({ ...this.defaultSlot });
    }
  }

  hoursHasError() {
    const { inputHoursStart, inputHoursEnd } = this.state;
    const condition =
      moment(inputHoursStart, 'HH:mm') > moment(inputHoursEnd, 'HH:mm');
    this.setState({
      showHoursError: condition
    });
    return condition;
  }

  handleDelete() {
    this.props.handleDelete(this.state.slotId);
    this.setState({ ...this.defaultSlot });
  }

  handleClose() {
    this.props.hideModal();
    this.setState({ ...this.defaultSlot });
  }

  handleChange(event) {
    this.setState({ message: null });

    const target = event.target;
    const name = target.name;
    const value = target.value;

    let formErrors = {
      inputHoursStart: '',
      inputHoursEnd: ''
    };

    switch (name) {
      case 'inputHoursStart':
        formErrors.inputHoursStart = reg.test(value) ? '' : 'Champ requis';
        break;
      case 'inputHoursEnd':
        formErrors.inputHoursEnd = reg.test(value) ? '' : 'Champ requis';
        break;
      default:
    }

    this.setState({
      [name]: value
    });
  }

  render() {
    if (this.props.type === MODAL_FULL_HOURS) {
      const enabled =
        hoursInputRegex.test(this.state.inputHoursStart) &&
        hoursInputRegex.test(this.state.inputHoursEnd) &&
        moment(this.state.inputHoursStart, 'HH') <
          moment(this.state.inputHoursEnd, 'HH');

      return (
        <div>
          <div className=".modal-layer-screen" />
          <div className="modal-full-hours" style={{ display: 'block' }}>
            <div className="modal-full-hours-title">
              <FormattedMessage
                id="app.parking-hours-contract-modal"
                defaultMessage="Créneau heure pleine"
              />
            </div>

            <a className="btn-close" onClick={this.handleClose}>
              <i className="icon-close" />
            </a>

            <div className="modal-full-hours-body">
              <div className="form-input form-input-hours">
                <InputMask
                  required
                  className="form-input-field"
                  type="text"
                  mask="99"
                  name="inputHoursStart"
                  id="inputHoursStart"
                  placeholder="__"
                  value={
                    this.state.inputHoursStart ? this.state.inputHoursStart : ''
                  }
                  onChange={this.handleChange}
                />
                <label className="form-input-label" htmlFor="inputHoursStart">
                  <span className="form-input-label-content" />
                </label>
              </div>
              <div className="form-input-separator">
                <div className="form-input-separator-text">à</div>
              </div>
              <div className="form-input form-input-hours">
                <InputMask
                  mask="99"
                  required
                  onChange={this.handleChange}
                  className="form-input-field"
                  type="text"
                  id="inputHoursEnd"
                  placeholder="__"
                  name="inputHoursEnd"
                  value={
                    this.state.inputHoursEnd ? this.state.inputHoursEnd : ''
                  }
                />
                <label className="form-input-label" htmlFor="inputHoursEnd">
                  <span className="form-input-label-content" />
                </label>
              </div>
              <p className="text-legend">
                <FormattedMessage
                  id="app.parking-hours-info-available"
                  defaultMessage="Une journée commence à 00h et se termine à 24h."
                />
              </p>
              {this.state.message && (
                <p className="text-legend negative">
                  <span className="icon-warning" />{' '}
                  <FormattedMessage
                    id={this.state.message}
                    defaultMessage="Une erreur est survenue"
                  />
                </p>
              )}
              <div className="modal-full-hours-actions">
                {this.state.slotId ? (
                  <button
                    onClick={this.handleDelete}
                    className="btn btn-delete"
                  >
                    <FormattedMessage
                      id="app.btnDelete"
                      defaultMessage="supprimer"
                    />
                  </button>
                ) : (
                  ''
                )}
                <button
                  disabled={!enabled}
                  type="submit"
                  className={
                    !enabled ? 'btn btn-primary btn-disable' : 'btn btn-primary'
                  }
                  onClick={() =>
                    this.handleSubmit({
                      id: this.state.slotId,
                      startHour: this.state.startHour,
                      endHour: this.state.endHour
                    })
                  }
                >
                  <FormattedMessage
                    id="app.btnCreateParks"
                    defaultMessage="valider"
                  />
                </button>
              </div>
            </div>
          </div>
        </div>
      );
    } else return null;
  }
}

function mapStateToProps(state) {
  return {
    message: state.hoursContract.message
  };
}

export default connect(mapStateToProps, { hideModal, fetchClearMessage })(
  ModalFullHours
);
