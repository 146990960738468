import React, { Component } from 'react';
import { menu } from '../../../utils';

class Menu extends Component {
  componentDidMount() {
    menu.init();
  }

  render() {
    return (
      <nav className="menu menu-tabs">
        <div className="menu-tabs-content">
          <a href="#" className="menu-item current">
            Chiffres clés
          </a>
          <a href="#" className="menu-item">
            Mes parkings
          </a>
        </div>
        <div className="menu-indicator"></div>
      </nav>
    );
  }
}

export default Menu;
