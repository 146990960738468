import React, {Component} from 'react';
import {connect} from 'react-redux';
import {
  fetchAddCalendar,
  fetchAddUserToList,
  fetchDeleteFromCalendar,
  fetchDeleteUserFromList,
  fetchGetList,
  fetchParkingCars,
  fetchUpdateList,
  fetchUpdateUser,
  fetchUserReminder,
  importUserCSV
} from '../../../../../actions/parkingAction';
import {FormattedMessage} from 'react-intl';
import EmailSearch from '../email-search';
import {goBack} from 'react-router-redux';
import Spinner from "../../../../../utils/Spinner";
import $ from 'jquery';
import {CSVLink} from 'react-csv';
import {
  hideModal,
  showCustomModal,
  showModal
} from '../../../../../actions/modalAction';
import {
  defaultPriceModalData,
  defaultPublicHours,
  valueToSet
} from '../../../../../utils';
import {
  fetchDeleteList,
  getCSVExample
} from '../../../../../actions/blackwhitelistAction';
import SchedulerPrice from '../../../../Layouts/Forms/scheduler-price';
import Modal from '../../../../Layouts/Modals/modal';
import Receipt from '../../../../Layouts/Forms/receipt';
import ListHeader from './list-header';
import PermissionList from './permission-list';
import MembersList from './members-list';
import SearchInput from '../search-input';
import ModalAddUser from '../../../../Layouts/Modals/modalAddUser';
import ModalDeleteList from '../../../../Layouts/Modals/modalDeleteList';
import ModalDeleteUser from '../../../../Layouts/Modals/modalDeleteUser';
import ModalConfirmCalendar from '../../../../Layouts/Modals/modalConfirmCalendar';
import {
  MODAL_ADD_USER,
  MODAL_CONFIRM_CALENDAR,
  MODAL_DELETE_LIST,
  MODAL_USER_DELETE
} from '../../../../Layouts/Modals/modals';
import Alert from '../../../../Layouts/Alert/alert';
import moment from 'moment';

class UserList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      page: 1,
      isLoading: false,
      list: {
        name: '',
        members: [],
        listType: 'white',
        hours: defaultPublicHours
      },
      csv: {
        data: [],
        title: ''
      },
      email: '',
      startDate: '',
      endDate: '',
      search: '',
      searchResult: [],
      listExisting: '',
      lastScheduler: null,
      userEditing: null,
      disableBtn: false,
      alert: false,
      message: '',
      nameAlert: '',
      onSave: false
    };

    this.smallSearch = React.createRef();
    this.longSearch = React.createRef();
    this.csvLink = React.createRef();

    this.handleChange = this.handleChange.bind(this);
    this.UserInputChange = this.UserInputChange.bind(this);
    this.addUserToList = this.addUserToList.bind(this);
    this.handleToggle = this.handleToggle.bind(this);
    this.saveList = this.saveList.bind(this);
    this.deleteList = this.deleteList.bind(this);
    this.showDeleteModal = this.showDeleteModal.bind(this);
    this.showPriceModal = this.showPriceModal.bind(this);
    this.calendarSubmit = this.calendarSubmit.bind(this);
    this.calendarDelete = this.calendarDelete.bind(this);
    this.showCalendarConfirmModal = this.showCalendarConfirmModal.bind(this);
    this.deleteUser = this.deleteUser.bind(this);
    this.updateUser = this.updateUser.bind(this);
  }

  componentDidMount() {
    const listId = parseInt(this.props.match.params.listId);
    this.props.fetchGetList(listId);
  }

  componentWillReceiveProps(nextProps) {
    const {email, list, page} = this.state;

    if (this.state.onSave && nextProps) {
      this.setState({
        alert: true,
        message: 'Liste enregistrée',
        typeAlert: 'alerts',
        nameAlert: 'Succès',
        onSave: false
      });

      setTimeout(
        function () {
          this.setState({
            alert: false
          });
        }.bind(this),
        3000
      );
    }

    if (list !== nextProps.list) {
      this.props.hideModal();

      this.setState({
        list: nextProps.list,
        isLoading: true,
        disableBtn: false,
        privilege: nextProps.list.privilege
      });

      if (email !== '' && nextProps.exist)
        this.props.showCustomModal(MODAL_ADD_USER);
      else
        this.setState({
          email: '',
          startDate: '',
          endDate: ''
        });
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    // CSV download
    const oldCSV = prevProps.listCSV;
    const newCSV = this.props.listCSV;
    if (oldCSV !== newCSV) {
      this.updateCSV(newCSV);
    }
  }

  // USER LIT

  addEditingUser(user) {
    this.setState({
      userEditing: user,
      email: user.email,
      startDate: user.start_date,
      endDate: user.end_date,
      edit: true
    });
  }

  updateUser() {
    const {userEditing, email, startDate, endDate} = this.state;
    this.props.fetchUpdateUser(this.props.match.params.listId, userEditing.id, {
      email,
      startDate: moment(startDate).format('YYYY-MM-DD'),
      endDate: moment(endDate).format('YYYY-MM-DD')
    });
    this.setState({
      edit: false
    });
  }

  deleteUser() {
    this.props.fetchDeleteUserFromList(
      this.props.match.params.listId,
      this.state.deleteUserId
    );
  }

  confirmDeleteUser(id) {
    this.setState({
      deleteUserId: id
    });
    this.props.showCustomModal(MODAL_USER_DELETE);
  }

  relaunchUser(member) {
    const {
      match: {params},
      list: {parking_id}
    } = this.props;
    this.props.fetchUserReminder(parking_id, params.listId, member);
  }

  importUser(e) {
    const file = e.target.files[0];

    const formData = new FormData();
    formData.append('csv', file);

    this.props.importUserCSV(this.props.match.params.listId, formData);

    e.target.value = '';
  }

  // USEFUL

  getTitle() {
    if (this.state.list.listType === 'black')
      return (
        <FormattedMessage
          id="app.user-unauthorized"
          defaultMessage="Liste non autorisée"
        />
      );
    else
      return (
        <FormattedMessage
          id="app.user-list"
          defaultMessage="Liste utilisateur"
        />
      );
  }

  handleChange(event) {
    const {name, value, title} = event.target;

    if (title) this.setState(valueToSet(this.state, event));
    else
      this.setState(
        {
          [name]: value
        },
        () => {
          if (name === 'search' && value.length >= 4)
            this.longSearch.current.focus();
          else if (name === 'search' && value.length < 4 && value.length > 0)
            this.smallSearch.current.focus();
        }
      );
  }

  UserInputChange(e) {
    const {email, startDate, endDate} = e.target.value;
    this.setState({
      email,
      startDate,
      endDate
    });
  }

  // BUTTON ACTION

  onClickOption(id) {
    $('#option-' + id).on('click', function () {
      let target = $(this).next('#div-option-' + id);
      if (target.hasClass('is-opened')) {
        target.removeClass('is-opened');
      } else {
        target.addClass('is-opened');
      }
    });
  }

  handleToggle(e) {
    const toggledValue = e.target.value === 'close' ? 1 : 0;

    this.setState({
      list: {
        ...this.state.list,
        privilege: toggledValue
      }
    });
  }

  showDeleteModal() {
    this.props.showCustomModal(MODAL_DELETE_LIST);
  }

  showPriceModal(e) {
    this.props.showModal(defaultPriceModalData(e));
  }

  showCalendarConfirmModal(value) {
    this.setState(
      {
        listExisting: value
      },
      () => {
        this.props.showCustomModal(MODAL_CONFIRM_CALENDAR);
      }
    );
  }

  calendarSubmit(props, force) {
    const data = {
      startDate: props.inputHoursStart,
      endDate: props.inputHoursEnd,
      price: props.inputHoursPrice.value,
      id: props.slotId,
      day: props.day
    };

    this.setState(
      {
        lastScheduler: props
      },
      () => {
        this.props.fetchAddCalendar(this.props.match.params.listId, {
          ...data,
          force: force
        });
      }
    );
  }

  calendarDelete(props) {
    this.props.fetchDeleteFromCalendar(
      this.props.match.params.listId,
      props.slotId
    );
  }

  // LIST CRUD

  deleteList() {
    const {
      match: {params},
      list: {parking_id}
    } = this.props;

    this.props.fetchDeleteList(parking_id, params.listId);
    this.props.goBack();
  }

  saveList() {
    const {
      match: {params},
      list: {parking_id}
    } = this.props;

    this.setState({onSave: true});

    this.props.fetchUpdateList(parking_id, params.listId, this.state.list);
  }

  addUserToList(force) {
    const {
      match: {params},
      list: {parking_id}
    } = this.props;

    const validRangeDate = this.state.startDate
      ? this.state.startDate && this.state.endDate
      : true;

    if (this.state.email && validRangeDate) {
      this.setState({disableBtn: true});
      this.props.fetchAddUserToList(params.listId, parking_id, {
        email: this.state.email,
        startDate: !!this.state.startDate
          ? moment(this.state.startDate).format('YYYY-MM-DD')
          : null,
        endDate: !!this.state.endDate
          ? moment(this.state.endDate).format('YYYY-MM-DD')
          : null,
        force: force
      });

      this.props.fetchParkingCars(parking_id);
    }
  }

  // CSV

  downloadCSV() {
    this.props.getCSVExample();
  }

  // Update report
  updateCSV(file) {
    const {content, title} = file;
    this.setState(
      {
        csv: {
          data: content,
          title: title
        }
      },
      () => {
        if (this.csvLink && this.csvLink.current && this.csvLink.current.link) {
          setTimeout(() => {
            this.csvLink.current.link.dataset.interception = 'off';
            this.csvLink.current.link.click();
          });
        }
      }
    );
  }

  render() {
    const {
      list: {name, members, listType, privilege, hours},
      search,
      searchResult,
      isLoading,
      email,
      startDate,
      endDate,
      page,
      edit,
      deleteUserId,
      listExisting,
      lastScheduler,
      csv: {data, headers, title},
      disableBtn
    } = this.state;

    const {exist, modal} = this.props;

    const receiptLegend = (
      <>
        <FormattedMessage
          id="app.accepted-format-csv"
          defaultMessage="Le format .csv sur une colonne est accepté."
        />{' '}
        <span className="text-link" onClick={() => this.downloadCSV()}>
          import_utilisateurs.csv
        </span>
        <i className="icon-download-small"/>
      </>
    );

    const searchData = {
      value: search,
      data: [...[].concat.apply([], members)],
      allResult: searchResult,
      handleChange: this.handleChange,
      edit: edit,
      email: this.state.email,
      onAdd: () => this.addUserToList(false),
      onUpdate: () => this.updateUser(),
      onEdit: member => this.addEditingUser(member),
      onDelete: member => this.confirmDeleteUser(member.id),
      onRelaunch: member => this.relaunchUser(member)
    };

    return (
      !isLoading ? <Spinner/> :
        <div
          className={`main-container users-list ${
            listType === 'black' ? 'users-list-warning' : ''
          }`}
        >
          <ModalDeleteList
            handleConfirm={() => this.deleteList()}
            type={modal.type}
          />
          <ModalConfirmCalendar
            listExisting={listExisting}
            handleConfirm={() => this.calendarSubmit(lastScheduler, true)}
            type={modal.type}
          />
          <CSVLink
            data={data}
            filename={'import_utilisateurs.csv'}
            className="hidden"
            ref={this.csvLink}
            separator={';'}
            target="_blank"
          />

          <ListHeader
            goBack={() => this.props.history.push(`/parking/details/${this.props.list.parking_id}`)}
            title={this.getTitle()}
          />

          <div className="container">
            <div className="row">
              <div className="col-xs-12">
                <h2 className="title-main">{name}</h2>
              </div>
            </div>

            {this.state.alert ? (
              <Alert
                nameAlert={this.state.nameAlert}
                type={this.state.typeAlert}
                style={false}
                message={this.state.message}
              />
            ) : null}

            <PermissionList
              listType={listType}
              value={name}
              privilege={privilege}
              handleChange={this.handleChange}
              handleToggle={this.handleToggle}
              onDelete={this.showDeleteModal}
              onSave={this.saveList}
            />

            <div className="col-xs-12 col-sm-8">
              {listType === 'white' && (
                <div className="form-block form-block-scheduler">
                  <h3 className="form-block-title">
                    <FormattedMessage
                      id="app.dispo-hebdo"
                      defaultMessage="Mise en disponibilité hebdomadaire"
                    />
                  </h3>

                  <SchedulerPrice
                    daysSlots={hours}
                    handleMethod={this.showPriceModal}
                    showConfirmModal={this.showCalendarConfirmModal}
                  />

                  <Modal
                    handleSubmit={this.calendarSubmit}
                    handleDelete={this.calendarDelete}
                  />
                </div>
              )}

              <div className="form-block">
                <Receipt
                  className={0}
                  label={this.getTitle()}
                  fileName={
                    <FormattedMessage
                      id="app.import-email"
                      defaultMessage="Importer vos adresses e-mail"
                    />
                  }
                  acceptedFiles={'.csv'}
                  legend={receiptLegend}
                  method={e => this.importUser(e)}
                />

                <ModalAddUser
                  handleConfirm={() => this.addUserToList(true)}
                  name={exist ? exist.name : ''}
                  type={modal.type}
                />
                <ModalDeleteUser
                  handleConfirm={() => this.deleteUser(deleteUserId)}
                  type={modal.type}
                />

                {search.length < 4 ? (
                  <>
                    <div className="form-block-section-title">
                      <h3 className="form-block-title">
                        <FormattedMessage
                          id="app.add-user"
                          defaultMessage="Ajouter un client"
                        />
                      </h3>

                      <SearchInput
                        {...searchData}
                        inputRef={this.smallSearch}
                      />
                    </div>

                    <MembersList
                      add
                      members={members}
                      actualPage={page}
                      handleClick={null}
                      email={email}
                      startDate={startDate}
                      endDate={endDate}
                      listType={listType}
                      handleChange={this.UserInputChange}
                      edit={edit}
                      onUpdate={() => this.updateUser()}
                      onAdd={() => this.addUserToList(false)}
                      onEdit={member => this.addEditingUser(member)}
                      onDelete={member => this.confirmDeleteUser(member.id)}
                      onRelaunch={member => this.relaunchUser(member)}
                      disabled={disableBtn}
                    />
                  </>
                ) : (
                  <EmailSearch {...searchData} inputRef={this.longSearch}/>
                )}
              </div>
            </div>
          </div>
        </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    list: state.parkings.list,
    publicHours: state.parkings.publicHours,
    modal: state.modal,
    listCSV: state.blackwhitelist.listCSV,
    receipt: state.parkings.receipt,
    exist: state.parkings.exist,
    loading:
      state.parkings.cars.deletion.pending ||
      state.parkings.cars.pending ||
      state.parkings.cars.add.pending
  };
}

export default connect(
  mapStateToProps,
  {
    goBack,
    hideModal,
    fetchGetList,
    fetchAddUserToList,
    fetchParkingCars,
    fetchUpdateList,
    showCustomModal,
    fetchDeleteList,
    showModal,
    fetchAddCalendar,
    fetchDeleteFromCalendar,
    fetchDeleteUserFromList,
    fetchUpdateUser,
    importUserCSV,
    fetchUserReminder,
    getCSVExample
  }
)(UserList);
