import React, { Component } from 'react';
import Term from './../Layouts/Forms/terms';
import terms from '../../content/terms';
import { DocumentTitle } from '../../utils';

class Terms extends Component {
  constructor(props) {
    super(props);
    this.terms = terms;
  }

  render() {
    return (
      <div className="main-container cgv">
        {DocumentTitle(
          'app.title-terms',
          'WATTPARK - Conditions générales de vente'
        )}
        <div className="container">
          <div className="row">
            <div className="col-xs-12 col-sm-8 col-sm-offset-2">
              {this.terms.map((term, idx) => (
                <Term key={idx} term={term} />
              ))}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Terms;
