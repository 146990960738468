import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import InputRange from '../../../components/Inputs/InputRange';
import moment from 'moment';
import SchedulerEnergy from './scheduler-energy';

class HoursContractMode extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const {
      hoursContract: { activated, fullHourCharge, slots },
      handleContractMode,
      handleContractFullCharge,
      handleShowModal
    } = this.props;

    const defaultLegend =
      "Si votre contart d'électricité implique une tarification heures pleines et heures creuses, vuillez l'indiquer et préciser les horaires." +
      ' Le cas échéant, une puissance variable peut être activée';

    const sortedSlots = slots.sort((a, b) =>
      moment(a.startHour, 'HH:mm:ss').diff(moment(b.startHour, 'HH:mm:ss'))
    );

    const offPeakHours = () => {
      let start = moment('00:00:00', 'HH:mm:ss');

      const opSlots = [];

      sortedSlots.forEach((slot, index) => {
        const startSlot = moment(slot.startHour, 'HH:mm:ss');
        const endSlot = moment(slot.endHour, 'HH:mm:ss');

        if (startSlot.isSame(start)) start = endSlot;
        else if (startSlot.isAfter(start)) {
          opSlots.push({
            startHour: start.format('HH:mm:ss'),
            endHour: startSlot.format('HH:mm:ss')
          });
          start = endSlot;
        }

        if (index === sortedSlots.length - 1) {
          if (
            moment(endSlot.format('HH:mm:ss'), 'HH:mm:ss').diff(
              moment('00:00:00', 'HH:mm:ss')
            )
          )
            opSlots.push({
              startHour: endSlot.format('HH:mm:ss'),
              endHour: '00:00:00'
            });
        }
      });

      if (opSlots.length === 0 && sortedSlots.length === 0) {
        opSlots.push({
          startHour: '00:00:00',
          endHour: '00:00:00'
        });
      }

      return opSlots;
    };

    return (
      <div className="form-block">
        <h3 className="form-block-title">
          <FormattedMessage
            id="app.parking-hours"
            defaultMessage="Heures pleines et heures creuses"
          />
        </h3>
        <p className="text-legend">
          <FormattedMessage
            id="app.parking-hours-legend"
            defaultMessage={defaultLegend}
          />
        </p>
        <div className="options">
          <div className="options-title">
            <FormattedMessage
              id="app.parking-hours-contract-mode"
              defaultMessage="Contart heures pleines et heures creuses"
            />
          </div>
          <div className="toggle-wrapper">
            <div className="toggle toggle-highlighted">
              <input
                className="toggle-input"
                value={activated}
                checked={activated}
                onChange={handleContractMode}
                id="toggle-input-hours"
                type="checkbox"
              />
              <span className="toggle-label">
                {activated ? (
                  <FormattedMessage
                    id="app.parking-hours-contract-mode-activated"
                    defaultMessage="Oui"
                  />
                ) : (
                  <FormattedMessage
                    id="app.parking-hours-contract-mode-deactivated"
                    defaultMessage="Non"
                  />
                )}
              </span>
              <label className="toggle-control" htmlFor="toggle-input-hours" />
            </div>
          </div>
        </div>
        {activated ? (
          <div className="form-block-content">
            <h3 className="form-block-title">
              <FormattedMessage
                id="app.parking-hours-contract-percents"
                defaultMessage="Pourcentage maximal de puissance généré en heures pleines"
              />
            </h3>
            <InputRange
              handleChange={handleContractFullCharge}
              value={fullHourCharge}
              min={0}
              max={100}
              step={1}
            />
            <h3 className="form-block-title">
              <FormattedMessage
                id="app.parking-hours-contract-schedule"
                defaultMessage="Horaires"
              />
            </h3>
            <p className="text-legend">
              <FormattedMessage
                id="app.parking-hours-contract-schedule-legend"
                defaultMessage="Ajustez les horaires en glissant les plages pour les faire
                            correspondre aux détails de votre contrat :"
              />
            </p>

            <SchedulerEnergy
              slots={sortedSlots}
              offPeakSlots={offPeakHours()}
              handleMethod={handleShowModal}
              handleDelete={handleShowModal}
              handleSubmit={handleShowModal}
            />

            <div className="scheduler-details">
              <h4 className="scheduler-details-title">
                Détail des plages horaires
              </h4>
              <div className="scheduler-details-content">
                <div className="scheduler-details-listing">
                  <h5 className="scheduler-details-listing-title">
                    Heures creuses
                  </h5>
                  <ul className="scheduler-details-list">
                    {offPeakHours().map((slot, index) => {
                      const start = moment(slot.startHour, 'HH:mm:ss').format(
                        'HH:mm'
                      );
                      const end = moment(slot.endHour, 'HH:mm:ss').format(
                        'HH:mm'
                      );

                      return (
                        <li
                          key={index}
                          className="scheduler-details-list-item"
                        >{`${start} - ${end}`}</li>
                      );
                    })}
                  </ul>
                </div>
                <div className="scheduler-details-listing">
                  <h5 className="scheduler-details-listing-title">
                    Heures pleines
                  </h5>
                  <ul className="scheduler-details-list">
                    {sortedSlots.map((slot, index) => {
                      const start = moment(slot.startHour, 'HH:mm:ss').format(
                        'HH:mm'
                      );
                      const end = moment(slot.endHour, 'HH:mm:ss').format(
                        'HH:mm'
                      );

                      return (
                        <li
                          key={index}
                          className="scheduler-details-list-item"
                        >{`${start} - ${end}`}</li>
                      );
                    })}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        ) : null}
      </div>
    );
  }
}

function mapStateToProps(state) {}

export default HoursContractMode;
