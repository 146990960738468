import { FormattedMessage } from 'react-intl';
import ListElement from './element';
import React from 'react';
import TextField from '../../../Inputs/TextField';

const WhiteList = ({
  whiteList,
  total,
  value,
  handleChange,
  addList,
  goToList,
  showAdd,
  adding
}) => {
  const addToList = e => {
    if (value === '') return null;
    else {
      addList(e);
    }
  };

  if (whiteList)
    return (
      <div className="form-block">
        <a className="icon-circle-add-small" onClick={showAdd}>
          <span></span>
          <span></span>
        </a>

        <h3 className="form-block-title">
          <FormattedMessage
            id="app.user-list"
            defaultMessage="Liste utilisateurs"
          />
        </h3>

        <div className="form-block-info">
          <span className="form-block-info-number">{total}</span>
          <span className="form-block-info-label">
            <FormattedMessage id="app.list" defaultMessage="liste(s)" />
          </span>
        </div>

        {adding && (
          <div className="users-list-item">
            <div className="customer-content customer-edit">
              <TextField
                label={
                  <FormattedMessage
                    id="app.list-title"
                    defaultMessage="Nom de la liste"
                  />
                }
                id="listName"
                name="listName"
                value={value}
                handleChange={handleChange}
                required
              />
              <button
                onClick={addToList}
                className={`btn btn-primary btn-small ${
                  value === '' ? 'btn-disable' : ''
                }`}
              >
                <FormattedMessage id="app.create" defaultMessage="Créer" />
              </button>
            </div>
          </div>
        )}

        {whiteList.content.map((list, index) => (
          <ListElement
            key={index}
            list={list}
            handleClick={() => goToList(list.id)}
          />
        ))}
      </div>
    );
  else return null;
};

export default WhiteList;
