import React, { Component } from 'react';
import Matriculation from './../../../Layouts/Forms/matriculation';
import {
  fetchDeleteBooking,
  fetchInitialStateBooking
} from '../../../../actions/parkingAction';
import { connect } from 'react-redux';
import Alert from './../../../Layouts/Alert/alert';
import { FormattedMessage } from 'react-intl';

class Booking extends Component {
  constructor(props) {
    super(props);
    let bookings = [];
    if (this.props.bookings) bookings = this.props.bookings;
    this.state = {
      bookings: bookings,
      bookingId: '',
      alert: false,
      display: 'none',
      message: '',
      nameAlert: '',
      typeAlert: ''
    };
    this.handulDeleteBooking = this.handulDeleteBooking.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.deleteBooking === true) {
      for (let i = 0; i < this.state.bookings.length; i++) {
        if (this.state.bookings[i] !== undefined) {
          if (
            parseInt(this.state.bookings[i].id) ===
            parseInt(this.state.bookingId)
          ) {
            delete this.state.bookings[i];
          }
        }
      }
      this.setState({
        alert: true,
        message: 'Réservation Annulée',
        typeAlert: 'alerts',
        nameAlert: 'Succès'
      });
      setTimeout(
        function() {
          this.setState({
            alert: false
          });
        }.bind(this),
        3000
      );
    }
  }

  handulDeleteBooking(e) {
    e.preventDefault();

    this.setState({
      bookingId: e.currentTarget.dataset.bookingid
    });
    this.props.fetchDeleteBooking(
      e.currentTarget.dataset.bookingid,
      e.currentTarget.dataset.userid
    );
    this.props.fetchInitialStateBooking();
  }

  render() {
    let bookings = [];
    if (this.state.bookings) {
      bookings = this.state.bookings;
    }
    return (
      <div id="view5" className="view">
        {this.state.alert ? (
          <Alert
            nameAlert={this.state.nameAlert}
            type={this.state.typeAlert}
            style={false}
            message={this.state.message}
          />
        ) : null}
        <div className="form-block">
          <h3 className="form-block-title">
            <FormattedMessage
              id="app.booking-title"
              defaultMessage="réservations pour ce parking"
            />
          </h3>
          {bookings.map(booking => (
            <Matriculation
              onClick={this.onClick}
              handulDeleteBooking={this.handulDeleteBooking}
              booking={booking}
              key={booking.id}
              bookingId={booking.id}
            />
          ))}
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    deleteBooking: state.parkings.deleteBooking
  };
}

export default connect(
  mapStateToProps,
  {
    fetchDeleteBooking,
    fetchInitialStateBooking
  }
)(Booking);
