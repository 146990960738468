import React, {useEffect, useState} from 'react';
import Spinner from "../../utils/Spinner";
import {connect} from 'react-redux';
import {FormattedMessage} from 'react-intl';
import {Link} from 'react-router-dom';

import {loadCertificates, fetchUpdatetFile} from '../../actions/filesAction';
import * as ROUTES from '../../constants/routes';
import CertificationChecking from '../Layouts/Forms/certification-checking';
import Search from '../Layouts/Header/search';
import Menu from './menu';
import {
  setInputBehaviour, handleSearchBanner, listParking, DocumentTitle, nav
} from '../../utils';

function CertificationValidation(props) {
  const [inputSearch, setInputSearch] = useState('');
  useEffect(() => {
    props.fetchData();
    nav.init();
  }, []);

  const logout = () => {
    localStorage.clear();
    window.location.href = ROUTES.LOGIN;
  };

  const UpdateFile = e => {
    e.preventDefault();
    let data = {status: e.currentTarget.dataset.status};
    props.fetchUpdatetFile(e.currentTarget.dataset.fileid, data);
  };

  const handleChange = event => {
    const target = event.target;
    const {value} = target;
    setInputSearch(value);
  };

  const {parkingsCertificate, parkingsWithoutCertificates} = props;
  setInputBehaviour();
  listParking();
  handleSearchBanner();
  return (<div className="main-container certification certification-validation">
      {DocumentTitle('app.title-certificat-validation', 'WATTPARK - Administration des certificats')}
      <header className="header">
        <div className="header-left">
          <div className="logo-secondary">
            <Link to={ROUTES.DASHBOARD_PARKINGS}>
              <img
                src="/img/logo_no_text.svg"
                alt="Logo sans texte Wattpark"
              ></img>
            </Link>
          </div>
          <Link className="link" to={ROUTES.DASHBOARD_PARKINGS}>
            <i className="icon-arrow-left"/>
            <span className="link-text">
              <FormattedMessage id="app.return" defaultMessage="Retour"/>
            </span>
          </Link>
          <nav className="nav-main">
            <Link className="nav-main-item" to={ROUTES.DASHBOARD_PARKINGS}>
              Dashboard
            </Link>
            <Link className="nav-main-item" to={ROUTES.ACCOUNT}>
              Compte
            </Link>
            <Link
              className="nav-main-item current"
              to={ROUTES.CERTIFICATIONVALIDATION}
            >
              Certificats
            </Link>
            <div className="nav-main-user">
              <i className="icon-user"/>
              <div className="nav-main-user-name">
                {localStorage.getItem('username')}
              </div>
              <Link to="" className="nav-main-user-connexion" onClick={logout}>
                <FormattedMessage
                  id="app.navLogout"
                  defaultMessage="Déconnexion"
                />
              </Link>
            </div>
          </nav>
        </div>
        <div className="header-center">
          <h2 className="header-title">
            <FormattedMessage
              id="app.valid-certificat"
              defaultMessage="Validation des certificats IRVE"
            />
          </h2>
        </div>
        <div className="header-right">
          <h2 className="nav-trigger-title">Certification</h2>
          <div className="nav-trigger">
            <span className="nav-trigger-line"></span>
            <span className="nav-trigger-line"></span>
            <span className="nav-trigger-line"></span>
          </div>
        </div>
      </header>
      <div className="screen-filter"></div>
      <Search inputSearch={inputSearch} handleChange={handleChange}/>
      <div className="container">
        {parkingsCertificate.loading && parkingsWithoutCertificates.loading ? <Spinner/> : <div className="row">
          <div className="col-xs-12 col-md-4">
            <Menu
              awaitingFile={parkingsWithoutCertificates.data.length}
              filePending={parkingsCertificate.data.filter(item => item.status === 'pending').length}
              fileApproved={parkingsCertificate.data.filter(item => item.status === 'approved').length}
              fileRejected={parkingsCertificate.data.filter(item => item.status === 'rejected').length}
            />
          </div>
          <div className="col-xs-12 col-md-8">
            <CertificationChecking
              emptyParkings={parkingsWithoutCertificates.data}
              files={parkingsCertificate.data}
              UpdateFile={UpdateFile}
            />
          </div>
        </div>}
      </div>
    </div>);
}

function mapStateToProps({files}) {
  return {
    parkingsCertificate: files.parkingsCertificate, parkingsWithoutCertificates: files.parkingsWithoutCertificates
  };
}

function mapDispatchToProps(dispatch, ownProps) {
  return {
    fetchData: () => {
      dispatch(loadCertificates());
    }, fetchUpdatetFile
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(CertificationValidation);
