import React, { Component } from 'react';
import _ from 'lodash';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';

class TableParking extends Component {
  constructor(props) {
    super(props);
    this.state = { thumb: '' };
  }

  renderParking() {
    let parkingfree = [];
    let parkingoccupy = [];
    let parkinginfo = [];
    if (this.props.parkingfree) parkingfree = this.props.parkingfree.st;
    if (this.props.parkingoccupy) parkingoccupy = this.props.parkingoccupy;
    if (this.props.parkinginfo) parkinginfo = this.props.parkinginfo;

    if (this.props.parking) {
      return _.map(this.props.parking, parking => {
        const ModeParking = () => {
          if (parkinginfo.length !== 0) {
            for (let i = 0; i < parkinginfo.length; i++) {
              if (parking.id === parkinginfo[i].id) {
                if (
                  parkinginfo[i].station === false ||
                  parkinginfo[i].certif === false ||
                  parkinginfo[i].addr === false
                ) {
                  return (
                    <div key={parking.id}>
                      <span className="icon-warning"> </span>
                      <FormattedMessage
                        id="app.incomplete-form"
                        defaultMessage="Fiche incomplete"
                      />
                    </div>
                  );
                } else {
                  if (parking.status === 'available') {
                    return (
                      <div key={parking.id}>
                        <span className="icon-circle-public"> </span>
                        <FormattedMessage
                          id="app.public"
                          defaultMessage="Publique"
                        />
                      </div>
                    );
                  } else if (parking.status === 'close') {
                    return (
                      <div key={parking.id}>
                        <span className="icon-circle-close"> </span>
                        <FormattedMessage
                          id="app.close"
                          defaultMessage="Fermé"
                        />
                      </div>
                    );
                  } else if (parking.status === 'private') {
                    return (
                      <div key={parking.id}>
                        <span className="icon-circle-private"> </span>
                        <FormattedMessage
                          id="app.private"
                          defaultMessage="Privé"
                        />
                      </div>
                    );
                  } else if (parking.status === 'coming soon') {
                    return (
                      <div key={parking.id}>
                        <span className="icon-circle-private"> </span>
                        <FormattedMessage
                          id="app.coming_soon"
                          defaultMessage="Arrive Bientôt"
                        />
                      </div>
                    );
                  }
                }
              }
            }
          } else {
            return (
              <div>
                <span className="icon-warning"> </span>
                <FormattedMessage
                  id="app.incomplete-form"
                  defaultMessage="Fiche incomplete"
                />
              </div>
            );
          }
        };

        const DispoParking = () => {
          for (let i = 0; i < parkinginfo.length; i++) {
            if (parking.id === parkinginfo[i].id) {
              if (
                parkinginfo[i].station === false ||
                parkinginfo[i].certif === false ||
                parkinginfo[i].addr === false
              ) {
                return <div className="parking-item-availability">-</div>;
              } else {
                if (parkinginfo || parkingoccupy || parkingfree) {
                  if (
                    parkinginfo.length !== 0 ||
                    parkingoccupy.length !== 0 ||
                    parkingfree.length !== 0
                  ) {
                    if (parking.stations.length > 0) {
                      return (
                        <div className="parking-item-availability">
                          {`${
                            parking.stations.filter(st => st.status === 0)
                              .length
                          }/${parking.stations.length}`}
                          &nbsp;{' '}
                          <FormattedMessage
                            id="app.occupied"
                            defaultMessage="occupées"
                          />
                        </div>
                      );
                    } else if (
                      parking.station.stationDispo !== null &&
                      parking.station.stationDispo === 0 &&
                      parking.status !== 'close'
                    ) {
                      return (
                        <div className="parking-item-availability">
                          <FormattedMessage
                            id="app.free"
                            defaultMessage="Libre"
                          />
                        </div>
                      );
                    } else if (parking.station.stationDispo === 'occupé') {
                      return (
                        <div className="parking-item-availability">
                          <FormattedMessage
                            id="app.occupied"
                            defaultMessage="occupées"
                          />
                        </div>
                      );
                    } else if (
                      parking.station.stationDispo === null ||
                      parking.status === 'close'
                    ) {
                      return <div className="parking-item-availability">-</div>;
                    } else {
                      return (
                        <div className="parking-item-availability">
                          <FormattedMessage
                            id="app.free"
                            defaultMessage="Libre"
                          />
                        </div>
                      );
                    }
                  }
                }
              }
            }
          }
        };

        const typeParking = () => {
          for (let i = 0; i < parkinginfo.length; i++) {
            if (parking.id === parkinginfo[i].id) {
              if (
                parkinginfo[i].station === false ||
                parkinginfo[i].certif === false ||
                parkinginfo[i].addr === false
              ) {
                return (
                  <div className="parking-item-action warning">
                    <FormattedMessage
                      id="app.complete"
                      defaultMessage="compléter"
                    />
                  </div>
                );
              } else {
                if (parkinginfo.length !== 0) {
                  if (
                    parking.status === 'available' ||
                    parking.status === 'private' ||
                    parking.status === 'coming soon' ||
                    parking.status === 'close' ||
                    parking.station.stationDispo === '3/4' ||
                    parking.station.stationDispo === '1/4'
                  ) {
                    return (
                      <div className="parking-item-action">
                        <FormattedMessage
                          id="app.manage"
                          defaultMessage="Gérer"
                        />
                      </div>
                    );
                  }
                } else {
                  return (
                    <div className="parking-item-action warning">
                      <FormattedMessage
                        id="app.complete"
                        defaultMessage="compléter"
                      />
                    </div>
                  );
                }
              }
            }
          }
        };
        return (
          <Link key={parking.id} to={`/parking/details/${parking.id}`}>
            <div className="parking-item">
              <div className="parking-item-info-wrapper">
                <div className="parking-item-img">
                  <img
                    src={
                      parking.thumbnail
                        ? parking.thumbnail
                        : '/img/parkingDefault.jpg'
                    }
                    alt="img Parking"
                  />
                </div>
                <div className="parking-item-info">
                  <div className="parking-item-name">{parking.name}</div>
                  <div className="parking-item-address">
                    {parking.address ? parking.address.street : ''}
                  </div>
                </div>
              </div>
              <div className="parking-item-mode-wrapper">
                <div className="parking-item-mode">{ModeParking()}</div>
              </div>
              <div className="parking-item-availability-wrapper">
                {DispoParking()}
              </div>
              <div className="parking-item-action-wrapper">{typeParking()}</div>
            </div>
          </Link>
        );
      });
    }
  }

  render() {
    return <div>{this.renderParking()}</div>;
  }
}

export default TableParking;
