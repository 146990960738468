import React, { Component } from 'react';
import Mode from './../../../Layouts/Forms/mode';
import Request from './../../../Layouts/Forms/request';
import { FormattedMessage } from 'react-intl';

class General extends Component {
  constructor(props) {
    super(props);
    this.state = {
      type: true,
      alert: false
    };
  }

  render() {
    let solde = '0';
    if (this.props.solde.total) {
      solde = this.props.solde.total.toString();
    }

    const pourcent = () => {
      if (this.props.solde.pourcent) {
        if (this.props.solde.pourcent > 0) {
          return (
            <div className="evolution positive">
              <i className="icon-evol-pos" /> + {this.props.solde.pourcent} %
              <span className="evolution-legend">
                &nbsp;
                <FormattedMessage
                  id="app.compared-to-last-month"
                  defaultMessage="par rapport au mois dernier"
                />
              </span>
            </div>
          );
        } else if (this.props.solde.pourcent < 0) {
          return (
            <div className="evolution negative">
              <i className="icon-evol-neg" /> - {this.props.solde.pourcent * -1}{' '}
              %
              <span className="evolution-legend">
                &nbsp;
                <FormattedMessage
                  id="app.compared-to-last-month"
                  defaultMessage="par rapport au mois dernier"
                />
              </span>
            </div>
          );
        }
      }
      return (
        <div className="evolution">
          <i className="icon-evol-neutral" />0 %
          <span className="evolution-legend">
            &nbsp;
            <FormattedMessage
              id="app.compared-to-last-month"
              defaultMessage="par rapport au mois dernier"
            />
          </span>
        </div>
      );
    };
    return (
      <div id="view1" className="view current">
        <Mode parkingId={this.props.parkingId} status={this.props.status} />
        <Request
          status={this.props.certificateStatus || 'none'}
          irve={this.props.irve}
        />
        <div className="dashboard-card">
          <div className="dashboard-card-title">
            <FormattedMessage
              id="app.CUMULATED-parking"
              defaultMessage="REVENU CUMULÉ DU PARKING"
            />
          </div>
          <div className="dashboard-card-subtitle">
            <FormattedMessage
              id="app.my-current-kitty-of-yourWATTPARK-account"
              defaultMessage="Cagnotte actuelle de votre compte WATTPARK"
            />
          </div>
          <div className="amount">
            {solde
              ? parseFloat(solde)
                  .toFixed(2)
                  .replace('.', ',')
              : 0}
            <span className="amount-currency"> €</span>
          </div>
          {pourcent()}
        </div>
      </div>
    );
  }
}

export default General;
