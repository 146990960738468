import { FETCH_GET_PARKING_STATISTICS } from '../actions/parkingCountAction';

export default function(state = [], action) {
  switch (action.type) {
    case FETCH_GET_PARKING_STATISTICS:
      const {
        nbFreeParking,
        freeParking,
        occupiedParking,
        information
      } = action.payload;

      return {
        ...state,
        parkingfree: { parkingfree: nbFreeParking, st: freeParking },
        parkingoccupy: { parkingoccupy: occupiedParking },
        parkinginfo: information,
        loading: true
      };
    default:
      return { ...state };
  }
}
