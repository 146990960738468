import React, { Component } from 'react';
import moment from 'moment';
import { FormattedMessage } from 'react-intl';
import $ from 'jquery';

class Matriculation extends Component {
  constructor(props) {
    super(props);
    this.onClickOption = this.onClickOption.bind(this);
  }

  onClickOption() {
    let id = this.props.bookingId;
    $('#option-' + this.props.bookingId).on('click', function() {
      let target = $(this).next('#div-option-' + id);
      if (target.hasClass('is-opened')) {
        target.removeClass('is-opened');
      } else {
        target.addClass('is-opened');
      }
    });
  }

  render() {
    const { user, energy, startDate, endDate } = this.props.booking;

    return (
      <div className="matriculation">
        <div className="matriculation-name">
          <div className="matriculation-name-title">Nom</div>
          <div className="matriculation-name-content">{user.fullName}</div>
        </div>
        <div className="matriculation-power">
          <div className="matriculation-power-title">{energy.label}</div>
          <div className="matriculation-power-content">
            {isNaN(energy.value)
              ? energy.value
              : parseFloat(energy.value).toFixed(2)}{' '}
            kWh
          </div>
        </div>
        <div className="matriculation-date">
          <div className="matriculation-date-title">Début</div>
          <div className="matriculation-date-content">
            {moment(startDate).format('DD/MM/YYYY')} -&nbsp;
            <span className="matriculation-date-content-details">
              {moment(startDate).format('HH:mm')}
            </span>
          </div>
        </div>
        <div className="matriculation-date">
          <div className="matriculation-date-title">Fin</div>
          <div className="matriculation-date-content">
            {moment(endDate).format('DD/MM/YYYY')} -&nbsp;
            <span className="matriculation-date-content-details">
              {moment(endDate).format('HH:mm')}
            </span>
          </div>
        </div>
        {!this.props.booking.oldBooking && (
          <div>
            <i
              className="icon-options trigger-options-panel"
              id={'option-' + this.props.bookingId}
              onClick={this.onClickOption}
            >
              <span />
              <span />
              <span />
            </i>
            <div
              className="options-panel"
              id={'div-option-' + this.props.bookingId}
            >
              <ul className="options-panel-list">
                <li className="options-panel-list-item">
                  <a
                    href="#"
                    className="options-panel-list-item-action delete"
                    onClick={this.props.handulDeleteBooking}
                    data-bookingid={this.props.bookingId}
                    data-userid={this.props.booking.user_id}
                    data-type="delete"
                  >
                    <FormattedMessage
                      id="app.btnDelete"
                      defaultMessage="Supprimer"
                    />
                  </a>
                </li>
              </ul>
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default Matriculation;
