import { FETCH_GET_PARTNERS } from '../actions/partnersAction';

export default function(state = [], action) {
  switch (action.type) {
    case FETCH_GET_PARTNERS:
      return {
        ...state,
        partners: action.payload,
        loading: true,
        message: action.message
      };
    default:
      return state;
  }
}
