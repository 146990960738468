import { TYPES } from '../actions/hoursContractAction';
import { HOURS_CONTRACT_CODES } from '../constants/errors';

export default function(state = [], action) {
  switch (action.type) {
    case TYPES.FETCH_GET_HOURS_CONTRACT_PARKING:
      return {
        ...state,
        hoursContract: action.payload,
        loading: true,
        message: action.message
      };
    case TYPES.FETCH_UPDATE_HOURS_CONTRACT_PARKING:
      return {
        ...state,
        hoursContract: {
          ...action.payload,
          slots: state.hoursContract.slots
        },
        loading: true,
        message: action.message
      };
    case TYPES.FETCH_ADD_HOURS_CONTRACT_SLOT:
      return {
        ...state,
        hoursContract: action.payload,
        loading: true,
        message: action.message
      };
    case TYPES.FETCH_UPDATE_HOURS_CONTRACT_SLOT:
      return {
        ...state,
        hoursContract: action.payload,
        loading: true,
        message: action.message
      };
    case TYPES.FETCH_ERRORS_HOURS_CONTRACT_SLOT:
      return {
        ...state,
        loading: true,
        message: HOURS_CONTRACT_CODES[action.code]
      };
    case TYPES.FETCH_DELETE_HOURS_CONTRACT_SLOT:
      return {
        ...state,
        hoursContract: action.payload,
        loading: true,
        message: action.message
      };
    case TYPES.FETCH_CLEAR_MESSAGE:
      return {
        ...state,
        message: null
      };
    default:
      return state;
  }
}
