import React, { Component } from 'react';
import { connect } from 'react-redux';
import { hideModal, showModalDeleteCar } from '../../../actions/modalAction';
import { FormattedMessage } from 'react-intl';
import { removeCarFromParking } from '../../../actions/parkingAction';

class ModalCarDelete extends Component {
  constructor(props) {
    super(props);
    this.carDelete = this.carDelete.bind(this);
  }

  carDelete() {
    this.props.removeCarFromParking(
      this.props.modal.modalData.parkingId,
      this.props.modal.modalData.carId
    );
    this.props.hideModal();
  }

  render() {
    if (this.props.displayDeleteCar) {
      return (
        <div>
          <div className=".modal-layer-screen"></div>
          <div className="modal-scheduler" style={{ display: 'block' }}>
            <div className="modal-scheduler-title">Suppression</div>
            <a
              className="btn-close"
              onClick={() => {
                this.props.hideModal();
              }}
            >
              <i className="icon-close" />
            </a>
            <div className="modal-scheduler-body">
              <div className="form-group">
                <FormattedMessage
                  id="app.delete-immat"
                  defaultMessage="êtes-vous sûr de supprimer immat"
                />{' '}
                <strong>{this.props.modal.modalData.immatriculation}</strong>
              </div>
              <div className="modal-scheduler-actions">
                <button
                  className="btn btn-primary  btn-small "
                  onClick={this.carDelete}
                >
                  <FormattedMessage id="app.btn-yes" defaultMessage="oui" />
                </button>
                <button
                  className="btn btn-secondary  btn-small btn-fixed"
                  onClick={() => this.props.hideModal()}
                >
                  <FormattedMessage id="app.btn-no" defaultMessage="non" />
                </button>
              </div>
            </div>
          </div>
        </div>
      );
    } else return null;
  }
}

function mapStateToProps(state) {
  return {
    modal: state.modal,
    displayDeleteCar: state.modal.displayDeleteCar
  };
}

export default connect(mapStateToProps, {
  hideModal,
  showModalDeleteCar,
  removeCarFromParking
})(ModalCarDelete);
