import {
  FETCH_GET_BANCK_DETAIL,
  FETCH_CREATE_BANCK_DETAIL,
  FETCH_UPDATE_WALLET,
  FETCH_TRANSFERT_SOLD
} from '../actions/lemonWayAction';

export default function(state = [], action) {
  switch (action.type) {
    case FETCH_GET_BANCK_DETAIL:
      return {
        ...state,
        bankDetail: action.payload,
        createBankDetail: '',
        transfertSold: '',
        userUpdated: '',
        updateBankDetail: '',
        loading: true
      };
    case FETCH_CREATE_BANCK_DETAIL:
      return {
        ...state,
        createBankDetail: action.payload,
        transfertSold: '',
        userUpdated: '',
        updateBankDetail: '',
        loading: true
      };
    case FETCH_UPDATE_WALLET:
      return {
        ...state,
        updateBankDetail: action.payload,
        transfertSold: '',
        userUpdated: '',
        createBankDetail: '',
        loading: true
      };
    case FETCH_TRANSFERT_SOLD:
      return {
        ...state,
        transfertSold: action.payload,
        createBankDetail: '',
        updateBankDetail: '',
        userUpdated: '',
        loading: true
      };
    default:
      return state;
  }
}
