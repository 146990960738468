import { FormattedMessage } from 'react-intl';
import ListElement from './element';
import React from 'react';

const BlackList = ({ blackList, goToList }) => {
  if (blackList)
    return (
      <div className="form-block form-block-warning">
        <h3 className="form-block-title scroll-hidden">
          <FormattedMessage
            id="app.user-unauthorized"
            defaultMessage="Liste non autorisée"
          />
        </h3>

        <div className="form-block-info">
          <span className="form-block-info-label">
            <FormattedMessage
              id="app.user-unauthorized-text"
              defaultMessage="Les propriétaires de ces comptes n'ont plus le droit de réserver de place sur votre parking."
            />
          </span>
        </div>

        {blackList.content.map((list, index) => (
          <ListElement
            key={index}
            list={list}
            handleClick={() => goToList(list.id)}
          />
        ))}
      </div>
    );
  else return null;
};

export default BlackList;
