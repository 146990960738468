import { applyMiddleware, createStore } from 'redux';
import { routerMiddleware } from 'react-router-redux';
import thunk from 'redux-thunk';
import rootReducer from './reducers';
import axios from 'axios';
import config from './config/index';
import promise from 'redux-promise-middleware';
import { composeWithDevTools } from '@redux-devtools/extension';
import { createBrowserHistory } from 'history';

import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';

firebase.initializeApp(config.firebase);

export const history = createBrowserHistory();
axios.interceptors.response.use(
  res => {
    return res;
  },
  error => {
    const originalRequest = error.config;
    console.log("ORIGINAL REQUEST :")
    console.log(originalRequest)
    console.log(error.response.data)
    if (error.response.data.message === "auth/id-token-expired") {
      console.log("id token expired")
      console.log(firebase.auth().currentUser)
      firebase.auth().currentUser.getIdToken(true).then((token) => {
        console.log("TOKEN REFRESHED");
        localStorage.setItem('token', token);
        originalRequest.headers['x-access-token'] = token;
        window.location.reload();
        return true;
      }).catch((err) => {
        console.log('TOKEN NOT REFRESHED');
        console.log(err)
      })
    } else if (error.response.data.message === 'Access denied') {
      window.location.href = '/error-401';
    } else {
      return Promise.reject(error.response);
    }
  }
);

export default function configureStore(initialState = {}) {
  return createStore(
    rootReducer,
    initialState,
    composeWithDevTools(
      applyMiddleware(promise, thunk, /*logger,*/ routerMiddleware(history))
    )
  );
}
