import React, {Component} from 'react';
import {listParking, setInputBehaviour} from '../../../utils';
import {fetchGetCharacteristicsParkings} from '../../../actions/characteristicsAction';
import {fetchGetParkingHoursContract} from '../../../actions/hoursContractAction';
import Menu from './menu';
import {Link} from 'react-router-dom';
import * as ROUTES from '../../../constants/routes';
import General from './general';
import Information from './informations';
import Price from './price';
import Reportings from './reporting';
import Booking from './booking';
import Permission from './permissions';
import Stations from './station';
import {
  fetchEditParking,
  fetchGetParkingById,
  fetchGetParkingImage,
  fetchGetParkingThumbanil,
  fetchParkingCars,
  fetchParkingSolde,
  fetchUpdateParkingStatus
} from '../../../actions/parkingAction';

import {connect} from 'react-redux';

import _ from 'lodash';
import {FormattedMessage} from 'react-intl';
import Avatar from './../../Layouts/Forms/avatar';
import Spinner from "../../../utils/Spinner";
import Alert from './../../Layouts/Alert/alert';
import {fetchGetIssuesByParking} from '../../../actions/issuesAction';
import Modal from '../../Layouts/Modals/modal';
import ModalCarDelete from '../../Layouts/Modals/modalCarDelete';
import ModalParkingActivation from '../../Layouts/Modals/modalParkingActivation';
import {hideModal} from '../../../actions/modalAction';
import {fetchAddSlots, fetchDeleteSlots} from '../../../actions/hoursAction';

const reg = /^\d+(\,\d{1,2})?$/;

class ViewParking extends Component {
  constructor(props) {
    super(props);
    this.state = {
      listCharacteristics: [],
      status: '',
      fileName: '',
      fileName1: '',
      fileParking: null,
      thumbnail: null,
      heightMax: '',
      energyProviderType: '',
      details: this.props.children,
      addressCompl: '',
      imageDeleted: [],
      isLoading: false,
      parkingEdit: false,
      parkingMode: false,
      name: '',
      zipCode: '',
      city: '',
      country: '',
      address: '',
      lat: '',
      longitude: '',
      checked: true,
      numChildren: 0,
      defaultImage: false,
      checkedImg: '',
      children: this.props.parkingListImage,
      array: [],
      images: [],
      imagesParking: [],
      picture: 'public/img/parkingDefault.jpg',
      listChar: [],
      parking: [],
      loading: false,
      alert: false,
      stationNull: false,
      style: 'none',
      message: '',
      nameAlert: '',
      display: 'none',
      typeAlert: '',
      alert2: false,
      style2: 'none',
      message2: '',
      nameAlert2: '',
      display2: 'none',
      typeAlert2: '',
      characteristicParking: [],
      hoursContractParking: null,
      typologies_id: null,
      urlWebSite: '',
      formErros: {
        address: '',
        zipCode: '',
        city: '',
        country: '',
        details: '',
        addressCompl: '',
        lat: '',
        forfaitOver8Hours: '',
        forfaitOver24Hours: '',
        longitude: '',
        heightMax: '',
        name: ''
      }
    };

    this.onClickCancel = this.onClickCancel.bind(this);
    this.onFileChange = this.onFileChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChangeInputDefault = this.handleChangeInputDefault.bind(this);
    this.onImageChange = this.onImageChange.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.onChangeSelect = this.onChangeSelect.bind(this);
    this.goToListInfo = this.goToListInfo.bind(this);
    this.onDeletePhoto = this.onDeletePhoto.bind(this);
    this.calendarSubmit = this.calendarSubmit.bind(this);
    this.calendarDelete = this.calendarDelete.bind(this);
  }

  onClickCancel() {
    this.setState({
      checked: true,
      numChildren: 0,
      fileParking: null,
      defaultImage: false,
      checkedImg: '',
      Children: null,
      array: [],
      images: [],
      imagesParking: [],
      characteristicParking: [],
      picture: 'public/img/parkingDefault.jpg',
      listChar: [],
      heightMax: '',
      details: '',
      thumbnail: '',
      energyProviderType: '',
      description: '',
      addressCompl: '',
      zipCode: '',
      country: '',
      city: '',
      fileName: '',
      address: '',
      lat: '',
      longitude: '',
      typologies_id: null,
      urlWebSite: ''
    });
  }

  componentDidMount() {
    const parkingId = parseInt(this.props.match.params.parkingId);
    const dynamicStatus = true;
    this.props.fetchGetParkingById(parkingId, dynamicStatus);
    this.props.fetchGetParkingThumbanil(parkingId);
    this.props.fetchParkingSolde(parkingId);
    //this.props.fetchInitialState();
    this.props.fetchGetParkingImage(parkingId);
    this.props.fetchGetCharacteristicsParkings(parkingId);
    this.props.fetchGetParkingHoursContract(parkingId);
    this.props.fetchParkingCars(parkingId);
    this.props.fetchGetIssuesByParking(parkingId);
    window.scrollTo(500, 0);
  }

  onFileChange(event) {
    let parkings = [];
    if (this.state.parking) {
      parkings = this.state.parking;
    }

    if (event.target.files && event.target.files[0]) {
      parkings.map(parking => {
        if (parking.files) {
          parking.files.map(file => {
            if (file.fileType === 'address proof') {
              file.name = event.target.files[0].name;
            }
          });
        }
      });
      this.setState({
        fileParking: event.target.files[0], fileName: event.target.files[0].name
      });
    }
  }

  componentWillReceiveProps(nextProps) {
    try {
      if (nextProps.hoursContractParking) {
        this.setState({hoursContractParking: nextProps.hoursContractParking});
      }
      if (nextProps.parkingMode) {
        if (nextProps.parkingMode.valid === true) {
          this.setState({status: nextProps.parkingMode.data});
          return _.map(this.props.parking, parking => {
            parking.status = nextProps.parkingMode.data;
            this.setState({status: parking.status});
          });
        }
      }
      if (this.state.parkingEdit === true) {
        this.setState({
          loading: false,
          characteristicParking: nextProps.parkingEdit.characteristicParking,
          parking: nextProps.parkingEdit.parking,
          thumbnail: nextProps.parkingEdit.thumbnail.url,
          parkingEdit: false
        });
        window.scrollTo(500, 0);
        this.setState({
          alert: true, message: (<FormattedMessage
            id="app.parking.updated"
            defaultMessage="Les informations de votre parking ont été mises à jour"
          />), typeAlert: 'alerts', nameAlert: (<FormattedMessage id="app.success" defaultMessage="Succès !"/>)
        });
        setTimeout(function () {
          this.setState({
            alert: false
          });
        }.bind(this), 5000);
      } else {
        if (nextProps.add) {
          this.setState({
            alert: false
          });
        }

        if (nextProps && nextProps.characteristicParking) {
          this.setState({
            characteristicParking: nextProps.characteristicParking
          });
        }
        if (nextProps.thumbnail) {
          this.setState({thumbnail: nextProps.thumbnail});
        }
        if (nextProps.parkingListImage) {
          let count = 0;
          if (this.state.array.length === 0) {
            nextProps.parkingListImage.map(image => {
              let checked = false;
              if (image) {
                image.value = 1;

                image.default = 0;
                if (image.status === 1) {
                  checked = true;
                  image.default = 1;
                }
                count++;
                this.state.array.push(<Avatar
                  key={image.id}
                  id={image.id}
                  nameFile={image.name}
                  imageId={image.id}
                  methodDelete={this.onDeletePhoto}
                  picture={image.image}
                  method={this.handleChangeInputDefault}
                  checked={checked}
                />);
              }
            });
            this.setState({
              images: nextProps.parkingListImage, children: this.state.array
            });
          }
        }
        if (nextProps.parkingById) {
          this.setState({
            parking: nextProps.parkingById, isLoading: true
          });
          return _.map(nextProps.parkingById, parking => {
            this.setState({
              status: parking.status,
              name: parking.name,
              heightMax: parking.maxHeight,
              energyProviderType: parking.energyProviderType,
              details: parking && parking.address ? parking.address.accessDescription : '',
              addressCompl: parking && parking.address ? parking.address.addressCompl : '',
              zipCode: parking && parking.address ? parking.address.zipCode : 0,
              city: parking && parking.address ? parking.address.city : '',
              country: parking && parking.address ? parking.address.country : '',
              address: parking && parking.address ? parking.address.street : '',
              lat: parking && parking.address ? parking.address.latitude : 0.0,
              longitude: parking && parking.address ? parking.address.longitude : 0.0,
              typologies_id: parking.typologies_id,
              urlWebSite: parking.urlWebSite
            });
            if (!parking.station) {
              this.setState({
                stationNull: true, alert: true, message: (<FormattedMessage
                  key={parking.id}
                  id="app.stations-needed"
                  defaultMessage="Les Stations sont nécessaires"
                />), typeAlert: 'alerts  alerts-warning', nameAlert: (<FormattedMessage
                  key={parking.id}
                  id="app.stations"
                  defaultMessage="Stations"
                />)
              });
            }
          });
        }
      }
    } catch (e) {
    }
  }

  handleChangeInputDefault(e) {
    if (!isNaN(e.currentTarget.dataset.imageid)) {
      this.state.checkedImg = e.currentTarget.dataset.imageid;
    }

    const target = e.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
    if (target.checked) {
      for (let i = 0; i < this.state.images.length; i++) {
        if (this.state.images[i] !== undefined) {
          this.state.images[i].default = 0;
          if (this.state.images[i].id === parseInt(e.currentTarget.dataset.val)) {
            this.state.images[i].default = 1;
            this.state.defaultImage = this.state.images[i].name;
          }
        }
      }
    }

    this.setState({
      [name]: value, images: this.state.images, defaultImage: this.state.defaultImage
    });
  }

  onImageChange(event) {
    if (event.target.files && event.target.files[0]) {
      let reader = new FileReader();
      event.target.files[0].value = 0;

      event.target.files[0].id = this.state.numChildren;
      const file = event.target.files[0];

      this.state.images.push(file);
      this.setState({
        imagesParking: this.state.images, fileName1: event.target.files[0].name
      });

      reader.onload = e => {
        this.state.array.push(<Avatar
          key={this.state.numChildren}
          id={this.state.numChildren}
          picture={e.target.result}
          methodDelete={this.onDeletePhoto}
          method={this.handleChangeInputDefault}
          nameFile={this.state.fileName1}
          checked={false}
        />);

        this.setState({
          numChildren: this.state.numChildren + 1,
          picture: e.target.result,
          children: [...this.state.array],
          images: [...this.state.images]
        });
      };
      reader.readAsDataURL(event.target.files[0]);
    }
  }

  handleSubmit(event) {
    event.preventDefault();
    if (!this.state.defaultImage) {
      for (let i = 0; i < this.state.images.length; i++) {
        if (this.state.images[i] !== undefined) {
          this.state.images[i].default = 1;
          this.state.defaultImage = this.state.images[i].name;
          break;
        }
      }
    }

    let parkingAdd = {
      checked: parseInt(this.state.checkedImg),
      images: this.state.imagesParking,
      imageDeleted: this.state.imageDeleted,
      file: this.state.fileParking,
      defaultImage: this.state.defaultImage,
      maxHeight: this.state.heightMax,
      characteristics: this.state.listChar,
      energyProviderType: this.state.energyProviderType,
      user_id: parseInt(localStorage.getItem('userId')),
      name: this.state.name,
      typologies_id: this.state.typologies_id,
      urlWebSite: this.state.urlWebSite,
      address: {
        city: this.state.city,
        country: this.state.country,
        department: this.state.zipCode,
        addressCompl: this.state.addressCompl,
        accessDescription: this.state.details,
        street: this.state.address,
        zipCode: this.state.zipCode,
        latitude: this.state.lat,
        longitude: this.state.longitude
      }
    };
    this.props.fetchEditParking(this.props.match.params.parkingId, parkingAdd);
    this.setState({
      imagesParking: this.state.imagesParking, loading: true, parkingEdit: true
    });
  }

  logout() {
    localStorage.clear();
    window.location.href = ROUTES.LOGIN;
  }

  onChangeSelect(e) {
    this.setState({
      energyProviderType: e.currentTarget.dataset.value
    });
  }

  handleChange(event) {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
    let formErrors = this.state.formErros;
    switch (name) {
      case 'name':
        formErrors.name = value.length < 3 && value.length > 0 ? 'Minimum 3 caractères sont requis' : '';
        break;
      case 'address':
        formErrors.address = value.length < 3 && value.length > 0 ? 'Minimum 3 caractères sont requis' : '';
        break;
      case 'zipCode':
        formErrors.zipCode = value <= 0 ? "Le code postal n'est pas valide" : '';
        break;
      case 'city':
        formErrors.city = value.length < 3 && value.length > 0 ? 'Minimum 3 caractères sont requis' : '';
        break;
      case 'country':
        formErrors.country = value.length < 3 && value.length > 0 ? 'Minimum 3 caractères sont requis' : '';
        break;

      case 'longitude':
        formErrors.longitude = isNaN(value) || value.length < 3 || value <= 0 ? 'Minimum 3 caractères sont requis' : '';
        break;
      case 'lat':
        formErrors.lat = isNaN(value) || value.length < 3 || value <= 0 ? 'Minimum 3 caractères sont requis' : '';
        break;
      case 'heightMax':
        formErrors.heightMax = reg.test(value) || value === '' ? '' : "La hauteur n'est pas valide";
        break;
      case 'details':
        formErrors.details = value.length < 6 && value.length > 0 ? 'Minimum 6 caractères sont requis' : '';
        break;
      case 'addressCompl':
        formErrors.addressCompl = value.length < 3 && value.length > 0 ? 'Minimum 3 caractères sont requis' : '';
        break;
      default:
        break;
    }
    if (target.checked && name != 'typologies_id') {
      this.state.listChar.push(parseInt(event.currentTarget.dataset.value));
    } else {
      if (this.state.listChar.length !== 0) {
        for (let i = 0; i < this.state.listChar.length; i++) {
          if (parseInt(this.state.listChar[i]) === parseInt(event.currentTarget.dataset.value)) delete this.state.listChar[i];
        }
      }
    }
    this.setState({
      [name]: value, listChar: this.state.listChar
    });
  }

  calendarSubmit(props) {
    let data = {
      startDate: `${props.inputHoursStart}`,
      endDate: `${props.inputHoursEnd}`,
      price: props.inputHoursPrice.value,
      slotId: props.slotId,
      name: 'matin',
      dayId: parseInt(props.val)
    };
    this.props.fetchAddSlots(parseInt(props.val), data);
  }

  calendarDelete(props) {
    this.props.fetchDeleteSlots(props.slotId);
  }

  goToListInfo(id) {
    this.props.history.push(`/parking/list/${id}`);
  }

  onDeletePhoto(event) {
    try {
      this.props.parkingListImage.map(image => {
        for (let i = 0; i < this.state.images.length; i++) {
          if (this.state.images[i] !== undefined) {
            if (image.id === parseInt(event.currentTarget.dataset.imageid)) {
              this.state.imageDeleted.push(image.id);
              this.setState({
                imageDeleted: this.state.imageDeleted
              });
              break;
            }
          }
        }
      });
      for (let i = 0; i < this.state.images.length; i++) {
        if (this.state.images[i] !== undefined) {
          if (event.currentTarget.dataset.picture === this.state.images[i].name) {
            delete this.state.images[i];
          }
          this.setState({
            images: this.state.images
          });
        }
      }
      for (let i = 0; i < this.state.children.length; i++) {
        if (this.state.array[i] !== undefined) {
          if (event.currentTarget.dataset.num === this.state.array[i].key) {
            delete this.state.array[i];
            this.setState({
              children: [...this.state.array]
            });
          }
        }
      }
    } catch (e) {
      return false;
    }
  }

  /*
   **Render
   */
  render() {
    listParking();
    setInputBehaviour();
    if (this.state.characteristicParking) {
      if (this.state.listChar.length === 0) {
        for (let i = 0; i < this.state.characteristicParking.length; i++) {
          this.state.listChar.push(this.state.characteristicParking[i].characteristics_id);
        }
      }
    }

    let parkings = [];
    let typeCertificat = false;
    let certificateStatus = 'none';
    let irve = null;
    let typeAddress = false;

    if (this.state.parking) {
      parkings = this.state.parking;
    }
    const formErrors = this.state.formErros;
    let thumb = this.state.thumbnail;
    const loading = this.state.loading;
    const isLoading = this.state.isLoading;
    const enabled = formErrors.address.length > 0 || this.state.address === '' || formErrors.longitude.length > 0 || this.state.longitude === '' || formErrors.lat.length > 0 || this.state.lat === '' || formErrors.country.length > 0 || this.state.country === '' || formErrors.city.length > 0 || this.state.city === '' || formErrors.zipCode > 0 || this.state.zipCode === '';

    return (
      <>
        {
          !isLoading ? <Spinner/> : <div className="main-container parking">
            {parkings.map(parking => {
              if (parking.files) {
                parking.files.map(file => {
                  if (file.fileType === 'certificat irve') {
                    typeCertificat = true;
                    certificateStatus = file.status;
                    irve = file;
                  }
                  if (file.fileType === 'address proof') {
                    typeAddress = true;
                    this.state.fileName = file.name;
                  }
                });
              }
              return (<div key={parking.id}>
                <header className="header">
                  <div className="header-left">
                    <Link
                      to={ROUTES.DASHBOARD_PARKINGS}
                      onClick={this.props.hideModal}
                    >
                      <span className="link">
                        <i className="icon-arrow-left"/>
                        <span className="link-text">
                          <FormattedMessage
                            id="app.navDsh"
                            defaultMessage="Accueil"
                          />
                        </span>
                      </span>
                    </Link>

                    <div className="header-profile">
                      <div className="header-profile-img">
                        <img src={thumb} alt="img Parking"/>
                      </div>
                      <div className="header-profile-info">
                        <h3 className="header-profile-info-title">
                          {parking.name}
                        </h3>
                        <span className="header-profile-info-status">
                          {this.state.status === 'available' ? (<FormattedMessage
                            id="app.public-mode"
                            defaultMessage="Mode Public"
                          />) : (<FormattedMessage
                            id="app.private-mode"
                            defaultMessage="Mode Privé"
                          />)}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="header-right">
                    <div className="header-user">
                      <i className="icon-user"/>
                      <div className="header-user-info">
                        <div className="header-user-name">
                          {localStorage.getItem('username')}
                        </div>
                        <Link
                          to=""
                          className="header-user-connexion"
                          onClick={this.logout}
                        >
                          <FormattedMessage
                            id="app.navLogout"
                            defaultMessage="Déconnexion"
                          />
                        </Link>
                      </div>
                    </div>
                  </div>
                </header>
                <div className="screen-filter"></div>
                <div className="container">
                  <div className="row">
                    {this.state.alert ? (<Alert
                      nameAlert={this.state.nameAlert}
                      type={this.state.typeAlert}
                      style={false}
                      message={this.state.message}
                    />) : null}
                    {typeCertificat === false ? (<Alert
                      nameAlert={<FormattedMessage
                        id="app.text-title-irve"
                        defaultMessage="Parkings manquant de Certificat IRVE"
                      />}
                      type="alerts  alerts-warning"
                      style={true}
                      url={ROUTES.CERTIFICATION}
                      message={<FormattedMessage
                        id="app.text-demande-irve"
                        defaultMessage="Valider le certificat IRVE de votre borne par un professionnel agréé"
                      />}
                      btnName={<FormattedMessage
                        id="app.btn-demande-irve"
                        defaultMessage="Demander"
                      />}
                    />) : null}
                    {this.state.fileName === '' ? (<Alert
                      nameAlert={<FormattedMessage
                        id="app.files"
                        defaultMessage="Fichiers"
                      />}
                      type="alerts  alerts-warning"
                      style={false}
                      message={<FormattedMessage
                        id="app.files-needed"
                        defaultMessage="Faites la demande de votre address proof"
                      />}
                    />) : null}
                    <div className="col-xs-12 col-md-4">
                      <Menu
                        typeCertificat={typeCertificat}
                        typeAddress={this.state.fileName}
                        station={this.state.alert}
                        nbStations={parking.station.length}
                        nbPermissions={this.props.parkingCars.total}
                        nbBookings={parking.bookings ? parking.bookings.length : 0}
                        nbReports={this.props.issues.length}
                      />
                    </div>
                    <div className="col-xs-12 col-md-8">
                      <div className="view-wrapper">
                        <General
                          status={this.state.status}
                          solde={this.props.parking_Solde}
                          parking={parking}
                          irve={irve}
                          certificateStatus={certificateStatus}
                          parkingId={this.props.match.params.parkingId}
                        />
                        <Information
                          enabled={enabled}
                          isLoading={loading}
                          error={formErrors}
                          onFileChange={this.onFileChange}
                          methodDelete={this.onDeletePhoto}
                          onClickCancel={this.onClickCancel}
                          handleChange={this.handleChange}
                          onChangeSelect={this.onChangeSelect}
                          handleChangeInputDefault={this.handleChangeInputDefault}
                          onImageChange={this.onImageChange}
                          handleSubmit={this.handleSubmit}
                          parking={parking}
                          heightMax={this.state.heightMax}
                          energyProviderType={this.state.energyProviderType}
                          details={this.state.details}
                          name={this.state.name}
                          addressCompl={this.state.addressCompl}
                          zipCode={this.state.zipCode}
                          city={this.state.city}
                          country={this.state.country}
                          address={this.state.address}
                          children={this.state.children}
                          longitude={this.state.longitude}
                          lat={this.state.latitude}
                          fileName={this.state.fileName}
                          images={this.state.images}
                          file={this.state.fileParking}
                          listImage={this.state.images}
                          characteristicParking={this.state.characteristicParking}
                          parkingId={this.props.match.params.parkingId}
                          typologies_id={this.state.typologies_id}
                          urlWebSite={this.state.urlWebSite}
                        />
                        <Price
                          parking={parking}
                          hoursContract={this.state.hoursContractParking}
                        />
                        <Reportings issues={this.props.issues}/>

                        <Permission
                          parkingid={this.props.match.params.parkingId}
                          lists={this.props.parkingCars.data}
                          goToListInfo={this.goToListInfo}
                        />

                        <Booking bookings={parking.bookings}/>

                        <Stations
                          stationNull={this.state.stationNull}
                          parkingid={this.props.match.params.parkingId}
                          stations={parking.station}
                        />
                        <Modal
                          handleSubmit={this.calendarSubmit}
                          handleDelete={this.calendarDelete}
                        />
                        <ModalParkingActivation/>
                        <ModalCarDelete/>
                      </div>
                    </div>
                  </div>
                </div>
              </div>);
            })}
          </div>
        }</>);
  }
}

function mapStateToProps(state) {
  return {
    parkingById: state.parkings.parkingById,
    validParking: state.parkings.validParking,
    characteristicPark: state.parkings.characteristicPark,
    validThumb: state.parkings.validThumb,
    characteristicParking: state.characteristics.characteristicParking,
    hoursContractParking: state.hoursContract.hoursContract,
    thumbnail: state.parkings.parkingThumb,
    loading: state.parkings.loading,
    parkingMode: state.parkings.parkingMode,
    parking_Solde: state.parkings.parking_Solde,
    parkingEdit: state.parkings.parkingEdit,
    parkingListImage: state.parkings.parkingImages,
    parkingCars: state.parkings.cars,
    name: state.parkings.name,
    issues: state.issues.issuesParkingList
  };
}

export default connect(mapStateToProps, {
  fetchGetParkingById,
  fetchGetCharacteristicsParkings,
  fetchGetParkingThumbanil,
  fetchUpdateParkingStatus,
  fetchParkingSolde,
  fetchEditParking,
  fetchGetParkingImage,
  fetchParkingCars,
  fetchGetIssuesByParking,
  hideModal,
  fetchAddSlots,
  fetchDeleteSlots,
  fetchGetParkingHoursContract
})(ViewParking);
