import React from 'react';
import { FormattedMessage } from 'react-intl';

const Transfert = ({
  error,
  idIban,
  iban,
  ibanHolder,
  IbanIsLoading,
  enabledIban,
  soldWattPark,
  methodChange,
  handleSubmitTransfertSold,
  detailBanck,
  goToTab
}) => {
  //Validator ZipCode Input
  let classZipCode = 'form-group';
  let classZipCode1 = 'form-input';
  let lengthZipCode1 = 0;
  if (soldWattPark) {
    classZipCode1 = 'form-input on-focus';
  }
  if (error) {
    if (error.soldWattPark.length > 0) {
      lengthZipCode1 = error.soldWattPark.length;
      classZipCode = 'form-group on-focus has-error has-feedback';
      classZipCode1 = 'form-input on-focus has-error has-feedback';
    }
  }

  return (
    <div className="form-block form-block-transfer">
      <h3 className="form-block-title">
        <i className="icon-warning" />
        <FormattedMessage
          id="app.transfert-sum"
          defaultMessage="Transférer une somme de mon revenu
                Wattpark"
        />
      </h3>
      <p className="form-block-text">
        <FormattedMessage
          id="app.valid-account-banc"
          defaultMessage="Je valide le compte bancaire de destination :"
        />
      </p>

      <div className="dashboard-card-subtitle">
        <FormattedMessage
          id="app.wattpark-balance"
          defaultMessage="Votre compte WATTPARK comprend actuellement le solde suivant"
        />
      </div>

      {iban !== '' && (
        <div className="bank-info">
          <i className="icon-bank" />
          <span className="bank-info-number">{iban}</span>
          <a
            onClick={() => {
              goToTab('view3');
            }}
            href="#"
            className="menu-item bank-info-user"
            data-target="#view3"
            style={{ fontSize: '1.3rem', lineHeight: 1.42, padding: 0 }}
          >
            {ibanHolder}
          </a>
        </div>
      )}

      <p className="form-block-text">
        <FormattedMessage
          id="app.recover-sum"
          defaultMessage="Je souhaite récupérer la somme suivante :"
        />
      </p>
      <div className={classZipCode}>
        <div className={classZipCode1}>
          <input
            required
            className={
              soldWattPark ? 'form-input-field on-focus' : 'form-input-field'
            }
            min={1}
            type="text"
            id="inputZIP"
            value={soldWattPark}
            onChange={methodChange}
            name="soldWattPark"
          />
          <label className="form-input-label" htmlFor="inputAmount">
            <span className="form-input-label-content">
              <FormattedMessage
                id="app.input-transfert-sum"
                defaultMessage="Saisir la somme à transférer (€)"
              />
            </span>
          </label>
        </div>
        {lengthZipCode1 > 0 && (
          <span className="help-block">{error.soldWattPark}</span>
        )}
      </div>
      <button
        disabled={!enabledIban}
        type="submit"
        className={
          !enabledIban
            ? 'btn btn-primary btn-disable pull-right'
            : 'btn btn-primary pull-right'
        }
        onClick={handleSubmitTransfertSold}
      >
        {IbanIsLoading ? (
          'Loading…'
        ) : (
          <FormattedMessage id="app.transfer" defaultMessage="Transférer" />
        )}
      </button>
    </div>
  );
};
export default Transfert;
