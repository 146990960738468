import React, {Component} from 'react';
import * as ROUTES from '../../constants/routes';
import {Link} from 'react-router-dom';
import Partners from './../Layouts/Forms/partner';
import {fetchGetPartners} from '../../actions/partnersAction';
import {DocumentTitle} from '../../utils';
import {connect} from 'react-redux';
import Spinner from "../../utils/Spinner";
import {FormattedMessage} from 'react-intl';
import {nav} from '../../utils';

class Certification extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    this.props.fetchGetPartners();
    nav.init();
  }

  render() {
    const logout = () => {
      localStorage.clear();
      window.location.href = ROUTES.LOGIN;
    };

    const {loading} = this.props;
    return (<div className="main-container certification">
        {DocumentTitle('app.title-certif', 'WATTPARK - Certification')}
        <header className="header">
          <div className="header-left">
            <div className="logo-secondary">
              <Link to={ROUTES.DASHBOARD_PARKINGS}>
                <img
                  src="/img/logo_no_text.svg"
                  alt="Logo sans texte Wattpark"
                ></img>
              </Link>
            </div>
            <Link className="link" to={ROUTES.DASHBOARD_PARKINGS}>
              <i className="icon-arrow-left"/>
              <span className="link-text">Retour</span>
            </Link>
            <nav className="nav-main">
              <Link className="nav-main-item" to={ROUTES.DASHBOARD_PARKINGS}>
                Dashboard
              </Link>
              <Link className="nav-main-item" to={ROUTES.ACCOUNT}>
                Compte
              </Link>
              {localStorage.getItem('usertype') === 'admin' ? (<Link
                  to={ROUTES.CERTIFICATIONVALIDATION}
                  className="nav-main-item"
                >
                  <FormattedMessage
                    id="app.navcertif"
                    defaultMessage="Certificats"
                  />
                </Link>) : ('')}
              <div className="nav-main-user">
                <i className="icon-user"/>
                <div className="nav-main-user-name">
                  {localStorage.getItem('username')}
                </div>
                <Link
                  to=""
                  className="nav-main-user-connexion"
                  onClick={logout}
                >
                  <FormattedMessage
                    id="app.navLogout"
                    defaultMessage="Déconnexion"
                  />
                </Link>
              </div>
            </nav>
          </div>
          <div className="header-center">
            <h2 className="header-title">CERTIFICATION IRVE</h2>
          </div>
          <div className="header-right">
            <h2 className="nav-trigger-title">Certification</h2>
            <div className="nav-trigger">
              <span className="nav-trigger-line"></span>
              <span className="nav-trigger-line"></span>
              <span className="nav-trigger-line"></span>
            </div>
          </div>
        </header>
        <div className="screen-filter"></div>
        <div className="container">
          {!loading ? <Spinner/> : <div className="row">
            <div className="col-xs-12 col-sm-4">
              <h2 className="title-main">
                Nos partenaires agréés <br/>à la certification IRVE
              </h2>
              <p className="text-main">
                La mise en service de votre WATTplug nécessite la validation
                de votre installation électrique par un partenaire agréé
                habilité à délivrer des certifications IRVE.
              </p>
              <ol className="list-number">
                <li className="list-number-item">
                  C’est le professionnel et lui seul qui transmettra la
                  certification à WATTPARK Pro
                </li>
                <li className="list-number-item">
                  Un email vous avertira à la disponibilité du certificat dans
                  votre dashboard WATTPARK
                </li>
                <li className="list-number-item">
                  Vous pourrez alors finaliser la mise en exploitation de
                  votre parking sur votre dashboard WATTPARK Pro
                </li>
              </ol>
            </div>
            <div className="col-xs-12 col-sm-8">
              <div className="form-block">
                {this.props.partners ? (<div>
                    <h3 className="form-block-title">Nos partenaires</h3>
                    <Partners partners={this.props.partners}/>
                  </div>) : (<h3 className="form-block-title">No Partners</h3>)}
              </div>
            </div>
          </div>}
        </div>
      </div>);
  }
}

function mapStateToProps(state) {
  return {
    partners: state.partners.partners, loading: state.partners.loading
  };
}

export default connect(mapStateToProps, {fetchGetPartners})(Certification);
