import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { fetchGetTypologies } from '../../../actions/typologiesAction';
import ElementTypology from './elementTypology';

class Typologies extends Component {
  componentDidMount() {
    this.props.fetchGetTypologies();
  }

  render() {
    let typologies = [];
    if (this.props.typologies) {
      typologies = this.props.typologies;
    }

    return (
      <div className="form-block">
        <h3 className="form-block-title">
          <FormattedMessage id="app.typology" defaultMessage="typologie" />
        </h3>
        <div className="row">
          {typologies.map(typology => {
            return (
              <ElementTypology
                key={typology.id}
                id={typology.id}
                icon={typology.icon}
                checked={typology.id == this.props.typologies_id}
                method={this.props.method}
                name={typology.typologiesName}
              />
            );
          })}
          <div className="col-xs-12 col-md-8">
            <div className="parking-url">
              <div className="form-input on-focus">
                <input
                  className="form-input-field"
                  type="url"
                  id="inputName"
                  name="urlWebSite"
                  value={this.props.urlWebSite}
                  onChange={this.props.method}
                />
                <label className="form-input-label" htmlFor="inputName">
                  <span className="form-input-label-content">
                    <FormattedMessage
                      id="app.web-address"
                      defaultMessage="Adresse internet"
                    />
                  </span>
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    typologies: state.typologies.typology
  };
}

export default connect(
  mapStateToProps,
  {
    fetchGetTypologies
  }
)(Typologies);
