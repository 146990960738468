import React, { Component } from 'react';
import { Route, Router } from 'react-router-dom';
import $ from 'jquery';
import './App.scss';
import 'bootstrap/dist/js/bootstrap.min.js';
import * as ScrollMagic from 'scrollmagic'; // Or use scrollmagic-with-ssr to avoid server rendering problems
import { TimelineMax, TweenMax } from 'gsap'; // Also works with TweenLite and TimelineLite
import { ScrollMagicPluginGsap } from 'scrollmagic-plugin-gsap';

import { history } from './store';

import PrivateRoute from './validation/PrivateRoute';
import Login from './components/Login';
import Signup from './components/Signup';
import DASHBOARDPARKINGS from './components/Parkings/Dashboard';
import Home from './components/Dashbord';
import COMPTE from './components/Users';
import CREATEPARKING from './components/Parkings/Create';
import GENERALPARKING from './components/Parkings/Views';
import Actions from './components/Parkings/Action';
import CertificationValidation from './components/CertificatinValidation';
import CertificationForm from './components/CertificatinValidation/CertificationForm';
import Certification from './components/Certification';
import Faq from './components/Faq';
import Terms from './components/Terms';
import LegalsMentions from './components/LegalsMentions';
import Confidentiality from './components/Confidentiality/';
import RsetPassword from './components/Reset';
import * as ROUTES from './constants/routes';
import USERLIST from './components/Parkings/Views/permissions/list';
import AccessDenied from './components/Errors/error401';
import UserDeletion from './components/Users/delete';
import UserDeleteRequest from './components/UserDeleteRequest';
import AdminRoute from './validation/AdminRoute';
import AdminBilling from './components/Billing';

ScrollMagicPluginGsap(ScrollMagic, TweenMax, TimelineMax);

window.jQuery = window.$ = $;
/**
 * @class App
 * @extends {Component}
 */

class App extends Component {
  /**
   * @memberof App
   * @summary handles button click
   */

  render() {
    return (
      <Router history={history}>
        <div>
          <Route exact path={ROUTES.LANDING} component={Home} />

          <Route exact path={ROUTES.LOGIN} component={Login} />

          <Route exact path={ROUTES.PASSWORD_FORGET} component={RsetPassword} />

          <Route exact path={ROUTES.FAQ} component={Faq} />

          <Route exact path={ROUTES.TERMS} component={Terms} />

          <Route exact path={ROUTES.USER_DELETION} component={UserDeletion} />

          <Route
            exact
            path={ROUTES.USER_DELETE_REQUEST}
            component={UserDeleteRequest}
          />

          <Route exact path="/error-401" component={AccessDenied} />

          <Route
            exact
            path={ROUTES.CONFIDENTIALITY}
            component={Confidentiality}
          />

          <Route
            exact
            path={ROUTES.SIGN_UP}
            render={props => <Signup {...props} />}
          />

          <Route
            exact
            path={ROUTES.MENTIONS_LEGALES}
            component={LegalsMentions}
          />

          <PrivateRoute
            exact
            path={ROUTES.DASHBOARD_PARKINGS}
            component={DASHBOARDPARKINGS}
          />

          <PrivateRoute exact path={ROUTES.COMPTE} component={COMPTE} />

          <PrivateRoute exact path={ROUTES.PARKING_LIST} component={USERLIST} />

          <PrivateRoute
            exact
            path={ROUTES.GENERAL_PARKING}
            component={GENERALPARKING}
          />

          <PrivateRoute
            exact
            path={ROUTES.CERTIFICATION}
            component={Certification}
          />

          <AdminRoute
            exact
            path={ROUTES.CERTIFICATIONVALIDATION}
            component={CertificationValidation}
          />

          <AdminRoute exact path={ROUTES.BILLING} component={AdminBilling} />

          <PrivateRoute
            exact
            path={ROUTES.PARKING_CERTIFICATION}
            component={CertificationForm}
          />

          <PrivateRoute
            exact
            path={ROUTES.CREATE_PARKING}
            component={CREATEPARKING}
          />
          <PrivateRoute
            exact
            path={ROUTES.PARKINGVALIDATION}
            component={Actions}
          />
        </div>
      </Router>
    );
  }
}

export default App;
