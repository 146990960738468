export const LANDING = '/';
export const SIGN_UP = '/signup';
export const LOGIN = '/login';
export const CREATE_PARKING = '/parking/create';
export const DASHBOARD_PARKINGS = '/parkings';
export const GENERAL_PARKING = '/parking/details/:parkingId';
export const PARKING_LIST = '/parking/list/:listId';
export const HOME = '/home';
export const ACCOUNT = '/account';
export const ADMIN = '/admin';
export const COMPTE = '/account';
export const LOGOUT = '/logout';
export const FAQ = '/faq';
export const TERMS = '/faq/term-and-condition';
export const CONFIDENTIALITY = '/faq/confidentiality';
export const MENTIONS_LEGALES = '/faq/mentions-legales';
export const CERTIFICATIONVALIDATION = '/certification/validation';
export const BILLING = '/billing/creation';
export const PARKING_CERTIFICATION = '/certification/validation/:parkingId';
export const PARKINGVALIDATION = '/parking/validation';
export const CERTIFICATION = '/certification';
export const PASSWORD_FORGET = '/pw-forget';
export const USER_DELETION = '/user/delete-account';
export const USER_DELETE_REQUEST = '/delete-account-request';
