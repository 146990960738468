import React, { Component } from 'react';
import Transfert from './../../Layouts/Forms/transfer';
import { FormattedMessage } from 'react-intl';

class Mony extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div id="view2" className="view">
        <div className="dashboard-card dashboard-part-visible">
          <div className="dashboard-card-title">
            <FormattedMessage
              id="app.user-balance"
              defaultMessage="Mon solde Wattpark"
            />
          </div>
          <div className="dashboard-card-subtitle">
            <FormattedMessage
              id="app.wattpark-balance"
              defaultMessage="Votre compte WATTPARK comprend actuellement le solde suivant"
            />
          </div>
          <div className="amount">
            {this.props.solde
              ? parseFloat(this.props.solde)
                  .toFixed(2)
                  .replace('.', ',')
              : 0}
            <span className="amount-currency">€</span>
          </div>
        </div>
        <Transfert
          goToTab={tab => {
            this.props.goToTab(tab);
          }}
          IbanIsLoading={this.props.IbanIsLoading}
          enabledIban={this.props.enabledIban}
          detailBanck={this.props.detailBanck}
          handleSubmitTransfertSold={this.props.handleSubmitTransfertSold}
          error={this.props.error}
          soldWattPark={this.props.soldWattPark}
          idIban={this.props.idIban}
          ibanHolder={this.props.ibanHolder}
          iban={this.props.iban}
          methodChange={this.props.methodChange}
        />
      </div>
    );
  }
}

export default Mony;
