import React from 'react';
import { Link } from 'react-router-dom';
import * as ROUTES from '../../../constants/routes';
import { FormattedMessage } from 'react-intl';
import * as moment from 'moment';

function Request({ irve, status = 'none' }) {
  let formattedDate =
    irve && irve.date ? moment(irve.date).format('DD/MM/YYYY') : null;
  if (status !== 'pending') {
    const title =
      status === 'none'
        ? 'app.irve-certification'
        : status === 'approved'
        ? 'app.certification.approved'
        : 'app.certification.rejected';
    const action =
      status === 'none'
        ? 'app.text-demande-irve'
        : status === 'approved'
        ? 'app.parking.certification.approved'
        : 'app.parking.certification.rejected';
    return (
      <div
        className={`form-block ${status === 'rejected' &&
          'form-block-warning'}`}
      >
        <h3 className="form-block-title">
          {status === 'rejected' && <i className="icon-warning" />}
          <FormattedMessage id={title} defaultMessage={title} />
        </h3>
        <div className="actions">
          <div className="actions-label">
            <FormattedMessage id={action} values={{ date: formattedDate }} />
          </div>
          {status === 'none' ? (
            <Link to={ROUTES.CERTIFICATION} className={`btn btn-secondary`}>
              <FormattedMessage id="action" defaultMessage="Demander" />
            </Link>
          ) : (
            <a
              rel="noopener noreferrer"
              target="_blank"
              href={irve && irve.file}
              className={`btn ${
                status === 'rejected' ? 'btn-secondary' : 'btn-third'
              }`}
            >
              <FormattedMessage
                id="app.download"
                defaultMessage="Télécharger"
              />
            </a>
          )}
        </div>
      </div>
    );
  }
  return null;
}
export default Request;
