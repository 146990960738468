import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { menu } from '../../utils';

class Menu extends Component {
  componentDidMount() {
    menu.init();
  }

  render() {
    const {
      fileApproved,
      fileRejected,
      filePending,
      awaitingFile
    } = this.props;
    return (
      <div className="menu">
        <div className="menu-scrollable">
          <div className="menu-scrollable-content">
            <a
              href="#"
              className="menu-item warning current"
              data-target="#view0"
            >
              <FormattedMessage
                id="app.awaiting-certificate"
                defaultMessage="En attente d'importation'"
              />{' '}
              <span className="menu-item-number">{awaitingFile}</span>
            </a>
            <a href="#" className="menu-item warning" data-target="#view1">
              <FormattedMessage
                id="app.waiting-for-validation"
                defaultMessage="En attente de validation"
              />{' '}
              <span className="menu-item-number">{filePending}</span>
            </a>
            <a href="#" className="menu-item" data-target="#view2">
              <FormattedMessage
                id="app.validated-by-WattPark"
                defaultMessage="Validés par WattPark"
              />{' '}
              <span className="menu-item-number">{fileApproved}</span>
            </a>
            <a href="#" className="menu-item" data-target="#view3">
              <FormattedMessage
                id="app.refused-by-WattPark"
                defaultMessage="Refusés par WattPark"
              />{' '}
              <span className="menu-item-number">{fileRejected}</span>
            </a>
          </div>
          <div className="menu-indicator"></div>
        </div>
      </div>
    );
  }
}

export default Menu;
