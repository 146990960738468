import axios from 'axios';
import config from './../config/index';

const baseUrl = config.baseUrl;

const checkDeletionToken = deletionToken => {
  const url = `${baseUrl}/api/user/check-deletion-token`;
  return axios.post(url, { token: deletionToken });
};


export default {
  checkDeletionToken
};
