import React, {Component} from 'react';
import * as ROUTES from '../../../constants/routes';
import Footer from '../../Layouts/Footer';
import Activation from './Card/activation';
import Balance from './Card/balance';
import {connect} from 'react-redux';
import {
  fetchGetParkingSolde,
  fetchInitialState,
  fetchParkingsList
} from '../../../actions/parkingAction';
import {fetchGetParkingStatistics} from '../../../actions/parkingCountAction';
import {fetchGetUserLicence} from '../../../actions/userLicenceAction';
import Main from './Card/main';
import Reportings from './Card/reporting';
import ListParking from './section-parking/section-parking';
import {Link} from 'react-router-dom';
import Spinner from "../../../utils/Spinner";
import {FormattedMessage} from 'react-intl';
import {DocumentTitle, handleCharts, nav, timeConvert} from '../../../utils';
import Alert from './../../Layouts/Alert/alert';
import {fetchGetUser} from '../../../actions/userAction';
import Menu from './Menu';

class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      parkings: null,
      associatedParking: null,
      parkingOccup: 0,
      parkingLib: 0,
      parkingF: 0,
      TauxActuel: 0,
      loading: false,
      sortDirection: 'descending',
      sortAssociatedDirection: 'descending',
      alert: false,
      alert2: false,
      alert3: false,
      alert4: false,
      style: 'none',
      parkingid: '',
      message: '',
      nameAlert: '',
      nameAlert3: '',
      nameAlert2: '',
      message2: '',
      message3: '',
      display: 'none',
      isLoading: false,
      typeAlert2: '',
      typeAlert4: '',
      nameAlert4: '',
      message4: '',
      typeAlert3: '',
      typeAlert: 'alerts alerts',
      userLicence: null
    };

    this.onSortParking = this.onSortParking.bind(this);
    this.onSortAssociatedParking = this.onSortAssociatedParking.bind(this);
    this.charts = this.charts.bind(this);
  }

  componentDidMount() {
    this.props.fetchGetUser(localStorage.getItem('firebaseId'));
    this.props.match.params = null;
    this.props.fetchParkingsList();
    this.props.fetchGetParkingSolde();
    this.props.fetchGetParkingStatistics();
    this.props.fetchInitialState();
    this.props.fetchGetUserLicence();
    nav.init();
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.userLicence !== this.state.userLicence)
      this.setState({userLicence: nextProps.userLicence});

    if (nextProps.getUser) {
      if (!nextProps.getUser.bank_details) {
        this.setState({
          alert: true,
          message: (
            <FormattedMessage
              id="app.message-alert-certificate"
              defaultMessage="Vos coordonnées bancaires sont nécessaires pour la récupération de votre cagnotte WATTPARK Pro."
            />
          ),
          typeAlert: 'alerts',
          nameAlert: (
            <FormattedMessage
              id="app.title-alert-certificate"
              defaultMessage="Coordonnées bancaires"
            />
          )
        });
      }

      if (!nextProps.getUser.ownerinfos) {
        this.setState({
          alert4: true,
          message4: (
            <FormattedMessage
              id="app.message-alert-identite"
              defaultMessage="Pour activer votre parking, veuillez envoyer une preuve d'identité."
            />
          ),
          typeAlert4: 'alerts',
          nameAlert4: (
            <FormattedMessage
              id="app.title-alert-identite"
              defaultMessage="justificatif d'identité"
            />
          )
        });
      }
    }
    if (nextProps.parking) {
      let parkingfree = [];
      let parkingoccupy = [];
      let parkinginfo = [];
      if (nextProps.parkingfree) parkingfree = nextProps.parkingfree;
      if (nextProps.parkingoccupy) parkingoccupy = nextProps.parkingoccupy;
      if (nextProps.parkinginfo) parkinginfo = nextProps.parkinginfo;
      let TauxActuel;
      let count = 0;
      let countCert = 0;
      let countAddr = 0;
      for (let i = 0; i < parkinginfo.length; i++) {
        if (
          parkinginfo[i].addr === false ||
          parkinginfo[i].certif === false ||
          parkinginfo[i].station === false
        ) {
          count++;
        }
        if (parkinginfo[i].addr === false) {
          countAddr++;
        }
        if (parkinginfo[i].certif === false) {
          countCert++;
        }
      }

      if (parkingfree.length !== 0 && parkingoccupy.length !== 0) {
        TauxActuel =
          (parkingoccupy.parkingoccupy * 100) / nextProps.parking.length;
      }
      if (
        parkingfree.length !== 0 &&
        parkingoccupy.length !== 0 &&
        parkinginfo.length !== 0
      ) {
        this.setState({
          parkingOccup: parkingoccupy.parkingoccupy,
          parkingF: count,
          parkingLib: parkingfree.parkingfree,
          TauxActuel: TauxActuel
        });
      }
      if (nextProps.parking.type) {
        this.setState({
          loading: true
        });
      } else {
        nextProps.parking.map((parking, idx) => {
          const parkingHasIrveFileApproved =
            parking.files &&
            parking.files.length > 0 &&
            parking.files.filter(
              f => f.type === 'certificat irve' && f.status === 'approved'
            ).length > 0
              ? true
              : false;
          const parkingHasAddressProofFile =
            parking.files &&
            parking.files.length > 0 &&
            parking.files.filter(f => f.type === 'address proof').length > 0
              ? true
              : false;

          if (!parkingHasAddressProofFile) {
            // CHECK ADDRESS FILE START
            const parkingWithoutAddressProofFile = nextProps.parking.filter(
              p =>
                !p.files ||
                p.files.length == 0 ||
                p.files.filter(f => f.type === 'address proof').length == 0
            ).length;

            this.setState({
              alert3: true,
              parkingid: parking.id,
              message3: (
                <FormattedMessage
                  key={idx}
                  id="app.files-needed"
                  defaultMessage="Afin d'activer la mise en exploitation de votre parking, veuillez transmettre un justificatif pour son adresse"
                />
              ),
              typeAlert3: 'alerts  alerts-warning',
              nameAlert3: ` ${
                parkingWithoutAddressProofFile > 0
                  ? parkingWithoutAddressProofFile
                  : ''
              } Parkings manquant de justificatif d'adresse`
            });
            // CHECK ADDRESS FILE END
          }
          if (!parkingHasIrveFileApproved) {
            // CHECK IRVE FILE START
            const parkingWithoutIrveFileCount = nextProps.parking.filter(
              p =>
                !p.files ||
                p.files.length == 0 ||
                p.files.filter(
                  f => f.type === 'certificat irve' && f.status === 'approved'
                ).length == 0
            ).length;
            this.setState({
              alert2: true,
              parkingid: parking.id,
              message2: (
                <FormattedMessage
                  key={idx}
                  id="app.text-demande-irve"
                  defaultMessage="Veuillez faire valider le certificat IRVE de votre borne par un professionnel agréé"
                />
              ),
              typeAlert2: 'alerts  alerts-warning',
              nameAlert2: `${
                parkingWithoutIrveFileCount > 0
                  ? parkingWithoutIrveFileCount
                  : ''
              } Parkings manquant de Certificat IRVE`
            });
            // CHECK IRVE FILE END
          }
        });
        this.setState({
          parkings: nextProps.parking,
          associatedParking: nextProps.associatedParking,
          loading: true
        });
      }
    }
  }

  logout() {
    localStorage.clear();
    window.location.href = ROUTES.LOGIN;
  }

  onSortParking() {
    let field = 'name';
    switch (this.state.sortDirection) {
      case 'ascending':
        return this.setState({
          parkings: this.state.parkings.sort((a, b) =>
            (a[field] || '')
              .toString()
              .localeCompare((b[field] || '').toString())
          ),
          sortDirection: 'descending'
        });
        break;
      case 'descending':
        return this.setState({
          parkings: this.state.parkings.sort((a, b) =>
            (b[field] || '')
              .toString()
              .localeCompare((a[field] || '').toString())
          ),
          sortDirection: 'ascending'
        });
      default:
        break;
    }
  }

  onSortAssociatedParking() {
    let field = 'name';
    switch (this.state.sortAssociatedDirection) {
      case 'ascending':
        return this.setState({
          associatedParking: this.state.associatedParking.sort((a, b) =>
            (a[field] || '')
              .toString()
              .localeCompare((b[field] || '').toString())
          ),
          sortAssociatedDirection: 'descending'
        });
        break;
      case 'descending':
        return this.setState({
          associatedParking: this.state.associatedParking.sort((a, b) =>
            (b[field] || '')
              .toString()
              .localeCompare((a[field] || '').toString())
          ),
          sortAssociatedDirection: 'ascending'
        });
      default:
        break;
    }
  }

  charts() {
    const dataArray = [
      {
        value: this.state.parkingF,
        colorID: 'buy'
      },
      {
        value: this.state.parkingOccup,
        colorID: 'help'
      },
      {
        value: this.state.parkingLib,
        colorID: 'plan'
      }
    ];
    let taux = 0;
    if (this.state.TauxActuel) taux = this.state.TauxActuel;
    handleCharts(dataArray, taux.toFixed(0));
  }

  handleNavMobile(event) {
    this.setState({displayNav: !this.state.displayNav});
  }

  render() {
    let loading = this.state.loading;
    let parkings = {};
    if (this.props.parking) {
      parkings = this.props.parking;
    }
    let parkingSolde = {};
    let h;
    let m;
    let hours;
    if (this.props.parkingSolde) {
      parkingSolde = this.props.parkingSolde;
      hours = parkingSolde.hoursTotal;

      let hourTab = timeConvert(hours);
      h = hourTab[0];
      m = hourTab[1];
    }
    const pourcent = () => {
      if (parkingSolde.percentHours) {
        if (parkingSolde.percentHours > 0) {
          return (
            <div className="evolution positive">
              <i className="icon-evol-pos"/> + {parkingSolde.percentHours} %
              <span className="evolution-legend">
                &nbsp;
                <FormattedMessage
                  id="app.compared-to-last-month"
                  defaultMessage="par rapport au mois dernier"
                />
              </span>
            </div>
          );
        } else if (parkingSolde.percentHours < 0) {
          return (
            <div className="evolution negative">
              <i className="icon-evol-neg"/> - {parkingSolde.percentHours * -1}{' '}
              %
              <span className="evolution-legend">
                &nbsp;
                <FormattedMessage
                  id="app.compared-to-last-month"
                  defaultMessage="par rapport au mois dernier"
                />
              </span>
            </div>
          );
        }
      }
      return (
        <div className="evolution">
          <i className="icon-evol-neutral"/>0 %
          <span className="evolution-legend">
            &nbsp;
            <FormattedMessage
              id="app.compared-to-last-month"
              defaultMessage="par rapport au mois dernier"
            />
          </span>
        </div>
      );
    };
    const shouldDisplayAlertMissingIrve =
      this.state.parkings &&
      this.state.parkings.filter(
        pk =>
          !pk.files ||
          pk.files.length == 0 ||
          pk.files.filter(
            f => f.type === 'certificat irve' && f.status === 'approved'
          ).length == 0
      ).length > 0;
    const shouldDisplayAlertMissingAddr =
      this.state.parkings &&
      this.state.parkings.filter(
        pk =>
          !pk.files ||
          pk.files.length == 0 ||
          pk.files.filter(f => f.type === 'address proof').length == 0
      ).length > 0;
    return (
      <div
        className={
          this.state.displayNav
            ? 'main-container dashboard nav-is-opened'
            : 'main-container dashboard'
        }
      >
        {DocumentTitle('app.title-parking', 'Mes parkings')}
        <header className="header">
          <div className="header-left">
            <h1 className="logo-main">
              <Link
                to={
                  localStorage.getItem('token')
                    ? ROUTES.DASHBOARD_PARKINGS
                    : ROUTES.LOGIN
                }
              >
                <img src="/img/logo.svg" alt="Logo Wattpark"/>
              </Link>
            </h1>
            <nav className="nav-main">
              <Link to="#" className="nav-main-item current">
                <FormattedMessage id="app.navDsh" defaultMessage="Accueil"/>
              </Link>
              <Link to={ROUTES.COMPTE} className="nav-main-item">
                <FormattedMessage id="app.navcpt" defaultMessage="Compte"/>
              </Link>
              {localStorage.getItem('usertype') === 'admin' ? (
                <>
                  <Link
                    to={ROUTES.CERTIFICATIONVALIDATION}
                    className="nav-main-item"
                  >
                    <FormattedMessage
                      id="app.navcertif"
                      defaultMessage="Certificats"
                    />
                  </Link>
                  <Link to={ROUTES.BILLING} className="nav-main-item">
                    <FormattedMessage
                      id="app.navbilling"
                      defaultMessage="Billing"
                    />
                  </Link>
                </>
              ) : (
                ''
              )}
              <div className="nav-main-user">
                <i className="icon-user"/>
                <div className="nav-main-user-name">
                  {localStorage.getItem('username')}
                </div>
                <Link
                  to=""
                  className="nav-main-user-connexion"
                  onClick={this.logout}
                >
                  <FormattedMessage
                    id="app.navLogout"
                    defaultMessage="Déconnexion"
                  />
                </Link>
              </div>
            </nav>
          </div>
          <div className="header-right">
            <div className="nav-trigger">
              <span className="nav-trigger-line"/>
              <span className="nav-trigger-line"/>
              <span className="nav-trigger-line"/>
            </div>
            <div className="header-user">
              <i className="icon-user"/>
              <div className="header-user-info">
                <div className="header-user-name">
                  {localStorage.getItem('username')}
                </div>
                <Link
                  to=""
                  className="header-user-connexion"
                  onClick={this.logout}
                >
                  <FormattedMessage
                    id="app.navLogout"
                    defaultMessage="Déconnexion"
                  />
                </Link>
              </div>
            </div>
          </div>
        </header>
        <div className="screen-filter"/>
        <div className="container">
          {
            !loading ? <Spinner/> :
              <>
                <Menu/>
                <div className="alerts-wrapper">
                  {process.env.REACT_APP_STAGE !== 'production' && (
                    <React.Fragment>
                      {this.state.alert ? (
                        <Alert
                          url={`${ROUTES.COMPTE}?tab=bank`}
                          nameAlert={this.state.nameAlert}
                          type={this.state.typeAlert}
                          style={true}
                          classBtnName="btn btn-third btn-small"
                          message={this.state.message}
                          btnName={
                            <FormattedMessage
                              id="app.btn-Indicate"
                              defaultMessage="Indiquer"
                            />
                          }
                        />
                      ) : null}
                      {this.state.alert4 ? (
                        <Alert
                          url={ROUTES.COMPTE}
                          nameAlert={this.state.nameAlert4}
                          type={this.state.typeAlert4}
                          style={true}
                          classBtnName="btn btn-third btn-small"
                          message={this.state.message4}
                          btnName={
                            <FormattedMessage
                              id="app.btn-transmit"
                              defaultMessage="Transmettre"
                            />
                          }
                        />
                      ) : null}
                      {this.state.alert2 && shouldDisplayAlertMissingIrve ? (
                        <Alert
                          url={`/parking/details/${this.state.parkingid}`}
                          nameAlert={this.state.nameAlert2}
                          type={this.state.typeAlert2}
                          style={true}
                          classBtnName="btn btn-secondary btn-small"
                          message={this.state.message2}
                          btnName={
                            <FormattedMessage
                              id="app.btn-demande-irve"
                              defaultMessage="Demander"
                            />
                          }
                        />
                      ) : null}
                      {this.state.alert3 && shouldDisplayAlertMissingAddr ? (
                        <Alert
                          url={`/parking/details/${this.state.parkingid}`}
                          nameAlert={this.state.nameAlert3}
                          type={this.state.typeAlert3}
                          style={false}
                          classBtnName="btn btn-secondary btn-small"
                          message={this.state.message3}
                          btnName={
                            <FormattedMessage
                              id="app.btn-transmit"
                              defaultMessage="Transmettre"
                            />
                          }
                        />
                      ) : null}
                    </React.Fragment>
                  )}
                </div>
                <div className="row">
                  <div className="col-xs-12 col-sm-8">
                    <Main
                      libre={this.state.parkingLib}
                      charts={this.charts}
                      occupe={this.state.parkingOccup}
                      enAttente={this.state.parkingF}
                      TauxActuel={this.state.TauxActuel}
                    />
                  </div>
                  <div className="col-xs-12 col-sm-4">
                    <Balance
                      solde={parkingSolde.total}
                      pourcent={parkingSolde.pourcent}
                    />
                  </div>

                  <div className="col-xs-12">
                    {!parkings.type ? '' : <Activation/>}
                  </div>
                  <div className="col-xs-12 col-sm-8">
                    <ListParking
                      title={
                        <FormattedMessage
                          id="app.my-car-parks"
                          defaultMessage="Mes parkings"
                        />
                      }
                      parkingfree={this.props.parkingfree}
                      parkingoccupy={this.props.parkingoccupy}
                      parkinginfo={this.props.parkinginfo}
                      parking={this.state.parkings}
                      method={this.onSortParking}
                    />
                    {this.state.associatedParking &&
                    this.state.associatedParking.length > 0 ? (
                      <ListParking
                        title={
                          <FormattedMessage
                            id="app.my-associated-parks"
                            defaultMessage="Parkings asscociés"
                          />
                        }
                        parkingfree={this.props.parkingfree}
                        parkingoccupy={this.props.parkingoccupy}
                        parkinginfo={this.props.parkinginfo}
                        parking={this.state.associatedParking}
                        method={this.onSortAssociatedParking}
                      />
                    ) : null}
                  </div>

                  {!parkings.type ? (
                    <div className="col-xs-12 col-sm-4">
                      <div className="dashboard-card dashboard-part dashboard-visible">
                        <div className="dashboard-card-title">
                          <FormattedMessage
                            id="app.average-rental-time"
                            defaultMessage="Temps moyen de location"
                          />
                        </div>
                        <div className="dashboard-card-subtitle">
                          <FormattedMessage
                            id="app.average-time-per-rental-on-the-current-month"
                            defaultMessage="Temps moyen par location sur le mois en cours"
                          />
                        </div>
                        <div className="amount">
                          {h ? h : 0}
                          <span className="amount-currency"> h</span> {m ? m : 0}
                          <span className="amount-currency"> m</span>
                        </div>
                        {pourcent()}
                      </div>
                    </div>
                  ) : (
                    ''
                  )}
                  {!parkings.type ? (
                    <div className="col-xs-12 col-sm-4">
                      <Reportings/>
                    </div>
                  ) : (
                    ''
                  )}
                </div>
              </>
          }
        </div>
        <Footer
          disabled={
            this.state.userLicence
              ? this.state.userLicence.remainingParking === 0
              : false
          }
        />
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    parking: state.parkings.parkings,
    loading: state.parkings.loading,
    associatedParking: state.parkings.associatedParking,
    parkingSolde: state.parkings.parkingSolde,
    getUser: state.users.getUser,
    parkingfree: state.parkingCount.parkingfree,
    parkingoccupy: state.parkingCount.parkingoccupy,
    parkinginfo: state.parkingCount.parkinginfo,
    userLicence: state.userLicence.userLicence
  };
}

export default connect(
  mapStateToProps,
  {
    fetchParkingsList,
    fetchGetParkingSolde,
    fetchGetUser,
    fetchInitialState,
    fetchGetParkingStatistics,
    fetchGetUserLicence
  }
)(Dashboard);
